import { createBrowserHistory } from 'history';

const history = createBrowserHistory({
  forceRefresh: true,
});

export const redirect = (route, state = {}) => {
  if (route.includes('http')) {
    window.location = route;
  } else {
    history.push(route, state);
  }
};

export default redirect;
