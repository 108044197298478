import React, { FormEvent, useEffect, useState } from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import axios from 'axios'
import TagManager from 'react-gtm-module'
import useGlobalNotifications, { Message } from '@peachjar/ui/dist/lib/hooks/useGlobalNotifications'
import { APP_ROUTES } from '../App/config'
import UserSignUp from './UserSignUp'
import { connect } from 'react-redux'
import { ActionResults, RootState } from '../App/Store/makeStore'
import AccountsBffClientHttp from '../App/Dependencies/AccountsBffClientHttp'
import emailIsValidish from '../Utility/formUtils'
import { Dispatch } from 'redux'
import { get } from 'lodash'
import { ErrorCodes } from '../OrgSignUp/ErrorCodes'
import {
  CHARACTER_LENGTH_ERROR,
  INVALID_EMAIL_ERROR,
  MISSED_FIELD_ERROR,
} from '../OrgSignUp/OrgSignUp.constants'
import { setEmail as setUserEmailAction, submitEmail } from '../LogIn/Redux/LogInActions'
import { OrganizationInput } from '../OrgSignUp/OrgSignUp.container'
import { clearOrgInfo as clearOrganization } from '../OrgSignUp/Redux/OrgSignUpActions'

export type Field = {
  id: string
  label: string
  value: any
  error: boolean
  errorText: string
  limit: number | null
  inputType: string
}

export type UserInput = {
  firstName: string
  lastName: string
  email: string
}

type Props = RouteComponentProps & {
  requestMagicLink: () => Promise<{ success: boolean }>,
  orgInfo: OrganizationInput
  clearOrgInfo: () => void
  setUserEmail: (email: string) => void
}

const FIELD_LIMIT = 50

const defaultFieldState = (key: string, label: string): Field => ({
  id: `${Date.now()}-${key}`,
  label,
  value: '',
  error: false,
  errorText: MISSED_FIELD_ERROR,
  limit: null,
  inputType: 'text',
})

const httpClient = new AccountsBffClientHttp(axios)

const UserSignUpContainer: React.FunctionComponent<Props> = ({
  history,
  orgInfo,
  clearOrgInfo,
  requestMagicLink,
  setUserEmail
}) => {

  const [firstName, setFirstName] = useState<Field>({
    ...defaultFieldState('firstName', 'First Name'),
    limit: FIELD_LIMIT,
  })
  const [lastName, setLastName] = useState<Field>({
    ...defaultFieldState('lastName', 'Last Name'),
    limit: FIELD_LIMIT,
  })

  const [email, setEmail] = useState<Field>({
    ...defaultFieldState('email', 'Email Address'),
    inputType: 'email',
  })
  const [userAlreadyExists, setUserAlreadyExists] = useState<boolean>(false)
  const [formValid, setFormValid] = useState<boolean>(false)
  const { notifyError } = useGlobalNotifications()

  const goBackUrl = APP_ROUTES.orgSignUp

  const checkForm = () => {
    const isEmailValid = email.value !== '' && emailIsValidish(email.value)
    const fieldsValid = [
      firstName,
      lastName,
      email,
    ].every(({ value, error }) => {
      if (typeof value === 'object' && typeof value !== null) {
        return value.description.length > 0 && !error
      }

      return value.length > 0 && !error
    })

    setFormValid(isEmailValid && fieldsValid)
  }

  const showError = (msg?: Message) => {
    const message = msg || 'Oops. Something went wrong. Please try again.'

    notifyError({
      persist: false,
      message,
    })
  }

  const getMagicLink = async () => {
    try {
      const { success: magicLinkRequestSuccessful } = await requestMagicLink()

      if (magicLinkRequestSuccessful) {
        history.push(APP_ROUTES.magicLinkSuccess)
        return
      }
      showError()
    } catch (e) {
      showError()
    }
  }

  const handleSubmit = async (event: FormEvent<HTMLElement>) => {
    event.preventDefault()

    if (!formValid) {
      const fields = [
        { field: firstName, setState: setFirstName },
        { field: lastName, setState: setLastName },
        { field: email, setState: setEmail },
      ]

      fields.forEach(({ field, setState }) => {
        const fieldValid = !!field.value
        setState({ ...field, error: !fieldValid })
      })

      return
    }

    try {
      // prep data for submission
      const orgInput: OrganizationInput = {
        ...orgInfo,
        firstName: firstName.value,
        lastName: lastName.value,
        email: email.value,
        userEmail: email.value
      }
      const response = await httpClient.attemptNewOrgRegistration(orgInput)

      const isSuccessful = get(
        response,
        'data.registerOrganization.success',
        false
      )

      if (isSuccessful) {
        TagManager.dataLayer({
          dataLayer: {
            event: 'signup',
            accountType: 'organization'
          }
        })
        clearOrgInfo()
        history.push(APP_ROUTES.magicLinkSuccess)
        // getMagicLink()
        return
      }

      const code = response.data.registerOrganization.errorCode

      const alreadyExistsCodes: ErrorCodes[] = [
        ErrorCodes.EmailAlreadyUsedForOrg,
        ErrorCodes.UserAlreadyAssignedToOrg,
      ]

      if (code && alreadyExistsCodes.includes(code)) {
        setEmail({
          ...email,
          error: true,
          errorText: ''
        })
        setUserAlreadyExists(true)
      }

    } catch (e) {
      showError()
    }
  }

  const handleFieldChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    id: string
  ) => {
    const value = event.currentTarget.value
    const label = id.split('-')[1]
    const hasInput = !!value.length
    const inputExceedsLimit = value.length > FIELD_LIMIT

    switch (label) {
      case 'firstName':
        setFirstName({
          ...firstName,
          value,
          error: !hasInput || inputExceedsLimit,
          errorText:
            value.length > FIELD_LIMIT
              ? CHARACTER_LENGTH_ERROR(FIELD_LIMIT)
              : MISSED_FIELD_ERROR,
        })
        break
      case 'lastName':
        setLastName({
          ...lastName,
          value,
          error: !hasInput || inputExceedsLimit,
          errorText:
            value.length > FIELD_LIMIT
              ? CHARACTER_LENGTH_ERROR(FIELD_LIMIT)
              : MISSED_FIELD_ERROR,
        })
        break
      case 'email':
        setEmail({
          ...email,
          value,
          error: !hasInput || !emailIsValidish(value),
          errorText: !hasInput ? MISSED_FIELD_ERROR : INVALID_EMAIL_ERROR,
        })

        if (emailIsValidish(value)) {
          setUserEmail(value)
          return
        }
        if (userAlreadyExists) {
          setUserAlreadyExists(false)
        }
        setUserEmail('')
        break
      default:
      // do nothing
    }
  }

  const mergedProps = {
    fields: [
      firstName,
      lastName,
      email,
    ],
    userAlreadyExists,
    formValid,
    goBackUrl,
    handleFieldChange,
    handleSubmit,
  }

  useEffect(() => {
    checkForm()
  }, [firstName, lastName, email])

  useEffect(() => {
    const orgIsEmpty = Object.keys(orgInfo).some((key) => orgInfo[key] === '')
    if (orgIsEmpty) {
      history.push(APP_ROUTES.orgSignUp)
    }
  }, [])

  return <UserSignUp {...mergedProps} />
}

export default connect(
  (state: RootState) => ({
    orgInfo: state.orgs.info,
  }),
  (dispatch: Dispatch<ActionResults>) => ({
    setUserEmail: (email: string) => dispatch(setUserEmailAction(email)),
    requestMagicLink: () => dispatch<any>(submitEmail()),
    clearOrgInfo: () => dispatch<any>(clearOrganization()),
  })
)(withRouter(UserSignUpContainer))
