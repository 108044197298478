import React from 'react'
import { Link } from 'react-router-dom'
import {
  createMuiTheme,
  MuiThemeProvider,
} from '@material-ui/core/styles'
import Tooltip from '@material-ui/core/Tooltip'
import IconButton from '@material-ui/core/IconButton'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline'
import { css, cx } from 'emotion'
import colors from '@peachjar/ui/dist/lib/styles/colors'
import AutocompleteInput from '@peachjar/ui/dist/lib/components/Inputs/AutocompleteInput'
import { ButtonPrimaryLarge } from '@peachjar/ui/dist/lib/components/Buttons/Buttons'
import { District, School } from './StaffSignUp'
import { get, has } from 'lodash'
import APP_ROUTES from '../App/config'

type StaffSignUpFormProps = {
  formValue: District | School | null
  isFormValid: boolean
  error: string | null
  handleSubmit: (event: React.FormEvent<HTMLElement>) => void
  lookUpSchools: (options: any, callback: (results: any[]) => void) => void
  onDropDownChange: (selectedItem: District | School) => void
}
const TOOLTIP_TEXT = `Only schools that are using Peachjar are available for selection.</br></br>
  Contact your district office if you would like to sign up as a district user.`

const renderDistrictOrSchool: React.FunctionComponent<
  District | School
> = entity => {
  if (entity.section.toLowerCase() === 'schools') {
    // tslint:disable-next-line:no-shadowed-variable
    const { schoolName, districtName, city, state } = entity as School

    const cityAndStateString =
      !!city && !!state
        ? `${districtName} | ${city}, ${state}`
        : `${districtName}`

    return (
      <div>
        {schoolName}
        <span className={styles.secondaryName}>{cityAndStateString}</span>
      </div>
    )
  }

  const { districtName, city, state } = entity as District
  const districtCityAndStateString =
    !!city && !!state ? `${city}, ${state}` : ''

  return (
    <div>
      {districtName}
      <span className={styles.secondaryName}>{districtCityAndStateString}</span>
    </div>
  )
}

function bothHaveProp(path: string, thing1: Record<string, any>, thing2: Record<string, any>): boolean {
  return has(thing1, path) && has(thing2, path)
}

function propIsEqual(path: string, thing1: Record<string, any>, thing2: Record<string, any>): boolean {
  return  get(thing1, path) === get(thing2, path)
}

const StaffSignUpForm: React.FunctionComponent<StaffSignUpFormProps> = ({
  formValue,
  error,
  isFormValid,
  handleSubmit,
  lookUpSchools,
  onDropDownChange,
}) => (
  <MuiThemeProvider theme={toolTipTheme}>
    <div className={styles.pageContainer}>
      <form onSubmit={handleSubmit}>
        <div className={cx(styles.inputWithToolTip, styles.inlined)}>
          <AutocompleteInput<District | School>
            lookup={lookUpSchools}
            label='School'
            value={formValue}
            error={error}
            onChange={val => {
              if (formValue || val) {
                onDropDownChange(val)
              }
            }}
            isEqual={(t1, t2) => {
              if (!t1 || !t2) {
                return false
              }
              if (bothHaveProp('schoolId', t1, t2)) {
                return propIsEqual('schoolId', t1, t2)
              }
              return bothHaveProp('districtId', t1, t2) && propIsEqual('districtId', t1, t2)
            }}
            groupBy={result => result.section}
            renderOption={val => renderDistrictOrSchool(val as District | School)}
          />
        </div>
        <span className={styles.tooltip}>
          <span className={cx(styles.tooltip, styles.tooltipButton)}>
            <Tooltip
              title={<div dangerouslySetInnerHTML={{ __html: TOOLTIP_TEXT }}></div>}
              enterTouchDelay={0}
              placement='right'>
              <IconButton arial-label={TOOLTIP_TEXT}>
                <HelpOutlineIcon fontSize='small' />
              </IconButton>
            </Tooltip>
          </span>
        </span>
        <div className={styles.ctas} onClick={event => handleSubmit(event)}>
          <ButtonPrimaryLarge
            disabled={!isFormValid}
          >
            Continue
          </ButtonPrimaryLarge>
          <Link to={APP_ROUTES.staffSignUpNew}>Can't find your school?</Link>
        </div>
      </form>
    </div>
  </MuiThemeProvider>
)

const styles = {
  pageContainer: css`
    margin-top: 40px;
    position: relative;
  `,
  secondaryName: css`
    font-size: 13px;
    font-family: 'Proxima Nova', Helvetica, Arial, sans-serif;
    color: ${colors.slate};
    display: block;
  `,
  ctas: css`
    margin-top: 40px;

    & > a {
      color: ${colors.jungle};
      text-decoration: underline;
      font-family: 'Proxima Nova', Helvetica, Arial, sans-serif;
      font-size: 13px;
      display: block;
      margin-top: 24px;
      width: 150px;
    }

    & > a:hover {
      color: #c5e8c9;
    }
  `,
  inputWithToolTip: css`
    width: 90% !important;
  `,
  inlined: css`
    display: inline-block;
  `,
  tooltip: css`
    position: absolute;
    top: 3px;
 `,
  tooltipButton: css`
   & .MuiIconButton-root {
     color: ${colors.prussian}
   }
   & .MuiIconButton-root:hover {
     background-color: rgba(97, 193, 108, 0.15);
  }

 `,
}

const toolTipTheme = createMuiTheme({
  overrides: {
    MuiTooltip: {
      tooltip: {
        backgroundColor: '#2d344b',
        borderRadius: '2px',
        fontSize: '13px',
        padding: '8px',
      },
    },
  },
})

export default StaffSignUpForm
