import React, { FormEvent } from 'react'
import { css, cx } from 'emotion'
import colors from '@peachjar/ui/dist/lib/styles/colors'
import TextInput from '@peachjar/ui/dist/lib/components/Inputs/TextInput'
import RadioGroupInput from '@peachjar/ui/dist/lib/components/Inputs/RadioGroupInput'
import AddressInput from '@peachjar/ui/dist/lib/components/Inputs/AddressInput'
import { FormProps } from './StaffSignUpExisting'
import FormFooter from '../App/components/FormFooter'
import { ADDRESS_INPUT_TYPE } from './StaffSignUp.constants'

const TextField: any = TextInput

const StaffSignUpExistingForm: React.FunctionComponent<FormProps> = ({
  fields,
  formValid,
  staffOptions,
  selectedStaffType,
  handleTeamMemberChange,
  handleFieldChange,
  handleSubmit,
}) => {
  return (
    <div className={styles.formContainer}>
      <form onSubmit={(e: FormEvent<HTMLElement>) => handleSubmit(e)}>
        {fields.map(
          (
            { id, label, value, error, errorText, inputType, textLimit },
            index
          ) => (
            <div className={styles.formField} key={index}>
              <TextField
                autoFocus={index === 0}
                id={id}
                name={id}
                label={label}
                type={inputType}
                value={value}
                error={error}
                errorText={errorText}
                textLimit={textLimit}
                onChange={e => handleFieldChange(e, id)}
              />
              {label.toLowerCase().replace(' ', '') === 'emailaddress' && (
                <p className={styles.emailDisclaimer}>
                  Use your school domain email address if you have one for this
                  school.
                </p>
              )}
            </div>
          )
        )}

        <div className={cx(styles.staffSelection, 'accounts-radio-group')}>
          <RadioGroupInput
            label='What type of team member are you at this school?'
            choices={staffOptions}
            keyMapper={c => c}
            value={selectedStaffType}
            onChange={v => handleTeamMemberChange(v)}
          />
        </div>

        <FormFooter
          isFormValid={formValid}
          handleSubmit={handleSubmit}
          primaryBtnText='Submit'
          renderLoginOption={false}
        />
      </form>
    </div>
  )
}

const styles = {
  formContainer: css`
    margin: 24px 0;
  `,
  formField: css`
    margin: 24px 0;
  `,
  emailDisclaimer: css`
    font-family: 'Proxima Nova', Helvetica, Arial, sans-serif;
    color: ${colors.slate};
    font-size: 13px;
    margin-top: 4px;
  `,
  staffSelection: css`
    margin-top: 15px;
  `,
}

export default StaffSignUpExistingForm
