import React from 'react'
import TwoTone from '../App/Layout/TwoTone'
import FlexInset from '../App/Layout/FlexInset'
import IllustratedQuote from '../App/Layout/IllustratedQuote'
import HouseIllustration from '../App/components/HouseIllustrationLarge'
import ParentSignUpForm from './ParentSignUpForm'
import { FormSection, EmailState, Grade } from './ParentSignUp.container'

type Props = {
  email: EmailState
  goBackUrl: string
  formSections: FormSection[]
  isFormValid: boolean
  lookUpSchools: (options: any, callback: any) => void
  onSchoolChange: (value: any, sectionId: string, fieldId: string) => void
  onGradeChange: (input: Grade, sectionId: string, fieldId: string) => void
  onEmailChange: (event: Event) => void
  handleSubmit: (event: Event) => void
  onAddChild: () => void
  onRemoveChild: (sectionId: string) => void
  history: any
}

const ParentSignUp: React.FunctionComponent<Props> = ({
  email,
  goBackUrl,
  formSections,
  isFormValid,
  handleSubmit,
  onGradeChange,
  onEmailChange,
  lookUpSchools,
  onSchoolChange,
  onAddChild,
  onRemoveChild,
  history,
}) => (
  <TwoTone goBackUrl={goBackUrl}>
    <FlexInset>
      <ParentSignUpForm
        email={email}
        formSections={formSections}
        isFormValid={isFormValid}
        lookUpSchools={lookUpSchools}
        handleSubmit={handleSubmit}
        onGradeChange={onGradeChange}
        onEmailChange={onEmailChange}
        onSchoolChange={onSchoolChange}
        onAddChild={onAddChild}
        onRemoveChild={onRemoveChild}
        history={history}
      />
    </FlexInset>
    <IllustratedQuote svg={<HouseIllustration />} />
  </TwoTone>
)

export default ParentSignUp
