import React, { FunctionComponent, useState } from 'react'
import { Dispatch } from 'redux'
import { css } from 'emotion'
import useGlobalNotifications from '@peachjar/ui/dist/lib/hooks/useGlobalNotifications'
import { RouteComponentProps } from 'react-router-dom'
import { connect } from 'react-redux'
import TwoTone from '../App/Layout/TwoTone'
import IllustratedQuote from '../App/Layout/IllustratedQuote'
import MagicEnvelope from './MagicEnvelope'
import MagicLinkSuccess from './MagicLinkSuccess'
import FlexInset from '../App/Layout/FlexInset'
import { submitEmail, resetMagicLinkState } from '../LogIn/Redux/LogInActions'
import { APP_ROUTES } from '../App/config'
import { RootState, ActionResults } from '../App/Store/makeStore'

type Props = RouteComponentProps & {
    email: string
    resendMagicLink: () => Promise<any>
    clearMagicLinkState: () => Promise<any>
}

const MagicLinkSuccessContainer: FunctionComponent<Props> = ({
    email,
    history,
    resendMagicLink,
    clearMagicLinkState,
}) => {
    const {
        notifySuccess,
        notifyError,
        close: closeNotification,
    } = useGlobalNotifications()

    const goBackUrl = APP_ROUTES.login

    const updateEmail = () => {
        // @ts-ignore
        history.push(goBackUrl)
    }

    const resendLink = async () => {
        const { success } = await resendMagicLink()

        if (success) {
            notifySuccess({
                message: 'Your email is on its way.',
            })
            // reset magicLink state after each successful notification
            clearMagicLinkState()
            return
        }

        notifyError({
            persist: false,
            message:
                'Unfortunately, something went wrong and we were unable to send the email. Please try again.',
        })
    }

    if (!email) {
        history.push(APP_ROUTES.login)
    }

    return (
        <TwoTone goBackUrl={goBackUrl}>
            <FlexInset>
                <MagicLinkSuccess
                    email={email}
                    resendMagicLinkHandler={resendLink}
                    updateEmailHandler={updateEmail}
                />
            </FlexInset>
            <IllustratedQuote svg={<MagicEnvelope />} />
        </TwoTone>
    )
}

export default connect(
    (state: RootState) => ({
        email: state.logIn.email,
    }),
    (dispatch: Dispatch<ActionResults>) => ({
        // @ts-ignore
        resendMagicLink: () => dispatch(submitEmail()),
        clearMagicLinkState: () => dispatch<any>(resetMagicLinkState()),
    })
)(MagicLinkSuccessContainer)
