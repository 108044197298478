import React, { ReactNode, FunctionComponent } from 'react'
import { css } from 'emotion'
const { colors } = require('@peachjar/components')

type Props = {
    children: ReactNode
}

const classNames = {
    heading: css`
      width: 100%;
      max-width: 420px;
      font-family: Proxima Nova, Helvetica, Arial, sans-serif;
      font-size: 34px;
      font-weight: 600;
      line-height: 1.29;
      color: ${colors.prussian};
    `,

}

const PrimaryHeading: FunctionComponent<Props> = ({children}) => (
    <h1 className={classNames.heading}>{children}</h1>
)

export default PrimaryHeading
