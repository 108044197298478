import {
  STAFF_JOIN_SCHOOL_OR_DISTRICT,
  StaffActionResults,
} from './StaffActions'

export type AcademicEntity = {
  id: number | null
  name: string
  description: string
}

export type StaffState = {
  schoolSelection: AcademicEntity
}

const initialState: StaffState = {
  schoolSelection: {
    id: null,
    name: '',
    description: '',
  },
}

export function staff(
  state: StaffState = initialState,
  { type, payload }: StaffActionResults
) {
  switch (type) {
    case STAFF_JOIN_SCHOOL_OR_DISTRICT:
      return {
        ...state,
        schoolSelection: {
          ...payload,
        },
      }
  }

  return state
}
