import React, { FormEvent } from 'react'
import { css } from 'emotion'
import TwoTone from '../App/Layout/TwoTone'
import FlexInset from '../App/Layout/FlexInset'
import UserSignUpForm from './UserSignUpForm'
import IllustratedQuote from '../App/Layout/IllustratedQuote'
import TreesIllustration from '../App/components/TreesIllustrationLarge'
import PrimaryHeading from '../App/Typography/PimaryHeading'
import { Field } from './UserSignUp.container'

export type UserFormProps = {
  fields: Field[]
  formValid: boolean
  userAlreadyExists: boolean
  handleFieldChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    id: string
  ) => void
  handleSubmit: (e: FormEvent<HTMLElement>) => void
}

type Props = UserFormProps & {
  goBackUrl: string
}

const UserSignUp: React.FunctionComponent<Props> = ({ goBackUrl, ...rest }) => {
  return (
    // @ts-ignore
    <TwoTone goBackUrl={goBackUrl}>
      <FlexInset>
        <PrimaryHeading>
          Next, let's create your account.
        </PrimaryHeading>
        <div className={styles.pageContainer}>
          <UserSignUpForm {...rest} />
        </div>
      </FlexInset>
      <IllustratedQuote svg={<TreesIllustration />} />
    </TwoTone>
  )
}

const styles = {
  pageContainer: css`
    margin-top: 40px;
  `,
}

export default UserSignUp
