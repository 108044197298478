import React from 'react'
import colors from '@peachjar/ui/dist/lib/styles/colors'
import { css } from 'emotion'

const UserAlreadyExistsError = () => (
  <span className={styles.error} id='duplicateError' role='alert'>
    An account with that email address already exists.{' '}
    <a href='/login'>Try logging in.</a>
  </span>
)

const styles = {
  error: css`
    color: #AC0B1F;
    & > a {
      color: ${colors.jungle} !important;
      text-decoration: underline;
      cursor: pointer;
      &:hover {
        color: #c5e8c9 !important;
      }
    }
  `,
}

export default UserAlreadyExistsError
