import React, { ReactNode, FunctionComponent } from 'react'
import { css } from 'emotion'

type Props = {
    children: ReactNode
    maxWidth?: string
}

const FlexInset: FunctionComponent<Props> = ({ children, maxWidth }) => {
    const classNames = {
        wrapper: css`
            display: flex;
            height: 100%;
            justify-content: center;
            align-items: center;
            @media screen and (min-width: 1025px) {
                align-items: center;
            }
        `,
        inner: css`
            margin: 72px 24px;
            max-width: ${maxWidth || '440px'};
            width: 100%;

            @media screen and (min-width: 1025px) {
                margin: 64px;
            }
        `,
    }

    return (
        <div className={classNames.wrapper}>
            <div className={classNames.inner}>{children}</div>
        </div>
    )
}

export default FlexInset
