import React, { ReactNode, FunctionComponent } from 'react'
import { css } from 'emotion'
const { elements } = require('@peachjar/components')
const { Quote } = elements

const classNames = {
  container: css`
    text-align: center;
    padding: 20px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    height: 100%;
    align-items: center;
  `,
  alignCenter: css`
    display: flex;
    justify-content: center;
    height: 100%;
    align-items: center;
  `,
  svgWrapper: css`
    margin: 0px auto;
    max-width: 900px;
    padding: 50px;
    padding-bottom: 0px;
  `,
}

interface Props {
  svg: ReactNode
  quote?: {
    content: string
    author: string
    authorTitle: string
  } | null
}

const IllustratedQuote: FunctionComponent<Props> = ({ svg, quote }) => (
    <div className={classNames.container}>
      <div className={classNames.svgWrapper}>{svg}</div>
      {quote && <Quote {...quote} />}
    </div>
)

export default IllustratedQuote
