import React, { useState, useEffect } from 'react'
import SubscriptionContext, {
  SubscriptionsByDistrict
} from './SubscriptionContext'
import { sortBy } from 'lodash'
import axios from 'axios'
import PortalBffClientHttp, {
  Subscription
} from '../Dependencies/PortalBffClientHttp'
import { checkGqlError } from '../../Utility/auth'

type Props = {
  children: {
    [k: string]: any;
  };
}
const client = new PortalBffClientHttp(axios)

const SubscriptionProvider = ({ children }: Props) => {
  const [loading, setLoading] = useState(true)
  const [subscriptions, setSubscriptions] = useState<SubscriptionsByDistrict[]>([])

  const getSubscriptions = async () => {
    try {
      const { data: { success, subscriptions: userSubscriptions } } = await client.getSubscriptions()
      if (success && userSubscriptions) {
        const subscriptionsList: Subscription[] = []
        const activeSubscriptions = userSubscriptions.filter(({ status }) => status === 'active')
        for (const subscription of activeSubscriptions) {
          const schoolId = Number(subscription.audienceId)
          const { data } = await client.getSchoolBySchoolId(schoolId)
          if (data.success) {
            subscriptionsList.push({
              ...subscription,
              name: data.name
            })
          }
        }
        const districtIds = [...new Set(subscriptionsList.map(subscription => Number(subscription.hierarchyId)))]
        const districts: SubscriptionsByDistrict[] = []
        for (const districtId of districtIds) {
          const {
            data: {
              success: successReq,
              name
            }
          } = await client.getDistrictNameById(districtId)
          if (successReq) {
            const schools = sortBy(
              subscriptionsList.filter(subscription => Number(subscription.hierarchyId) === districtId),
              subscription => subscription.name
            )

            districts.push({
              districtName: name,
              districtId,
              schools
            })
          }
        }
        const subscriptionsGrouped = sortBy(districts, district => district.districtName)
        setSubscriptions(subscriptionsGrouped)
      }

    } catch (e) {
      checkGqlError(e)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    getSubscriptions()
  }, [])

  const contextState = {
    loading,
    subscriptions,
    getSubscriptions
  }

  return (
    <SubscriptionContext.Provider value={contextState}>
      {children}
    </SubscriptionContext.Provider>
  )
}

export default SubscriptionProvider
