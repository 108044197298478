import { AxiosInstance } from 'axios'
import { AddressAndPlaceId } from '@peachjar/ui/dist/lib/components/Inputs/AddressInput'
import AccountsBffClient, {
  LoginAttemptResponse,
  MagicLinkResponse,
  SearchForMySchoolsResponse,
  VerifyEmailResponse,
  CompleteMyProfileResponse,
  ParentRegistrationResponse,
  NewOrgRegistrationResponse,
  ChangeSubscriptionResponse,
  UpdateChildEnrollmentResponse,
  GenericSuccessResponse,
  GetMyProfileResponse,
  ChangeNotificationPreferencesResponse,
  UpdateChildrenNewModelResponse,
  ManageDistrictAccountResponse,
  UpdateProfileResponse,
  GetNotificationPreferencesResponse,
  LinkedAccountsResponse,
  LoginStatusResponse
} from './AccountsBffClient'
import { get } from 'lodash'
import {
  REQUEST_MAGIC_LINK_MUTATION,
  COMPLETE_MY_PROFILE,
  SEARCH_FOR_MY_SCHOOL,
  VERIFY_EMAIL,
  SUBMIT_PARENT_REGISTRATION,
  REGISTER_ORGANIZATION,
  SUBMIT_ACADEMIC_LEAD,
  SUBMIT_ACADEMIC_MEMBERSHIP_REQUEST,
  CHANGE_PARENT_SUBSCRIPTIONS,
  UPDATE_CHILDREN_ENROLLMENTS,
  GET_MY_PROFILE,
  UPDATE_PROFILE,
  LOGIN_STATUS,
  CHANGE_NOTIFICATION_PREFERENCES,
  MANAGE_DISTRICT_ACCOUNT,
  UPDATE_CHILDREN_NEW_MODEL,
  GET_NOTIFICATION_PREFERENCES,
  GET_LINKED_ACCOUNTS,
  DISMISS_PENDING_NOTIFICATION,
  VERIFY_CHANGE_EMAIL
} from './accounts.graphql'
import apolloBffClient from '../Dependencies/ApolloBffClient'
import { OrganizationInput } from '../../OrgSignUp/OrgSignUp.container'
import { Enrollment } from '../../ParentSignUp/ParentSignUp.container'
import { AcademicMembership } from '../../StaffSignUp/StaffSignUpExisting.container'
import {
  EnrollmentInput,
  ChangeEnrollment,
} from '../../AccountInformation/Parent.types'
import { ErrorCodes as OrgErrorCodes } from '../../OrgSignUp/ErrorCodes'
import { ErrorCodes as ParentErrorCodes } from '../../ParentSignUp/ErrorCodes'
import { ChildNewModel } from '../../App/hooks/useProfile'

type AcademicLead = {
  fullName: string
  phone: string
  email: string
  academicGroupName: string
  address: AddressAndPlaceId
}

export type UpdateChildEnrollmentInput = {
  familyId: number
  add: EnrollmentInput[]
  change: ChangeEnrollment[]
  remove: number[]
}

export type UpdateProfileInput = {
  firstName?: string
  lastName?: string
  email?: string
}

export type VerifyChangeEmailInput = {
  userId: number
  email: string
  hierarchyType?: string
}

export type VerifyChangeEmailResult = {
  data?: {
    verifyChangeEmail: {
      email: string
      status: string
    }
  }
  errors?: Array<{ message: string }>
}

export type EnrollmentNewModelInput = {
  institutionType: string
  institutionId: string
  institutionName: string
  hierarchyType: string
  hierarchyId: string
  hierarchyName: string
  gradeLevel: string | null
  schoolYear: string
  schoolType: string
  childUid: string
  community: string | null
  postalCode: string
  status: string
}

export type UpdateChildrenNewModelInput = {
  guardianId?: number
  add?: EnrollmentNewModelInput[]
  change?: EnrollmentNewModelInput[]
  remove?: EnrollmentNewModelInput[]
}

export type ChangeNotficationPreferencesInput = {
  notifType: string
  notifTypeId: string
  notifStatus: string
  refId: string
}

export type GetNotficationPreferencesInput = {
  notifType: string
  notifTypeId: string
  refId: string
}

export type LoginStatus = {
  loggedIn: boolean
  redirectTo?: string
  email?: string
  accountType?: string
}

export default class AccountsBffClientHttp implements AccountsBffClient {
  constructor(protected readonly http: AxiosInstance) {}

  async getLoginStatus(): Promise<LoginStatusResponse> {
    try {
      const res = await apolloBffClient.query({
        query: LOGIN_STATUS,
        fetchPolicy: 'no-cache',
      })
      return res.data!.loginStatus
    } catch (error) {
      // tslint:disable-next-line:no-console
      console.error('An error occurred retrieving login status', error)
      return {
        loggedIn: false,
        redirectTo: '',
        email: undefined,
        accountType: undefined
      }
    }
  }

  async updateProfile(input: UpdateProfileInput): Promise<UpdateProfileResponse> {
    try {
      const { data, errors } = await apolloBffClient.mutate({
        variables: {
          input,
        },
        mutation: UPDATE_PROFILE,
        errorPolicy: 'all'
      })

      if (errors) {
        return {
          data: {
            updateProfile: {
              success: false
            },
          },
          errors: [{ message: errors[0].message }],
        }
      }
      return {
        data: {
          updateProfile: {
            ...data.updateProfile,
            success: true
          },
        }
      }
    } catch (err) {
      return {
        data: {
          updateProfile: {
            success: false
          },
        },
        errors: [{ message: err.message }],
      }
    }
  }

  async verifyChangeEmail(input: VerifyChangeEmailInput): Promise<VerifyChangeEmailResult> {
    try {
      const { data, errors } = await apolloBffClient.mutate({
        mutation: VERIFY_CHANGE_EMAIL,
        variables: {
          input,
        },
      })

      if (errors) {
        return {
          errors: errors.map(({ message }) => ({ message })),
        }
      }

      return { data }
    } catch (err) {
      return {
        errors: [{ message: err.message }],
      }
    }
  }

  async attemptAcademicMembershipRequest(
    input: AcademicMembership
  ): Promise<GenericSuccessResponse> {
    try {
      const res = await apolloBffClient.mutate({
        variables: {
          input,
        },
        mutation: SUBMIT_ACADEMIC_MEMBERSHIP_REQUEST,
      })

      return {
        success: true,
      }
    } catch (e) {
      return {
        success: false,
      }
    }
  }

  async attemptMagicLinkRequest(
    email: string,
    destination: string
  ): Promise<MagicLinkResponse> {
    try {
      const res = await apolloBffClient.mutate({
        variables: {
          input: {
            email,
            destination,
          },
        },
        mutation: REQUEST_MAGIC_LINK_MUTATION,
      })

      return {
        data: res.data,
      }
    } catch (err) {
      // user email not found, return success false
      return {
        data: {
          requestMagicLink: {
            success: false,
          },
        },
      }
    }
  }

  async attemptParentRegistration(
    email: string,
    enrollments: Enrollment[]
  ): Promise<ParentRegistrationResponse> {
    try {
      const res = await apolloBffClient.mutate({
        variables: {
          input: {
            email,
            enrollments,
          },
        },
        mutation: SUBMIT_PARENT_REGISTRATION,
      })

      return {
        data: {
          registerFamilyNewModel: {
            ...res.data.registerFamilyNewModel,
            success: true,
          },
        },
      }
    } catch (err) {
      // tslint:disable-next-line:no-console
      console.log('error registering parent', err)
      return {
        data: {
          registerFamilyNewModel: {
            success: false,
            errorCode: get(
              err,
              'graphQLErrors[0].extensions.code',
              ParentErrorCodes.BadImplementation
            ),
          },
        },
        errors: [{ message: err.message }],
      }
    }
  }

  async searchForMySchool(
    searchTerms: string,
    query: any = SEARCH_FOR_MY_SCHOOL
  ): Promise<SearchForMySchoolsResponse> {
    try {
      const res = await apolloBffClient.query({
        query,
        variables: {
          searchTerms,
        },
      })

      return {
        data: {
          searchAcademicGroups: {
            ...res.data.searchAcademicGroups,
            success: true,
          },
        },
      }
    } catch (e) {
      return {
        data: {
          searchAcademicGroups: {
            success: false,
          },
        },
      }
    }
  }

  async verifyEmail(
    email: string,
    query: any = VERIFY_EMAIL
  ): Promise<VerifyEmailResponse> {
    try {
      const res = await apolloBffClient.query({
        query,
        variables: {
          input: { email },
        },
      })

      return {
        data: {
          verifyEmail: {
            ...res.data.verifyEmail,
            success: true,
          },
        },
      }
    } catch (e) {
      return {
        data: {
          verifyEmail: {
            success: false,
          },
        },
      }
    }
  }

  async completeMyProfile(
    firstName: string,
    lastName: string
  ): Promise<CompleteMyProfileResponse> {
    try {
      const res = await apolloBffClient.mutate({
        variables: {
          input: {
            firstName,
            lastName,
          },
        },
        mutation: COMPLETE_MY_PROFILE,
      })

      return {
        data: {
          updateProfile: {
            ...res.data.updateProfile,
            success: true,
          },
        },
      }
    } catch (err) {
      return {
        data: {
          updateProfile: {
            success: false,
          },
        },
      }
    }
  }

  async getMyProfile(): Promise<GetMyProfileResponse> {
    try {
      const res = await apolloBffClient.query({
        query: GET_MY_PROFILE,
        fetchPolicy: 'no-cache',
      })

      return {
        data: {
          profile: {
            ...res.data.profileNewModel,
            success: true,
          },
        },
      }
    } catch (e) {
      return {
        data: {
          profile: {
            success: false,
          },
        },
      }
    }
  }

  async updateChildrenEnrollments(
    input: UpdateChildEnrollmentInput
  ): Promise<UpdateChildEnrollmentResponse> {
    try {
      const res = await apolloBffClient.mutate({
        variables: {
          input,
        },
        mutation: UPDATE_CHILDREN_ENROLLMENTS,
      })

      return {
        data: {
          updateChildren: {
            ...res.data.updateChildren!,
            success: true,
          },
        },
      }
    } catch (e) {
      return {
        data: {
          updateChildren: {
            success: false,
          },
        },
      }
    }
  }

  async changeSubscriptions(input): Promise<ChangeSubscriptionResponse> {
    try {
      const res = await apolloBffClient.mutate({
        variables: {
          input,
        },
        mutation: CHANGE_PARENT_SUBSCRIPTIONS,
      })

      return {
        data: {
          changeSubscriptions: {
            ...res.data.changeSubscriptions,
            success: true,
          },
        },
      }
    } catch (e) {
      return {
        data: {
          changeSubscriptions: {
            success: false,
          },
        },
      }
    }
  }

  async manageDistrictAccount(
    guardianId: number | null,
    managedBy: string,
  ): Promise<ManageDistrictAccountResponse> {
    try {
      const res = await apolloBffClient.mutate({
        variables: {
          input: {
            guardianId,
            managedBy
          },
        },
        mutation: MANAGE_DISTRICT_ACCOUNT,
      })

      return {
        data: {
          changeManageStatus: {
            success: true,
            ...res.data.changeManageStatus
          }
        }
      }
    } catch (e) {
      return {
        data: {
          changeManageStatus: {
            success: false,
            managedBy: ''
          }
        }
      }
    }
  }

  async attemptNewOrgRegistration(
    input: OrganizationInput
  ): Promise<NewOrgRegistrationResponse> {
    try {
      const res = await apolloBffClient.mutate({
        variables: {
          input,
        },
        mutation: REGISTER_ORGANIZATION,
      })

      return {
        data: {
          registerOrganization: {
            ...res.data.registerOrganization,
            success: true,
          },
        },
      }
    } catch (e) {
      return {
        data: {
          registerOrganization: {
            success: false,
            errorCode: get(
              e,
              'graphQLErrors[0].extensions.code',
              OrgErrorCodes.BadImplementation
            ),
          },
        },
      }
    }
  }

  async attemptNewAcademicLead(
    lead: AcademicLead
  ): Promise<GenericSuccessResponse> {
    try {
      const res = await apolloBffClient.mutate({
        variables: {
          input: lead,
        },
        mutation: SUBMIT_ACADEMIC_LEAD,
      })

      return {
        success: true,
      }
    } catch (e) {
      return {
        success: false,
      }
    }
  }

  async changeNotficationPreferences(
    input: ChangeNotficationPreferencesInput
  ): Promise<ChangeNotificationPreferencesResponse> {
    try {
      const { data } = await apolloBffClient.mutate({
        variables: {
          input,
        },
        mutation: CHANGE_NOTIFICATION_PREFERENCES,
      })
      return {
        data: {
          changeNotificationPreferences: {
            ...data.changeNotificationPreferences,
            success: true,
          },
        },
      }
    } catch (e) {
      return {
        data: {
          changeNotificationPreferences: {
            success: false,
          },
        },
      }
    }
  }
  async updateChildrenNewModel(
    input: UpdateChildrenNewModelInput
  ): Promise<UpdateChildrenNewModelResponse> {
    try {
      const res = await apolloBffClient.mutate({
        variables: {
          input,
        },
        mutation: UPDATE_CHILDREN_NEW_MODEL,
      })

      return {
        data: {
          updateChildrenNewModel: {
            ...res.data.updateChildrenNewModel,
            success: true,
          },
        },
      }
    } catch (e) {
      return {
        data: {
          updateChildrenNewModel: {
            success: false,
            errorCode: get(
              e,
              'graphQLErrors[0].extensions.code',
              OrgErrorCodes.BadImplementation
            ),
          },
        },
      }
    }
  }

  async getNotificationPreferences(
    input: GetNotficationPreferencesInput
    ): Promise<GetNotificationPreferencesResponse> {
      try {
        const res = await apolloBffClient.query({
          query: GET_NOTIFICATION_PREFERENCES,
          variables: {
            input,
          },
          fetchPolicy: 'no-cache',
        })

        return {
          data: {
            getNotificationPreferences: {
              ...res.data.getNotificationPreferences,
              success: true,
            },
          },
        }
      } catch (e) {
        return {
          data: {
            getNotificationPreferences: {
              success: false,
            },
          },
        }
      }
  }

  async getLinkedAccounts(): Promise<LinkedAccountsResponse> {
      try {
        const res = await apolloBffClient.query({
          query: GET_LINKED_ACCOUNTS,
        })

        return {
          data: {
              linkedAccounts: res.data.linkedAccounts,
              success: true,
          },
        }
      } catch (e) {
        return {
          data: {
              linkedAccounts: [],
              success: false,
          },
        }
      }
  }

  async dismissPendingNotification(
    guardianId: number
  ): Promise<GenericSuccessResponse> {
    try {
      const res = await apolloBffClient.mutate({
        variables: {
          guardianId,
        },
        mutation: DISMISS_PENDING_NOTIFICATION,
      })

      return {
        success: true,
      }

    } catch (e) {
      return {
        success: false,
      }
    }
  }
}
