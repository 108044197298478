import React, { FunctionComponent } from 'react'
import TwoTone from '../App/Layout/TwoTone'
import IllustratedQuote from '../App/Layout/IllustratedQuote'
import DeskWoman from '../LogIn/DeskWoman'
import CompleteProfileContainer from './CompleteProfile.container'
import FlexInset from '../App/Layout/FlexInset'
import config from '../config'

const CompleteProfile: FunctionComponent = () => {
    return (
        // @ts-ignore
        <TwoTone>
            <FlexInset>
                <CompleteProfileContainer />
            </FlexInset>
            <IllustratedQuote svg={<DeskWoman />} />
        </TwoTone>
    )
}

export default CompleteProfile
