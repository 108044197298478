import React from 'react'

const MagicEnvelope = (props: any) => (
    <svg
        width='602px'
        height='497px'
        viewBox='0 0 602 497'
        version='1.1'
        xmlns='http://www.w3.org/2000/svg'
        xmlnsXlink='http://www.w3.org/1999/xlink'
        style={{ width: '100%' }}
        {...props}
    >
        <title>FA7FEE6B-8858-4B9C-AC66-008435DE6CD1@1.00x</title>
        <desc>Created with sketchtool.</desc>
        <g
            id='Auth,-Account-+-Help'
            stroke='none'
            strokeWidth='1'
            fill='none'
            fillRule='evenodd'
        >
            <g
                id='Email-on-its-way'
                transform='translate(-778.000000, -292.000000)'
            >
                <g
                    id='magic-link'
                    transform='translate(780.000000, 292.000000)'
                >
                    <g id='Group' transform='translate(179.508197, 55.327869)'>
                        <path
                            d='M142.1393,104.508197 L2.94408166,104.508197 C1.31854114,104.508197 0,103.18302 0,101.546121 L0,2.96207618 C0,1.3251768 1.31854114,0 2.94408166,0 L142.1393,0 C143.763426,0 145.081967,1.3251768 145.081967,2.96207618 L145.081967,101.546121 C145.081967,103.18302 143.763426,104.508197 142.1393,104.508197'
                            id='Fill-33'
                            fill='#FEFEFE'
                        />
                        <path
                            d='M137.455245,99.5901639 L7.62529378,99.5901639 C6.12951422,99.5901639 4.91803279,98.3724652 4.91803279,96.8707802 L4.91803279,7.63598222 C4.91803279,6.13429723 6.12951422,4.91803279 7.62529378,4.91803279 L137.455245,4.91803279 C138.951024,4.91803279 140.163934,6.13429723 140.163934,7.63598222 L140.163934,96.8707802 C140.163934,98.3724652 138.951024,99.5901639 137.455245,99.5901639'
                            id='Fill-35'
                            fill='#EFF1F5'
                        />
                        <path
                            d='M137.455245,99.5901639 L7.62529378,99.5901639 C6.12951422,99.5901639 4.91803279,98.373061 4.91803279,96.8721108 L26.2089618,63.5659253 C26.5846925,62.9795942 27.4190146,62.9265521 27.8647484,63.4612745 L48.6099393,88.3853627 C49.0042423,88.8598752 49.722845,88.8813788 50.1457206,88.4326706 L98.4906874,37.2082648 C98.9421357,36.7280181 99.7207411,36.7925288 100.089329,37.3401534 L140.163934,96.8721108 C140.163934,98.373061 138.951024,99.5901639 137.455245,99.5901639'
                            id='Fill-37'
                            fill='#DEE0E7'
                        />
                        <path
                            d='M51.6393443,32.582661 C51.6393443,37.6751617 47.5098396,41.8032787 42.417339,41.8032787 C37.3262259,41.8032787 33.1967213,37.6751617 33.1967213,32.582661 C33.1967213,27.4901604 37.3262259,23.3606557 42.417339,23.3606557 C47.5098396,23.3606557 51.6393443,27.4901604 51.6393443,32.582661'
                            id='Fill-39'
                            fill='#DEE0E7'
                        />
                    </g>
                    <g
                        id='Group-2'
                        transform='translate(382.377049, 99.590164)'
                    >
                        <path
                            d='M123.488143,127.557031 C126.133006,125.900949 120.880352,111.067962 113.138247,99.8025844 C107.797193,92.028756 102.943763,88.3908282 101.710444,87.4910331 C100.181985,86.3759759 93.7601726,81.8482985 84.0362032,79.8492321 C80.0268481,79.0240611 74.8269483,77.9549265 68.2497239,79.4014871 C61.042295,80.9858154 56.3271679,84.6797114 53.8605305,86.6572516 C52.5659023,87.6962495 47.3246543,92.0330613 43.3195766,99.6748622 C41.0682353,103.971492 39.2831313,107.376937 39.3458665,112.181584 C39.3658278,113.705639 39.4656339,121.551222 45.5523825,126.025801 C49.0199329,128.574503 54.9213272,130.391314 58.3346971,127.935893 C61.1948558,125.876553 59.8702858,122.567258 62.8915601,117.511472 C65.390991,113.328213 68.9198508,111.207165 71.0599796,109.919898 C80.9821354,103.958576 92.6295116,107.098531 95.1802713,107.784499 C113.397743,112.695343 120.627985,129.348011 123.488143,127.557031'
                            id='Fill-3'
                            fill='#C5C8D3'
                        />
                        <path
                            d='M123.998805,127.631226 C124.885295,127.310718 122.69776,113.031551 113.586138,99.834641 C108.212691,92.0490099 103.329825,88.4055588 102.089026,87.5043976 C100.551297,86.3876475 94.090537,81.8530959 84.3075925,79.8509943 C80.273921,79.0245705 75.0424843,77.9538127 68.4253696,79.4025696 C61.1742282,80.9893033 56.4305043,84.6888075 53.948907,86.6693502 C52.646427,87.7099256 47.3733912,92.0533217 43.3440231,99.7067249 C41.3730897,103.450784 39.3935496,107.125855 39.3462128,112.232436 C39.3333028,113.705626 39.3376061,116.764113 40.9556649,120.203473 C41.5724782,121.514253 42.9466806,123.994962 45.5903707,126.097671 C47.8410217,127.888496 49.7746594,128.418844 50.3340947,128.559696 C51.4228417,128.832775 52.2419123,128.970752 52.4843342,128.996622 C52.774093,129.026805 55.8854136,129.353063 57.1262122,128.650243 C57.3184284,128.542449 51.8417009,124.134376 50.4230305,118.947309 C48.6041488,112.304298 56.1393111,104.45974 61.0020943,100.801916 C66.4544362,96.6999795 71.9282948,95.814628 75.5258938,95.2325382 C85.6832295,93.5897514 93.7979093,96.3995924 96.9823868,97.6888135 C105.964909,101.326516 111.150443,107.199155 113.873028,110.417178 C121.570283,119.515026 123.311704,127.878435 123.998805,127.631226'
                            id='Fill-5'
                            fill='#DEE0E7'
                        />
                        <path
                            d='M129.50174,82.3770492 C131.796911,82.3655031 134.882624,70.0042443 133.789136,57.6978025 C132.314803,41.115626 123.758764,30.3584889 122.138729,28.3778601 C119.041475,24.5954793 112.673855,17.0206199 101.873137,13.8311295 C98.2421221,12.7593107 92.1255141,11.0469972 85.6179624,13.7186102 C83.8551098,14.4413305 79.3094891,16.3858955 76.7820605,21.292027 C74.6340347,25.4624552 74.0598814,31.5457122 77.1427095,35.7175829 C77.6981091,36.4677119 79.0108717,38.0242293 84.1753662,40.2255692 C90.5256748,42.933246 95.4752225,43.5362343 97.8800305,44.0122776 C113.627411,47.1253126 123.13412,65.0735892 123.875615,66.5147023 C128.455858,75.4354661 127.780723,82.3842563 129.50174,82.3770492'
                            id='Fill-7'
                            fill='#C5C8D3'
                        />
                        <path
                            d='M129.025096,82.3714997 C129.918753,82.5575746 131.71035,78.0268659 132.685379,74.4207623 C134.211448,68.7793736 134.332791,63.1855856 133.517651,57.2917697 C132.782453,51.9633909 131.106489,39.8367854 121.738793,28.3765879 C118.522484,24.4430499 112.268312,16.9885124 101.684328,13.831008 C98.0911415,12.759274 92.0382563,11.0470959 85.5985004,13.7184976 C84.0966996,14.3401898 79.5327671,16.3322015 76.8546509,21.2913153 C76.3178856,22.2865999 74.436352,26.0412751 75.1658389,30.6066024 C75.3314367,31.6307358 75.6597771,33.523219 77.0059731,35.4762848 C79.2386882,38.7174508 82.6020367,39.698311 83.6855603,39.9666051 C83.2401593,39.4675204 82.6320156,38.6741776 82.1823319,37.5707098 C81.8254401,36.6937054 80.8761079,34.3641624 81.9467833,32.1096264 C83.2587176,29.347351 86.6220661,28.7660471 87.4671859,28.6593064 L87.4686135,28.6593064 C95.7499312,26.9283766 103.487346,30.2517618 104.787859,30.8474901 C111.603066,33.9660486 115.595971,38.5530125 117.804418,41.1421949 C126.406938,51.2392853 128.217093,62.448498 128.755286,66.071911 C130.100055,75.1261161 127.928725,82.1421515 129.025096,82.3714997'
                            id='Fill-9'
                            fill='#DEE0E7'
                        />
                        <path
                            d='M143.249879,49.1769953 C145.893406,49.4180251 150.056006,36.5430178 149.999429,24.0539451 C149.952754,14.0282541 147.211633,9.2291791 145.59638,6.94656972 C144.289468,5.10154416 142.04339,2.01980629 137.910493,0.636754476 C137.514459,0.506196679 131.250051,-1.47799488 126.054934,2.2981383 C125.028075,3.04418285 122.513259,4.8734267 121.869704,8.16032682 C121.241707,11.3625796 122.779167,13.9766049 123.277039,14.8230785 C124.933309,17.6408314 126.872461,18.0769805 129.588123,20.3839798 C132.204775,22.6048971 133.456526,24.7913815 135.706847,28.719593 C142.098552,39.8801325 141.049062,48.9761372 143.249879,49.1769953'
                            id='Fill-11'
                            fill='#C5C8D3'
                        />
                        <path
                            d='M144.568987,25.4536152 C142.191718,17.9266317 136.275272,10.4025179 131.4926,11.0266577 C128.177084,11.4585338 124.639314,12.9980789 124.217313,16.0226464 C123.836106,15.5893355 121.08607,12.381113 121.856924,8.24170254 C122.471638,4.93734819 124.936121,3.16249525 126.148668,2.28869942 C131.366,-1.46474869 137.55112,0.498063623 137.93936,0.628630816 C142.051051,2.01178218 144.28484,5.09374186 145.584601,6.93890022 C149.485292,12.4829841 149.745525,18.4589441 149.963559,24.0475069 C150.205506,30.2673837 149.184265,34.972107 148.623004,37.1759665 C147.008149,43.4991491 144.016166,49.4277606 143.250939,49.1723654 C142.190311,48.8165339 148.371211,37.4901886 144.568987,25.4536152'
                            id='Fill-13'
                            fill='#DEE0E7'
                        />
                        <path
                            d='M124.437052,135.871859 C122.915643,134.208618 128.321593,125.096254 136.277087,118.51277 C146.678881,109.905603 158.200581,108.307501 163.199293,107.679262 C169.451267,106.893239 186.23409,104.782703 199.592666,115.342622 C202.444771,117.597914 208.048605,122.15482 210.417472,130.224944 C212.614267,137.708808 210.827579,144.11569 210.073327,146.69813 C209.148436,149.852354 207.60265,155.13738 202.677069,159.196327 C201.20298,160.412274 197.454663,163.502805 192.014298,163.52459 C188.321905,163.536099 182.194683,162.140655 180.898403,158.308975 C179.520387,154.235553 185.088372,151.806554 184.761434,145.447441 C184.488986,140.120436 180.291846,136.255462 178.460706,134.569059 C177.647663,133.820673 174.742502,131.259947 167.964272,129.111774 C164.538592,128.024659 157.090712,125.749101 147.738562,127.00558 C133.390568,128.932277 126.04163,137.626297 124.437052,135.871859'
                            id='Fill-15'
                            fill='#DEE0E7'
                        />
                        <path
                            d='M125.566527,135.390122 C124.49845,134.467739 128.938724,125.212596 137.418743,118.320347 C147.833935,109.85663 159.36745,108.285163 164.373346,107.667394 C170.631075,106.894472 187.434634,104.819111 200.807137,115.203034 C203.663958,117.420739 209.274236,121.901697 211.645828,129.837321 C213.845149,137.196455 212.056406,143.496557 211.299851,146.035957 C210.249001,149.561794 208.691387,154.733116 203.896523,158.325854 C197.170793,163.367644 187.061381,163.054489 186.02058,160.443918 C184.938146,157.729437 194.691532,155.124559 196.994215,146.414589 C198.988247,138.873256 194.469016,130.274314 188.894627,125.541408 C183.402067,120.876827 177.299382,120.320266 168.912676,119.554461 C164.182413,119.123161 156.183316,118.47123 147.199405,121.511677 C133.360335,126.196186 126.745145,136.407875 125.566527,135.390122'
                            id='Fill-17'
                            fill='#C5C8D3'
                        />
                        <path
                            d='M125.904714,126.61101 C127.268046,127.172857 129.897534,119.302724 138.533402,109.789831 C146.813431,100.667664 155.445011,95.7308275 161.765786,92.181496 C172.758189,86.0097375 178.25439,82.9252843 185.889909,82.7356254 C191.889143,82.5873206 194.778722,84.1958566 201.308141,82.5630785 C204.649305,81.7274384 210.378445,80.2957273 214.135469,75.0779676 C217.186533,70.8427267 218.774229,64.3387086 216.650632,59.4104282 C212.390575,49.5239214 193.738359,47.2437362 180.438009,50.6846912 C166.49029,54.293915 158.194541,64.2545741 147.457941,77.1456778 C138.949261,87.3644442 134.532007,96.1814457 132.335527,101.121134 C127.046541,113.016885 124.196976,125.907988 125.904714,126.61101'
                            id='Fill-19'
                            fill='#DEE0E7'
                        />
                        <path
                            d='M125.578425,126.636921 C126.149216,126.798294 127.621859,118.876749 132.973033,109.548547 C134.111762,107.560663 138.589624,99.997565 151.702139,88.4844048 C165.50674,76.3600274 172.286319,69.8679874 183.274062,67.7772821 C191.416407,66.22639 197.78359,67.6359024 199.14921,67.9557918 C206.132847,69.5923685 211.048792,72.9354978 213.680142,75.0290593 C214.710422,74.0508262 216.317201,72.2443083 217.164826,69.5623789 C217.164826,69.5623789 218.683133,64.7526141 216.191626,59.3387726 C212.325939,50.9388212 194.682764,46.7988247 180.031964,50.6003668 C166.104644,54.2148308 157.821028,64.1899516 147.10013,77.0997715 C138.603894,87.3333744 134.1931,96.161749 131.999833,101.110037 C126.276218,114.018429 124.870643,126.435562 125.578425,126.636921'
                            id='Fill-21'
                            fill='#C5C8D3'
                        />
                        <path
                            d='M147.788045,45.4495072 C148.972255,46.1728282 152.401289,37.4518452 162.725757,32.0779951 C165.657538,30.5519294 175.605473,25.3723831 186.698815,27.6231882 C190.889537,28.4727359 193.3873,30.0073112 196.732979,28.7847567 C200.83891,27.28422 202.958703,22.8251583 204.098361,19.388674 C203.480387,17.7860214 202.191266,15.1607914 199.531106,13.0674151 C196.02734,10.3117036 192.158539,10.0635052 188.488064,9.87771093 C181.851317,9.54299764 176.628722,11.3073338 174.230123,12.2561608 C165.71215,15.630241 160.845968,21.3941172 157.760412,25.1383675 C150.337545,34.1486789 146.49605,44.6609453 147.788045,45.4495072'
                            id='Fill-23'
                            fill='#C5C8D3'
                        />
                        <path
                            d='M147.58842,44.2502713 C148.138782,44.5182177 150.680449,40.2452522 152.405867,37.6848755 C154.881778,34.0087641 158.880116,29.0921602 165.475873,23.9926722 C165.475873,23.9926722 174.22733,17.920638 186.561138,17.4357826 C191.404316,17.24581 193.604331,18.2523812 194.602129,19.7395546 C195.248267,20.7050123 195.574195,22.1482369 195.495572,23.4610325 C195.345474,25.936347 194.733644,27.5808858 194.233316,28.1607275 C197.851404,27.8417436 198.97214,27.0251451 201.570988,23.9926722 C203.282111,21.9965424 204.098361,20.1903851 204.098361,18.4522777 C204.098361,16.7127526 202.178529,14.2572861 199.556809,12.132145 C195.944438,9.20600007 191.921799,8.88843396 188.480969,8.66443643 C186.718384,8.54960226 181.156163,8.34119949 174.301664,11.0149928 C166.180621,14.1835654 161.49183,19.3695334 157.676469,23.5886261 C157.676469,23.5886261 150.44458,31.5844871 148.643398,38.694282 C148.557628,39.0373668 148.354637,39.995736 147.951516,41.9096389 C147.522663,43.9497177 147.486925,44.2006516 147.58842,44.2502713'
                            id='Fill-25'
                            fill='#DEE0E7'
                        />
                        <path
                            d='M157.377049,30.7377049 C149.794515,42.3245049 139.998787,60.248149 134.012349,83.8693752 C128.940025,103.882419 128.281113,121.361297 128.794401,134.016393 L120.743275,134.016393 C119.797441,117.243993 120.591884,84.8475746 140.020415,53.1218808 C145.513751,44.1507631 151.566513,36.754203 157.377049,30.7377049'
                            id='Fill-27'
                            fill='#DEE0E7'
                        />
                        <path
                            d='M95.0780147,205.130266 C95.5913448,208.773191 98.7029592,211.481181 102.368165,211.47541 L147.210496,211.47541 C150.878577,211.481181 153.985878,208.773191 154.500646,205.130266 L167.213115,145.081967 L82.3770492,145.081967 L95.0780147,205.130266 Z'
                            id='Fill-29'
                            fill='#DEE0E7'
                        />
                        <path
                            d='M157.469908,134.016393 L92.1216935,134.016393 C86.7410739,134.016393 82.3770492,138.421005 82.3770492,143.852459 L167.213115,143.852459 C167.213115,138.421005 162.850528,134.016393 157.469908,134.016393'
                            id='Fill-31'
                            fill='#FCFDFE'
                        />
                        <line
                            x1='186.885246'
                            y1='211.47541'
                            x2='0.614754098'
                            y2='211.47541'
                            id='Stroke-46-Copy'
                            stroke='#C5C8D3'
                            strokeWidth='3'
                            stroke-linecap='round'
                            stroke-linejoin='round'
                        />
                    </g>
                    <g id='Group-4' transform='translate(0.000000, 258.196721)'>
                        <polygon
                            id='Fill-41'
                            fill='#DEE0E7'
                            points='157.377049 210.245902 211.47541 210.245902 211.47541 30.7377049 157.377049 30.7377049'
                        />
                        <polygon
                            id='Fill-43'
                            fill='#DEE0E7'
                            points='77.2725708 210.245902 52.8688525 198.189456 132.973331 38.1147541 157.377049 50.1697696'
                        />
                        <polygon
                            id='Fill-44'
                            fill='#DEE0E7'
                            points='309.836066 202.450511 256.864424 209.016393 237.295082 50.828178 290.268151 44.2622951'
                        />
                        <polygon
                            id='Fill-45'
                            fill='#DEE0E7'
                            points='218.852459 210.245902 237.295082 210.245902 237.295082 0 218.852459 0'
                        />
                        <line
                            x1='520.081967'
                            y1='211.47541'
                            x2='0'
                            y2='211.47541'
                            id='Stroke-46'
                            stroke='#C5C8D3'
                            strokeWidth='3'
                            stroke-linecap='round'
                            stroke-linejoin='round'
                        />
                        <path
                            d='M202.868852,180.737705 C202.868852,190.923819 194.612343,199.180328 184.42623,199.180328 C174.240116,199.180328 165.983607,190.923819 165.983607,180.737705 C165.983607,170.551591 174.240116,162.295082 184.42623,162.295082 C194.612343,162.295082 202.868852,170.551591 202.868852,180.737705'
                            id='Fill-54'
                            fill='#C5C8D3'
                        />
                        <path
                            d='M82.3770492,187.5 C82.3770492,192.593057 78.2487946,196.721311 73.1557377,196.721311 C68.0626808,196.721311 63.9344262,192.593057 63.9344262,187.5 C63.9344262,182.406943 68.0626808,178.278689 73.1557377,178.278689 C78.2487946,178.278689 82.3770492,182.406943 82.3770492,187.5'
                            id='Fill-55'
                            fill='#C5C8D3'
                        />
                    </g>
                    <g
                        id='Group-3'
                        transform='translate(212.704918, 292.622951)'
                    >
                        <path
                            d='M203.974196,1.55237434 L5.87781422,65.1134016 C2.35804425,66.2424913 0.421095476,70.0046928 1.5522965,73.5178935 L42.1135235,199.460772 C43.2461582,202.973973 47.0153946,204.905875 50.5351645,203.775354 L248.631546,140.214327 C252.15275,139.085237 254.086831,135.323036 252.95563,131.809835 L212.394403,5.8683879 C211.263202,2.35518721 207.493966,0.42185355 203.974196,1.55237434'
                            id='Fill-47'
                            fill='#47506B'
                        />
                        <path
                            d='M205.196176,0.237705652 L109.170151,31.0021424 C106.569817,31.8350423 105.140636,34.6108992 105.976355,37.2010322 L147.600343,166.250516 C148.436063,168.842077 151.221316,170.266436 153.820217,169.434965 L249.846242,138.669099 C252.446577,137.836199 253.875757,135.061771 253.040038,132.471638 L211.41605,3.42215488 C210.580331,0.830593237 207.795077,-0.595194257 205.196176,0.237705652'
                            id='Fill-48'
                            fill='#47506B'
                        />
                        <path
                            d='M215.233091,26.4347126 L134.009761,118.90634 C132.877674,120.195173 131.128864,120.759573 129.457143,120.370923 L9.61293825,92.5909984 C8.01545331,92.2209233 6.72918528,91.0363971 6.22666991,89.4732227 L0.225037488,70.7865725 C-0.562997978,68.3332173 0.784656877,65.7055411 3.2358469,64.9182384 L204.303381,0.225106826 C206.754572,-0.563624701 209.378501,0.786649381 210.166537,3.23857565 L216.169597,21.9266547 C216.672112,23.4884003 216.315212,25.2016052 215.233091,26.4347126'
                            id='Fill-49'
                            fill='#2D344B'
                        />
                        <line
                            x1='148.770492'
                            y1='46.7213115'
                            x2='162.295082'
                            y2='15.9836066'
                            id='Stroke-50'
                            stroke='#F8A91A'
                            strokeWidth='7'
                        />
                        <line
                            x1='170.901639'
                            y1='41.8032787'
                            x2='178.278689'
                            y2='23.3606557'
                            id='Stroke-51'
                            stroke='#F8A91A'
                            strokeWidth='7'
                        />
                        <path
                            d='M225.401464,25.8210553 C234.568579,25.703941 242.094578,33.0387669 242.211697,42.205492 C242.330263,51.3707712 234.995125,58.8964501 225.82801,59.0150103 C216.662341,59.1321246 209.134896,51.7972987 209.017777,42.6320194 C208.900657,33.4652943 216.235795,25.9396155 225.401464,25.8210553 Z'
                            id='Stroke-52'
                            stroke='#FBC35E'
                            strokeWidth='7'
                        />
                        <line
                            x1='210.245902'
                            y1='36.8852459'
                            x2='148.770492'
                            y2='11.0655738'
                            id='Stroke-53'
                            stroke='#FBC35E'
                            strokeWidth='7'
                        />
                    </g>
                    <path
                        d='M200.759184,331.967213 L168.093275,331.967213 C167.608835,331.967213 167.213115,331.69082 167.213115,331.352459 C167.213115,331.014098 167.608835,330.737705 168.093275,330.737705 L200.759184,330.737705 C201.243624,330.737705 201.639344,331.014098 201.639344,331.352459 C201.639344,331.69082 201.243624,331.967213 200.759184,331.967213'
                        id='Fill-56'
                        fill='#C5C8D3'
                    />
                    <path
                        d='M200.759184,339.344262 L168.093275,339.344262 C167.608835,339.344262 167.213115,339.067869 167.213115,338.729508 C167.213115,338.391148 167.608835,338.114754 168.093275,338.114754 L200.759184,338.114754 C201.243624,338.114754 201.639344,338.391148 201.639344,338.729508 C201.639344,339.067869 201.243624,339.344262 200.759184,339.344262'
                        id='Fill-57'
                        fill='#C5C8D3'
                    />
                    <path
                        d='M81.6885246,81.147541 L149.459016,81.147541 C151.115871,81.147541 152.459016,79.8043952 152.459016,78.147541 L152.459016,3 C152.459016,1.34314575 151.115871,7.05355176e-15 149.459016,0 L115.57377,0 L81.6885246,0 C80.0316703,-1.39730022e-16 78.6885246,1.34314575 78.6885246,3 L78.6885246,78.147541 C78.6885246,79.8043952 80.0316703,81.147541 81.6885246,81.147541 Z'
                        id='Fill-67'
                        fill='#FFFFFF'
                    />
                    <path
                        d='M85.3770492,77.4590164 L145.770492,77.4590164 C147.427346,77.4590164 148.770492,76.1158706 148.770492,74.4590164 L148.770492,6.68852459 C148.770492,5.03167034 147.427346,3.68852459 145.770492,3.68852459 L85.3770492,3.68852459 C83.7201949,3.68852459 82.3770492,5.03167034 82.3770492,6.68852459 L82.3770492,74.4590164 C82.3770492,76.1158706 83.7201949,77.4590164 85.3770492,77.4590164 Z'
                        id='Fill-68'
                        fill='#F0F2F6'
                    />
                    <path
                        d='M135.24606,23.8354052 C135.24606,22.9587659 134.094011,22.5616347 133.505076,23.2366347 L124.224749,27.4563068 C121.143601,25.4645036 112.139913,25.6132741 109.249339,27.2472904 L98.7739289,22.37352 C98.1358141,21.8276183 97.1165519,22.2640937 97.1311475,23.0829462 C97.1989289,27.1440118 98.4874535,32.5821265 99.0235191,37.1116347 C98.1591748,39.2399134 97.7214699,41.5181921 97.7349945,43.8161429 C97.7349945,54.24852 106.077208,62.7050773 116.366962,62.7050773 C126.657945,62.7050773 135.000158,54.24852 135.000158,43.8161429 C135.017372,41.5550773 134.599339,39.3112249 133.766962,37.2087659 C133.961224,32.2329462 135.24606,23.8354052 135.24606,23.8354052'
                        id='Fill-69'
                        fill='#DEE0E7'
                    />
                </g>
            </g>
        </g>
    </svg>
)

export default MagicEnvelope
