import React from 'react'
import { LoadingSpinner as PJLoadingSpinner } from '@peachjar/ui/dist/lib/components/Spinners/Spinners'

type Props = {
  height: string
}

const LoadingSpinner = ({ height = '180px' }) => (
  <PJLoadingSpinner height={height} />
)

export default LoadingSpinner
