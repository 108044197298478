import React, { useState, useEffect } from 'react'
import { get } from 'lodash'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import { useHistory } from 'react-router-dom'
import axios from 'axios'
import AccountsBffClientHttp from '../App/Dependencies/AccountsBffClientHttp'
import { SEARCH_FOR_SCHOOLS_AND_DISTRICTS } from '../App/Dependencies/accounts.graphql'
import TwoTone from '../App/Layout/TwoTone'
import FlexInset from '../App/Layout/FlexInset'
import StaffSignUpForm from './StaffSignUpForm'
import IllustratedQuote from '../App/Layout/IllustratedQuote'
import SchoolIllustration from '../App/components/SchoolIllustrationLarge'
import PrimaryHeading from '../App/Typography/PimaryHeading'
import { APP_ROUTES } from '../App/config'
import { MISSED_FIELD_ERROR } from '../OrgSignUp/OrgSignUp.constants'
import { setSchoolOrDistrictSelection } from './Redux/StaffActions'
import { AcademicEntity } from '../StaffSignUp/Redux/StaffState'
import { RootState, ActionResults } from '../App/Store/makeStore'

const httpClient = new AccountsBffClientHttp(axios)

export type District = {
  districtName: string
  description: string
  districtId: number
  city: string | null
  state: string | null
  section: string
}

export type School = {
  schoolId: number
  schoolName: string
  description: string
  districtId: number
  districtName: string
  gradeLevels: any[]
  section: string
  city: string | null
  state: string | null
}

type Props = {
  selectedSchool: AcademicEntity
  setSelection: (entity: AcademicEntity) => void
}

const StaffSignUp: React.FunctionComponent<Props> = ({
  selectedSchool,
  setSelection,
}) => {
  const [formValue, setFormValue] = useState<District | School | null>(null)
  const [isFormDirty, setIsFormDirty] = useState<boolean>(false)
  const history = useHistory()
  const goBackUrl = APP_ROUTES.signup

  const lookUpSchools = async (options, callback) => {
    try {
      if (!options.input) {
        callback([])
        return
      }
      const res = await httpClient.searchForMySchool(
        options.input
      )

      const matchedDistricts = get(
        res,
        'data.searchAcademicGroups.districts',
        []
      )
      const matchedSchools = get(res, 'data.searchAcademicGroups.schools', [])

      let results = []

      if (matchedDistricts.length) {
        const updatedDistricts = matchedDistricts.reduce(
          (accum, district) => [
            ...accum,
            {
              ...district,
              description: district.districtName,
              section: 'Districts',
            },
          ],
          []
        )

        results = [...results, ...updatedDistricts] as []
      }

      if (matchedSchools.length) {
        const updatedSchools = matchedSchools.reduce(
          (accum, school) => [
            ...accum,
            { ...school, description: school.schoolName, section: 'Schools' },
          ],
          []
        )

        results = [...results, ...updatedSchools] as []
      }

      callback(results)
    } catch (e) {
      // tslint:disable:no-console
      console.log('Unable to fetch school names - ', e)
    }
  }

  const onDropDownChange = (val: any) => {
    setFormValue(val)
    setIsFormDirty(true)

    if (val) {
      const name = get(val, 'description', '')
      const description = get(val, 'section', '')

      setSelection({
        id:
          description.toLowerCase() === 'schools'
            ? val.schoolId
            : val.districtId,
        name,
        description: description.toLowerCase(),
      })
      return
    }

    setSelection({
      id: null,
      name: '',
      description: '',
    })
  }

  const handleSubmit = (event: React.FormEvent<HTMLElement>): void => {
    event.preventDefault()

    if (typeof formValue === 'object' && formValue !== null) {
      history.push(APP_ROUTES.staffSignUpExisting)
    } else {
      setIsFormDirty(true)
    }
  }

  return (
    <TwoTone goBackUrl={goBackUrl}>
      <FlexInset>
        <PrimaryHeading>Which school are you joining?</PrimaryHeading>
        <StaffSignUpForm
          formValue={formValue}
          error={formValue === null && isFormDirty ? MISSED_FIELD_ERROR : null}
          isFormValid={formValue !== null}
          handleSubmit={handleSubmit}
          lookUpSchools={lookUpSchools}
          onDropDownChange={onDropDownChange}
        />
      </FlexInset>
      <IllustratedQuote svg={<SchoolIllustration />} />
    </TwoTone>
  )
}

export default connect(
  (state: RootState) => ({
    selectedSchool: state.staff.schoolSelection,
  }),
  (dispatch: Dispatch<ActionResults>) => ({
    setSelection: (entity: AcademicEntity) =>
      dispatch(setSchoolOrDistrictSelection(entity)),
  })
)(StaffSignUp)
