import { AcademicEntity } from './StaffState'

export const STAFF_JOIN_SCHOOL_OR_DISTRICT = 'STAFF_JOIN_SCHOOL_OR_DISTRICT'

// ---------------------------------------------------------------------------------------------------
type SetAcademicSelection = {
  type: typeof STAFF_JOIN_SCHOOL_OR_DISTRICT
  payload: AcademicEntity
}

export function setSchoolOrDistrictSelection(
  entity: AcademicEntity
): SetAcademicSelection {
  return {
    type: STAFF_JOIN_SCHOOL_OR_DISTRICT,
    payload: entity,
  }
}

// ---------------------------------------------------------------------------------------------------

export type StaffActionResults = SetAcademicSelection
