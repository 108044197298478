import { AsYouType } from 'libphonenumber-js'

type ZipValidation = boolean | string

export default function emailIsValidish(email: string): boolean {
  // we don't care to be super strict here as the intent is to lookup
  // the users email. Just ensure it follows a basic email structure (permissive)
  return /\S+@\S+\.\S+/.test(email)
}

export function formatPhoneNumber(phoneNumber: string): string {
  return new AsYouType('US').input(phoneNumber)
}

export function validateZipCode(zip: string): ZipValidation {
  return /^(\d{5}(-\d{4})?|[A-Z]\d[A-Z] ?\d[A-Z]\d)$/.test(zip)
}
