export const INVALID_EMAIL_ERROR = 'Enter a valid email address.'
export const MISSED_FIELD_ERROR = 'You missed this field.'
export const INVALID_POSTAL_CODE = 'Enter a valid postal code.'
export const INVALID_PHONE_NUMBER_ERROR = 'Enter a valid phone number.'

export const CHARACTER_LENGTH_ERROR = (limit: number) =>
  `Enter ${limit} characters or less.`

export const SELECT_INPUT_TYPE = 'select'
export const PHONE_NUMBER_MAX_LENGTH = 13 // example: (858)998-3321

let i = 0

const c = (description: string) => {
  i += 1
  return {
    id: i,
    description,
  }
}

export type Organization = {
  id: number
  description: string
}

export const organizations = [c('For Profit'), c('Non-Profit'), c('Government')]
