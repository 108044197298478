import React from 'react'
import { css } from 'emotion'

const BackArrow = () => {
    return (
        <div className={styles.container}>
            <svg
                width='24px'
                height='24px'
                viewBox='0 0 24 24'
                version='1.1'
                xmlns='http://www.w3.org/2000/svg'
                xmlnsXlink='http://www.w3.org/1999/xlink'
            >
                <title>baseline-arrow_back-24px</title>
                <desc>Created with Sketch.</desc>
                <g
                    id='Navigation'
                    stroke='none'
                    strokeWidth='1'
                    fill='none'
                    fillRule='evenodd'
                >
                    <g
                        id='Navigation---1280---8px-gutter'
                        transform='translate(-19.000000, -801.000000)'
                    >
                        <g
                            id='baseline-arrow_back-24px'
                            transform='translate(15.000000, 797.000000)'
                        >
                            <polygon
                                id='Path'
                                opacity='0'
                                points='0 0 24 0 24 24 0 24'
                            ></polygon>
                            <polygon
                                id='Path'
                                fill='#2D344B'
                                points='20 11 7.83 11 13.42 5.41 12 4 4 12 12 20 13.41 18.59 7.83 13 20 13'
                            ></polygon>
                        </g>
                    </g>
                </g>
            </svg>
        </div>
    )
}

const styles = {
    container: css`
        cursor: pointer;
    `,
}

export default BackArrow
