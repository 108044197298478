import * as Sentry from '@sentry/browser'
import config from '../config'

const key = config.REACT_APP_SENTRY_API_KEY
const projectId = config.REACT_APP_SENTRY_PROJECT_ID

export default function configureSentry() {
    if (!key || !projectId) {
        // tslint:disable-next-line:no-console
        console.warn('No sentry configuration provided.')
        return
    }

    Sentry.init({
        dsn: `https://${key}@sentry.io/${projectId}`
    })
}
