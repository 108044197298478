import React, { FunctionComponent, useEffect } from 'react'
import TagManager from 'react-gtm-module'
import TwoTone from '../App/Layout/TwoTone'
import { connect } from 'react-redux'
import { RootState } from '../App/Store/makeStore'
import IllustratedQuote from '../App/Layout/IllustratedQuote'
import DeskWoman from './DeskWoman'
import LogInFormContainer from './LogInFormContainer'
import FlexInset from '../App/Layout/FlexInset'
import config from '../config'

import axios from 'axios'
import AccountsBffClient, { LoginStatus } from '../App/Dependencies/AccountsBffClientHttp'

const accountsBff = new AccountsBffClient(axios)

const { REACT_APP_PEACHJAR_HOMEPAGE } = config

type Props = {
  loginStatus: LoginStatus
}

const LogIn: FunctionComponent<Props> = ({ loginStatus }) => {
  const goBackUrl = REACT_APP_PEACHJAR_HOMEPAGE

  useEffect(() => {
    const { loggedIn, redirectTo, email, accountType } = loginStatus
    if (loggedIn) {
      if (email) {
        TagManager.dataLayer({
          dataLayer: {
            event: 'login',
            email,
            accountType
          }
        })
      }

      window.location.href = redirectTo!
    } else {
      // tslint:disable-next-line:no-console
      console.log('Not logged in already.')
    }
  }, [loginStatus])

  return (
    // @ts-ignore
    <TwoTone goBackUrl={goBackUrl}>
      <FlexInset>
        <LogInFormContainer />
      </FlexInset>
        <IllustratedQuote svg={<DeskWoman />} />
    </TwoTone>
  )
}

export default connect((state: RootState) => ({
  loginStatus: state.logIn.loginStatus,
}),
null
)(LogIn)
