import React from 'react'
import SignUp from './SignUp'
import { useHistory } from 'react-router-dom'
import HouseIllustration from '../App/components/HouseIllustration'
import SchoolIllustration from '../App/components/SchoolIllustration'
import TreeIllustration from '../App/components/TreesIllustration'
import { APP_ROUTES } from '../App/config'
import config from '../config'

const { REACT_APP_PEACHJAR_HOMEPAGE } = config

export type Config = {
  headine: string
  subheadline: string
  image: React.ReactNode
  onClickHandler: () => void
}

const SignUpContainer = () => {
  const history = useHistory()

  const signUpConfig = [
    {
      headline: 'Parent / Guardian',
      subheadline:
        'You\'re an adult family member looking for school and community resources that enrich your child.',
      image: HouseIllustration,
      onClickHandler: () => history.push(APP_ROUTES.parentSignUp),
    },
    {
      headline: 'Community Organization',
      subheadline:
        'You provide a child enrichment program, class, camp, event, or resource in a community or online.',
      image: TreeIllustration,
      onClickHandler: () => history.push(APP_ROUTES.orgSignUp),
    }
  ]

  const onCloseHandler = () => {
    window.location.href = REACT_APP_PEACHJAR_HOMEPAGE
  }

  return <SignUp sections={signUpConfig} onClose={onCloseHandler} />
}

export default SignUpContainer
