export const APP_ROUTES = Object.freeze({
  login: '/login',
  signup: '/signup',
  parentSignUp: '/signup/parent',
  errorBoundary: '/error-boundary',
  orgSignUp: '/signup/org',
  orgSignUpAccountInfo: '/signup/org/account-information',
  orgSignUpLegacy: '/signup/orgs',
  staffSignUp: '/signup/school',
  staffSignUpExisting: '/signup/school/info',
  staffSignUpNew: '/signup/school/new',
  staffSignUpThankYou: '/signup/school/thank-you',
  staffSignUpNewThankYou: '/signup/school/thank-you-1',
  signupOrgsThankyou: '/signup/orgs/thank-you',
  signupOrgsThankyouKit: '/signup/orgs/thank-you-starter-kit',
  magicLinkSuccess: '/login/magic-link',
  magicLinkExpired: '/login/magic-link/expired',
  updateEmailExpired: '/auth/update-email/expired',
  completeProfile: '/complete-profile',
  parentAccountInfo: '/parent/account-information',
  parentEmailNotificationsManage: '/parent/email-notifications/manage',
  parentEmailNotifications: '/parent/email-notifications',
  familyAccountInfo: '/family/account-information',
})

export const NOTIFICATIONS = Object.freeze({
  genericError:
    'Something went wrong. Please try again. If you need assistance contact us at (858) 997-2117.',
})

export default APP_ROUTES
