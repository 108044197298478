import React, { useState, useEffect, FormEvent } from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { connect } from 'react-redux'
import axios from 'axios'
import useGlobalNotifications from '@peachjar/ui/dist/lib/hooks/useGlobalNotifications'
import { AcademicEntity } from './Redux/StaffState'
import { APP_ROUTES } from '../App/config'
import StaffSignUpExisting from './StaffSignUpExisting'
import AccountsBffClientHttp from '../App/Dependencies/AccountsBffClientHttp'
import {
  INVALID_EMAIL_ERROR,
  MISSED_FIELD_ERROR,
} from '../OrgSignUp/OrgSignUp.constants'
import { STAFF_MEMBER_OPTIONS } from './StaffSignUp.constants'
import emailIsValidish from '../Utility/formUtils'
import { get } from 'lodash'
import { RootState } from '../App/Store/makeStore'

export type Field = {
  id: string
  label: string
  value: any
  error: boolean
  errorText: string
  inputType: string
  textLimit: number | null
}

export type AcademicMembership = {
  firstName: string
  lastName: string
  email: string
  isVolunteer: boolean
  academicGroupType: string
  academicGroupId: number
}

type Props = RouteComponentProps & {
  schoolSelection: AcademicEntity
}

const TEXT_LIMIT = 50
const EXCEEDED_TEXT_LIMIT_ERROR = `Enter ${TEXT_LIMIT} characters or less.`

const defaultFieldState = (key: string, label: string): Field => ({
  id: `${Date.now()}-${key}`,
  label,
  value: '',
  error: false,
  errorText: MISSED_FIELD_ERROR,
  inputType: 'text',
  textLimit: TEXT_LIMIT,
})

const httpClient = new AccountsBffClientHttp(axios)

const StaffSignUpExistingContainer: React.FunctionComponent<Props> = ({
  schoolSelection,
  history,
}) => {
  const [firstName, setFirstName] = useState<Field>({
    ...defaultFieldState('firstName', 'First Name'),
  })
  const [lastName, setLastName] = useState<Field>({
    ...defaultFieldState('lastName', 'Last Name'),
  })
  const [email, setEmail] = useState<Field>({
    ...defaultFieldState('email', 'Email Address'),
    inputType: 'email',
    textLimit: null,
  })
  const [formValid, setFormValid] = useState<boolean>(false)
  const [selectedStaffType, setSelectedStaffType] = useState<string>('staff')
  const { notifyError } = useGlobalNotifications()

  const goBackUrl = APP_ROUTES.staffSignUp

  const checkForm = () => {
    const isEmailValid = email.value !== '' && emailIsValidish(email.value)
    const fieldsValid = [firstName, lastName, email].every(
      ({ value, error }) => value.length > 0 && !error
    )

    setFormValid(isEmailValid && fieldsValid)
  }

  const showError = (msg?: string) => {
    const message = msg || 'Oops. Something went wrong. Please try again.'

    notifyError({
      persist: false,
      message,
    })
  }

  const handleSubmit = async (event: FormEvent<HTMLElement>) => {
    event.preventDefault()

    if (!formValid) {
      const fields = [
        { field: firstName, setState: setFirstName },
        { field: lastName, setState: setLastName },
        { field: email, setState: setEmail },
      ]

      fields.forEach(({ field, setState }) => {
        const fieldValid = !!field.value
        setState({ ...field, error: !fieldValid })
      })

      return
    }

    try {
      const membershipInput: AcademicMembership = {
        firstName: firstName.value,
        lastName: lastName.value,
        email: email.value,
        isVolunteer: selectedStaffType === 'volunteer',
        academicGroupType:
          schoolSelection.description === 'schools' ? 'school' : 'district',
        academicGroupId: schoolSelection.id as number,
      }

      const response = await httpClient.attemptAcademicMembershipRequest(
        membershipInput
      )
      const isSuccessful = get(response, 'success', false)

      if (isSuccessful) {
        history.push(APP_ROUTES.staffSignUpThankYou)
        return
      }

      showError()
    } catch (e) {
      showError()
    }
  }

  const handleTeamMemberChange = (value: string) => {
    setSelectedStaffType(value)
  }

  const handleFieldChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    id: string
  ) => {
    const value = event.currentTarget.value
    const label = id.split('-')[1]
    const hasInput = !!value.length
    const exceededTextLimit = hasInput ? value.length > TEXT_LIMIT : false

    switch (label) {
      case 'firstName':
        setFirstName({
          ...firstName,
          value,
          error: !hasInput || exceededTextLimit,
          errorText: exceededTextLimit
            ? EXCEEDED_TEXT_LIMIT_ERROR
            : MISSED_FIELD_ERROR,
        })
        break
      case 'lastName':
        setLastName({
          ...lastName,
          value,
          error: !hasInput || exceededTextLimit,
          errorText: exceededTextLimit
            ? EXCEEDED_TEXT_LIMIT_ERROR
            : MISSED_FIELD_ERROR,
        })
        break
      case 'email':
        setEmail({
          ...email,
          value,
          error: !hasInput || !emailIsValidish(value),
          errorText: !hasInput ? MISSED_FIELD_ERROR : INVALID_EMAIL_ERROR,
        })

        break
      default:
      // do nothing
    }
  }

  useEffect(() => {
    const academicName = get(schoolSelection, 'name', null)
    const academicContext = get(schoolSelection, 'description', null)

    if (!academicName || !academicContext) {
      history.push(APP_ROUTES.staffSignUp)
    }
  }, [])

  const mergedProps = {
    fields: [firstName, lastName, email],
    staffOptions: STAFF_MEMBER_OPTIONS,
    schoolSelection,
    selectedStaffType,
    formValid,
    goBackUrl,
    handleTeamMemberChange,
    handleFieldChange,
    handleSubmit,
  }

  useEffect(() => {
    checkForm()
  }, [firstName, lastName, email])

  return <StaffSignUpExisting {...mergedProps} />
}

export default connect(
  (state: RootState) => ({ schoolSelection: state.staff.schoolSelection }),
  null
)(withRouter(StaffSignUpExistingContainer))
