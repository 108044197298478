import React, { FormEvent } from 'react'
import { css } from 'emotion'
import { toString } from 'lodash'
import TextInput from '@peachjar/ui/dist/lib/components/Inputs/TextInput'
import SelectInput from '@peachjar/ui/dist/lib/components/Inputs/SelectInput'
import { OrgFormProps } from './OrgSignUp'
import FormFooter from '../App/components/FormFooter'
import { SELECT_INPUT_TYPE, Organization } from './OrgSignUp.constants'

const TextField: any = TextInput

const OrgSignUpForm: React.FunctionComponent<OrgFormProps> = ({
  fields,
  formValid,
  handleSelectChange,
  handleFieldChange,
  handleSubmit,
}) => {
  return (
    <div className={styles.formContainer}>
      <form onSubmit={(e: FormEvent<HTMLElement>) => handleSubmit(e)}>
        {fields.map(
          (
            { id, label, value, error, errorText, limit, inputType, options },
            index
          ) => (
            <div className={styles.formField} key={index}>
              {inputType === SELECT_INPUT_TYPE && (
                <SelectInput<Organization>
                  picker={org => [org.description, toString(org.id)]}
                  // @ts-ignore
                  options={options}
                  label={label}
                  // @ts-ignore
                  value={value}
                  error={error ? errorText : null}
                  onChange={handleSelectChange}
                />
              )}
              {inputType !== SELECT_INPUT_TYPE && (
                <TextField
                  autoFocus={index === 0}
                  id={id}
                  name={id}
                  label={label}
                  type={inputType}
                  value={value}
                  error={error}
                  errorText={errorText}
                  textLimit={limit}
                  onChange={e => handleFieldChange(e, id)}
                />
              )}
            </div>
          )
        )}
        <FormFooter
          isFormValid={formValid}
          handleSubmit={handleSubmit}
          renderDisclaimer={false}
          renderLoginOption={true}
          primaryBtnText='Submit'
          />
      </form>
    </div>
  )
}

const styles = {
  formContainer: css`
    margin: 24px 0;
  `,
  formField: css`
    margin: 24px 0;
  `,
}

export default OrgSignUpForm
