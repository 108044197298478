import * as React from 'react'
import axios from 'axios'
import useGlobalNotifications from '@peachjar/ui/dist/lib/hooks/useGlobalNotifications'
import { css } from 'emotion'
import Grid from '@material-ui/core/Grid'
import { colors, elements } from '@peachjar/components'
import { LinkedAccount } from '../../App/Dependencies/AccountsBffClient'
import AccountsBffClientHttp from '../../App/Dependencies/AccountsBffClientHttp'
import config from '../../config'

const { REACT_APP_BFF_BASE_URL, REDIRECT_AFTER_LOGIN_URL } = config

const client = axios.create({
  baseURL: REACT_APP_BFF_BASE_URL,
  withCredentials: true,
})

const { Note } = elements.typography

const accountHttpClient = new AccountsBffClientHttp(axios)

type Props = {
  userId: string;
  fullName: string;
  linkedAccounts: LinkedAccount[];
  getUserType: (scopes: string[]) => string;
}

const getLinkedAccountsSorted = (linkedAccounts: LinkedAccount[]): LinkedAccount[] => {
  const sortedBy = ['district', 'school', 'organization', 'family', 'parent']
  const sortedList = linkedAccounts.sort((a, b) => {
    return sortedBy.indexOf(a.hierarchy.type) - sortedBy.indexOf(b.hierarchy.type)
  })
  return sortedList
}

const SwitchAccounts = ({
  userId,
  fullName,
  linkedAccounts,
  getUserType
}: Props) => {

  const {
    notifyError,
  } = useGlobalNotifications()

  const filteredAccounts = linkedAccounts.filter(account => account.userId !== Number(userId))
  const sortedLinkedAccounts = getLinkedAccountsSorted(filteredAccounts)

  const handleClickSwitchAccount = async (account: LinkedAccount) => {
    let destination = REDIRECT_AFTER_LOGIN_URL
    try {
      await client.get(`/auth/sessions/switch-to/${account.userId}`)
      if (['parent', 'family'].includes(account.hierarchy.type)) {
        const { redirectTo, loggedIn } = await accountHttpClient.getLoginStatus()
        if (loggedIn) {
          destination = redirectTo
        }
      }

      window.location.href = destination
    } catch (error) {
      notifyError({ message: 'An error occurred trying to switch accounts.'})
    }
  }

  const getAccountName = (account: LinkedAccount): string => {
    const userType = getUserType(account.scopes)

    if (['Parent', 'Staff', 'Volunteer', 'Organization'].includes(fullName) && fullName !== userType) {
      return userType
    }
    return fullName
  }

  if (sortedLinkedAccounts.length > 0) {
    return (
      <div>
        <div className={classNames.otherAccounts}>
          <Note muted>Other Accounts</Note>
        </div>
        <ul className={classNames.otherAccountsList}>
          {sortedLinkedAccounts.map(account => {
            const accountName = getAccountName(account)
            return (
              <li className={classNames.otherAccountsItem}
                onClick={() => handleClickSwitchAccount(account)}
                key={account.userId}>
                <Grid container>
                  <Grid item xs={12}>
                    <Grid item xs={12}>{accountName}</Grid>
                    <Grid item xs={12} className={classNames.ellipsis}>
                      <small>{account.email}</small>
                    </Grid>
                    <Grid item xs={12}>
                      {accountName !== account.hierarchy.name &&
                        <Note bold>{account.hierarchy.name}</Note>
                      }
                    </Grid>
                  </Grid>
                </Grid>
              </li>
              )
            }
          )}
        </ul>
      </div>
    )
  }
  return null
}

const classNames = {
  otherAccounts: css`
    padding: 1rem 1rem 0rem;
  `,
  otherAccountsList: css`
    list-style-type: none;
    padding: 8px;
    margin: 0px;
    border-bottom: 1px solid rgba(0,0,0,.1);
  `,
  otherAccountsItem: css`
    color: ${colors.prussian};
    border-radius: 3px;
    padding: 10px 15px;
    & + li {
      padding: 17px 15px;
    }
    cursor: pointer;
    line-height: 1.23;

    &:hover {
      text-decoration: none;
      background-color: #ededed;
    }
  `,
  ellipsis: css`
    text-overflow: ellipsis;
    overflow: hidden;
  `
}

export default SwitchAccounts
