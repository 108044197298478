import { OrganizationInput } from '../OrgSignUp.container'
export const ORG_SIGNUP_SET_INFO = 'ORG_SIGNUP_SET_INFO'
export const ORG_SIGNUP_CLEAR_INFO = 'ORG_SIGNUP_CLEAR_INFO '

type SetOrgInfo = {
  type: typeof ORG_SIGNUP_SET_INFO,
  info: OrganizationInput
}

type OrgClearInfo = {
  type: typeof ORG_SIGNUP_CLEAR_INFO
}

export function setOrgInfo(info: OrganizationInput): SetOrgInfo {
  return {
    type: ORG_SIGNUP_SET_INFO,
    info,
  }
}

export function clearOrgInfo(): OrgClearInfo {
  return {
    type: ORG_SIGNUP_CLEAR_INFO,
  }
}

export type OrgSignUpActionResults =
  | SetOrgInfo
  | OrgClearInfo
