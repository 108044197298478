import React from 'react'

const HouseIllustrationLarge = ({ ...rest }) => (
  <svg
    width='600px'
    height='418px'
    viewBox='0 0 600 418'
    version='1.1'
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
    {...rest}
  >
    <g
      id='Auth,-Account-+-Help'
      stroke='none'
      strokeWidth='1'
      fill='none'
      fillRule='evenodd'
    >
      <g id='Parent-info' transform='translate(-780.000000, -332.000000)'>
        <g
          id='Illustration-/-House'
          transform='translate(780.000000, 332.000000)'
        >
          <g id='house_fancy' transform='translate(0.000000, 0.000000)'>
            <path
              d='M16.8224192,397.681689 C-62.80514,370.975589 160.942256,386.849871 255.705097,375.426605 C350.467938,364.00334 508.864923,349.366357 583.06284,375.426605 C621.33433,388.868553 590.512041,405.156235 532.926969,406.583723 C478.870389,407.923743 509.333619,396.198017 338.281825,406.583723 C110.469681,420.415732 96.4499783,424.38779 16.8224192,397.681689 Z'
              id='Background-Shape'
              fill='#DFE1E7'
            ></path>
            <path
              d='M345.651852,155.956522 L396.47858,155.956522 L396.47858,207.847826 C396.48998,208.649 396.178383,209.4211 395.613856,209.989718 C395.049329,210.558336 394.279498,210.8755 393.478261,210.869647 L348.586957,210.869647 C347.797046,210.858118 347.044045,210.533305 346.493648,209.966601 C345.943252,209.399896 345.640558,208.637735 345.651852,207.847826 L345.651852,155.956522 L345.651852,155.956522 Z'
              id='Path'
              fill='#2D344B'
            ></path>
            <path
              d='M145.478261,237.108696 L294.065217,135.326087 C295.129674,134.60917 296.5225,134.60917 297.586957,135.326087 L446.173913,237.108696 C447.319914,237.87213 447.827124,239.298511 447.420402,240.614082 C447.01368,241.929653 445.789952,242.820897 444.413043,242.804574 L147.23913,242.804574 C145.862222,242.820897 144.638494,241.929653 144.231772,240.614082 C143.82505,239.298511 144.33226,237.87213 145.478261,237.108696 Z'
              id='Path'
              fill='#47506B'
            ></path>
            <path
              d='M294.26087,135.326087 C295.333879,134.611266 296.731338,134.611266 297.804348,135.326087 L446.391304,237.108696 C447.509383,237.890713 447.99217,239.306826 447.584631,240.608963 C447.177091,241.911101 445.973109,242.799294 444.608696,242.804348 L296.021739,242.804348 L294.26087,135.326087 Z'
              id='Path'
              fill='#2D344B'
            ></path>
            <path
              d='M403.565217,386.108772 L188.086957,386.108772 C186.358065,386.108772 184.956522,384.707152 184.956522,382.978261 L184.956522,244.673913 C184.956522,243.627294 185.479733,242.64988 186.347826,242.065217 L294.086957,170.065217 C295.141453,169.367648 296.510721,169.367648 297.565217,170.065217 L405.304348,242.065217 C406.172441,242.64988 406.695729,243.627294 406.695729,244.673913 L406.695729,382.978261 C406.701491,383.81028 406.373554,384.609915 405.785213,385.198256 C405.196872,385.786597 404.397237,386.114535 403.565217,386.108772 Z'
              id='Path'
              fill='#757D99'
            ></path>
            <rect
              id='Rectangle'
              fill='#47506B'
              x='341.521739'
              y='149.978261'
              width='58.9782609'
              height='5.97826087'
              rx='1.37'
            ></rect>
            <path
              d='M300.565217,258.130435 L381.130435,258.130435 C381.99488,258.130435 382.695652,258.831206 382.695652,259.695652 L382.695652,386.130435 L299,386.130435 L299,259.695652 C299,258.831206 299.700772,258.130435 300.565217,258.130435 Z'
              id='Path'
              fill='#FFFFFF'
            ></path>
            <path
              d='M306.26087,265.478261 L374.369565,265.478261 C375.234011,265.478261 375.934783,266.179033 375.934783,267.043478 L375.934783,386.108696 L304.695652,386.108696 L304.695652,267.043478 C304.695652,266.179033 305.396424,265.478261 306.26087,265.478261 L306.26087,265.478261 Z'
              id='Path'
              fill='#DFE1E7'
            ></path>
            <rect
              id='Rectangle'
              fill='#FFFAE5'
              x='317.73913'
              y='272.26087'
              width='18.8913043'
              height='18.8913043'
              rx='0.72'
            ></rect>
            <rect
              id='Rectangle'
              fill='#FFFAE5'
              x='344.195652'
              y='272.26087'
              width='18.8913043'
              height='18.8913043'
              rx='0.72'
            ></rect>
            <rect
              id='Rectangle'
              fill='#FFFAE5'
              x='317.73913'
              y='295.695652'
              width='18.8913043'
              height='18.8913043'
              rx='0.72'
            ></rect>
            <rect
              id='Rectangle'
              fill='#FFFAE5'
              x='344.195652'
              y='295.695652'
              width='18.8913043'
              height='18.8913043'
              rx='0.72'
            ></rect>
            <path
              d='M319.304348,329.586957 L329.76087,329.586957 C330.175991,329.586957 330.57411,329.751863 330.867645,330.045398 C331.161181,330.338933 331.326087,330.737052 331.326087,331.152174 L331.326087,386.108696 L317.73913,386.108696 L317.73913,331.152174 C317.73913,330.287728 318.439902,329.586957 319.304348,329.586957 Z'
              id='Path'
              fill='#F0F2F6'
            ></path>
            <path
              d='M334.782609,329.586957 L345.23913,329.586957 C346.103576,329.586957 346.804348,330.287728 346.804348,331.152174 L346.804348,386.108696 L333.260869,386.108696 L333.260869,331.152174 C333.260542,330.304427 333.935189,329.610505 334.782609,329.586957 L334.782609,329.586957 Z'
              id='Path'
              fill='#F0F2F6'
            ></path>
            <path
              d='M350.347825,329.586957 L360.869565,329.586957 C361.284687,329.586957 361.682806,329.751863 361.976341,330.045398 C362.269876,330.338933 362.434783,330.737052 362.434783,331.152174 L362.434783,386.108696 L348.847825,386.108696 L348.847825,331.152174 C348.847097,330.31257 349.508951,329.62194 350.347825,329.586957 Z'
              id='Path'
              fill='#F0F2F6'
            ></path>
            <circle
              id='Oval'
              fill='#9EA3B7'
              cx='369.152174'
              cy='322.195652'
              r='4.56521739'
            ></circle>
            <path
              d='M248.108696,250.130435 C228.717391,249.217391 212.717391,262.5 212.717391,281.695652 L212.586957,281.695652 L212.586957,339.717391 C212.586957,340.581837 213.287728,341.282609 214.152174,341.282609 L278.673913,341.282609 C279.538359,341.282609 280.23913,340.581837 280.23913,339.717391 L280.23913,282.608696 C280.23913,264.456522 266.282609,251 248.108696,250.130435 Z'
              id='Path'
              fill='#FFFFFF'
            ></path>
            <path
              d='M248.043478,254.695652 C239.964333,254.303113 232.077151,257.237833 226.219639,262.816025 C220.362127,268.394217 217.045843,276.128716 217.043478,284.217391 L216.934783,284.217391 L216.934783,334.782609 C216.934783,335.647054 217.635554,336.347826 218.5,336.347826 L274.608696,336.347826 C275.439007,336.301627 276.088816,335.614204 276.086957,334.782609 L276.086957,285.065217 C276.262713,269.11056 263.961591,255.789113 248.043478,254.695652 Z'
              id='Path'
              fill='#DFE1E7'
            ></path>
            <path
              d='M267.391304,282.23913 C267.391304,270.461058 257.84329,260.913043 246.065217,260.913043 C234.287145,260.913043 224.73913,270.461058 224.73913,282.23913 L224.73913,282.23913'
              id='Path'
              fill='#FFFAE5'
            ></path>
            <rect
              id='Rectangle'
              fill='#FFFAE5'
              x='225.630435'
              y='291.130435'
              width='17.4347826'
              height='17.4347826'
              rx='0.72'
            ></rect>
            <rect
              id='Rectangle'
              fill='#FFFAE5'
              x='250.043478'
              y='291.130435'
              width='17.4347826'
              height='17.4347826'
              rx='0.72'
            ></rect>
            <rect
              id='Rectangle'
              fill='#FFFAE5'
              x='225.630435'
              y='312.76087'
              width='17.4347826'
              height='17.4347826'
              rx='0.72'
            ></rect>
            <rect
              id='Rectangle'
              fill='#FFFAE5'
              x='250.043478'
              y='312.76087'
              width='17.4347826'
              height='17.4347826'
              rx='0.72'
            ></rect>
            <g id='plant' transform='translate(415.217391, 321.818182)'>
              <path
                d='M49.1041743,0.66952917 C48.3097943,0.139020888 47.2534221,0 46.3027987,0 C42.5898418,0 39.0998349,1.35670385 36.0848795,3.37822187 C34.4328338,4.4858883 32.9549538,5.89645739 31.6501984,7.36510663 C30.8970917,8.21287687 30.2255647,9.13001467 29.7501414,10.1500251 C29.4293255,10.8384857 29.2091271,11.5558077 29.0598741,12.2957046 C28.8779743,13.1974115 28.7334807,14.1174069 28.6681128,15.0341161 C28.6423821,15.3950989 28.4293973,17.310601 29.0388285,17.3430344 C29.0353332,17.3902558 29.0345152,17.4352626 29.0382335,17.4756258 C27.7149609,19.5133605 26.8973787,21.9015051 26.139215,24.1657024 C25.4795866,26.1359984 24.891796,28.1229398 24.5102229,30.1612461 C24.007879,32.8447922 23.6779161,35.5687015 23.5210034,38.2908247 C23.4873155,38.8758412 23.4641876,39.4611434 23.4442574,40.0469457 C23.4273763,40.5465923 23.4102721,41.0462388 23.393614,41.5458853 C23.3750225,42.1058265 23.2749255,42.7504948 23.3653549,43.3071499 C23.4440343,43.791937 23.8700784,43.7185689 23.8888931,43.2361392 C23.9116491,42.6535517 23.9079308,42.0698211 23.9338103,41.4868049 C23.9682418,40.7130458 24.0037145,39.9392867 24.0421619,39.1657419 C24.3098059,33.7921488 25.3138987,28.4975676 27.1669562,23.421662 C27.9168652,21.3675677 28.776167,19.4230612 29.8805797,17.5229186 C30.8515795,15.852239 32.0195755,14.2348531 33.2134508,12.7284839 C34.3738614,11.2646211 35.5803046,9.83462054 37.0021125,8.59436276 C38.1559044,7.58792582 39.4250384,6.71115123 40.7296451,5.89531436 C42.3866733,4.85915868 44.0405037,3.84814962 45.8354069,3.0527444 C46.7684056,2.63932514 47.719029,2.2691981 48.6593156,1.87249563 C48.8284987,1.80112777 49.0098779,1.73190308 49.1725912,1.6492478 C49.7153903,1.37356353 49.5366883,0.958358282 49.1041743,0.66952917'
                id='Fill-1'
                fill='#9EA3B7'
              ></path>
              <path
                d='M35.6277289,29.9486166 C34.1882506,29.9486166 32.9073397,30.1429338 31.5810188,30.7372399 C30.4361373,31.2501612 29.3927764,31.9291836 28.3797781,32.6684457 C26.9548792,33.7081584 25.8325356,34.9182556 25.0469218,36.5216106 C24.5775733,37.4794626 24.2332863,38.8348557 24.5484814,39.8795997 C24.9197871,41.1100941 25.9392726,42.1363447 27.2819786,41.9195226 C28.1122668,41.785581 28.8734033,41.2145957 29.5044624,40.6771978 C30.2593792,40.0342784 30.8636872,39.261225 31.3626627,38.4026393 C32.1766326,37.0021004 32.6549428,35.4188026 33.3463926,33.9553043 C33.6744952,33.2607121 33.991563,32.5611565 34.384537,31.9010355 C34.5790846,31.5741365 34.7862052,31.2503652 35.0813371,31.0074347 C35.2392356,30.8775046 35.9050043,30.6525916 35.6504004,30.3607758 C39.3833198,30.4117008 42.6902943,33.3900982 43.4068902,37.0767541 C43.4736343,37.4203787 43.525799,37.766791 43.5809063,38.1125233 C43.6156827,38.3308412 43.6016384,39.1359182 43.7894982,39.275367 C44.2038063,39.5830246 44.0296565,38.6484254 44.011867,38.513872 C43.9659219,38.1660999 43.9169005,37.8185998 43.8654714,37.4717116 C43.5856546,35.5849037 42.7771017,33.8308815 41.4287111,32.485279 C39.886776,30.946583 37.8063408,29.9486166 35.6277289,29.9486166'
                id='Fill-20'
                fill='#757D99'
                transform='translate(34.245059, 35.948617) scale(-1, 1) translate(-34.245059, -35.948617) '
              ></path>
              <path
                d='M5.64359035,17.312253 C5.07887346,17.312253 4.51587826,17.324218 3.9518703,17.3551802 C3.52195222,17.3789003 2.86497257,17.2848593 2.50118794,17.567612 C2.12322463,17.8612802 2.14925265,18.4624707 2.20829676,18.8870196 C2.52043045,21.1321431 3.28223111,23.1229571 4.87591566,24.7135721 C7.32974021,27.1628359 10.1427919,28.4073046 13.4715428,28.9614747 C15.8098111,29.3507581 17.9684151,28.8102324 19.6897078,27.0556754 C20.9473575,29.5186885 21.9014819,32.2443026 22.3802152,34.9952112 C22.6163916,36.352613 22.6885004,37.6936416 22.6469771,39.0758132 C22.6202401,39.9611209 22.5739568,40.8443294 22.4840234,41.7254388 C22.445032,42.10832 22.2600002,42.6878895 22.4020909,43.0496744 C22.5687917,43.4745381 23.1309767,43.5156811 23.3833574,43.1533714 C23.5934045,42.8518315 23.5548182,42.2596672 23.5979619,41.9028152 C23.669463,41.3100212 23.7356978,40.7168073 23.7830951,40.1212844 C23.8600651,39.1573643 23.9169824,38.1907154 23.9130435,37.2231219 C23.9032088,35.0491588 23.4451359,32.8666944 22.8185392,30.8030406 C21.6164903,26.8439782 19.6661104,23.3254181 16.6327826,20.5647485 C14.7676775,18.8671828 12.633076,18.179718 10.2112548,17.8106912 C8.70345254,17.5809415 7.17104018,17.312253 5.64359035,17.312253'
                id='Fill-16'
                fill='#C6C9D5'
              ></path>
              <path
                d='M6.52173913,41.2252964 C6.52173913,54.4321057 7.57686544,65.1383399 21.7391304,65.1383399 C35.9013954,65.1383399 36.9565217,54.4321057 36.9565217,41.2252964'
                id='Base'
                fill='#9EA3B7'
              ></path>
              <path
                d='M14.4236692,32.5296443 C8.09162903,32.5296443 3.82699279,37.5401497 1.81060774,42.7477292 C1.05050991,44.7106576 0.872358736,46.8661113 0.579415163,48.9272306 C0.48536213,49.5886597 0.520204268,50.5965751 0.246755701,51.1858195 C0.147517825,51.3995058 -0.0799930414,51.476338 0.0287849436,51.7674498 C0.094632437,51.9437584 0.223838699,52.0517387 0.417855487,52.0867434 C0.816155049,52.1587303 1.37933259,51.7328407 1.72080628,51.5647395 C4.55318176,50.1700923 6.41412525,48.0136497 7.93224699,45.3752509 C8.87329581,43.7399222 9.82813631,42.0422972 10.387166,40.2469756 C10.7286397,39.1502632 10.9274265,37.9952097 11.0441892,36.8574608 C11.0901269,36.4094215 11.1346128,35.9507028 11.1319167,35.4998948 C11.1292206,35.0526465 10.9172642,34.816217 10.7649336,34.4269143 C12.8777567,33.6416836 15.2625766,33.4277006 17.4027757,34.2414096 C19.3822447,34.9940088 21.0265654,36.4483814 21.9472897,38.2755433 C22.1865183,38.7504788 22.370995,39.2515195 22.5053861,39.7625473 C22.593321,40.0972666 22.5975726,40.5315612 22.760584,40.8341435 C23.1558764,41.5677572 23.987525,41.077297 23.9076784,40.3944103 C23.6850413,38.4894275 22.5750704,36.5769295 21.1563939,35.2524893 C19.3671049,33.582156 16.9417395,32.5296443 14.4236692,32.5296443'
                id='Fill-18'
                fill='#9EA3B7'
              ></path>
            </g>
            <path
              d='M132.999866,75.918469 C107.867988,75.918469 82.7335792,75.9197159 57.6017011,75.9172221 C52.41387,75.9159752 51.0232782,73.7825287 53.0946147,69.0318384 C55.3570152,63.8409922 61.5229422,60.839703 66.9410623,62.5467096 C69.0933795,63.2250234 70.5611561,63.0504574 72.0567698,61.2860934 C77.564728,54.7872487 88.5490113,52.8769974 96.1738576,59.0977832 C98.9930009,61.3958206 102.853506,59.7848253 104.044177,56.2560973 C105.099458,53.1351057 104.711003,49.7921659 106.39515,46.687384 C111.035096,38.1324007 119.241991,33.8019157 129.005236,35.5974522 C131.152492,35.9927197 131.830706,35.4291207 132.759454,33.6061524 C135.495086,28.2320119 141.653421,25.1259831 146.551493,26.3541799 C152.309986,27.7980905 157.657247,31.2382886 157.046096,39.2334137 C156.88793,41.2932931 157.377611,42.3718619 159.760217,42.9491767 C164.266038,44.0377208 167.701394,46.8557156 170.087797,50.8395622 C172.006029,54.0440961 175.794411,54.5715349 178.600901,56.5129588 C179.514465,57.1451373 180.406519,56.309714 181.256817,55.7934973 C190.432952,50.2235934 200.702327,53.9992077 204.268011,63.4968477 C204.365441,63.756203 204.589404,64.0093237 204.583077,64.2599507 C204.481851,68.4308324 205.70542,71.452072 210.568063,72.1029539 C211.697998,72.2538289 213.411247,73.654098 212.973445,75.0643423 C212.467315,76.7015224 210.525042,75.881062 209.242003,75.8835558 C183.827957,75.930938 158.413912,75.918469 132.999866,75.918469'
              id='Fill-43'
              fill='#DFE1E7'
            ></path>
            <path
              d='M488.966393,32.5443688 C473.866882,32.5443688 458.76864,32.545634 443.667861,32.5431037 C438.649909,32.5418385 438.083059,31.7915908 440.554626,28.5185876 C441.155715,27.7227937 441.914051,26.7625272 443.037605,26.7144506 C447.791789,26.5145532 449.414983,23.1352755 452.778039,21.7853357 C457.268453,19.9849942 457.963383,17.7241296 458.545451,14.2613504 C459.860492,6.45270124 471.084626,0.439332863 480.468717,0.0395381213 C494.60192,-0.561419165 504.433658,5.7695077 506.380223,13.4933903 C507.432763,17.668462 508.787116,18.93237 514.713043,18.6236678 C520.022663,18.3465948 524.443331,21.8764281 525.555473,25.9667332 C525.979025,27.5292221 526.401309,27.8720841 528.596425,27.4533118 C532.076148,26.7890958 535.535581,26.8877793 537.844828,29.4598765 C538.502983,30.1911466 539.447732,30.9527809 539.025448,31.8472584 C538.500446,32.9593457 536.973629,32.5241261 535.86656,32.5279216 C525.445146,32.5582858 515.022464,32.545634 504.599782,32.5443688 C499.389075,32.5443688 494.1771,32.5443688 488.966393,32.5443688'
              id='Fill-45'
              fill='#DFE1E7'
            ></path>
            <path
              d='M338.908851,104.167302 C340.969358,99.8616162 343.386419,97.8716673 346.290146,99.4024939 C349.715567,101.206278 352.945822,103.304404 356.712784,104.397492 C357.213211,104.543405 357.57602,105.330831 357.901298,105.88052 C358.310397,106.569832 359.007241,107.276754 358.540593,108.149715 C358.112728,108.94972 357.224471,108.633995 356.532631,108.635253 C331.138474,108.649089 305.744318,108.631479 280.350161,108.675505 C278.101994,108.679278 277.835517,107.98745 278.760055,106.074231 C279.819709,103.886797 281.549934,102.605029 283.759319,102.086787 C285.016641,101.791187 285.53208,101.242756 286.009988,100.084259 C288.355738,94.4062381 294.10314,91.7068511 300.040704,93.5722709 C301.738401,94.1056074 302.357679,93.6729004 303.177128,92.2125144 C306.573775,86.1521013 316.89883,81.3885512 324.808075,87.9219231 C328.002049,90.5609324 329.907424,93.6238435 330.566736,97.586131 C330.721869,98.5245013 330.900771,99.2263923 332.004212,99.1609831 C335.510953,98.9521768 337.194889,101.344644 338.908851,104.167302'
              id='Fill-47'
              fill='#DFE1E7'
            ></path>
            <g id='mailbox' transform='translate(95.652174, 263.272967)'>
              <polygon
                id='Fill-64'
                fill='#9DA3B6'
                points='43.4782609 132.379206 46.7391304 132.379206 46.7391304 41.0748587 43.4782609 41.0748587'
              ></polygon>
              <path
                d='M17.0434783,17.1618152 L61.2173913,17.1618152 C63.4265303,17.1618152 65.2173913,18.9526762 65.2173913,21.1618152 L65.2173913,45.4226848 L65.2173913,45.4226848 L13.0434783,45.4226848 L13.0434783,21.1618152 C13.0434783,18.9526762 14.8343393,17.1618152 17.0434783,17.1618152 Z'
                id='Rectangle'
                fill='#9EA3B7'
              ></path>
              <g
                id='Group-6'
                transform='translate(51.448938, 16.304348) rotate(18.000000) translate(-51.448938, -16.304348) translate(42.753285, 2.173913)'
              >
                <path
                  d='M2.55905429,3.7336366 L2.56935758,3.73100751 C3.08122518,3.60039454 3.60639131,3.89045075 3.76841109,4.39326063 L10.0420884,23.8629026 C10.1934355,24.3325912 9.93536881,24.8360401 9.46568024,24.9873872 C9.44813549,24.9930407 9.43041998,24.9981498 9.41255917,25.0027073 L9.40225588,25.0053364 C8.89038828,25.1359494 8.36522214,24.8458932 8.20320237,24.3430833 L1.92952506,4.8734413 C1.77817793,4.40375274 2.03624465,3.90030383 2.50593321,3.7489567 C2.52347797,3.74330328 2.54119347,3.73819413 2.55905429,3.7336366 Z'
                  id='Rectangle'
                  fill='#47506B'
                ></path>
                <circle
                  id='Oval'
                  fill='#757D99'
                  cx='8.88701537'
                  cy='24.6844483'
                  r='2.7173913'
                ></circle>
                <path
                  d='M2.35587657,5.38769866 C3.99169278,5.52393202 5.8173342,5.07145346 7.83280084,4.03026298 C9.96569332,2.92841029 12.0062353,2.07629256 13.9544268,1.47390981 C14.2182275,1.39242431 14.4981793,1.54014064 14.5797396,1.80391828 C14.5817094,1.81028893 14.5835515,1.81669836 14.5852649,1.82314272 L16.7311017,9.89402144 C16.7999824,10.1534788 16.6517443,10.4210735 16.3952233,10.5001914 C14.1824548,11.1826685 12.1823188,12.0213427 10.3948154,13.016214 C8.5844161,14.0238284 6.58773253,14.4682115 4.40476471,14.3493632 C4.18845064,14.3376651 4.00432,14.1879533 3.94865745,13.9785965 L1.83117403,6.01435989 C1.76031725,5.74751319 1.91911144,5.47369994 2.18593559,5.40275833 C2.24130122,5.38803805 2.29878514,5.382944 2.35587657,5.38769866 Z'
                  id='Rectangle-2'
                  fill='#FFDA80'
                ></path>
              </g>
              <path
                d='M17.0434783,17.1618152 L32.9565217,17.1618152 C35.1656607,17.1618152 36.9565217,18.9526762 36.9565217,21.1618152 L36.9565217,45.4226848 L36.9565217,45.4226848 L13.0434783,45.4226848 L13.0434783,21.1618152 C13.0434783,18.9526762 14.8343393,17.1618152 17.0434783,17.1618152 Z'
                id='Rectangle'
                fill='#47506B'
              ></path>
              <path
                d='M3.85958285,47.2457266 L21.6901416,45.6247667 C23.0717981,45.4991616 24.4198722,46.0984563 25.2522846,47.2083396 L36.9565217,62.8139891 L36.9565217,62.8139891 L13.0434783,62.8139891 L2.52214114,50.5390958 C1.80329683,49.7004441 1.90041991,48.4378441 2.73907159,47.7189998 C3.05438086,47.4487347 3.44600151,47.2833249 3.85958285,47.2457266 Z'
                id='Rectangle'
                fill='#757D99'
                transform='translate(18.478261, 54.118337) scale(1, -1) translate(-18.478261, -54.118337) '
              ></path>
              <path
                d='M34.7826087,130.205293 C34.7826087,130.205293 38.8523658,104.118337 39.1110323,104.118337 C39.3702289,104.118337 36.9468205,130.205293 36.9468205,130.205293 L34.7826087,130.205293 Z'
                id='Fill-87'
                fill='#9DA3B6'
              ></path>
              <path
                d='M30.4189723,130.205293 C30.4189723,130.205293 36.5869458,112.436908 36.9195402,112.820099 C37.2521345,113.20329 33.5559591,130.205293 33.5559591,130.205293 L30.4189723,130.205293 Z'
                id='Fill-88'
                fill='#9DA3B6'
                transform='translate(33.679842, 121.509641) scale(-1, 1) translate(-33.679842, -121.509641) '
              ></path>
              <g
                id='mail'
                transform='translate(23.913043, 35.640076) rotate(-14.000000) translate(-23.913043, -35.640076) translate(8.695652, 25.857467)'
              >
                <rect
                  id='Rectangle'
                  fill='#FFE6AA'
                  x='0'
                  y='0'
                  width='30.4347826'
                  height='19.5652174'
                  rx='0.5'
                ></rect>
                <rect
                  id='Rectangle'
                  fill='#FFDA80'
                  opacity='0.418550037'
                  x='15.2173913'
                  y='0'
                  width='15.2173913'
                  height='19.5652174'
                  rx='0.5'
                ></rect>
                <path
                  d='M0.5,0 L29.9347826,0 C30.210925,-2.01283612e-15 30.4347826,0.223857625 30.4347826,0.5 L30.4347826,4.01812801 C30.4347826,4.21814206 30.3155842,4.39891113 30.1317423,4.47770052 L15.2173913,10.8695652 L15.2173913,10.8695652 L0.303040351,4.47770052 C0.119198427,4.39891113 2.44946571e-17,4.21814206 0,4.01812801 L0,0.5 C-3.38176876e-17,0.223857625 0.223857625,5.07265313e-17 0.5,0 Z'
                  id='Rectangle'
                  fill='#FCC35D'
                ></path>
              </g>
              <path
                d='M36.9565217,21.5096413 L45.6521739,21.5096413 L45.6521739,43.2487717 L36.9565217,43.2487717 L36.9565217,25.8574674 C36.9565217,24.408192 36.9565217,22.9589166 36.9565217,21.5096413 Z'
                id='Rectangle'
                fill='#9EA3B7'
              ></path>
              <line
                x1='58.6956522'
                y1='131.296203'
                x2='23.9130435'
                y2='131.296203'
                id='Stroke-86'
                stroke='#9DA3B6'
                strokeWidth='3'
                stroke-linecap='round'
                stroke-linejoin='round'
              ></line>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default HouseIllustrationLarge
