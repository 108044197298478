import React, { FormEvent } from 'react'
import { css } from 'emotion'
import colors from '@peachjar/ui/dist/lib/styles/colors'
import { Choice } from '@peachjar/ui/dist/lib/components/Inputs/RadioGroupInput'
import TwoTone from '../App/Layout/TwoTone'
import FlexInset from '../App/Layout/FlexInset'
import StaffSignUpExistingForm from './StaffSignUpExistingForm'
import { AcademicEntity } from './Redux/StaffState'
import IllustratedQuote from '../App/Layout/IllustratedQuote'
import SchoolIllustration from '../App/components/SchoolIllustrationLarge'
import PrimaryHeading from '../App/Typography/PimaryHeading'
import { Field } from './StaffSignUpExisting.container'

export type FormProps = {
  fields: Field[]
  staffOptions: Array<Choice<string>>
  schoolSelection: AcademicEntity
  selectedStaffType: string
  formValid: boolean
  handleTeamMemberChange: (value: string) => void
  handleFieldChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    id: string
  ) => void
  handleSubmit: (e: FormEvent<HTMLElement>) => void
}

type Props = FormProps & {
  goBackUrl: string
}

const StaffSignUpExisting: React.FunctionComponent<Props> = ({
  goBackUrl,
  schoolSelection,
  ...rest
}) => {
  const {
    name: academicGroupName,
    description: academicGroupContext,
  } = schoolSelection

  const academicContextString =
    academicGroupContext === 'schools' ? 'School' : 'District'

  return (
    // @ts-ignore
    <TwoTone goBackUrl={goBackUrl}>
      <FlexInset>
        <PrimaryHeading>Tell us a little bit about you.</PrimaryHeading>
        <div className={styles.academicKicker}>
          <span className={styles.academicContext}>
            {academicContextString}
          </span>
          <span className={styles.academicName}>{academicGroupName}</span>
        </div>
        <div className={styles.pageContainer}>
          <StaffSignUpExistingForm
            {...rest}
            schoolSelection={schoolSelection}
          />
        </div>
      </FlexInset>
      <IllustratedQuote svg={<SchoolIllustration />} />
    </TwoTone>
  )
}

const styles = {
  pageContainer: css`
    margin-top: 40px;
  `,
  academicKicker: css`
    margin-top: 40px;
  `,
  academicContext: css`
    font-family: 'Proxima Nova', Helvetica, Arial, sans-serif;
    font-size: 13px;
    color: ${colors.slate};
    display: block;
    margin-bottom: 4px;
  `,
  academicName: css`
    font-family: 'Proxima Nova', Helvetica, Arial, sans-serif;
    font-size: 16px;
    color: ${colors.slate};
  `,
}

export default StaffSignUpExisting
