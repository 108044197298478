import * as Sentry from '@sentry/browser'
import some from 'lodash/some'
import { NOTIFICATIONS, APP_ROUTES } from '../App/config'
import config from '../config'

const { REACT_APP_ACCOUNTS_APP_URL } = config
const { genericError } = NOTIFICATIONS

export function isGqlAuthError(error: Error): boolean {
  return (
    error.hasOwnProperty('graphQLErrors') &&
    some(
      // @ts-ignore
      error.graphQLErrors,
      ({ message }) =>
        message.includes('user to be authenticated') ||
        message.includes('is not authenticated')
    )
  )
}

export function handleGqlAuthError(error: Error) {
  // Don't redirect to login when we are already there.
  if (window.location.pathname !== '/login'
    && !window.location.pathname.includes('signup')
    && !window.location.pathname.includes('email-notifications')
  ) {
    window.location.href = `${REACT_APP_ACCOUNTS_APP_URL}${APP_ROUTES.login}`
  }
}

export function checkGqlError(err: any): string | null | undefined {
  if (err && isGqlAuthError(err)) {
    handleGqlAuthError(err)
    return null
  } else if (err) {
    Sentry.captureException(new Error(err))
    return genericError
  }
}
