import React from 'react'
import styles from './ManageNotifications.style'
import happyTurbleSVG from '../assets/happy-turtle-icon.svg'

const ManageNotifications: React.FunctionComponent<{}> = ({}) => {
  return (
    <div className={styles.container}>
      <div>
        <img className={styles.turtleImage} src={happyTurbleSVG} />
        <p className={styles.title}>
          Manage Notifications
        </p>
        <p className={styles.description}>
          Contact us at <a href='tel:8589972117' className={styles.greenText}>(858) 997-2117</a> or <a href='malito:support@peachjar.com' className={styles.greenText}>support@peachjar.com</a> to stop<br/>
          receiving email notifications from your school.
        </p>
      </div>
    </div>
  )
}

export default ManageNotifications
