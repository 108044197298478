import React from 'react'
import { css } from 'emotion'
import { colors } from '@peachjar/components'
import PrimaryHeading from '../App/Typography/PimaryHeading'
import { ButtonPrimaryLarge } from '@peachjar/ui/dist/lib/components/Buttons/Buttons'

type Props = {
    resendMagicLinkHandler: () => void
}

const MagicLinkExpired = ({ resendMagicLinkHandler }: Props) => (
    <div>
        <PrimaryHeading>Uh Oh!</PrimaryHeading>
        <p className={styles.emailConfirmation}>
            The magic log in link you are trying to use is expired. Use the
            button below to receive a new one.
        </p>
        <div className={styles.ctaSection}>
            <ButtonPrimaryLarge onClick={resendMagicLinkHandler}>
                Resend Link
            </ButtonPrimaryLarge>
        </div>
    </div>
)

const styles = {
    emailConfirmation: css`
        font-size: 16px;
        font-family: 'Proxima Nova', Helvetica, Arial, sans-serif;
        color: ${colors.prussian};
        padding-top: 36px;
    `,
    ctaSection: css`
        margin-top: 40px;
    `,
    ctaSectionOptions: css`
        text-align: right;
    `,
}

export default MagicLinkExpired
