import React, { FormEvent, useEffect, useState } from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import { APP_ROUTES } from '../App/config'
import OrgSignUp from './OrgSignUp'
import {
  CHARACTER_LENGTH_ERROR,
  INVALID_PHONE_NUMBER_ERROR,
  INVALID_POSTAL_CODE,
  MISSED_FIELD_ERROR,
  Organization,
  organizations,
  PHONE_NUMBER_MAX_LENGTH,
  SELECT_INPUT_TYPE,
} from './OrgSignUp.constants'
import { formatPhoneNumber, validateZipCode, } from '../Utility/formUtils'
import { ActionResults, RootState } from '../App/Store/makeStore'
import { setOrgInfo as setOrganization } from './Redux/OrgSignUpActions'

export type Field = {
  id: string
  label: string
  value: any
  error: boolean
  errorText: string
  limit: number | null
  inputType: string
  options?: Organization[]
}

export type OrganizationInput = {
  name: string
  orgCategory: string
  postalCode: string
  phone: string
  email?: string
  firstName?: string
  lastName?: string,
  userEmail?: string,
}

type Props = RouteComponentProps & {
  orgInfo: OrganizationInput
  setOrgInfo: (info: OrganizationInput) => void
}

const FIELD_LIMIT = 50

const ORG_CATEGORY_MAPPING = {
  ForProfit: 'profit',
  NonProfit: 'nonprofit',
  Government: 'government',
}

const defaultFieldState = (key: string, label: string): Field => ({
  id: `${Date.now()}-${key}`,
  label,
  value: '',
  error: false,
  errorText: MISSED_FIELD_ERROR,
  limit: null,
  inputType: 'text',
})

const OrgSignUpContainer: React.FunctionComponent<Props> = ({
  orgInfo,
  setOrgInfo,
  history,
}) => {
  const [orgName, setOrgName] = useState<Field>({
    ...defaultFieldState('orgName', 'Organization Name'),
    limit: FIELD_LIMIT,
    value: orgInfo.name
  })
  const [orgType, setOrgType] = useState<Field>({
    ...defaultFieldState('orgType', 'Organization Type'),
    inputType: SELECT_INPUT_TYPE,
    options: organizations,
    value: orgInfo.orgCategory
  })
  const [postalCode, setPostalCode] = useState<Field>({
    ...defaultFieldState('postalCode', 'Postal Code'),
    value: orgInfo.postalCode
  })

  const [phoneNumber, setPhoneNumber] = useState<Field>({
    ...defaultFieldState('phoneNumber', 'Phone Number'),
    inputType: 'tel',
    value: orgInfo.phone
  })

  const [formValid, setFormValid] = useState<boolean>(false)

  const goBackUrl = APP_ROUTES.signup

  const checkForm = () => {
    const fieldsValid = [
      orgName,
      orgType,
      postalCode,
      phoneNumber,
    ].every(({ value, error }) => {
      if (typeof value === 'object' && typeof value !== null) {
        return value.description.length > 0 && !error
      }

      return value.length > 0 && !error
    })

    setFormValid(fieldsValid)
  }

  const handleSubmit = async (event: FormEvent<HTMLElement>) => {
    event.preventDefault()

    if (!formValid) {
      const fields = [
        { field: orgName, setState: setOrgName },
        { field: orgType, setState: setOrgType },
        { field: postalCode, setState: setPostalCode },
        { field: phoneNumber, setState: setPhoneNumber },
      ]

      fields.forEach(({ field, setState }) => {
        const fieldValid = !!field.value
        setState({ ...field, error: !fieldValid })
      })

      return
    }

    const orgInput: OrganizationInput = {
      name: orgName.value,
      orgCategory:
        ORG_CATEGORY_MAPPING[
          orgType.value.description.replace(' ', '').replace('-', '')
        ],
      postalCode: postalCode.value,
      phone: phoneNumber.value,
    }
    setOrgInfo(orgInput)
    history.push(APP_ROUTES.orgSignUpAccountInfo)

  }

  const handleSelectChange = (o: Organization) =>
    setOrgType({ ...orgType, value: o, error: false })

  const handleFieldChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    id: string
  ) => {
    const value = event.currentTarget.value
    const label = id.split('-')[1]
    const hasInput = !!value.length
    const inputExceedsLimit = value.length > FIELD_LIMIT

    switch (label) {
      case 'orgName':
        setOrgName({
          ...orgName,
          value,
          error: !hasInput || inputExceedsLimit,
          errorText:
            value.length > FIELD_LIMIT
              ? CHARACTER_LENGTH_ERROR(FIELD_LIMIT)
              : MISSED_FIELD_ERROR,
        })
        break
      case 'postalCode':
        setPostalCode({
          ...postalCode,
          value,
          error: !hasInput || !validateZipCode(value),
          errorText: !hasInput ? MISSED_FIELD_ERROR : INVALID_POSTAL_CODE,
        })
        break
      case 'phoneNumber':
        setPhoneNumber({
          ...phoneNumber,
          value:
            value.replace(' ', '').length > PHONE_NUMBER_MAX_LENGTH
              ? phoneNumber.value
              : formatPhoneNumber(value),
          error:
            value.length - 1 !== PHONE_NUMBER_MAX_LENGTH &&
            value.length - 1 < PHONE_NUMBER_MAX_LENGTH,
          errorText:
            value.length > 0 ? INVALID_PHONE_NUMBER_ERROR : MISSED_FIELD_ERROR,
        })
        break
      default:
      // do nothing
    }
  }

  const mergedProps = {
    fields: [
      orgName,
      orgType,
      postalCode,
      phoneNumber,
    ],
    formValid,
    goBackUrl,
    handleSelectChange,
    handleFieldChange,
    handleSubmit,
  }

  useEffect(() => {
    checkForm()
  }, [orgName, orgType, postalCode, phoneNumber])

  return <OrgSignUp {...mergedProps} />
}

export default connect(
  (state: RootState) => ({
    orgInfo: state.orgs.info,
  }),
  (dispatch: Dispatch<ActionResults>) => ({
    setOrgInfo: (info: OrganizationInput) => dispatch(setOrganization(info))
  })
)(withRouter(OrgSignUpContainer))
