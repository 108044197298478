import React, { FormEvent } from 'react'
import { css } from 'emotion'
import TwoTone from '../App/Layout/TwoTone'
import FlexInset from '../App/Layout/FlexInset'
import OrgSignUpForm from './OrgSignUpForm'
import IllustratedQuote from '../App/Layout/IllustratedQuote'
import TreesIllustration from '../App/components/TreesIllustrationLarge'
import PrimaryHeading from '../App/Typography/PimaryHeading'
import { Field } from './OrgSignUp.container'
import { Organization } from './OrgSignUp.constants'

export type OrgFormProps = {
  fields: Field[]
  formValid: boolean
  handleSelectChange: (o: Organization) => void
  handleFieldChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    id: string
  ) => void
  handleSubmit: (e: FormEvent<HTMLElement>) => void
}

type Props = OrgFormProps & {
  goBackUrl: string
}

const OrgSignUp: React.FunctionComponent<Props> = ({ goBackUrl, ...rest }) => {
  return (
    // @ts-ignore
    <TwoTone goBackUrl={goBackUrl}>
      <FlexInset>
        <PrimaryHeading>
          Great! Tell us about your organization.
        </PrimaryHeading>
        <div className={styles.pageContainer}>
          <OrgSignUpForm {...rest} />
        </div>
      </FlexInset>
      <IllustratedQuote svg={<TreesIllustration />} />
    </TwoTone>
  )
}

const styles = {
  pageContainer: css`
    margin-top: 40px;
  `,
}

export default OrgSignUp
