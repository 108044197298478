import * as React from 'react'
import { css } from 'emotion'
import { matchPath, Link } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import { components, colors } from '@peachjar/components'
import { APP_ROUTES } from '../../App/config'
import logout from '../../Utility/logout'
import { LinkedAccount } from '../Dependencies/AccountsBffClient'
import { Flags } from '../Dependencies/PortalBffClientHttp'
import SwitchAccounts from './SwitchAccounts'
import config from '../../config'

const { Profile } = components

const helpPageLink = config.REACT_APP_PEACHJAR_SUPPORT_URL
                     || 'https://peachjar.com/contact-us.html'

type Props = {
  userId: string;
  entityName: string;
  firstName: string;
  lastName: string;
  emailAddress: string;
  hierarchyType: string;
  avatarURI?: string;
  hideProfileLinks: boolean;
  linkedAccounts: LinkedAccount[];
  flags: Flags;
  getUserType: (scopes: string[]) => string
}

const ProfileDropdown = ({
  userId,
  entityName,
  firstName,
  lastName,
  emailAddress,
  hierarchyType,
  avatarURI,
  linkedAccounts,
  flags,
  getUserType,
  hideProfileLinks = false
}: Props) => {
  const location = useLocation()
  const pathname = location.pathname
  const queryString = window.location.search

  const dropDownConfig = {
    activeLinks: [
      APP_ROUTES.parentAccountInfo,
      APP_ROUTES.parentEmailNotifications,
    ],
    profileLinks: [
      {
        text: 'Account Information',
        link: `${APP_ROUTES.parentAccountInfo}${queryString}`,
        linkType: 'internal',
        requiresRoleCheck: true,
        allowedRoles: ['parent', 'family'],
        isTargetBlank: false
      },
      {
        text: 'Notifications',
        link: `${APP_ROUTES.parentEmailNotifications}${queryString}`,
        linkType: 'internal',
        requiresRoleCheck: true,
        allowedRoles: ['parent', 'family'],
        isTargetBlank: false
      },
      {
        text: 'Help',
        link: helpPageLink,
        linkType: 'external',
        requiresRoleCheck: false,
        allowedRoles: [],
        isTargetBlank: true
      },
    ],
  }
  // @ts-ignore
  const isPortalNavEnabled = flags.portal_nav === 1
  const { activeLinks, profileLinks } = dropDownConfig

  const getLink = (
    content: any,
    link: string,
    linkType: string,
    index: number,
    isTargetBlank: boolean
  ): React.ReactElement => {
    if (linkType === 'internal') {
      return (
        <Link key={index} to={link} className={classNames.profileLink}>
          {content}
        </Link>
      )
    }

    return (
      <a
        href={link}
        key={index}
        className={classNames.profileLink}
        target={isTargetBlank ? '_blank' : ''}>
        {content}
      </a>
    )
  }
  const isDropDownActive = activeLinks.includes(pathname)

  const getUserName = () => `${firstName} ${lastName}`.trim()

  return (
    <Profile
      name={firstName}
      emailAddress={emailAddress}
      profilePictureURI={avatarURI}
      entityName={entityName}
      fullName={getUserName()}
      data-testid='menu-profile-dropdown'
      className={`${classNames.dropdownTrigger} ${
        isPortalNavEnabled && isDropDownActive && userId !== '' ? classNames.dropDownActive : ''
      }`}
    >
      {userId !== '' && (
        <SwitchAccounts
          userId={userId}
          fullName={getUserName()}
          linkedAccounts={linkedAccounts}
          getUserType={getUserType}
        />
      )}
      {isPortalNavEnabled && (
        <ul className={classNames.profileMenu}>
          {!hideProfileLinks && profileLinks.map(
            (
              {
                text,
                link,
                linkType,
                allowedRoles,
                requiresRoleCheck,
                isTargetBlank
              },
              idx
            ) => {
                if (
                  requiresRoleCheck &&
                  !allowedRoles.includes(hierarchyType)
                ) {
                  return
                }

                const isActive = matchPath(pathname, {
                  path: link.replace(queryString, ''),
                  exact: true,
                })
                activeLinks.includes(pathname)
                const liContent = (
                  <li
                    className={`${classNames.profileLinkList} ${
                      isActive ? classNames.activeLink : ''
                    }`}
                  >
                    {text}
                  </li>
                )

                return getLink(liContent, link, linkType, idx, isTargetBlank)

            }
          )}
          <a
            className={classNames.profileLink}
            href={void(0)}
            onClick={() => logout()}
            data-testid='click-profiledropdown-logout'
          >
            <li className={classNames.profileLinkList} tabIndex={0}>Log Out</li>
          </a>
        </ul>
      )}
    </Profile>
  )
}

const classNames = {
  dropdownTrigger: css`
    border-left: 1px solid ${colors.prussian};
    height: 100%;
    & .MuiSvgIcon-root {
      fill: #fff;
    }
    & .MuiButton-label-2 {
      span {
        color: #fff;
      }
    }

    @media (max-width: 480px) {
      & button {
        min-width: 50px;
        width: inherit !important;
        & > span > div > span {
          display: none;
        }
        & > span > svg {
          display: none;
        }
      }
      div[role=tooltip] {
        left: -42%
      }
    }
  `,
  dropDownActive: css`
    border-bottom: 4px solid ${colors.leaf};
  `,
  profileMenu: css`
    list-style-type: none;
    padding: 8px;
  `,
  profileLink: css`
    &:hover {
      text-decoration: none;
    }
  `,
  profileLinkList: css`
    color: ${colors.prussian};
    padding: 17px 15px;
    height: 54px;
    font-size: 13px;
    border-radius: 3px;

    &:hover {
      text-decoration: none;
      background-color: #ededed;
    }
  `,
  activeLink: css`
    border-left: 4px solid ${colors.leaf};
    font-weight: bold;
    background-color: rgba(0, 0, 0, 0.03);
  `,
}

export default ProfileDropdown
