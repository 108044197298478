import React from 'react'
import { css } from 'emotion'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import { withStyles } from '@material-ui/core/styles'
import colors from '@peachjar/ui/dist/lib/styles/colors'

type Props = {
  classes: {
    [k: string]: any
  }
  name: string
  disabled?: boolean
}

const CommunityBanner: React.FunctionComponent<Props> = ({
  classes,
  name,
  disabled
}) => {
  return (
    <Paper
      className={`
          ${cn.content} ${classes.elevation2}
          ${disabled ? cn.disabled : cn.active}`
        }
    >
      <span className={cn.label}>
        {name}
      </span>
    </Paper>
  )
}

// Override default material-ui styles
const materialOverrides = {
  elevation2: {
    boxShadow: 'none',
  },
}

const cn = {
  content: css`
    margin-bottom: 16px;
    padding: 8px 12px 8px 11px;
    display: inline-block;
  `,
  active: css`
    background-color: #fff4d9 !important;
    color: #b34809 !important;
  `,
  disabled: css`
    background-color: ${colors.mercury} !important;
    color: ${colors.slate} !important;
  `,
  flexRow: css`
    display: flex;
    flex-direction: row;
  `,
  label: css`
    font-family: 'Proxima Nova', Arial, Helvetica, sans-serif;
    font-size: 16px;
    line-height: 24px;
  `
}

export default withStyles(materialOverrides)(CommunityBanner)
