import { applyMiddleware, combineReducers, createStore, Store } from 'redux'
import thunk, { ThunkMiddleware } from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'
import { logIn, LogInState } from '../../LogIn/Redux/LogInState'
import { staff, StaffState } from '../../StaffSignUp/Redux/StaffState'
import { StaffActionResults } from '../../StaffSignUp/Redux/StaffActions'
import { LogInActionResults } from '../../LogIn/Redux/LogInActions'
import { OrgSignUpActionResults } from '../../OrgSignUp/Redux/OrgSignUpActions'
import { orgs , OrgSignUpState } from '../../OrgSignUp/Redux/OrgSignUpState'
import Dependencies from '../Dependencies/Dependencies'

export type RootState = {
  logIn: LogInState,
  staff: StaffState,
  orgs: OrgSignUpState,
}
export type ActionResults = LogInActionResults | StaffActionResults | OrgSignUpActionResults

export default function makeStore(
  deps: Dependencies,
  initialState?: object
): Store<RootState, ActionResults> {
  const rootReducer = combineReducers({
    logIn,
    staff,
    orgs
  })
  const middleware = composeWithDevTools(
    applyMiddleware(
      thunk.withExtraArgument(deps) as ThunkMiddleware<
        RootState,
        ActionResults,
        Dependencies
      >
    )
  )

  return createStore(rootReducer, initialState!, middleware)
}
