import React, { Component, ReactNode } from 'react'
import * as Sentry from '@sentry/browser'
import ErrorBoundaryPage from './ErrorBoundaryPage'

interface Props {
    children: ReactNode
}

interface State {
    hasError: boolean
}

class ErrorBoundary extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = { hasError: false }
    }

    static getDerivedStateFromError(error: Error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true }
    }

    componentDidCatch(error: Error, errorInfo: any) {
        this.setState({hasError: true})

        // tslint:disable-next-line:no-console
        console.error(error, errorInfo)

        // log to sentry (passing additional error context where possible
        Sentry.withScope(scope => {
            Object.keys(errorInfo).forEach(key => {
                scope.setExtra(key, errorInfo[key])
            })
            Sentry.captureException(error)
        })
    }

    render() {
        if (this.state.hasError) {
            return <ErrorBoundaryPage/>
        }

        return this.props.children
    }
}

export default ErrorBoundary
