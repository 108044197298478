/*
 * Define the expected configuration variables
 */
type Config = {
  PEACHJAR_DOMAIN: string,
  // API Urls
  REACT_APP_API_BASE_URL: string
  REACT_APP_GRAPHQL_URI: string
  REACT_APP_BFF_BASE_URL: string
  REACT_APP_BFF_GQL_URI: string
  REACT_APP_PORTAL_BFF_GRAPHQL_URI: string

  // Apps
  REACT_APP_ACCOUNTS_APP_URL: string
  REACT_APP_INTERNAL_APP_URL: string
  REACT_APP_ONBOARDING_URL: string

  // Login
  REDIRECT_AFTER_LOGIN_URL: string

  // Logging
  REACT_APP_SENTRY_API_KEY: string
  REACT_APP_SENTRY_PROJECT_ID: string

  // Other URLs
  REACT_APP_PEACHJAR_HOMEPAGE: string
  REACT_APP_PEACHJAR_SUPPORT_URL: string

  // Parent Redirect Urls
  REACT_APP_PARENT_HOMEPAGE_URL: string

  // Flyer board Url
  REACT_APP_FLYER_BOARD_APP_URL: string

  // GTM Tag ID
  REACT_APP_GTM_TAG: string

}

/**
 * Load a configuration based on the domain (which is how we dynamically determine the environment)
 *
 * @param {string} domain
 */
function getConfig(domain: string): Config {
  switch (domain) {
    // For production, use the following configuration
    case 'accounts.peachjar.com':
      return {
        PEACHJAR_DOMAIN: 'peachjar.com',
        REACT_APP_API_BASE_URL: 'https://portal-api.peachjar.com',
        REACT_APP_BFF_GQL_URI: 'https://accounts-bff.peachjar.com/graphql',
        REACT_APP_GRAPHQL_URI: 'https://portal-api.peachjar.com/graphql',
        REACT_APP_BFF_BASE_URL: 'https://accounts-bff.peachjar.com',
        REACT_APP_ACCOUNTS_APP_URL: 'https://accounts.peachjar.com',
        REACT_APP_INTERNAL_APP_URL: 'https://internal.peachjar.com',
        REACT_APP_ONBOARDING_URL:
          'https://join.peachjar.com/onboarding/setup/school',
        REDIRECT_AFTER_LOGIN_URL: 'https://portal.peachjar.com/dashboard',
        REACT_APP_SENTRY_API_KEY: '22770cf895fc47a1a8c801c3468bb6ab',
        REACT_APP_SENTRY_PROJECT_ID: '1217397',
        REACT_APP_PEACHJAR_HOMEPAGE: 'https://www.peachjar.com',
        REACT_APP_PEACHJAR_SUPPORT_URL: 'https://peachjar.com/contact-us.html',
        REACT_APP_PARENT_HOMEPAGE_URL: 'https://accounts.peachjar.com/',
        REACT_APP_PORTAL_BFF_GRAPHQL_URI: 'https://portal-bff.peachjar.com/graphql',
        REACT_APP_FLYER_BOARD_APP_URL: 'https://app.peachjar.com',
        REACT_APP_GTM_TAG: 'GTM-WRWNQXN'
      }

    // For Kauai or any other environment, use the following configuration
    case 'accounts.peachjar-kauai.com':
      return {
        PEACHJAR_DOMAIN: 'peachjar-kauai.com',
        REACT_APP_API_BASE_URL: 'https://portal-api.peachjar-kauai.com',
        REACT_APP_BFF_GQL_URI:
          'https://accounts-bff.peachjar-kauai.com/graphql',
        REACT_APP_GRAPHQL_URI: 'https://portal-api.peachjar-kauai.com/graphql',
        REACT_APP_BFF_BASE_URL: 'https://accounts-bff.peachjar-kauai.com',
        REACT_APP_ACCOUNTS_APP_URL: 'https://accounts.peachjar-kauai.com',
        REACT_APP_INTERNAL_APP_URL: 'https://internal.peachjar-kauai.com',
        REACT_APP_ONBOARDING_URL:
          'https://join.peachjar-kauai.com/onboarding/setup/school',
        REDIRECT_AFTER_LOGIN_URL: 'https://portal.peachjar-kauai.com/dashboard',
        REACT_APP_SENTRY_API_KEY: '22770cf895fc47a1a8c801c3468bb6ab',
        REACT_APP_SENTRY_PROJECT_ID: '1217397',
        REACT_APP_PEACHJAR_HOMEPAGE: 'https://www.peachjar.com',
        REACT_APP_PEACHJAR_SUPPORT_URL: 'https://peachjar.com/contact-us.html',
        REACT_APP_PARENT_HOMEPAGE_URL: 'https://accounts.peachjar-kauai.com/',
        REACT_APP_PORTAL_BFF_GRAPHQL_URI: 'https://portal-bff.peachjar-kauai.com/graphql',
        REACT_APP_FLYER_BOARD_APP_URL: 'https://app.peachjar-kauai.com',
        REACT_APP_GTM_TAG: 'GTM-KCX8GFH'
      }

    case 'localhost':
    case 'peachjar.net':
    default:
      return {
        PEACHJAR_DOMAIN: 'peachjar.net:10443',
        REACT_APP_API_BASE_URL: 'https://portal-api.peachjar.net:10443',
        REACT_APP_BFF_GQL_URI:
          'https://accounts-bff.peachjar.net:10443/graphql',
        REACT_APP_GRAPHQL_URI: 'https://portal-api.peachjar.net:10443/graphql',
        REACT_APP_BFF_BASE_URL: 'https://accounts-bff.peachjar.net:10443',
        REACT_APP_ACCOUNTS_APP_URL: 'https://accounts.peachjar.net:10443',
        REACT_APP_INTERNAL_APP_URL: 'https://internal.peachjar.net:10443',
        REACT_APP_ONBOARDING_URL:
          'https://join.peachjar.net:10443/onboarding/setup/school',
        REDIRECT_AFTER_LOGIN_URL: 'https://portal.peachjar.net:10443/dashboard',
        REACT_APP_SENTRY_API_KEY: '22770cf895fc47a1a8c801c3468bb6ab',
        REACT_APP_SENTRY_PROJECT_ID: '1217397',
        REACT_APP_PEACHJAR_HOMEPAGE: 'https://www.peachjar.com',
        REACT_APP_PEACHJAR_SUPPORT_URL: 'https://peachjar.com/contact-us.html',
        REACT_APP_PARENT_HOMEPAGE_URL: 'https://accounts.peachjar.net:10443/',
        REACT_APP_PORTAL_BFF_GRAPHQL_URI: 'https://portal-bff.peachjar.net:10443/graphql',
        REACT_APP_FLYER_BOARD_APP_URL: 'https://app.peachjar.net:10443',
        REACT_APP_GTM_TAG: 'GTM-KCX8GFH'
      }
  }
}

// Export the config for our current domain
export default getConfig(window.location.hostname)
