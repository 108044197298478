import React from 'react'
import { css } from 'emotion'
import { RouteComponentProps } from 'react-router-dom'
import colors from '@peachjar/ui/dist/lib/styles/colors'
import { ButtonSecondaryLarge } from '@peachjar/ui/dist/lib/components/Buttons/Buttons'
import TwoTone from '../App/Layout/TwoTone'
import FlexInset from '../App/Layout/FlexInset'
import IllustratedQuote from '../App/Layout/IllustratedQuote'
import SchoolIllustration from '../App/components/SchoolIllustrationLarge'
import PrimaryHeading from '../App/Typography/PimaryHeading'
import config from '../config'

const { REACT_APP_PEACHJAR_HOMEPAGE } = config

type Props = RouteComponentProps

const StaffSignUpNewThankYou: React.FunctionComponent<{}> = () => (
  <TwoTone>
    <FlexInset>
      <PrimaryHeading>Thanks for your interest in Peachjar!</PrimaryHeading>
      <div className={styles.pageContainer}>
        <p className={styles.pageCopy}>
          We'll reach out to you within 3 - 4 business days. If you haven't
          heard from us within that time please contact us.
        </p>
        <ButtonSecondaryLarge
          onClick={() => {
            window.location.href = REACT_APP_PEACHJAR_HOMEPAGE
          }}
        >
          Back to Home
        </ButtonSecondaryLarge>
      </div>
    </FlexInset>
    <IllustratedQuote svg={<SchoolIllustration />} />
  </TwoTone>
)

const styles = {
  pageContainer: css`
    margin-top: 40px;
  `,
  pageCopy: css`
    font-family: 'Proxima Nova', Helvetica, Arial, sans-serif;
    font-size: 16px;
    color: ${colors.prussian};
    margin-bottom: 40px;
  `,
}

export default StaffSignUpNewThankYou
