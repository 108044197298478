import React from 'react'
import { css } from 'emotion'
import TextInput from '@peachjar/ui/dist/lib/components/Inputs/TextInput'
import { components, colors, elements, fonts } from '@peachjar/components'
import { Field } from './CompleteProfile.container'

const {
  Buttons: { ButtonPrimaryLarge },
} = components
const { ProximaNova } = fonts

const TextField: any = TextInput

type Props = {
  fields: Field[]
  isFormValid: boolean
  handleInputChange: (
    id: string,
    event: React.ChangeEvent<HTMLInputElement>
  ) => void
  handleFormSubmit: () => void
}

const CompleteProfileForm = ({
  fields,
  isFormValid,
  handleInputChange,
  handleFormSubmit,
}: Props) => (
  <form className={classNames.form} onSubmit={(e: any) => e.preventDefault()}>
    {fields.map(
      ({ label, alias, value, error, errorText, textLimit }, index) => {
        return (
          <div
            className={`${index === 0 ? classNames.fieldMargin : ''}`}
            key={index}
          >
            <TextField
              autoFocus={index === 0}
              id={alias}
              className={classNames.input}
              name={alias}
              label={label}
              type='text'
              value={value}
              error={error}
              errorText={errorText}
              textLimit={textLimit}
              onChange={e => handleInputChange(alias, e)}
            />
          </div>
        )
      }
    )}
    <div className={classNames.buttonWrapper}>
      <ButtonPrimaryLarge
        id='btnSubmit'
        disabled={!isFormValid}
        role='submit'
        onClick={handleFormSubmit}
        className={classNames.btnSubmit}
      >
        Log In
      </ButtonPrimaryLarge>
    </div>
  </form>
)

const classNames = {
  form: css`
    margin-top: 3rem;
  `,
  fieldMargin: css`
    margin-bottom: 31px;
  `,
  buttonWrapper: css`
    margin-left: -5px;
    margin-top: 2rem;
  `,
  btnSubmit: css`
    width: auto !important;
  `,
  input: css`
    width: 100%;
    margin-top: 2rem;
  `,
  forgotLink: css`
    float: right;
    &,
    &:hover {
      color: ${colors.jungle};
      text-decoration: none;
    }
  `,
  getStarted: css`
    color: ${colors.slate};
    font-family: ${ProximaNova};
  `,
  getStartedLinkIntro: css`
    color: ${colors.prussian};
    font-family: ${ProximaNova};
  `,
  getStartedLink: css`
    color: ${colors.jungle};
    font-family: ${ProximaNova};
    &:hover {
      color: #c5e8c9;
      text-decoration: none;
    }
  `,
  linkWrapper: css`
    margin-top: 32px;
  `,
}

export default CompleteProfileForm
