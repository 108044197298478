import React, { useState, useEffect, FormEvent } from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import axios from 'axios'
import useGlobalNotifications from '@peachjar/ui/dist/lib/hooks/useGlobalNotifications'
import { AddressAndPlaceId } from '@peachjar/ui/dist/lib/components/Inputs/AddressInput'
import { APP_ROUTES } from '../App/config'
import StaffSignUpNew from './StaffSignUpNew'
import AccountsBffClientHttp from '../App/Dependencies/AccountsBffClientHttp'
import {
  INVALID_EMAIL_ERROR,
  MISSED_FIELD_ERROR,
  INVALID_PHONE_NUMBER_ERROR,
  PHONE_NUMBER_MAX_LENGTH,
} from '../OrgSignUp/OrgSignUp.constants'
import { ADDRESS_INPUT_TYPE } from './StaffSignUp.constants'
import emailIsValidish, { formatPhoneNumber } from '../Utility/formUtils'
import { get } from 'lodash'

export type Field = {
  id: string
  label: string
  value: any
  error: boolean
  errorText: string
  inputType: string
}

type Props = RouteComponentProps

const defaultFieldState = (key: string, label: string): Field => ({
  id: `${Date.now()}-${key}`,
  label,
  value: '',
  error: false,
  errorText: MISSED_FIELD_ERROR,
  inputType: 'text', // text || address
})

const httpClient = new AccountsBffClientHttp(axios)

const StaffSignUpNewContainer: React.FunctionComponent<Props> = ({
  history,
}) => {
  const [fullName, setFullName] = useState<Field>({
    ...defaultFieldState('fullName', 'Full Name'),
  })
  const [phoneNumber, setPhoneNumber] = useState<Field>({
    ...defaultFieldState('phoneNumber', 'Phone Number'),
    inputType: 'tel',
  })
  const [email, setEmail] = useState<Field>({
    ...defaultFieldState('email', 'Email Address'),
    inputType: 'email',
  })
  const [schoolName, setSchoolName] = useState<Field>({
    ...defaultFieldState('schoolName', 'School'),
  })
  const [schoolAddress, setSchoolAddress] = useState<Field>({
    ...defaultFieldState('schoolAddress', 'Physical Address'),
    inputType: ADDRESS_INPUT_TYPE,
  })
  const [formValid, setFormValid] = useState<boolean>(false)
  const { notifyError } = useGlobalNotifications()

  const goBackUrl = APP_ROUTES.staffSignUp

  const checkForm = () => {
    const isEmailValid = email.value !== '' && emailIsValidish(email.value)
    const fieldsValid = [
      fullName,
      email,
      schoolName,
      schoolAddress,
      phoneNumber,
    ].every(({ value, error }) => {
      if (typeof value === 'object' && typeof value !== null) {
        return value.address.length > 0 && !error
      }

      return value.length > 0 && !error
    })

    setFormValid(isEmailValid && fieldsValid)
  }

  const showError = (msg?: string) => {
    const message = msg || 'Oops. Something went wrong. Please try again.'

    notifyError({
      persist: false,
      message,
    })
  }

  const handleSubmit = async (event: FormEvent<HTMLElement>) => {
    event.preventDefault()

    if (!formValid) {
      const fields = [
        { field: fullName, setState: setFullName },
        { field: phoneNumber, setState: setPhoneNumber },
        { field: email, setState: setEmail },
        { field: schoolName, setState: setSchoolName },
        { field: schoolAddress, setState: setSchoolAddress },
      ]

      fields.forEach(({ field, setState }) => {
        const fieldValid = !!field.value
        setState({ ...field, error: !fieldValid })
      })

      return
    }

    try {
      // prep data for submission
      const lead = {
        fullName: fullName.value,
        phone: phoneNumber.value,
        email: email.value,
        academicGroupName: schoolName.value,
        address: schoolAddress.value,
      }

      const response = await httpClient.attemptNewAcademicLead(lead)
      const isSuccessful = get(response, 'success', false)

      if (isSuccessful) {
        history.push(APP_ROUTES.staffSignUpNewThankYou)
        return
      }

      showError()
    } catch (e) {
      showError()
    }
  }

  const handleAddressChange = (address: AddressAndPlaceId | null) => {
    if (address !== null) {
      setSchoolAddress({
        ...schoolAddress,
        value: { ...address },
        error: false,
      })
      return
    }

    setSchoolAddress({
      ...schoolAddress,
      value: '',
      error: true,
    })
  }

  const handleFieldChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    id: string
  ) => {
    const value = event.currentTarget.value
    const label = id.split('-')[1]
    const hasInput = !!value.length

    switch (label) {
      case 'fullName':
        setFullName({
          ...fullName,
          value,
          error: !hasInput,
          errorText: MISSED_FIELD_ERROR,
        })
        break
      case 'schoolName':
        setSchoolName({
          ...schoolName,
          value,
          error: !hasInput,
          errorText: MISSED_FIELD_ERROR,
        })
        break
      case 'phoneNumber':
        setPhoneNumber({
          ...phoneNumber,
          value:
            value.replace(' ', '').length > PHONE_NUMBER_MAX_LENGTH
              ? phoneNumber.value
              : formatPhoneNumber(value),
          error:
            value.length - 1 !== PHONE_NUMBER_MAX_LENGTH &&
            value.length - 1 < PHONE_NUMBER_MAX_LENGTH,
          errorText:
            value.length > 0 ? INVALID_PHONE_NUMBER_ERROR : MISSED_FIELD_ERROR,
        })
        break
      case 'email':
        setEmail({
          ...email,
          value,
          error: !hasInput || !emailIsValidish(value),
          errorText: !hasInput ? MISSED_FIELD_ERROR : INVALID_EMAIL_ERROR,
        })

        break
      default:
      // do nothing
    }
  }

  const mergedProps = {
    fields: [fullName, phoneNumber, email, schoolName, schoolAddress],
    formValid,
    goBackUrl,
    handleAddressChange,
    handleFieldChange,
    handleSubmit,
  }

  useEffect(() => {
    checkForm()
  }, [fullName, phoneNumber, email, schoolName, schoolAddress])

  return <StaffSignUpNew {...mergedProps} />
}

export default withRouter(StaffSignUpNewContainer)
