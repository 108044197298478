import React, { useState, useEffect } from 'react'
import get from 'lodash/get'
import axios from 'axios'
import AccountsBffClient from '../Dependencies/AccountsBffClientHttp'
import { checkGqlError } from '../../Utility/auth'
import { GradeChangeInput } from '../../AccountInformation/Parent.types'
import {
  grades as gradeOptions
} from '../../ParentSignUp/ParentSignUp.constants'

const httpClient = new AccountsBffClient(axios)

export type LinkedAccount = {
  userId: number
  email: string
  hierarchy: {
    id: string
    type: string
    name: string
  }
  scopes: string[]
}

export type ChildNewModel = {
  institutionType: string
  institutionId: string
  institutionName: string
  hierarchyType: string
  hierarchyId: string
  hierarchyName: string
  gradeLevel: string | null
  schoolYear: string
  schoolType: string
  childUid: string
  community: string | null
  postalCode: string
  status: string
  grades?: GradeChangeInput[] | null
  institutionGradeLevel: string
  wasAdded?: boolean
  wasChanged?: boolean
}

export type Hierarchy = {
  id: string,
  type: string,
}

export type Profile = {
  loading: boolean
  userId: number | null
  managedBy: string
  firstName: string
  lastName: string
  email: string
  scopes: string[]
  hierarchy: Hierarchy | null
  linkedAccounts: LinkedAccount[] | null
  children: ChildNewModel[] | null
  groupMembershps: any
  revertBlocked: boolean
  updateLinkedAccounts: (accounts: LinkedAccount[]) => void
  updateChildren: (children: ChildNewModel[]) => void
  removeChild: (childUid: string) => void
  updateChild: (childUid: string, changes: object) => void
  updateEmail: (email: string) => void
  setManagedBy: (manageBy: string) => void
  setRevertBlocked: (revertBlocked: boolean) => void
  getActiveChildren: () => ChildNewModel[]
  getPendingChildren: () => ChildNewModel[]
}

const useProfile = (): Profile => {
  const [loading, setLoading] = useState(true)
  const [userId, setUserId] = useState<number | null>(null)
  const [firstName, setFirstName] = useState<string>('')
  const [lastName, setLastName] = useState<string>('')
  const [email, setEmail] = useState<string>('')
  const [managedBy, setManagedBy] = useState<string>('')
  const [hierarchy, setHierarchy] = useState<Hierarchy | null>(null)
  const [linkedAccounts, setLinkedAccounts] = useState<LinkedAccount[] | null>(
    null
  )
  const [children, setChildren] = useState<ChildNewModel[] | null>(
    null
  )
  const [groupMembershps, setGroupMemberships] = useState<any>(null)
  const [scopes, setScopes] = useState<string[]>([])
  const [revertBlocked, setRevertBlocked] = useState<boolean>(true)

  const getActiveChildren = (): ChildNewModel[] =>
    children!.filter(c => c.status === 'active')

  const getPendingChildren = (): ChildNewModel[] =>
    children!.filter(c => c.status === 'pending')

  const mapChildren = (childrenList: ChildNewModel[]): ChildNewModel[] => {
    let grades: Array<{ id: number, description: string }>
    return childrenList.map((child) => {
      grades = []
      if (child.institutionGradeLevel) {
        const [lowGrade, highGrade] = child.institutionGradeLevel.split('-')
        const lowIndex = gradeOptions.findIndex(g => g.description === lowGrade)
        const highIndex = gradeOptions.findIndex(g => g.description === highGrade)
        grades = gradeOptions.slice(lowIndex, highIndex + 1)
      }

      return  {
         ...child,
         grades,
         wasAdded: false,
         wasChanged: false
       }
     })
  }

  useEffect(() => {
    const getProfile = async () => {
      try {
        const response = await httpClient.getMyProfile()
        const success = get(response, 'data.profile.success', false)
        const userid = get(response, 'data.profile.userId')
        const fname = get(response, 'data.profile.firstName')
        const lname = get(response, 'data.profile.lastName')
        const profileManagedBy = get(response, 'data.profile.managedBy')
        const userEmail = get(response, 'data.profile.email')
        const userAccounts = get(response, 'data.profile.linkedAccounts', [])
        const childrenList = get(response, 'data.profile.children', [])
        const hierarchyParams = get(response, 'data.profile.hierarchy', null)
        const memberships = get(
          response,
          'data.profile.academicGroupMemberships',
          []
        )
        const userScopes = get(response, 'data.profile.scopes', [])
        const isRevertBlocked = get(response, 'data.profile.revertBlocked', true)
        const childrenMapped = mapChildren(childrenList || [])

        if (success) {
          setUserId(userid)
          setFirstName(fname)
          setLastName(lname)
          setEmail(userEmail)
          setLinkedAccounts(userAccounts)
          setChildren(childrenMapped)
          setManagedBy(profileManagedBy)
          setGroupMemberships(memberships)
          setHierarchy(hierarchyParams)
          setScopes(userScopes)
          setRevertBlocked(isRevertBlocked)
        }
      } catch (e) {
          checkGqlError(e)
      } finally {
        setLoading(false)
      }
    }

    getProfile()
  }, [])

  return {
    loading,
    userId,
    firstName,
    lastName,
    managedBy,
    email,
    hierarchy,
    linkedAccounts,
    children,
    groupMembershps,
    scopes,
    setManagedBy,
    revertBlocked,
    setRevertBlocked,
    getActiveChildren,
    getPendingChildren,
    updateLinkedAccounts(accounts: LinkedAccount[]) {
      if (Array.isArray(accounts) && accounts.length) {
        setLinkedAccounts(accounts)
      }
    },
    updateChildren(childrenList: ChildNewModel[]) {
      if (Array.isArray(childrenList) && childrenList.length) {
        setChildren(childrenList)
      }
    },
    updateChild(childUid: string, changes: object) {
      if (children) {
        const index = children!.findIndex(c => c.childUid === childUid)
        const updatedChild: ChildNewModel = {
          ...children![index],
          ...changes
        }
        const newChildren = [
          ...children.slice(0, index),
          updatedChild,
          ...children.slice(index + 1)
        ]
        setChildren(newChildren)
      }
    },
    removeChild(childUid: string) {
      setChildren(children!.filter(c => c.childUid !== childUid))
    },
    updateEmail(e) {
      setEmail(e)
    }
  }
}

export default useProfile
