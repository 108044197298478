import React, { useEffect, useState, useContext } from 'react'
import axios from 'axios'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import { css } from 'emotion'
import config from '../config'
import APP_ROUTES from '../App/config'
import ProfileDropdown from './components/ProfileDropdown'
import FlyerBoardDropDown from './components/FlyerBoardDropDown'
import useProfile from './hooks/useProfile'
import PortalBffClientHttp, {
  Flags,
  ForEntity,
} from './Dependencies/PortalBffClientHttp'
import AccountsBffClientHttp, { LoginStatus } from './Dependencies/AccountsBffClientHttp'
import { LinkedAccount } from './Dependencies/AccountsBffClient'
import SubscriptionContext, {
  SubscriptionsByDistrict
} from './context/SubscriptionContext'

import { ActionResults, RootState } from '../App/Store/makeStore'
import {
  getLoginStatus
} from '../LogIn/Redux/LogInActions'
const { colors, components } = require('@peachjar/components')

const {
  REACT_APP_FLYER_BOARD_APP_URL,
  REACT_APP_PEACHJAR_HOMEPAGE
 } = config

const { Brandbar: PJBrandbar } = components

const portalHttpClient = new PortalBffClientHttp(axios)
const accountHttpClient = new AccountsBffClientHttp(axios)

type Entity = {
  id: number;
  name: string;
  type: string;
}

type Props = {
  loginStatus: LoginStatus,
  handleGetLoginStatus: () => void
}

const PeachjarBrandBar: React.FunctionComponent<Props> = ({
  loginStatus,
  handleGetLoginStatus
}) => {
  const profile = useProfile()

  const {
    scopes,
    loading,
    children,
    getActiveChildren
  } = profile
  const userId = (profile.userId || '').toString()

  const {
    subscriptions: userSubscriptions
  } = useContext(SubscriptionContext)

  const [logoLinkUrl, setLogoLinkUrl] = useState<string>(
    REACT_APP_PEACHJAR_HOMEPAGE || 'https://www.peachjar.com'
    )

  const [flags, setFlags] = useState<Flags>({
    portal_nav: 0,
    portal_nav_banner: 0,
    upload_flyer: 0,
    approval_center: 0,
    approval_center_banner: 0,
    org_uploader: 0,
    managed_services: 0,
    comm_free: 0,
  })
  const [linkedAccounts, setLinkedAccounts] = useState<LinkedAccount[]>([])
  const [subscriptions, setSubscriptions] = useState<SubscriptionsByDistrict[]>(userSubscriptions)
  const pathname = window.location.pathname

  useEffect(() => {
    setSubscriptions(userSubscriptions)
  }, [userSubscriptions])

  useEffect(() => {
    const getFlags = async () => {
      const featureFlags: Flags = await portalHttpClient.getFeatureFlags()
      setFlags(featureFlags)
    }

    const getLinkedAccounts = async () => {
      const { data } = await accountHttpClient.getLinkedAccounts()
      if (data.success) {
        const linkedAccountList: LinkedAccount[] = []
        for (const linkedAccount of data.linkedAccounts) {
          if (linkedAccount.scopes.includes('staff') || linkedAccount.scopes.includes('volunteer')) {
            const entities = await getRolesForUser(linkedAccount.userId)
            const entity = await getEntity(entities)
            if (entity) {
              linkedAccountList.push({
                ...linkedAccount,
                hierarchy: {
                  id: entity.id.toString(),
                  name: `${entity.name}`,
                  type: entity.type
                }
              })
              continue
            }
          }
          linkedAccountList.push(linkedAccount)
        }
        setLinkedAccounts(linkedAccountList)
      }
    }

    handleGetLoginStatus()
    if (userId && !loading) {
      getFlags()
      getLinkedAccounts()
    }
  }, [loading])

  useEffect(() => {
    const getlogoLinkUrl = () => {
      const { redirectTo, loggedIn } =  loginStatus

      if (!loggedIn ||
        redirectTo
        && redirectTo.includes(APP_ROUTES.parentAccountInfo)) {
        setLogoLinkUrl('javascript:;')
        return
      }

      const originUri = document.referrer
      const params = new URLSearchParams(window.location.search)
      const flyerParam = params.get('flyer')
      const resourceParam = params.get('resource')
      const resourceIdParam = params.get('resourceId')

      if (flyerParam && resourceParam && resourceIdParam && children) {
        const activeChildren = getActiveChildren()
        const child = activeChildren.find(({ institutionId }) => institutionId === resourceIdParam)
        if (child) {
          const url = `${REACT_APP_FLYER_BOARD_APP_URL}/flyers/${flyerParam}/${resourceParam}/${resourceIdParam}`
          setLogoLinkUrl(url)
          return
        }
      }

      if (redirectTo) {
        setLogoLinkUrl(redirectTo)
        return
      }

      if (
        originUri &&
        originUri.includes('app.peachjar') &&
        originUri.includes('/flyers/')
      ) {
        setLogoLinkUrl(originUri)
        return
      }

      setLogoLinkUrl(REACT_APP_PEACHJAR_HOMEPAGE || 'https://www.peachjar.com')
    }
    getlogoLinkUrl()
  }, [loginStatus])

  const getEntity = async (entities: ForEntity[]): Promise<Entity | null> => {
    if (entities.length > 0) {
      const districtEntities = entities.filter(({ forEntity: { entityType } }) => entityType === 'district')
      let userEntities: ForEntity[] = districtEntities
      if (districtEntities.length === 0) {
        userEntities = entities.filter(({ forEntity: { entityType } }) => entityType === 'school')
      }

      const { forEntity } = userEntities[0]
      const entityId = Number(forEntity.entityId)
      let name: string | null = ''
      if (forEntity.entityType === 'district') {
        name = await getDistrictNameById(entityId)
      } else {
        name = await getSchoolNameById(entityId)
      }
      if (name) {
        const moreEntities = forEntity.entityType === 'school' ? entities.length > 1 ? `+more` : '' : ''
        return {
          id: entityId,
          name: `${name} ${moreEntities}`,
          type: forEntity.entityType
        }
      }
    }
    return null
  }

  const getRolesForUser = async (userIdParam: number): Promise<ForEntity[]> => {
    const { data: { entities } } = await portalHttpClient.getRolesForUser(
      userIdParam.toString()
    )

    if (entities.length > 0) {
      return entities
    }
    return []
  }

  const getSchoolNameById = async (entityId: number): Promise<string | null> => {
      const { data: { success, name }} =  await portalHttpClient.getSchoolBySchoolId(entityId)
      if (success) {
        return name
      }

      return null
  }

  const getDistrictNameById = async (entityId: number): Promise<string | null> => {
    const { data: { success, name }} =  await portalHttpClient.getDistrictNameById(entityId)
    if (success) {
      return name
    }

    return null
  }

  const getUserType = (userScopes: string[]): string => {
    if (userScopes.includes('parent')) {
      return 'Parent'
    }

    if (userScopes.includes('volunteer')) {
      return 'Volunteer'
    }

    if (userScopes.includes('staff')) {
      return 'Staff'
    }

    if (userScopes.includes('org_member')) {
      return 'Organization'
    }

    return ''
  }
  const userType = getUserType(scopes)

  const firstName = profile.firstName || userType
  const lastName = profile.lastName || ''
  const emailAddress = profile.email || 'No email provided.'
  const hierarchyType = profile.hierarchy ? profile.hierarchy.type : ''
  const account = linkedAccounts.find(linkedAccount => linkedAccount.userId.toString() === userId.toString())
  let accountName = ''

  if (account) {
    accountName = userType === 'Parent' ? firstName !== userType ? 'Parent' : '' : account.hierarchy.name
  }

  if (loading) { return null }
  return (
      <PJBrandbar
          logoURI={logoLinkUrl}
          className={classNames.brandbar}
          dark={true}
      >
        <div className={classNames.brandbarContent}>
         {!pathname.includes('login') &&
          !pathname.includes('signup') &&
          !pathname.includes('complete-profile') &&
          (
           <>
            {userId ? (
              <>
              {subscriptions.length > 0 &&
                  <FlyerBoardDropDown
                    subscriptions={subscriptions}
                  />
              }

                <ProfileDropdown
                  userId={userId}
                  entityName={accountName}
                  getUserType={getUserType}
                  hierarchyType={hierarchyType}
                  linkedAccounts={linkedAccounts}
                  firstName={firstName}
                  lastName={lastName}
                  emailAddress={emailAddress}
                  flags={flags}
                  hideProfileLinks={false}
                />
              </>
            )
            : (
              <div className={classNames.linksContainer}>
                <div>
                  <a
                      href={APP_ROUTES.signup}
                      className={classNames.link}
                  >
                      Sign Up
                  </a>
                </div>
                <div>
                  <a
                      href={APP_ROUTES.login}
                      className={`${classNames.link} ${classNames.logInLink}`}
                  >
                      Log In
                  </a>
                </div>
              </div>
            )
            }
           </>
         )}
        </div>
      </PJBrandbar>
  )
}

const classNames = {
  brandbar: css`
    z-index: 50;
  `,
  brandbarContent: css`
    flex: 1 1 auto;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
    align-items: center;
    margin-left: 1rem;
    & button {
      background-color: #47506b !important;
      & > span:first-child {
        border: none !important;
        background-color:#47506b !important;
      }
      & span {
        color: #fff !important;
      }
    }
    & button:focus {
      outline: none;
    }
  `,
  linksContainer: css`
    display: flex;
    margin-right: 1rem;
  `,
  link: css`
    display: flex;
    align-items: center;
    margin-left: auto;
    color: ${colors.white};
    padding: 6px 16px;
    border-radius: 3px;
    &:hover {
      color: ${colors.white};
      text-decoration: none;
      background-color: rgba(45, 52, 75, 0.5);
    }
 `,
 logInLink: css`
   border: 1px solid ${colors.white};
   margin-left: 5px;
 `
}

export default connect(
  (state: RootState) => ({
    loginStatus: state.logIn.loginStatus,
  }),
  (dispatch: Dispatch<ActionResults>) => ({
    handleGetLoginStatus: () => dispatch<any>(getLoginStatus()),
  })
)(PeachjarBrandBar)
