import colors from '@peachjar/ui/dist/lib/styles/colors'
import { css } from 'emotion'

const styles = {
  hidden: css`
    visibility: hidden;
  `,
  disabled: css`
    pointer-events: none;
  `,
  content: css`
    font-family: 'Proxima Nova', Arial, Helvetica, sans-serif;
    margin-bottom: 18px;
    margin-top: 56px;
  `,
  emailLabel: css`
    color: ${colors.slate};
    font-size: 13px;
    margin-top: 30px;
  `,
  email: css`
    color: ${colors.slate};
    font-size: 16px;
  `,
  emailDisclaimer: css`
    color: ${colors.slate};
    font-size: 13px;
  `,
  subscriptionDisclaimer: css`
    color: ${colors.prussian};
    font-size: 16px;
  `,
  dependents: css``,
  closeIcon: css`
    position: absolute;
    top: 100px;
    left: 50px;
    cursor: pointer;
    @media (max-width: 480px) {
      top: 80px;
      left: 20px;
    }
  `,
  addSectionContainer: css`
    margin-top: 40px;
    margin-bottom: 40px;
  `,
  addIcon: css``,
  addText: css`
    font-family: 'Proxima Nova', Helvetica, Arial, sans-serif;
    color: ${colors.jungle};
    font-size: 16px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  `,
  addTextDisabled: css`
    font-family: 'Proxima Nova', Helvetica, Arial, sans-serif;
    color: ${colors.slate};
    font-size: 16px;
    cursor: pointer;
  `,
  iconOverrides: css`
    color: ${colors.jungle};
    font-size: 16px;
    vertical-align: bottom;
    height: 22px !important;
    width: 22px !important;
    cursor: pointer;
  `,
  secondaryCta: css`
    margin-right: 16px;
  `,
  footer: css`
    display: flex;
    justify-content: flex-end;
  `,
  notificationsIcon: css`
    padding-right: 4px;
    font-size: 16px;
  `,
  disableText: css`
    color: ${colors.silver};
    cursor: not-allowed;
  `,
  districtName: css`
    font-family: 'Proxima Nova', Arial, Helvetica, sans-serif;
    color: ${colors.prussian};
    font-size: 16px;
    padding-bottom: 16px;
    line-height: 24px;
    text-transform: capitalize;
  `,
  separator: css`
    padding-top: 40px;
  `,
  buttonManageMyself: css`
    & .MuiButton-root {
      background: ${colors.white} !important;
    }
    & .MuiButton-root:hover {
      background: ${colors.white} !important;
    }
    margin-top: 16px;
  `,
  emptyState: css`
    display: flex;
    margin-top: 40px;
    justify-content: center;
    flex-wrap: wrap;

    p {
      margin-top: 16px;
      margin-bottom: 16px;
      text-align: center;
      font-family: 'Proxima Nova', Arial, Helvetica, sans-serif;
      color: ${colors.prussian};
      font-size: 16px;
      line-height: 24px;
    }
    img {
      width: 390px;
      height: 128px;
      @media (max-width: 480px) {
        width: 340px;
      }
    }
  `,
  dismissButton: css`
    margin-top: 16px;
  `,
  childNotUpToDateBanner: css`
    margin-bottom: 40px;
  `,
  pendingDistrictName: css`
    font-family: 'Proxima Nova-Bold', Arial, Helvetica, sans-serif;
    color: ${colors.prussian};
    font-size: 16px;
    font-weight: bold;
    line-height: 24px;
    text-transform: capitalize;
    display: block;
  `,
  pendingInfo: css`
    font-family: 'Proxima Nova', Arial, Helvetica, sans-serif;
    color: ${colors.prussian};
    font-size: 16px;
    line-height: 24px;
    text-transform: capitalize;
 `,
  alignPendingInfo: css`
    display: flex;
    align-items: center;
  `,
  pendingInfoContainer: css`
   & + div {
     padding-top: 20px;
   }
  `,
  pendingSchoolsContainer: css`
   padding-bottom: 20px;
  `,
  paddingInfoIcon: css`
    padding-right: 10px;
  `,
  paddingSchoolsBottom: css`
    margin-bottom: 16px;
  `,
  manageMyselfMessage: css`
    margin-bottom: 16px;
  `,
  description: css`
    margin-top: 32px;
    margin-bottom: 32px;
    font-family: 'Proxima Nova', Arial, Helvetica, sans-serif;
    color: ${colors.prussian};
    font-size: 16px;
    line-height: 24px;
  `,
  revertToDistrict: css`
    font-family: 'Proxima Nova', Helvetica, Arial, sans-serif;
    color: ${colors.jungle};
    font-size: 13px;
    &:hover {
      color: ${colors.jungle};
      cursor: pointer;
      text-decoration: none;
    }
  `
}

export default styles
