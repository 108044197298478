import ApolloClient from 'apollo-boost'
import config from '../../config'
import { checkGqlError } from '../../Utility/auth'

const GRAPHQL_URI = config.REACT_APP_PORTAL_BFF_GRAPHQL_URI

function createApolloClient({ initialState = {} }) {
  return new ApolloClient({
    uri: GRAPHQL_URI,
    credentials: 'include',
    onError: ({ networkError, graphQLErrors }) => {
      checkGqlError({ graphQLErrors })
      // tslint:disable-next-line:no-console
      console.log('graphQLErrors', graphQLErrors)
      // tslint:disable-next-line:no-console
      console.log('networkError', networkError)
    },
  })
}

const apolloPortalBffClient = createApolloClient({})

export default apolloPortalBffClient
