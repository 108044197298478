import AccountsBffClient, { LoginStatusResponse, MagicLinkResponse, VerifyEmailResponse } from './AccountsBffClient'

export default class AccountsBffClientMock implements AccountsBffClient {
  async attemptMagicLinkRequest(
    email: string,
    destination: string
  ): Promise<MagicLinkResponse> {
    return {
      data: {
        requestMagicLink: {
          success: true,
        },
      },
    }
  }
  async verifyEmail(
    email: string
  ): Promise<VerifyEmailResponse> {
    return {
      data: {
        verifyEmail: {
          success: true,
        },
      },
    }
  }
  async getLoginStatus(
  ): Promise<LoginStatusResponse> {
    return {
      loggedIn: false,
      redirectTo: ''
    }
  }
}
