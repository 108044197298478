import gql from 'graphql-tag'

export const GET_ACCOUNT_FEATURE_FLAGS = gql`
  query getAccountFeatureFlags {
    profile {
      id
      featureFlags {
        portal_nav
        portal_nav_banner
        upload_flyer
        approval_center
        approval_center_banner
        org_uploader
        managed_services
        comm_free
      }
    }
  }
`

export const GET_ROLES_FOR_USER = gql`
  query getRolesForUser($input: V2_GetRolesForUserInput) {
    getRolesForUser(input: $input){
      forEntity {
        entityType
        entityId
      }
    }
  }
`

export const GET_SCHOOLS_BY_SCHOOL_ID = gql`
  query getSchoolsBySchoolId($schoolIds: [Int!]!) {
    getSchoolsBySchoolId(schoolIds: $schoolIds){
      items {
        schoolId
        name
      }
    }
  }
`
export const GET_SUBSCRIPTIONS = gql`
  query subscriptions {
    subscriptions {
      id
      type
      audienceId
      audienceType
      hierarchyType
      hierarchyId
      status
    }
  }
`

export const GET_DISTRICT_NAME_BY_ID = gql`
  query getDistrict($input: V2_DistrictIdsInput) {
    getDistrict(input: $input){
      name
    }
  }
`
