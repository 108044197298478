import React from 'react'
import { css } from 'emotion'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import { withStyles } from '@material-ui/core/styles'
import { elements, colors } from '@peachjar/components'

const { orange } = colors
const {
  typography: { Paragraph },
} = elements

interface IProps {
  classes: { [key: string]: string }
  subheadline: string
  iconSrc?: any
  children?: any
  variant?: string
  inlineStyles?: { [key: string]: string }
}

/**
 * Generic message banner intended for use on next gen portal dashboard.
 * Example usage:
 * <MessageBanner
 *     headline="Verify Your Non-Profit Status"
 *    subheadline="subheadline text here..."
 *     variant="pop"
 *     iconSrc={distributionIcon}
 *   >
 *     <ButtonSecondarySmall
 *       data-testid="click-community-free-deal"
 *     onClick={() => window.location = `${REACT_APP_PEACHJAR_HOMEPAGE}/index.php?a=4&b=4`}
 *     >
 *       Update Account Information
 *   </ButtonSecondarySmall>
 */
const MessageBanner = ({
  subheadline,
  iconSrc,
  inlineStyles,
  children,
  classes,
  variant = 'default',
  ...rest
}: IProps): React.ReactElement => {
  return (
    <Grid item xs={12}>
      <Paper
        className={`${cn.welcomeContent} ${classes.elevation2} ${cn.variants[variant]}`}
      >
        <div className={cn.flexRow}>
          <div className={`${variant === 'warning' ? cn.iconWarning : cn.iconDefault}`}>
            <img src={iconSrc} alt='Manage Icon' />
          </div>
          <div>
            <p>
              <Paragraph><span dangerouslySetInnerHTML={{ __html: subheadline }}></span></Paragraph>
            </p>
            {children && (
              <div className={cn.flexColumn}>
                <div className={cn.ctasContainer}>
                  <div className={cn.ctas}>{children}</div>
                </div>
              </div>
            )}
          </div>
        </div>
      </Paper>
    </Grid>
  )
}

// Override default material-ui styles
const materialOverrides = {
  elevation2: {
    boxShadow: 'none',
  },
}

const cn = {
  welcomeContent: css`
    padding: 24px 24px 24px 16px !important;
    margin-top: 24px !important;
    p {
      margin-bottom: 0px;
    }
  `,
  flexRow: css`
    display: flex;
    flex-direction: row;
  `,
  flexColumn: css`
    display: flex;
    flex-direction: column;
  `,
  iconDefault: css`
    float: left;
    margin-right: 16px !important;
    @media (max-width: 480px) {
      display: none;
    }
  `,
  iconWarning: css`
     float: left;
    margin-right: 8px !important;
  `,
  ctasContainer: css`
    padding-top: 4px;
    display: flex;
    flex-direction: column;
    @media (max-width: 480px) {
      padding-left: 0px;
    }
  `,
  ctas: css`
    margin-top: 8px;
  `,
  variants: {
    default: css`
      background-color: #f0f2f6 !important;
      p {
        color: #2d344b;
        font-size: 16px;
      }
    `,
    pop: css`
      background-color: #F0F8F2 !important;
    `,
    warning: css`
      background-color: #fffae5 !important;
      p {
        span {
          color: #333b56 !important;
          font-size: 13px;
        }
      }
    `,
    prussian: css`
      background-color: #47506b !important;
      p {
        color: ${colors.white} !important;
        font-size: 1rem;
        line-height: 1.5;
        span {
          color: ${colors.white} !important;
        }
      }
    `
  },
}

export default withStyles(materialOverrides)(MessageBanner)
