import React from 'react'
import {css} from 'emotion'
import colors from '@peachjar/ui/dist/lib/styles/colors'

type Props = {
  title: string;
  description: string;
  onClick: () => void
}

const Card: React.FunctionComponent<Props> = ({
  title,
  description,
  onClick
}) =>
  <div
    className={styles.container}
    onClick={onClick}
    role='button'
    tabIndex={0}
    aria-label={description}
    >
      <span>
        {title}
      </span>
      <p>
        {description}
      </p>
  </div>

const styles = {
  container: css`
    margin-top: 16px;
    margin-top: 16px;
    border-radius: 6px;
    border: 1px solid ${colors.silver};
    padding: 24px;
    cursor: pointer;
    user-select: none; /* supported by Chrome and Opera */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    &:hover {
      box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14),
        0px 3px 1px -2px rgba(0, 0, 0, 0.12);
    }

    & span {
      font-family: 'Proxima Nova', Helvetica, Arial, sans-serif;
      color: ${colors.jungle} !important;
      font-size: 16px;
    }

    p {
      margin-bottom: 0;
      font-family: 'Proxima Nova', Arial, Helvetica, sans-serif;
      color: ${colors.slate};
      font-size: 13px;
      line-height: 16px;
      display: block;
      padding-top: 4px;
    }
  `
}

export default Card
