import React, { FunctionComponent } from 'react'
import { css } from 'emotion'
import { RouteComponentProps } from 'react-router-dom'
import { colors } from '@peachjar/components'
import { ButtonPrimaryLarge } from '@peachjar/ui/dist/lib/components/Buttons'
import TwoTone from '../App/Layout/TwoTone'
import IllustratedQuote from '../App/Layout/IllustratedQuote'
import FlexInset from '../App/Layout/FlexInset'
import { APP_ROUTES } from '../App/config'
import MagicEnvelope from '../MagicLink/MagicEnvelope'
import PrimaryHeading from '../App/Typography/PimaryHeading'

type Props = RouteComponentProps & {
    resendMagicLink: (email: string, destination: string) => Promise<any>
}

const UpdateEmailExpired: FunctionComponent<Props> = ({
    history,
}) => {

    const goToAccount = async () => {
        history.push(APP_ROUTES.parentAccountInfo)
    }

    return (
        <TwoTone goBackUrl={null}>
            <FlexInset>
                <PrimaryHeading>Uh Oh!</PrimaryHeading>
                <p className={styles.emailConfirmation}>
                    The email verification link has expired after 3 hours.
                    Your email address has not been updated.
                    If you still want to update your email address, go to your account and update it again.
                </p>
                <div className={styles.ctaSection}>
                    <ButtonPrimaryLarge onClick={goToAccount}>
                        Go To Account
                    </ButtonPrimaryLarge>
                </div>
            </FlexInset>
            <IllustratedQuote svg={<MagicEnvelope />} />
        </TwoTone>
    )
}

export default UpdateEmailExpired

const styles = {
    emailConfirmation: css`
        font-size: 16px;
        font-family: 'Proxima Nova', Helvetica, Arial, sans-serif;
        color: ${colors.prussian};
        padding-top: 36px;
    `,
    ctaSection: css`
        margin-top: 40px;
    `,
    ctaSectionOptions: css`
        text-align: right;
    `,
}
