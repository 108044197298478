import React, { FunctionComponent } from 'react'
import Modal from '@peachjar/ui/dist/lib/components/Modal/Modal'
import ModalHeader from '@peachjar/ui/dist/lib/components/Modal/ModalHeader'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogActions from '@material-ui/core/DialogActions'
import { ButtonFlatLarge } from '@peachjar/ui/dist/lib/components/Buttons/Buttons'
import { css } from 'emotion'
import colors from '@peachjar/ui/dist/lib/styles/colors'

type Props = {
  isOpen: boolean
  schoolName: string
  submitText?: string
  onSubmit: () => void
  onCancel: () => void
}

const ChildRemovalConfirmation: FunctionComponent<Props> = ({
  isOpen,
  schoolName,
  submitText = 'Remove',
  onSubmit,
  onCancel,
}) => (
  <Modal
    title='child removal'
    open={isOpen}
    close={() => onCancel()}
    width={'xs'}
    >
    <ModalHeader color='#FFEBE5'>
      Child Removal Confirmation
    </ModalHeader>
    <DialogContent className={styles.content}>
      <DialogContentText>
        <div className={styles.text}>
          <p>
            Removing this child from your account will:
            <ul>
              <li>
                1. Result in all <span className={styles.schoolName}> {schoolName}'s</span> flyers
                no longer being associated with your account
              </li>
              <li>
                2. Stop email notifications of new flyers sent from
                <span className={styles.schoolName}> {schoolName} </span>
              </li>
            </ul>
          </p>
          <p>
            If you would like to continue viewing flyers from
            <span className={styles.schoolName}> {schoolName} </span>
            but wish to stop receiving email notifications,{' '}
            <a
              href='/parent/email-notifications'
              className={styles.link}>
              turn off email notifications
            </a>{' '}
            instead of removing the child.
          </p>
          <p>
            If this child is now attending a different school,{' '}
            <a
              href={void(0)}
              onClick={onCancel}
              className={styles.link}
              tabIndex={0}>
              update their school information
            </a>{' '}
            to view flyers from
            their new school instead of removing the child.
          </p>
        </div>
      </DialogContentText>
    </DialogContent>
    <DialogActions className={styles.footer}>
      <ButtonFlatLarge onClick={() => onCancel()} color='primary'>
        Cancel
      </ButtonFlatLarge>
      <ButtonFlatLarge onClick={() => onSubmit()}>
        {submitText}
      </ButtonFlatLarge>
    </DialogActions>
  </Modal>
)

const styles = {
  content: css`
    padding: 16px 16px 0px 16px !important;
  `,
  text: css`
    font-family: 'Proxima Nova', Helvetica, Arial, sans-serif;
    font-size: 16px;
    line-height: 24px;
    padding: 8px;
    color: ${colors.prussian};
    ul {
      padding-top: 4px;
      padding-left: 16px;
    }

     li {
      list-style-type: none;
      padding-bottom: 8px;
    }
 `,
  schoolName: css`
   font-weight: bold;
   text-transform: capitalize;
  `,
  link: css`
    color: ${colors.jungle};
    text-decoration: underline;
    font-family: 'Proxima Nova', Helvetica, Arial, sans-serif;
    cursor: pointer;

   &:hover {
     color: #c5e8c9;
   }
 `,
 footer: css`
  padding: 0px 16px 8px 16px !important;
 `
}

export default ChildRemovalConfirmation
