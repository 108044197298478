import React from 'react'
import { css, cx } from 'emotion'
import { PageContainer } from '@peachjar/ui/dist/lib/components/PageLayouts/Common'
import { ButtonPrimaryLarge } from '@peachjar/ui/dist/lib/components/Buttons/Buttons'
import colors from '@peachjar/ui/dist/lib/styles/colors'
import CloseIcon from '@material-ui/icons/Close'
import Page from '../App/components/Page'
import PrimaryHeading from '../App/Typography/PimaryHeading'
import { Config } from './SignUp.container'

type Props = {
  section: Config[]
  onClose: () => void
}

const SignUp = ({ sections, onClose }) => (
  <div className={cx(styles.containerFluid, 'container-fluid')}>
    <div className={styles.closeIcon} onClick={onClose}>
      <CloseIcon />
    </div>
    <PageContainer>
      <Page>
        <PrimaryHeading>Sign Up</PrimaryHeading>
        <div className={cx('row', styles.sectionContainer)}>
          {sections.map(
            ({ headline, subheadline, image: Image, onClickHandler }) => (
              <div className={cx('col-lg-4', styles.section)}>
                <Image />
                <h3 className={styles.headline}>{headline}</h3>
                <p className={styles.subheadline}>{subheadline}</p>
                <ButtonPrimaryLarge onClick={onClickHandler}>
                  Join
                </ButtonPrimaryLarge>
              </div>
            )
          )}
        </div>
      </Page>
    </PageContainer>
  </div>
)

const styles = {
  containerFluid: css`
    background-color: #fff;
  `,
  page: css`
    padding: 64px 0 !important;
  `,
  sectionContainer: css`
    margin-top: 100px;
    justify-content: center;
  `,
  section: css`
    text-align: center;
    margin-bottom: 80px;

    @media screen and (min-width: 992px) {
      margin-bottom: 0;
    }
  `,
  headline: css`
    color: ${colors.prussian};
    font-size: 25px;
    font-family: Proxima Nova, Helvetica, Arial, sans-serif;
    text-align: center;
    padding: 24px 0 16px 0;
  `,
  subheadline: css`
    color: ${colors.prussian};
    font-size: 16px;
    font-family: Proxima Nova, Helvetica, Arial, sans-serif;
    text-align: center;
    padding: 0 46px 40px 46px;
    margin: 0;
  `,
  closeIcon: css`
    position: absolute;
    top: 100px;
    right: 50px;
    cursor: pointer;
  `,
}

export default SignUp
