import React from 'react'
import {
  ButtonPrimaryLarge,
  ButtonSecondaryLarge,
} from '@peachjar/ui/dist/lib/components/Buttons'
import EmailIcon from '@material-ui/icons/Email'
import SwitchInput from '@peachjar/ui/dist/lib/components/Inputs/SwitchInput'
import Card from '../App/components/Card'
import { MESSAGES } from './EmailNotifications.constants'
import styles from './EmailNotifications.styles'

type Props = {
  userEmail: string;
  unsubscribeFrom: string;
  isSubscribed: boolean;
  isManage: boolean;
  loading: boolean;
  schoolList: string[];
  handleConfirm: () => void;
  handleCancel: () => void;
  handleChangeSubscription: (checked: boolean) => void;
}

const EmailNotifications: React.FunctionComponent<Props> = ({
  userEmail,
  unsubscribeFrom,
  isSubscribed,
  isManage,
  loading,
  schoolList,
  handleConfirm,
  handleCancel,
  handleChangeSubscription,
}) => {

  return (
      <>
        {isManage ? (
          <>
            <p className={styles.disclaimerHeader}>
              {MESSAGES.disclaimer}
            </p>
            <div className={styles.container}>
              <div className={styles.header}>
                <span className={styles.emailIcon}>
                  <EmailIcon />
                </span>
                <span className={styles.userEmail}>
                  {userEmail}
                </span>
              </div>
              <div>
                <div className={styles.label}>
                  Unsubscribe from
                </div>
                <span className={styles.unsubscribeFrom}>
                  {unsubscribeFrom}
                </span>
              </div>
              <div>
                  <div className={styles.label}>
                    Sent from all of your schools
                  </div>
                  {schoolList && schoolList.map((school) => (
                    <span className={styles.schoolName}>
                      {school}
                    </span>
                  ))}
              </div>
              <div className={styles.footer}>
                <div className={styles.cancelButton}>
                  <ButtonSecondaryLarge onClick={handleCancel} >
                    Cancel
                  </ButtonSecondaryLarge>
                </div>
                <div>
                  <ButtonPrimaryLarge
                    onClick={handleConfirm}
                    disabled={loading}>
                    Confirm
                  </ButtonPrimaryLarge>
                </div>
              </div>
            </div>
          </>
        )
        :
        (
          <>
          <div className={styles.switchWrapper}>
            <SwitchInput
              value={isSubscribed}
              onChange={handleChangeSubscription}
              label={unsubscribeFrom}
            />
          </div>
          <p className={styles.disclaimer}>
            {MESSAGES.disclaimer}
          </p>
          </>
        )
      }
      <Card
        title='Manage Children’s Schools'
        description={MESSAGES.manageAccountBanner}
        onClick={() => window.location.href = '/parent/account-information'}
      />
    </>
  )
}

export default EmailNotifications
