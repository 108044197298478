import PortalApiClientHttp from './PortalApiClientHttp'
import AccountsBffClientHttp from './AccountsBffClientHttp'
import Dependencies from './Dependencies'
import axios from 'axios'
import config from '../../config'

export default function getDefaultDependencies(): Dependencies {
    return {
        portalApi: new PortalApiClientHttp(
            axios.create({
                baseURL: config.REACT_APP_API_BASE_URL,
                withCredentials: true,
            })
        ),
        accountsBff: new AccountsBffClientHttp(
            axios.create({
                baseURL: config.REACT_APP_BFF_BASE_URL,
                withCredentials: true,
            })
        ),
    }
}
