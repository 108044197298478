import 'core-js/es6/symbol'
import 'core-js/fn/array/includes'
import 'core-js/fn/object/values'
import 'core-js/fn/object/entries'
import 'core-js/fn/string/includes'
import 'core-js/fn/number/is-safe-integer'
import 'core-js/fn/regexp/flags'
import 'core-js/fn/array/fill'

// This import must come after the above imports, or IE11 will break.
import 'react-app-polyfill/ie11'

import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import './index.css'
import App from './App/App'
import * as serviceWorker from './serviceWorker'
import configureSentry from './App/configureSentry'
import makeStore from './App/Store/makeStore'
import getDefaultDependencies from './App/Dependencies/getDefaultDependencies'
import getMockDependencies from './App/Dependencies/getMockDependencies'
import TagManager, { TagManagerArgs } from 'react-gtm-module'
import config from './config'

configureSentry()

const tagManagerArgs: TagManagerArgs = {
  gtmId: config.REACT_APP_GTM_TAG || ''
}

TagManager.initialize( tagManagerArgs )

// Checks if the URL path contains mock=1 like /login?mock=1
const mockTestingMode = window!.document!.location!.search.includes('mock=1')
const deps = mockTestingMode ? getMockDependencies() : getDefaultDependencies()
const store = makeStore(deps)

ReactDOM.render(
    <Provider store={store}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
    </Provider>,
    document.getElementById('soul')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
