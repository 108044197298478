import {
  ORG_SIGNUP_SET_INFO,
 ORG_SIGNUP_CLEAR_INFO,
 OrgSignUpActionResults
} from './OrgSignUpActions'
import { OrganizationInput } from '../OrgSignUp.container'

export type OrgSignUpState = {
  info: OrganizationInput
}

const initialState: OrgSignUpState = {
  info: {
    email: '',
    name: '',
    orgCategory: '',
    phone: '',
    postalCode: '',
    firstName: '',
    lastName: '',
    userEmail: ''
  }
}

export function orgs(
  state: OrgSignUpState = initialState,
  action: OrgSignUpActionResults
) {
  switch (action.type) {
    case ORG_SIGNUP_SET_INFO:
      return {
        ...state,
        info: action.info,
      }

    case ORG_SIGNUP_CLEAR_INFO:
      return {
        ...state,
        ...initialState
      }
    }

  return state
}
