
export const changeEmailMessages = {
  updatedSuccess: 'Your account email address has been updated.',
  updatedError: 'Unfortunately, we were unable to update your account email address at this time. Please try again in a few minutes. If this issue persists, contact Peachjar at support@peachjar.com for assistance.',
  invalidEmailError: 'Enter a valid email address.'
}

export const parentMessages = {
  addChildSuccess: 'Your child has been added to your Peachjar account.',
  addChildError: 'Unfortunately, we were unable to add your child to your Peachjar account at this time. Please try again in a few minutes. If this issue persists, contact Peachjar at support@peachjar.com for assistance.',
  updateChildSuccess: 'Your child’s school information has been updated.',
  updateChildError: 'Unfortunately, we were unable to update your child’s school information at this time. Please try again in a few minutes. If this issue persists, contact Peachjar at support@peachjar.com for assistance.',
  removeChildSuccess: 'Your child has been removed from your Peachjar account.',
  removeChildError: 'Unfortunately, we were unable to remove your child from your Peachjar account at this time. Please try again in a few minutes. If this issue persists, contact Peachjar at support@peachjar.com for assistance.',
  changeManageByError: 'Unfortunately, we were unable to switch your account to be managed by you at this time. Please try again in a few minutes. If this issue persists, contact Peachjar at support@peachjar.com for assistance.',
}

export const bannersMessages = {
  manageByParent: 'Your children\'s school information below is being <b>managed by you</b>. Your district can no longer make updates to your account. However, we will alert you to any updates your district attempts to make to your child’s school information.',
  manageByDistrict: 'Your children\'s school information below is currently <b>managed by your district</b>. Your district will make updates to your account as your contact and school information changes over time. To update your child’s school information yourself, select Manage Myself.',
  manageByDistrictDisclaimer: 'If you choose to manage yourself, your district will no longer be able to make updates. However, we will alert you to any updates your district attempts to make to your child’s school information.',
  emailUpdated: 'Your Peachjar account email address has been updated. Contact the school district if you would like to update your email address in their system too.',
  childrenEmptyState: 'Add a child to access flyers from your child’s school and school-approved community enrichment resources.',
  childNotbeUpToDate: 'Based on new information from your district, your child’s school information might not be up to date.',
  childNotbeUptoDateDisclaimer: 'If your child is attending the school above, please update your child’s school information below. Otherwise, you can simply dismiss this alert.',
  revertManageBy: 'To switch back to being managed by your district, select Revert to District Managed. This option is available until you make a change to your account email address and/or child’s school information.',
  emailNeedsToBeVerified: 'Please check your email for a confirmation email. Click the link to verify your email.',
}
