/* tslint:disable */
import React from 'react'
import { useHistory } from 'react-router-dom'
import AddIcon from '@material-ui/icons/Add'
import CloseIcon from '@material-ui/icons/Close'
import MessageBanner from '../App/components/Banners/MessageBanner'
import CommunityBanner from '../App/components/Banners/CommunityBanner'
import Card from '../App/components/Card'
import accountIcon from '../assets/account-icon.svg'
import infoIcon from '../assets/info-icon.svg'
import accountInfoIcon from '../assets/account-icon-pencil.svg'
import accountGreenIcon from '../assets/account-green-icon.svg'
import ChildrenEmptyStateIcon from '../assets/children-empty-state-icon.svg'
import {
  ButtonFlatSmall,
  ButtonSecondarySmall
} from '@peachjar/ui/dist/lib/components/Buttons'
import FlexInset from '../App/Layout/FlexInset'
import ChildEnrollment from './components/ChildEnrollment'
import PrimaryHeading from '../App/Typography/PimaryHeading'
import SecondaryHeading from '../App/Typography/SecondaryHeading'
import ChangeEmailForm from './components/ChangeEmailForm'
import {
  grades as gradeOptions,
} from '../ParentSignUp/ParentSignUp.constants'
import APP_ROUTES from '../App/config'
import {
  ChildGroupByCommunity,
  InstitutionFromSearch,
  GradeChangeInput,
  PendingSchools
} from './Parent.types'
import styles from './Parent.styles'
import { ChildNewModel } from '../App/hooks/useProfile'
import { bannersMessages } from './Parent.constants'

type Props = {
  userId: number | null
  email: string
  managedBy: string
  childrenGrouped: ChildGroupByCommunity[]
  pendingSchools: PendingSchools[]
  childrenList: ChildNewModel[] | null
  childrenAddingIds: Array<string>
  dismissible: boolean
  accountManagementEnabled: boolean
  notifStatus: string
  revertBlocked: boolean
  updateRevertBlock: () => void
  changeManagedByStatus:() => void
  lookUpSchools: (options, callback) => void
  handleSubmit: (childUId: string) => void
  handleSchoolChange: (
    value: InstitutionFromSearch & { schoolId: number; districtId: number },
    id: number
  ) => void
  handleGradeChange: (input: GradeChangeInput, childUId: string) => void
  handleRemoveChild: (childUId: string) => void
  handleRemoveAddedChild: (childUId: string) => void
  handleAddChild: () => void
  handleClosePage: () => void
  handleCancel: (childUId: string) => void
  handleDismissPendingNotification: (guardianId: number) => void
}


const ParentAccountInfo: React.FunctionComponent<Props> = ({
  userId,
  email,
  managedBy,
  childrenGrouped,
  pendingSchools,
  childrenList,
  childrenAddingIds,
  dismissible,
  accountManagementEnabled,
  notifStatus,
  revertBlocked,
  updateRevertBlock,
  changeManagedByStatus,
  lookUpSchools,
  handleSubmit,
  handleSchoolChange,
  handleGradeChange,
  handleRemoveChild,
  handleRemoveAddedChild,
  handleAddChild,
  handleClosePage,
  handleCancel,
  handleDismissPendingNotification
}) => {
  const history = useHistory()

  const renderChildrenGrouped = () =>
      childrenGrouped.map(({ community, districts }, index) =>
        <>
          {community && (
            <CommunityBanner
              name={`${community} Community`}
              disabled={!accountManagementEnabled}
            />
          )}
          {
            districts.map(({district, childrenIds}) =>
                <>
                <div className={styles.districtName}>
                  {district}
                </div>
                {childrenIds.map((
                  childUid,
                  index
                ) => {
                  const child = childrenList!.find(c => c.childUid === childUid)
                  if(!child) return null

                  const {
                    institutionId,
                    institutionName,
                    gradeLevel,
                    grades,
                    wasChanged
                  } = child

                  const currentGrade = (grades || gradeOptions).find(g => g.description === gradeLevel)
                  const pendingDistrict = pendingSchools.find(d=> d.districtName === district)
                  const gradeLevelChanged = pendingDistrict ?
                    pendingDistrict.schools.some(s => (
                      s.institutionName === institutionName &&
                      !s.isNewSchool
                    )) : false

                  return (
                    <ChildEnrollment
                      index={index}
                      childUid={childUid}
                      disabled={!accountManagementEnabled}
                      grades={grades || gradeOptions}
                      wasAdded={false}
                      wasChanged={wasChanged}
                      institution={{ institutionId, institutionName }}
                      currentGrade={currentGrade || null}
                      lookUpSchools={lookUpSchools}
                      handleSubmit={handleSubmit}
                      handleSchoolChange={handleSchoolChange}
                      handleGradeChange={handleGradeChange}
                      handleRemoveChild={handleRemoveChild}
                      handleCancel={() => handleCancel(childUid)}
                      gradeLevelChanged={gradeLevelChanged}
                    />
                  )
                }
                )}
              </>
            )
          }
          {index < childrenGrouped.length-1 && (
            <div className={styles.separator}></div>
          )}
        </>
  )

  const renderAddedChildren = () =>
    childrenAddingIds.map((childUid, index) => {
      const {
        institutionId,
        institutionName,
        gradeLevel,
        grades,
        wasAdded,
        wasChanged
      } = childrenList!.find(c => c.childUid === childUid)!
      const currentGrade = (grades || gradeOptions).find(g => g.description === gradeLevel)

      return (
        <ChildEnrollment
        index={index}
        childUid={childUid}
        disabled={false}
        grades={grades || gradeOptions}
        wasAdded={wasAdded}
        wasChanged={wasChanged}
        institution={{ institutionId, institutionName }}
        currentGrade={currentGrade || null}
        lookUpSchools={lookUpSchools}
        handleSubmit={handleSubmit}
        handleSchoolChange={handleSchoolChange}
        handleGradeChange={handleGradeChange}
        handleRemoveChild={handleRemoveAddedChild}
        handleCancel={handleRemoveAddedChild}
        gradeLevelChanged={false}
      />
      )
    }
  )

  const renderButtonAdd = () => (
    accountManagementEnabled ? (
      <>
      <span className={styles.addIcon}>
         <AddIcon className={styles.iconOverrides} />
       </span>
       <span
         className={styles.addText}
         onClick={handleAddChild}
         tabIndex={0}
       >
         Add Child
     </span>
     </>
    )
    : null
  )
  const InfoIcon = () => <img src={infoIcon} className={styles.paddingInfoIcon} />

  const renderPendingSchools = () => (
    <>
    {pendingSchools.map((p) =>
      <div className={styles.pendingSchoolsContainer}>
        <span className={styles.pendingDistrictName}>
          {p.districtName}
        </span>
        {p.schools.map(({ institutionName, gradeLevel, isNewSchool}) => (
          <div className={styles.pendingInfoContainer}>
            <div className={styles.alignPendingInfo}>
              {isNewSchool && <InfoIcon />}
              <span className={styles.pendingInfo}>
                {institutionName}
              </span>
            </div>
            <div className={styles.alignPendingInfo}>
              <InfoIcon />
              <span className={styles.pendingInfo}>
                Grade {`${gradeLevel === 'N' || !gradeLevel ? 'Unknown' : gradeLevel}`}
              </span>
            </div>
          </div>
        ))}
      </div>
    )}
    </>
  )

  return (
    <>
      <FlexInset>
        <PrimaryHeading>Account Information</PrimaryHeading>
        <div className={styles.content}>
          <SecondaryHeading>Parent Account</SecondaryHeading>
          <ChangeEmailForm
            email={email}
            updateRevertBlock={updateRevertBlock}
            hierarchyType={"parent"}
          />
          <Card
            title={`Turn ${notifStatus === 'active' ? 'Off': 'On'} Email Notifications`}
            description="Manage email notifications sent from schools."
            onClick={()=> history.push(APP_ROUTES.parentEmailNotifications)}
          />
        </div>
        <div
          className={styles.content}
        >
          <div className={styles.dependents}>
            <SecondaryHeading>Children’s School Information</SecondaryHeading>
            {managedBy === 'sis' &&
              <div className={styles.manageMyselfMessage}>
                <MessageBanner
                  subheadline={bannersMessages.manageByDistrict}
                  iconSrc={accountIcon}
                  variant={"prussian"}
                >
                  <p>
                    {bannersMessages.manageByDistrictDisclaimer}
                  </p>
                  <div className={styles.buttonManageMyself}>
                    <ButtonFlatSmall onClick={changeManagedByStatus} >
                      Manage Myself
                    </ButtonFlatSmall>
                  </div>
                </MessageBanner>
              </div>
            }
            {managedBy === 'family' && (
              <MessageBanner
                subheadline={bannersMessages.manageByParent}
                iconSrc={accountGreenIcon}
                variant={"pop"}
              />
            )}
            {!revertBlocked && managedBy === 'family' &&
              <MessageBanner
                iconSrc={infoIcon}
                subheadline={bannersMessages.revertManageBy}
                variant={'warning'}
              >
                <div>
                  <a
                    className={styles.revertToDistrict}
                    onClick={changeManagedByStatus}>
                    Revert to District Managed
                  </a>
                </div>
              </MessageBanner>
            }
            {managedBy === 'family' && (
              <p className={styles.description}>
                Access flyers from your child’s school and school-approved community enrichment resources.
              </p>
            )}
            {pendingSchools && pendingSchools.length > 0 && (
              <div className={styles.childNotUpToDateBanner}>
                <MessageBanner
                  subheadline={bannersMessages.childNotbeUpToDate}
                  iconSrc={accountInfoIcon}
                >
                  {renderPendingSchools()}
                  <p>
                    {bannersMessages.childNotbeUptoDateDisclaimer}
                  </p>
                  <div className={styles.dismissButton}>
                    <ButtonSecondarySmall
                      onClick={() => handleDismissPendingNotification(userId!)}>
                      Dismiss
                    </ButtonSecondarySmall>
                  </div>
                </MessageBanner>
              </div>
            )}
            {!childrenList || childrenList.length === 0 && (
              <div className={styles.emptyState}>
                <img src={ChildrenEmptyStateIcon} />
                <p>
                  {bannersMessages.childrenEmptyState}
                </p>
                {renderButtonAdd()}
              </div>
            )}
            {renderChildrenGrouped()}
            {renderAddedChildren()}
          </div>
          {childrenList && childrenList.length > 0 && (
            <div className={styles.addSectionContainer}>
              {renderButtonAdd()}
            </div>
           )}
        </div>

      </FlexInset>
      {dismissible && (
        <div className={styles.closeIcon} onClick={handleClosePage}>
          <CloseIcon />
        </div>
      )}
    </>
  )
}


export default ParentAccountInfo
