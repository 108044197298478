import React, { FunctionComponent, useState } from 'react'
import TextInput from '@peachjar/ui/dist/lib/components/Inputs/TextInputWrapper'
import { ButtonFlatSmall, ButtonPrimarySmall } from '@peachjar/ui/dist/lib/components/Buttons'
import {
  createMuiTheme,
  MuiThemeProvider,
} from '@material-ui/core/styles'
import MessageBanner from '../../App/components/Banners/MessageBanner'
import AccountsBffClientHttp from '../../App/Dependencies/AccountsBffClientHttp'
import { changeEmailMessages, bannersMessages } from '../Parent.constants'
import { css } from 'emotion'
import emailSendIcon from '../../assets/email-send-icon.svg'
import useGlobalNotifications from '@peachjar/ui/dist/lib/hooks/useGlobalNotifications'
import colors from '@peachjar/ui/dist/lib/styles/colors'
import axios from 'axios'
import { CssBaseline } from '@material-ui/core'

const accountsBff = new AccountsBffClientHttp(axios)

const EMAIL_EXPRESSION = /^[^\s@]+@[^\s@]+$/

function isEmailValid(email) {
  return EMAIL_EXPRESSION.test(email)
}

type Props = {
  email: string,
  disabled?: boolean,
  updateRevertBlock: () => void,
  hierarchyType: string
}

const ChangeEmailForm: FunctionComponent<Props> = ({
  email,
  disabled = false,
  updateRevertBlock,
  hierarchyType
}) => {
  const {
    notifyError,
    close: closeNotifications,
  } = useGlobalNotifications()

  const [isLoading, setIsLoading] = useState(false)
  const [defaultEmail, setDefaultEmail] = useState(email)
  const [inputEmail, setInputEmail] = useState(email)
  const [showBannerVerifyEmail, setShowBannerVerifyEmail] = useState(false)
  const [dirty, setDirty] = useState(false)
  const [error, setError] = useState<null | string>(null)

  const isFormValid = dirty && !error

  const handleInputChange = v => {
    const newEmailInput = v.target.value
    setError(
      isEmailValid(newEmailInput) ? null : changeEmailMessages.invalidEmailError
    )
    setInputEmail(newEmailInput)
    setDirty(true)
  }

  const resetFormState = (emailState: string) => {
    setError(null)
    setDirty(false)
    setDefaultEmail(emailState)
    setInputEmail(emailState)
  }

  const handleCancel = () => {
    resetFormState(defaultEmail)
    closeNotifications()
  }

  const handleSendVerificationEmail = async () => {
    closeNotifications()
    setIsLoading(true)
    try {
      const { data, errors } = await accountsBff.verifyChangeEmail({
        userId: 1,
        email: inputEmail,
        hierarchyType
      })

      if (data) {
        const { verifyChangeEmail } = data
        resetFormState(verifyChangeEmail.email)
        setShowBannerVerifyEmail(true)
        updateRevertBlock()
      }
      if (errors) {
        notifyError({
          key: 'UpdateEmailError',
          message: errors![0].message,
        })
      }
      setIsLoading(false)
    } catch (err) {
      notifyError({
        key: 'UpdateEmailError',
        message: changeEmailMessages.updatedError,
      })
    }
  }

  return (
    <>
    <MuiThemeProvider theme={inputTheme}>
    <CssBaseline />
      <div>
        <TextInput
          label={'Email Address'}
          value={inputEmail}
          error={error}
          onChange={handleInputChange}
          disabled={disabled}
        />
      </div>
      {showBannerVerifyEmail && (
        <div className={styles.content}>
          <MessageBanner
            iconSrc={emailSendIcon}
            subheadline={bannersMessages.emailNeedsToBeVerified}
            variant={'warning'}
          />
        </div>
      )}
      {dirty && (
        <div className={styles.buttonBar}>
          <div className={styles.secondaryCta}>
            <ButtonFlatSmall onClick={handleCancel} disabled={!dirty}>
              Cancel
            </ButtonFlatSmall>
          </div>
          <div>
            <ButtonPrimarySmall
              loading={isLoading}
              onClick={handleSendVerificationEmail}
              disabled={!isFormValid}
            >
              Update
            </ButtonPrimarySmall>
          </div>
        </div>
      )}
      </MuiThemeProvider>
    </>
  )
}

const inputTheme = createMuiTheme({
  overrides: {
    MuiCssBaseline: {
      '@global': {
        input: {
          backgroundColor: 'white !important',
          color: 'black !important',
          borderRadius: '2px',
          fontSize: '13px',
          padding: '8px',
        },
      },
    },
  },
})

export default ChangeEmailForm

const styles = {
  content: css`
    font-family: 'Proxima Nova', Arial, Helvetica, sans-serif;
    margin-bottom: 18px;
    margin-top: 32px;
    & .MuiAlert-standardWarning {
      border: none !important;
    }
  `,
  emailDisclaimer: css`
    color: ${colors.slate};
    font-size: 13px;
  `,
  subscriptionDisclaimer: css`
    color: ${colors.prussian};
    font-size: 16px;
  `,
  secondaryCta: css`
    margin-right: 16px;
  `,
  buttonBar: css`
    display: flex;
    justify-content: flex-end;
    margin-top: 32px;
  `,
  link: css`
    color: ${colors.jungle} !important;
    cursor: pointer;
    &:hover {
      text-decotarion: underline;
    }
  `,
}
