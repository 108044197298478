import React, { useState } from 'react'
import {
  createMuiTheme,
  MuiThemeProvider,
} from '@material-ui/core/styles'
import { css, cx } from 'emotion'
import { Grade } from '../../ParentSignUp/ParentSignUp.container'
import Dashed from '@peachjar/ui/dist/lib/components/Dashed'
import { elements } from '@peachjar/components'
import AutocompleteInput from '@peachjar/ui/dist/lib/components/Inputs/AutocompleteInput'
import SelectInput from '@peachjar/ui/dist/lib/components/Inputs/SelectInput'
import CloseIcon from '@material-ui/icons/Close'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline'
import Tooltip from '@material-ui/core/Tooltip'
import IconButton from '@material-ui/core/IconButton'
import colors from '@peachjar/ui/dist/lib/styles/colors'
import {
  ButtonPrimarySmall,
  ButtonFlatSmall,
} from '@peachjar/ui/dist/lib/components/Buttons/Buttons'
import ChildRemovalConfirmation from '../components/ChildRemovalConfirmation'
import infoIcon from '../../assets/info-icon.svg'

const toolTipText =
  'Enter the school your child or children attend. Only schools that are using Peachjar are available for selection.'

let i = 0

const c = (description: string) => {
  i += 1
  return {
    id: i,
    description,
  }
}

const currentYear = new Date().getFullYear()
const nextYear = currentYear + 1

const {
  typography: { Note, Label },
} = elements

type SchoolItem = {
  description: string
  schoolId: number
  schoolName: string
  districtId: number
  districtName: string
  gradeLevels: string
}

const INPUT_REQUIRED_MESSAGE_ERROR = 'Your missed this field.'

const ChildEnrollment = ({
  index,
  childUid,
  disabled,
  wasAdded,
  wasChanged,
  institution,
  currentGrade,
  grades,
  lookUpSchools,
  gradeLevelChanged,
  handleSubmit,
  handleSchoolChange,
  handleGradeChange,
  handleRemoveChild,
  handleCancel,
}) => {
  const [isRemovalModalOpen, setIsRemovalModalOpen] = useState<boolean>(false)
  const [hasSchoolError, setHasSchoolError] = useState<boolean>(false)
  const [hasGradeError, setHasGradeError] = useState<boolean>(false)

  const isFormInvalid = institution.institutionName === '' || !currentGrade

  const onClickButtonSubmit = () => {
    if (institution.institutionName === '') {
      setHasSchoolError(true)
    }
    if (!currentGrade) {
      setHasGradeError(true)
    }
    if (isFormInvalid) { return }

    handleSubmit(childUid)
  }

  return (
    <MuiThemeProvider theme={toolTipTheme}>
      <div className={styles.cardBorder}></div>
      <div className={styles.enrollmentGroup} key={`${Date.now()}+${index}`}>
        <div className={cx(styles.inputWithToolTip, styles.inlined)}>
          <h4 className={styles.formSectionHeadline}>Child</h4>
          {disabled ?
          (
            <div>
              <Note className={styles.label}>
                School Name
              </Note>
              <Label className={styles.textInput}>
                {institution.institutionName}
              </Label>
            </div>
          )
          : (
            <AutocompleteInput<SchoolItem>
              lookup={lookUpSchools}
              label='School Name'
              value={institution.institutionName}
              isEqual={(s1, s2) => {
                // Typescript in this project does not seem to be working correctly.
                // this should be (s1?.schoolId === s2?.schoolId) || false
                return (s1 && s2 && s1.schoolId === s2.schoolId) || false
              }}
              onChange={val => {
                if (!!val) {
                  setHasSchoolError(false)
                }
                handleSchoolChange(val, childUid)
              }}
              renderOption={val => (
                <div>
                  {val.schoolName}
                  <span className={styles.secondaryName}>{val.districtName}</span>
                </div>
              )}
              error={hasSchoolError ? INPUT_REQUIRED_MESSAGE_ERROR : null}
            />
          )}

        {disabled ?
          (
            <div className={styles.labelGrade}>
              <Note className={styles.label}>
                {`Grade (${currentYear}/${nextYear} school year)`}
              </Note>
              <Label className={styles.textInput}>
                {!currentGrade || currentGrade.description === 'N' ? <Dashed testid='dashed' /> : currentGrade.description}
              </Label>
           </div>
          )
          :
          (
          <div
            className={cx(
              styles.inputWithToolTip,
              styles.enrollmentGroup,
              styles.gradeOptions
            )}
          >
            <SelectInput<Grade>
              // @ts-ignore
              picker={grade => [grade.description, grade.id]}
              // @ts-ignore
              options={grades}
              label={`Grade (${currentYear}/${nextYear} school year)`}
              // @ts-ignore
              value={currentGrade}
              onChange={(input) => {
                setHasGradeError(false)
                handleGradeChange(input, childUid)
              }}
            />
            {hasGradeError && (
              <div className={styles.messageError}>
                {INPUT_REQUIRED_MESSAGE_ERROR}
              </div>
            )}
          </div>
          )
        }

          {!disabled && (
            <div className={`${styles.removeIcon} ${styles.tooltipButton}`}>
                <Tooltip
                  title='Remove Child'
                  placement='right'
                  enterTouchDelay={0}
                >
                  <IconButton
                    arial-label='Remove Child'
                    aria-haspopup='dialog'
                    onClick={() => {
                      if (wasAdded) {
                        handleRemoveChild(childUid)
                      } else {
                        setIsRemovalModalOpen(true)
                      }
                    }}
                  >
                    <CloseIcon fontSize='small' />
                  </IconButton>
                </Tooltip>
              </div>
          )}
        </div>
        {!disabled && (
          <span className={`${styles.tooltip} ${styles.tooltipButton}`}>
            <Tooltip
              title={toolTipText}
              enterTouchDelay={0}
              placement='right'>
              <IconButton arial-label={toolTipText}>
                <HelpOutlineIcon fontSize='small' />
              </IconButton>
            </Tooltip>
          </span>
        )}
        {gradeLevelChanged &&
          <span className={`${styles.infoIcon}`}>
            <img src={infoIcon} />
          </span>
        }

        {(wasAdded || wasChanged) && (
          <div className={styles.footer}>
            <div>
              <ButtonFlatSmall onClick={() => handleCancel(childUid)}>
                Cancel
              </ButtonFlatSmall>
            </div>
            <div
              onClick={onClickButtonSubmit}
            >
              <ButtonPrimarySmall

                disabled={isFormInvalid}
              >
                {wasAdded ? 'Add' : 'Update'}
              </ButtonPrimarySmall>
            </div>
          </div>
        )}
      </div>
      <ChildRemovalConfirmation
        isOpen={isRemovalModalOpen}
        schoolName={institution.institutionName}
        onSubmit={() => {
          setIsRemovalModalOpen(false)
          handleRemoveChild(childUid)
        }}
        onCancel={() => {
          setIsRemovalModalOpen(false)
        }}
        />
    </MuiThemeProvider>
  )
}

const styles = {
  enrollmentGroup: css`
    background-color: ${colors.platinum};
    padding: 20px 24px;
    border-radius: 6px;
    margin-bottom: 16px;
    position: relative;
    & .MuiAutocomplete-inputRoot {
      & .MuiAutocomplete-input {
        text-transform: capitalize;
      }
    }
  `,
  cardBorder: css`
    height: 1px;
    border-bottom: 1px solid ${colors.silver};
    z-index: 1;
    position: relative;
    top: 64px;
  `,
  inputWithToolTip: css`
    width: 90% !important;
  `,
  gradeOptions: css`
    width: 100% !important;
    padding: 0;
    margin-top: 24px;
    & .MuiInputLabel-root {
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  `,
  inlined: css`
    display: inline-block;
  `,
  tooltip: css`
    position: absolute;
    top: 93px;
  `,
  infoIcon: css`
    position: absolute;
    top: 185px;
    padding-left: 15px;
  `,
  tooltipButton: css`
    & .MuiIconButton-root {
      color: ${colors.prussian}
    }
    & .MuiIconButton-root:hover {
      background-color: rgba(97, 193, 108, 0.15);
    }
  `,
  formSectionHeadline: css`
    font-size: 13px;
    color: ${colors.slate};
    margin-bottom: 49px;
  `,
  secondaryName: css`
    font-size: 13px;
    font-family: 'Proxima Nova', Helvetica, Arial, sans-serif;
    color: ${colors.slate};
    display: block;
  `,
  removeIcon: css`
    position: absolute;
    top: 4px;
    right: 4px;
    cursor: pointer;
  `,
  ctaLink: css`
    color: ${colors.jungle};
    font-size: 16px;
    display: block;
    margin-top: 10px;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
      color: ${colors.jungle};
    }
  `,
  footer: css`
    display: flex;
    justify-content: flex-end;

    & > div {
      margin-left: 10px;
    }

 `,
 label: css`
  font-size: 13px;
  color: ${colors.slate};
  display: block;
  padding-bottom: 8px;
  line-height: 16px;
 `,
 labelGrade: css`
  padding-top: 24px;
 `,
 textInput: css`
  font-size: 16px;
  color: ${colors.prussian};
  text-transform: capitalize;
  line-height: 24px;
  padding-bottom: 24px;
 `,
 messageError: css`
  margin-top: 3px;
  color: #AC0B1F;
  line-height: 1.48;
  font-size: 13px;
 `
}

const toolTipTheme = createMuiTheme({
  overrides: {
    MuiTooltip: {
      tooltip: {
        backgroundColor: '#2d344b',
        borderRadius: '2px',
        fontSize: '13px',
        padding: '8px',
      },
    },
  },
})

export default ChildEnrollment
