import Dependencies from './Dependencies'
import PortalApiClientMock from './PortalApiClientMock'
import AccountsBffClientMock from './AccountsBffClientMock'

export default function getMockDependencies(): Dependencies {
    return {
        portalApi: new PortalApiClientMock(),
        accountsBff: new AccountsBffClientMock(),
    }
}
