import React, { FormEvent } from 'react'
import { css } from 'emotion'
import TextInput from '@peachjar/ui/dist/lib/components/Inputs/TextInput'
import { UserFormProps } from './UserSignUp'
import FormFooter from '../App/components/FormFooter'
import UserAlreadyExistsError from '../App/components/UserAlreadyExistsError'
const TextField: any = TextInput

const UserSignUpForm: React.FunctionComponent<UserFormProps> = ({
  fields,
  formValid,
  handleFieldChange,
  handleSubmit,
  userAlreadyExists
}) => {
  return (
    <div className={styles.formContainer}>
      <form onSubmit={(e: FormEvent<HTMLElement>) => handleSubmit(e)}>
        {fields.map(
          (
            { id, label, value, error, errorText, limit, inputType },
            index
          ) => (
            <div className={styles.formField} key={index}>
                <TextField
                  autoFocus={index === 0}
                  id={id}
                  name={id}
                  label={label}
                  type={inputType}
                  value={value}
                  error={error}
                  errorText={errorText}
                  textLimit={limit}
                  onChange={e => handleFieldChange(e, id)}
                />
                {inputType === 'email' && userAlreadyExists &&
                <UserAlreadyExistsError />}
            </div>
          )
        )}
        <FormFooter
          isFormValid={formValid}
          handleSubmit={handleSubmit}
          renderLoginOption={true}
          />
      </form>
    </div>
  )
}

const styles = {
  formContainer: css`
    margin: 24px 0;
  `,
  formField: css`
    margin: 24px 0;
  `,
}

export default UserSignUpForm
