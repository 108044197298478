// @ts-nocheck
import React, { Component, useEffect } from 'react'
import { css } from 'emotion'
import {
  createGenerateClassName,
  StylesProvider,
} from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom'
import GlobalNotificationProvider from '@peachjar/ui/dist/lib/contexts/Notifications/GlobalNotificationProvider'
import PageNotFound from './PageNotFound'
import LogIn from '../LogIn/LogIn'
import SignUp from '../SignUp/SignUp.container'
import ParentSignUp from '../ParentSignUp/ParentSignUp.container'
import OrgSignUp from '../OrgSignUp/OrgSignUp.container'
import UserSignUp from '../UserSignUp/UserSignUp.container'
import StaffSignUp from '../StaffSignUp/StaffSignUp'
import StaffSignUpExisting from '../StaffSignUp/StaffSignUpExisting.container'
import StaffSignUpThankYou from '../StaffSignUp/StaffSignUpThankYou'
import StaffSignUpNew from '../StaffSignUp/StaffSignUpNew.container'
import StaffSignUpNewThankYou from '../StaffSignUp/StaffSignUpNewThankYou'
import CompleteProfile from '../CompleteProfile/CompleteProfile'
import MagicLinkSuccess from '../MagicLink/MagicLinkSuccess.container'
import MagicLinkExpired from '../MagicLink/MagicLinkExpired.container'
import ParentAccountInfo from '../AccountInformation/Parent.container'
import ErrorBoundary from './ErrorBoundary'
import BrandBar from './BrandBar'
import EmailNotifications from '../EmailNotifications/EmailNotifications.container'
import { APP_ROUTES } from './config'
import 'bootstrap/dist/css/bootstrap.min.css'
import SubscriptionProvider from './context/SubscriptionProvider'
import UpdateEmailExpired from '../UpdateEmail/UpdateEmailExpired'

const generateClassName = createGenerateClassName({
  productionPrefix: 'accounts',
})

class App extends Component {
  render() {
    return (
      <StylesProvider generateClassName={generateClassName}>
        <GlobalNotificationProvider>
          <SubscriptionProvider>
          <div className={classNames.app}>
            <CssBaseline />
            <Router>
            <BrandBar />
            <div className={classNames.content}>
              <ErrorBoundary>
                  <Switch>
                    <Redirect
                      exact
                      // tslint:disable-next-line: quotemark
                      from="/"
                      to={APP_ROUTES.login}
                    />
                    <Route exact path={APP_ROUTES.login} component={LogIn} />
                    <Route exact path={APP_ROUTES.signup} component={SignUp} />
                    <Route
                      exact
                      path={APP_ROUTES.parentSignUp}
                      component={ParentSignUp}
                    />
                    <Route
                      exact
                      path={APP_ROUTES.orgSignUp}
                      component={OrgSignUp}
                    />
                    <Route
                      exact
                      path={APP_ROUTES.orgSignUpAccountInfo}
                      component={UserSignUp}
                    />
                    <Route
                      exact
                      path={APP_ROUTES.orgSignUpLegacy}
                      component={OrgSignUp}
                    />
                    <Route
                      exact
                      path={APP_ROUTES.staffSignUp}
                      component={StaffSignUp}
                    />
                    <Route
                      exact
                      path={APP_ROUTES.staffSignUpExisting}
                      component={StaffSignUpExisting}
                    />
                    <Route
                      exact
                      path={APP_ROUTES.staffSignUpThankYou}
                      component={StaffSignUpThankYou}
                    />
                    <Route
                      exact
                      path={APP_ROUTES.staffSignUpNewThankYou}
                      component={StaffSignUpNewThankYou}
                    />
                    <Route
                      exact
                      path={APP_ROUTES.staffSignUpNew}
                      component={StaffSignUpNew}
                    />
                    <Route
                      exact
                      path={APP_ROUTES.completeProfile}
                      component={CompleteProfile}
                    />
                    <Route
                      exact
                      path={APP_ROUTES.magicLinkSuccess}
                      component={MagicLinkSuccess}
                    />
                    <Route
                      exact
                      path={APP_ROUTES.magicLinkExpired}
                      component={MagicLinkExpired}
                    />
                    <Route
                      exact
                      path={APP_ROUTES.updateEmailExpired}
                      component={UpdateEmailExpired}
                    />
                    <Route
                      exact
                      path={APP_ROUTES.parentAccountInfo}
                      component={ParentAccountInfo}
                    />
                    <Route
                      exact
                      path={APP_ROUTES.parentEmailNotificationsManage}
                      component={EmailNotifications}
                    />
                    <Route
                      exact
                      path={APP_ROUTES.parentEmailNotifications}
                      component={EmailNotifications}
                    />
                    <Route
                      path={APP_ROUTES.errorBoundary}
                      component={() => {
                        throw new Error('Test error for error boundary')
                      }}
                    />
                    <Route component={PageNotFound} />
                  </Switch>
              </ErrorBoundary>
            </div>
            </Router>
          </div>
          </SubscriptionProvider>
        </GlobalNotificationProvider>
      </StylesProvider>
    )
  }
}

const classNames = {
  app: css`
    display: flex;
    flex-flow: column nowrap;
    min-height: 100vh;
  `,
  content: css`
    flex: 1 1 auto;
    display: flex;
    flex-flow: column nowrap;
  `,
}

export default App
