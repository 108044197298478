import React, { useContext } from 'react'
import { Subscription } from '../Dependencies/PortalBffClientHttp'

export type SubscriptionsByDistrict = {
  districtId: number,
  districtName: string;
  schools: Subscription[]
}

export type SubscriptionProvider = {
  loading: boolean
  subscriptions: SubscriptionsByDistrict[]
  getSubscriptions: () => void
}

const SubscriptionContext = React.createContext<SubscriptionProvider>({
  loading: false,
  subscriptions: [],
  getSubscriptions: () => true
})

export const useSubscriptionContext = () => useContext(SubscriptionContext)

export default SubscriptionContext
