import { Choice } from '@peachjar/ui/dist/lib/components/Inputs/RadioGroupInput'

export const ADDRESS_INPUT_TYPE = 'address'

export const STAFF_MEMBER_OPTIONS: Array<Choice<string>> = [
  {
    label: 'Staff',
    value: 'staff',
  },
  {
    label: 'Volunteer',
    value: 'volunteer',
  },
]
