import React, { FunctionComponent, useState, useEffect } from 'react'
import { Dispatch } from 'redux'
import queryString from 'query-string'
import useGlobalNotifications from '@peachjar/ui/dist/lib/hooks/useGlobalNotifications'
import { RouteComponentProps } from 'react-router-dom'
import { connect } from 'react-redux'
import TwoTone from '../App/Layout/TwoTone'
import IllustratedQuote from '../App/Layout/IllustratedQuote'
import MagicEnvelope from './MagicEnvelope'
import MagicLinkExpired from './MagicLinkExpired'
import FlexInset from '../App/Layout/FlexInset'
import {
    submitEmail,
    setEmail,
    resetMagicLinkState,
} from '../LogIn/Redux/LogInActions'
import { APP_ROUTES } from '../App/config'
import { ActionResults } from '../App/Store/makeStore'
import { first, isArray } from 'lodash'

type Props = RouteComponentProps & {
    setUserEmail: (email: string) => void
    resendMagicLink: (email: string, destination: string) => Promise<any>
    clearMagicLinkState: () => Promise<any>
}

function normalizeQueryParam(param: string | string[] | null | undefined): string | null {
    if (!param) {
        return null
    }
    if (isArray(param) && param.length > 0) {
        return atob(first(param)!)
    }
    return atob(param as string)
}

const MagicLinkExpiredContainer: FunctionComponent<Props> = ({
    history,
    setUserEmail,
    resendMagicLink,
    clearMagicLinkState,
}) => {
    const [params, setParams] = useState(() => {
        const { email, destination } = queryString.parse(window.location.search)

        return {
            email: normalizeQueryParam(email),
            destination: normalizeQueryParam(destination),
        }
    })
    const { notifyError } = useGlobalNotifications()

    const resendLink = async () => {
        const { success } = await resendMagicLink(
            // @ts-ignore
            params.email,
            params.destination
        )

        if (success) {
            clearMagicLinkState()
            history.push(APP_ROUTES.magicLinkSuccess)
            return
        }

        notifyError({
            persist: false,
            message: 'Oops. Something went wrong. Please try again.',
        })
    }

    useEffect(() => {
        if (params.email) {
            // @ts-ignore
            setUserEmail(params.email)
        }
    }, [])

    if (!params.email || !params.destination) {
        history.push(APP_ROUTES.login)
    }

    return (
        <TwoTone goBackUrl={null}>
            <FlexInset>
                <MagicLinkExpired resendMagicLinkHandler={resendLink} />
            </FlexInset>
            <IllustratedQuote svg={<MagicEnvelope />} />
        </TwoTone>
    )
}

export default connect(null, (dispatch: Dispatch<ActionResults>) => ({
    // @ts-ignore
    resendMagicLink: (email: string, destination: string) =>
        // @ts-ignore
        dispatch(submitEmail(email, destination)),
    setUserEmail: (email: string) => dispatch(setEmail(email)),
    clearMagicLinkState: () => dispatch<any>(resetMagicLinkState()),
}))(MagicLinkExpiredContainer)
