export enum GradeLevels {
  ECE = 'ECE',
  PK = 'PK',
  KG = 'KG',
  FIRST = '1',
  SECOND = '2',
  THIRD = '3',
  FOURTH = '4',
  FIFTH = '5',
  SIXTH = '6',
  SEVENTH = '7',
  EIGTH = '8',
  NINTH = '9',
  TENTH = '10',
  ELEVENTH = '11',
  TWELFTH = '12',
  CE = 'CE',
  N = 'N'
}

export const SCHOOL_NAME_TOOLTIP =
  'Enter the school your child or children attend. Only schools that are using Peachjar are available for selection.'

export const SCHOOL_FIELD_TYPE = 'school_field'
export const GRADE_FIELD_TYPE = 'grade_field'

export const numberToStringMapping = {
  1: 'One',
  2: 'Two',
  3: 'Three',
  4: 'Four',
  5: 'Five',
  6: 'Six',
  7: 'Seven',
  8: 'Eight',
  9: 'Nine',
  10: 'Ten',
  11: 'Eleven',
  12: 'Twelve',
  13: 'Thirteen',
  14: 'Fourteen',
  15: 'Fifteen',
  16: 'Sixteen',
  17: 'Seventeen',
  18: 'Eighteen',
  19: 'Nineteen',
  20: 'Twenty',
}

let gradeId = 0

export const convertToDescriptionApi = (description: string) => {
  gradeId += 1
  return {
    id: gradeId,
    description,
  }
}

export const grades: Array<{ id: number, description: string }> = [
  convertToDescriptionApi(GradeLevels.ECE),
  convertToDescriptionApi(GradeLevels.PK),
  convertToDescriptionApi(GradeLevels.KG),
  convertToDescriptionApi(GradeLevels.FIRST),
  convertToDescriptionApi(GradeLevels.SECOND),
  convertToDescriptionApi(GradeLevels.THIRD),
  convertToDescriptionApi(GradeLevels.FOURTH),
  convertToDescriptionApi(GradeLevels.FIFTH),
  convertToDescriptionApi(GradeLevels.SIXTH),
  convertToDescriptionApi(GradeLevels.SEVENTH),
  convertToDescriptionApi(GradeLevels.EIGTH),
  convertToDescriptionApi(GradeLevels.NINTH),
  convertToDescriptionApi(GradeLevels.TENTH),
  convertToDescriptionApi(GradeLevels.ELEVENTH),
  convertToDescriptionApi(GradeLevels.TWELFTH),
  convertToDescriptionApi(GradeLevels.CE),
]
