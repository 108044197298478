import React, { ReactNode, FunctionComponent } from 'react'
import { css } from 'emotion'
import colors from '@peachjar/ui/dist/lib/styles/colors'

type Props = {
  children: ReactNode
}

const classNames = {
  heading: css`
    width: 100%;
    max-width: 420px;
    font-family: Proxima Nova, Helvetica, Arial, sans-serif;
    font-size: 25px;
    font-weight: normal;
    line-height: 1.29;
    color: ${colors.prussian};
    margin-bottom: 24px;
  `,
}

const SecondaryHeading: FunctionComponent<Props> = ({ children }) => (
  <h1 className={classNames.heading}>{children}</h1>
)

export default SecondaryHeading
