import React, { useRef, useState, Fragment } from 'react'
import Button from '@material-ui/core/Button'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Grow from '@material-ui/core/Grow'
import Paper from '@material-ui/core/Paper'
import Popper from '@material-ui/core/Popper'
import Avatar from '@material-ui/core/Avatar'
import { css } from 'emotion'
import flyerBoardIcon from '../../assets/flyerboard_icon_white.svg'
import { useLocation } from 'react-router-dom'
import config from '../../config'
import { SubscriptionsByDistrict } from '../../App/context/SubscriptionContext'

const { colors, elements } = require('@peachjar/components')

const { REACT_APP_FLYER_BOARD_APP_URL } = config
const {  Paragraph } = elements.typography

type Props = {
  subscriptions: SubscriptionsByDistrict[]
}

const FlyerBoardDropDown = ({
  subscriptions,
  }: Props) => {
  const flyerBoardButton = useRef<any>()
  const location = useLocation()
  const [open, setOpen] = useState(false)

  const handleToggle = () => {
    setOpen(!open)
  }

  const handleClose = (event: React.SyntheticEvent<EventTarget>) => {
    // This prevents both handleToggle and handleClose being called on profile button click
    const clickedNode = event.target
    const flyerBoardButtonRef = flyerBoardButton.current
    // @ts-ignore
    const isNodeChildOfflyerBoardButton =
    flyerBoardButtonRef && flyerBoardButtonRef.contains(clickedNode)

    if (isNodeChildOfflyerBoardButton) {
      return
    }

    setOpen(false)
  }

  const getLink = (name, audienceType, audienceId, idx) => {
    const resource = audienceType === 'school' ? 'schools' : 'districts'
    const link = `${REACT_APP_FLYER_BOARD_APP_URL}/flyers/all/${resource}/${audienceId}/`
    return (
        <a
          href={link}
          key={idx}
          className={classNames.flyerBoardLink}
        >
          <li
            className={`${audienceType === 'school' ? classNames.flyerBoardLinkList : classNames.districtNameLink}`}
          >
            {name}
          </li>
        </a>
      )
  }

  return (
    <Fragment>
      <Button
        ref={flyerBoardButton}
        aria-owns={open ? 'menu-list-grow' : ''}
        aria-haspopup='true'
        onClick={handleToggle}
        style={{
          display: 'flex',
          height: '100%',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: '0px 1rem',
          borderRadius: '0px',
          textTransform: 'none',
        }}
        className={classNames.dropDownActive}
      >
        <div className={classNames.dropdownTriggerContent}>
          <Avatar className={classNames.iconContainer}>
            <img
              src={flyerBoardIcon}
              alt='Flyer board'
              style={{ width: '16px' }}
            />
          </Avatar>
          <Paragraph className={classNames.flyearBoard}>
            Flyers
          </Paragraph>
        </div>
      </Button>
      <Popper
        open={open}
        anchorEl={flyerBoardButton.current}
        transition
        disablePortal
        placement='bottom-end'
        className={classNames.popper}
      >
        {({ TransitionProps }) => (
            <Grow
              {...TransitionProps}
              // id="menu-list-grow"
              style={{ transformOrigin: 'center top' }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <div className={classNames.flyerBoardContainer}>
                    {subscriptions.map(
                        (
                          {
                            districtId,
                            districtName,
                            schools,
                          }
                        ) => (
                          <ul className={classNames.flyerBoardMenu}>
                           {getLink(districtName, 'district', districtId, districtName)}
                           {schools.map(
                              (
                                {
                                  name,
                                  audienceId,
                                  audienceType
                                },
                                idx
                              ) => getLink(name, audienceType, audienceId, idx)
                            )}
                          </ul>
                        )
                      )}
                  </div>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
      </Popper>
    </Fragment>
  )
}

const classNames = {
  iconContainer: css`
    && {
      width: 1rem;
      height: 1.5rem;
      background-color: transparent;
    }
  `,
  flyearBoard: css`
    white-space: nowrap;
    padding: 0 0 0 5px;
    font-size: 13px !important;
  `,
  dropDownActive: css`
    border-bottom: 4px solid ${colors.leaf};
  `,
  dropdownTriggerContent: css`
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
  `,
  popper: css`
   z-index: 1;
   width: 240px;
 `,
  flyerBoardContainer: css`
    overflow: auto;
    overflow-x: hidden;
    max-height: calc(70vh);
  `,
  flyerBoardLinkList: css`
    color: ${colors.prussian};
    padding: 17px 32px;
    height: 54px;
    font-size: 13px;
    border-radius: 3px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    &:hover {
      text-decoration: none;
      background-color: #ededed;
    }
  `,
  flyerBoardLink: css`
    &:hover {
      text-decoration: none;
    }
  `,
  activeLink: css`
    border-left: 4px solid ${colors.leaf};
    font-weight: bold;
    padding-left: 22px;
    background-color: rgba(0, 0, 0, 0.03);
  `,
  flyerBoardMenu: css`
    list-style-type: none;
    padding: 8px 4px 15px 4px;
    margin-block-start: 0em;
    margin-block-end: 0em;
    & + ul {
      border-top: 1px solid rgba(0,0,0,.1);
    }
  `,
  districtNameLink: css`
     color: ${colors.prussian};
     padding: 16px 10px 10px 11px;
     font-size: 16px;
     height: 56px;
     border-radius: 3px;
     text-overflow: ellipsis;
     overflow: hidden;
     white-space: nowrap;
     &:hover {
       text-decoration: none;
       background-color: #ededed;
     }
   `
}

export default FlyerBoardDropDown
