/* tslint:disable */
import React, { useEffect, useState, useContext } from 'react'
import { v4 as uuid4 } from 'uuid'
import axios from 'axios'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import { ActionResults, RootState } from '../App/Store/makeStore'
import get from 'lodash/get'
import sortBy from 'lodash/sortBy'
import useGlobalNotifications from '@peachjar/ui/dist/lib/hooks/useGlobalNotifications'
import useProfile, {
  Profile,
  ChildNewModel
} from '../App/hooks/useProfile'
import SubscriptionContext from '../App/context/SubscriptionContext'
import AccountsBffClientHttp, {
  UpdateChildrenNewModelInput,
  LoginStatus
} from '../App/Dependencies/AccountsBffClientHttp'

import LoadingSpinner from '../App/components/LoadingSpinner'
import {
  convertToDescriptionApi,
} from '../ParentSignUp/ParentSignUp.constants'
import { getSchoolYear } from '../ParentSignUp/ParentSignUp.container'
import { parentMessages as MESSAGES } from './Parent.constants'
import APP_ROUTES from '../App/config'
import config from '../config'
import {
  GradeChangeInput,
  InstitutionFromSearch,
  ChildGroupByCommunity,
  ChildrenGroupByDistrict,
  PendingSchools,
} from './Parent.types'
import Parent from './Parent'
import ManageNotifications from '../ManageNotifications/ManageNotifications'
import {
  getLoginStatus
} from '../LogIn/Redux/LogInActions'

const httpClient = new AccountsBffClientHttp(axios)

const { REACT_APP_FLYER_BOARD_APP_URL} = config;

type Props = {
  loginStatus: LoginStatus,
  handleGetLoginStatus: () => void
}

const ParentContainer: React.FunctionComponent<Props> = ({
  loginStatus,
  handleGetLoginStatus
}) => {
  const {
    userId: _userId,
    loading: loadingProfile,
    email,
    managedBy,
    setManagedBy,
    hierarchy,
    revertBlocked: _revertBlocked,
    children: _children,
    updateChild: _updateChild,
    removeChild: _removeChild,
    updateEmail: updateProfileEmail,
    getActiveChildren,
    getPendingChildren,
  } = useProfile() as Profile

  const {
    getSubscriptions
  } = useContext(SubscriptionContext)
  const {
    notifySuccess,
    notifyError,
  } = useGlobalNotifications()

  const [userId, setUserId] = useState(_userId)
  const [dismissible, setDismissable] = useState<boolean>(true)
  const [dismissLocation, setDismissLocation] = useState<string>('')
  const [children, setChildren] = useState(_children)
  const [childrenGrouped, setChildrenGrouped] = useState<Array<ChildGroupByCommunity>>([])
  const [pendingSchools, setPendingSchools] = useState<Array<PendingSchools>>([])
  const [childrenAddingIds, setChildrenAddingIds] = useState<Array<string>>([])
  const [hierarchyType, setHierarchyType] = useState<string | null>(null)
  const [notifStatus, setNotifStatus] = useState<string>('')
  const [revertBlocked, setRevertBlocked] = useState<boolean>(_revertBlocked)

  useEffect(() => {
    setRevertBlocked(_revertBlocked)
  }, [_revertBlocked])
  useEffect(() => {
    const getNotificationPreferences = async () => {
     const {
       data:
       { getNotificationPreferences }
      } = await httpClient.getNotificationPreferences({
        refId: 'parent',
        notifType: 'flyer-email',
        notifTypeId: _userId!.toString()
      })

      if(getNotificationPreferences.success){
        setNotifStatus(getNotificationPreferences.notifStatus);
      }
    }
    setUserId(_userId)
    if(_userId) {
      getNotificationPreferences();
    }

  }, [_userId])


  useEffect(() => {
    if(!children && _children){
      const pendingChildren = getPendingChildren();
      const activeChildren = getActiveChildren()
      setChildren(activeChildren)
      setChildrenGrouped(getChildrenGroupedByCommunity(activeChildren))
      if(pendingChildren.length > 0) {
        setPendingSchools(getPendingSchools(pendingChildren))
      }
    }
    else {
      if(children){
        setChildrenGrouped(getChildrenGroupedByCommunity(children))
      }
    }
  }, [_children])

  useEffect(() => {
    setHierarchyType(hierarchy ? hierarchy!.type : null)
  }, [hierarchy])

  useEffect(() => {
    const { redirectTo, loggedIn } = loginStatus

    if (loggedIn) {
      if (redirectTo && redirectTo.includes(APP_ROUTES.parentAccountInfo)) {
        setDismissable(false)
        return
      }
      setDismissable(true)
      const originUri = document.referrer
      const params = new URLSearchParams(window.location.search)
      const flyerParam = params.get('flyer')
      const resourceParam = params.get('resource')
      const resourceIdParam = params.get('resourceId')

      if (flyerParam && resourceParam && resourceIdParam && children) {
        const child = children!.find(({ institutionId }) => institutionId === resourceIdParam)
        if (child) {
          const url = `${REACT_APP_FLYER_BOARD_APP_URL}/flyers/${flyerParam}/${resourceParam}/${resourceIdParam}`
          setDismissLocation(url)
          return
        }
      }

      if (redirectTo) {
        setDismissLocation(redirectTo)
        return
      }

      if (
        originUri &&
        originUri.includes('app.peachjar') &&
        originUri.includes('/flyers/')
      ) {
        setDismissLocation(originUri)
        return
      }

      setDismissable(false)
    }

  }, [loginStatus])

  const handleGradeChange = (input: GradeChangeInput, childUId: string) => {
    const updatedChild = {
      wasChanged: true,
      gradeLevel: input.description,
    }
    updateChild(childUId, updatedChild)
  }

  const updateChild = (childUid: string, changes: object) => {
    const index = children!.findIndex(c => c.childUid === childUid)
    const updatedChild: ChildNewModel = {
      ...children![index],
      ...changes
    }
    if(children){
      const newChildren = [
        ...children.slice(0, index),
        updatedChild,
        ...children.slice(index + 1)
      ]

     setChildren(newChildren)
    }
  }

  const handleSchoolChange = async (
    value: InstitutionFromSearch & { schoolId: number; districtId: number },
    id: number
  ) => {
    if (id && value) {
      const gradeLevels: string[] | null = get(value, 'gradeLevels', null)

      const updatedChild = {
        institutionId: value.schoolId.toString(),
        institutionType: 'school',
        institutionName: value.description,
        hierarchyId: value.districtId.toString(),
        hierarchyName: value.districtName.toLocaleLowerCase(),
        hierarchyType: 'district',
        grades: gradeLevels
          ? gradeLevels.map(grade => convertToDescriptionApi(grade))
          : null,
        gradeLevel: 'N',
        wasChanged: true,
        community: value.city,
        postalCode: value.postalCode
      }

      updateChild(id.toString(), updatedChild)
    }
    else {
      if(value === null){
        const index = children!.findIndex(c => c.childUid === id.toString())
        if(children![index].institutionName!== ''){
          updateChild(id.toString(), {
            institutionName: '',
            institutionId: null,
            wasChanged: true,
          })
        }
      }
    }

  }

  const lookUpSchools = async (options, callback) => {
    try {
      const searchTerm = `parentRole-${options.input}` || 'San Diego'
      const res = await httpClient.searchForMySchool(searchTerm)
      const results = res.data.searchAcademicGroups.schools.reduce(
        (accum, curr) => [...accum, { ...curr, description: curr.schoolName }],
        []
      )

      callback(results)
    } catch (e) {
      // tslint:disable:no-console
      console.log('Unable to fetch school names', e)
    }
  }

  const handleClosePage = () => {
    window.location.href = dismissLocation
  }

  const handleCancel = (childUId: string) => {
    const oldChild = _children!.find(c => c.childUid === childUId)!
    const cancelChild = {
      ...oldChild,
      wasChanged: false
    }
    updateChild(childUId, cancelChild)
  }

  const updateRevertBlock = () => {
    if (!revertBlocked) {
      setRevertBlocked(true)
    }
  }

  const handleSubmit = async (childUId: string) => {
    const child = children!.find(c => c.childUid === childUId)!

    child.status = 'active'

    const newModel = {...child}

    // @ts-ignore
    delete newModel.__typename
    delete newModel.grades
    delete newModel.wasAdded
    delete newModel.wasChanged
    // @ts-ignore
    delete newModel.institutionGradeLevel

    const updateInput: UpdateChildrenNewModelInput = {
      guardianId: userId!,
      add: child.wasAdded ? [newModel] : [],
      change: !child.wasAdded ? [newModel]: [],
    }
    const errorMessage = child.wasAdded ?
        MESSAGES.addChildError
      : MESSAGES.updateChildError

    try {
      const { data: { updateChildrenNewModel }} = await httpClient.updateChildrenNewModel(updateInput)
      const { success } = updateChildrenNewModel;
      if (success) {
        let message = child.wasAdded ?
        MESSAGES.addChildSuccess : MESSAGES.updateChildSuccess
        notifySuccess({ message })

        const updatedChild = {
          ...child,
          wasAdded: false,
          wasChanged: false,
        }

        if(child.wasAdded){
          removeChildrenAddingId(childUId)
          updateChild(childUId, {
            ...child,
            wasAdded: false,
            wasChanged: false,
          })
          _updateChild(childUId, child)
        }
        else {
          updateChild(childUId, updatedChild)
          _updateChild(childUId, updatedChild)
        }
        getSubscriptions()
        handleGetLoginStatus()
        updateRevertBlock()
      }
      else {
        notifyError({ message: errorMessage })
      }
    } catch (e) {
      notifyError({ message: errorMessage })
    }

  }

  const handleAddChild = () => {
    let childUid = uuid4()
    setChildren([
      ...children!,
      {
        childUid,
        institutionType: 'school',
        hierarchyType: 'district',
        institutionId: '',
        institutionName: '',
        hierarchyId: '',
        hierarchyName: '',
        gradeLevel: 'N',
        schoolYear: getSchoolYear(),
        schoolType: 'peachjar-school',
        community: null,
        postalCode: '',
        status: 'active',
        institutionGradeLevel: '',
        wasAdded: true,
        wasChanged: false,
      }
    ])

    setChildrenAddingIds([
      ...childrenAddingIds,
      childUid
    ])
  }

  const handleRemoveChild = async (childUId: string) => {
    if(children){
      const child = children.find(c => c.childUid === childUId)!
      const {
        grades,
        institutionGradeLevel,
        wasAdded,
        wasChanged,
        ...rest
      } = child;

      const deleteChild = {
        ...rest,
        status: "removed",
      }

      // @ts-ignore
      delete deleteChild.__typename

      try {
        const { data: { updateChildrenNewModel }} = await httpClient.updateChildrenNewModel({
          guardianId: userId!,
          change: [deleteChild]
        })
        if(updateChildrenNewModel.success){
          notifySuccess({ message: MESSAGES.removeChildSuccess })
          removeChild(childUId)
          _removeChild(childUId)
          getSubscriptions()
          handleGetLoginStatus()
          updateRevertBlock()
        }
        else{
          notifyError({ message: MESSAGES.removeChildError })
        }
      } catch(err) {
        notifyError({ message: MESSAGES.removeChildError })
      }

    }
  }

  const handleRemoveAddedChild = (childUId: string) => {
    removeChildrenAddingId(childUId)
    setChildren(children!.filter(c => c.childUid !== childUId))
  }

  const removeChildrenAddingId = (childUId: string) => {
    const newChildrenAddingIds = childrenAddingIds.filter(c => c !== childUId)
    setChildrenAddingIds(newChildrenAddingIds)
  }

  const removeChild = (childUid: string) => {
    setChildren(children!.filter(c => c.childUid !== childUid))
  }

  const changeManagedByStatus = async () => {
    try {
      const newManageBy = managedBy === 'sis' ? 'family' : 'sis'
      const {
        data: {
        changeManageStatus
       }
      } = await httpClient.manageDistrictAccount(userId, newManageBy)
      if (changeManageStatus.success) {
        setManagedBy(changeManageStatus.managedBy)

        return
      }

      console.log('Unable to change account management status: ')
      notifyError({ message: MESSAGES.changeManageByError })
    } catch (e) {
      console.log('Unable to change account management status: ', e)
      notifyError({ message: MESSAGES.changeManageByError })
    }
  }

  const sortChildrenByGradesAsc = (children: ChildNewModel[]): Array<ChildNewModel> =>
    children.sort((a, b) => {
      if(a.institutionName !== b.institutionName){
        // @ts-ignore
        return a.institutionName - b.institutionName
      }
      // @ts-ignore
      return a.gradeLevel - b.gradeLevel
    }
  )

  const getPendingSchools = (children: ChildNewModel[]): Array<PendingSchools> => {
    let pendingSchoolsList: Array<PendingSchools> = []
    const districts = sortBy(
      [...new Set(children.map(c => c.hierarchyName))]
    )
    districts.forEach(districtName => {
     const schools = sortBy(
        children.filter(c => c.hierarchyName === districtName ),
        c => c.institutionName
      )
      .map(({ institutionId, institutionName, gradeLevel}) => ({
        institutionName,
        gradeLevel,
        isNewSchool: !(_children!.find(c => c.institutionId === institutionId && c.status === 'active'))
      }))
      pendingSchoolsList.push({
        districtName,
        schools
      })
    })
    return pendingSchoolsList;
  }

  const getChildrenGroupedByCommunity = (children: ChildNewModel[] | null): Array<ChildGroupByCommunity> => {
    const linkedChildGroupByCommunity: Array<ChildGroupByCommunity> = []
    if (children) {
      let childrenGroupedByDistrict: Array<ChildrenGroupByDistrict> = []
      let childrenByDistrict: ChildrenGroupByDistrict
      const communites = sortBy(
        [...new Set(children.map(item => item.community))]
      )
      communites.forEach(community => {
        childrenGroupedByDistrict = [];
        const childrenList = sortBy(
          children.filter(l => l.community === community),
          c => c.institutionName
        )
        const districts = sortBy(
          [...new Set(childrenList.map(item => item.hierarchyName))]
        )
        districts.forEach(hierarchyName => {
          childrenByDistrict = {
            district: hierarchyName,
            childrenIds:
            sortChildrenByGradesAsc(
              childrenList.filter(c => c.hierarchyName === hierarchyName)
            )
            .map(c => c.childUid)
          }
          childrenGroupedByDistrict.push(childrenByDistrict)
        })

        linkedChildGroupByCommunity.push({
          community,
          districts: childrenGroupedByDistrict
        })
      })
    }
    return linkedChildGroupByCommunity;
  }

  const handleDismissPendingNotification = async (guardianId: number) => {
    const { success } = await httpClient.dismissPendingNotification(guardianId)
    if (success) {
      setPendingSchools([])
    }
  }

  const accountManagementEnabled = managedBy === 'sis' ? false : true

  const mergedProps = {
    userId,
    email,
    managedBy,
    childrenGrouped,
    pendingSchools,
    childrenList: children,
    childrenAddingIds,
    dismissible,
    accountManagementEnabled,
    notifStatus,
    revertBlocked,
    updateRevertBlock,
    changeManagedByStatus,
    lookUpSchools,
    handleSubmit,
    handleSchoolChange,
    handleGradeChange,
    handleRemoveChild,
    handleRemoveAddedChild,
    handleAddChild,
    handleClosePage,
    handleCancel,
    handleDismissPendingNotification
  }

  if (loadingProfile) {
    return <LoadingSpinner />
  }

  if(hierarchyType !== 'family' && hierarchyType !== 'parent'){
    return <ManageNotifications />
  }

  return (
    <Parent {...mergedProps} />
  )
}


export default connect((state: RootState) => ({
  loginStatus: state.logIn.loginStatus,
}),
(dispatch: Dispatch<ActionResults>) => ({
  handleGetLoginStatus: () => dispatch<any>(getLoginStatus()),
})
)(ParentContainer)
