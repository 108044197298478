import {
  LOG_IN_ATTEMPT_FAILED,
  LOG_IN_ATTEMPT_SUBMITTED,
  LOG_IN_ATTEMPT_SUCCESSFUL,
  LOG_IN_EMAIL_SET,
  LOG_IN_USER_TYPE_LOOKUP_FAILED,
  LOG_IN_USER_TYPE_RECEIVED,
  LOG_IN_USER_TYPE_REQUESTED,
  LOG_IN_EMAIL_STRUCTURE_FAILED,
  LOG_IN_EMAIL_STRUCTURE_SUCCESSFUL,
  LOG_IN_CLEAR_ERRORS,
  MAGIC_LINK_REQUEST_SUCCESSFUL,
  MAGIC_LINK_RESET,
  MAGIC_LINK_REQUEST_FAILED,
  MAGIC_LINK_REQUESTED,
  SET_LOGIN_STATUS,
  LogInActionResults,
} from './LogInActions'
import { UserRole } from '../../App/Dependencies/PortalApiClient'

export type LogInState = {
  email: string
  loginAttemptInProgress: boolean
  userTypeLookupFailed: boolean
  userType?: UserRole
  successfullyAuthenticated: boolean
  userEmailStructureError: boolean
  magicLinkRequested: boolean
  magicLinkRequestSuccessful: boolean
  magicLinkRequestFailed: boolean
  loginStatus: {
    loggedIn: boolean
    redirectTo: string
    email?: string
    accountType?: string
  }
}

const initialState: LogInState = {
  email: '',
  loginAttemptInProgress: false,
  userTypeLookupFailed: false,
  userType: undefined,
  successfullyAuthenticated: false,
  userEmailStructureError: false,
  magicLinkRequested: false,
  magicLinkRequestSuccessful: false,
  magicLinkRequestFailed: false,
  loginStatus: {
    loggedIn: false,
    redirectTo: '',
    email: undefined,
    accountType: undefined
  }
}

export function logIn(
  state: LogInState = initialState,
  action: LogInActionResults
) {
  switch (action.type) {
    case LOG_IN_EMAIL_SET:
      return {
        ...state,
        email: action.email,
      }

    case LOG_IN_USER_TYPE_REQUESTED:
      return {
        ...state,
        userTypeLookupFailed: false,
      }

    case LOG_IN_CLEAR_ERRORS:
      return {
        ...state,
        magicLinkRequestFailed: false,
        userEmailStructureError: false,
      }

    case MAGIC_LINK_REQUESTED:
      return {
        ...state,
        magicLinkRequested: true,
        magicLinkRequestFailed: false,
      }

    case MAGIC_LINK_REQUEST_SUCCESSFUL:
      return {
        ...state,
        magicLinkRequestSuccessful: true,
      }

    case MAGIC_LINK_RESET:
      return {
        ...state,
        magicLinkRequestSuccessful: false,
      }

    case MAGIC_LINK_REQUEST_FAILED:
      return {
        ...state,
        magicLinkRequestFailed: true,
      }

    case LOG_IN_USER_TYPE_RECEIVED:
      return {
        ...state,
        userType: action.role,
      }

    case LOG_IN_USER_TYPE_LOOKUP_FAILED:
      return {
        ...state,
        userTypeLookupFailed: true,
      }

    case LOG_IN_EMAIL_STRUCTURE_FAILED:
      return {
        ...state,
        userEmailStructureError: true,
      }

    case LOG_IN_EMAIL_STRUCTURE_SUCCESSFUL:
      return {
        ...state,
        userEmailStructureError: false,
      }

    case LOG_IN_ATTEMPT_SUBMITTED:
      return {
        ...state,
        loginAttemptInProgress: true,
        successfullyAuthenticated: false,
      }

    case LOG_IN_ATTEMPT_SUCCESSFUL:
      return {
        ...state,
        loginAttemptInProgress: false,
        successfullyAuthenticated: true,
      }

    case LOG_IN_ATTEMPT_FAILED:
      return {
        ...state,
        loginAttemptInProgress: false,
        successfullyAuthenticated: false,
      }

    case SET_LOGIN_STATUS:
      return {
        ...state,
        loginStatus: {
          loggedIn: action.loggedIn,
          redirectTo: action.redirectTo,
          email: action.email,
          accountType: action.accountType
        }
      }
  }

  return state
}
