import React from 'react'
import { css } from 'emotion'

const Page = ({ children, ...rest }) => (
  <div className={styles.pageWrapper} {...rest}>
    {children}
  </div>
)

const styles = {
  pageWrapper: css`
    padding: 140px 0;
  `,
}

export default Page
