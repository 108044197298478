import { Dispatch, Action } from 'redux'
import { get, pullAll } from 'lodash'
import { ActionResults, RootState } from '../../App/Store/makeStore'
import Dependencies from '../../App/Dependencies/Dependencies'
import { UserRole } from '../../App/Dependencies/PortalApiClient'
import config from '../../config'

export const LOG_IN_EMAIL_SET = 'LOG_IN_EMAIL_SET'
export const LOG_IN_USER_TYPE_REQUESTED = 'LOG_IN_USER_TYPE_REQUESTED '
export const LOG_IN_USER_TYPE_RECEIVED = 'LOG_IN_USER_TYPE_RECEIVED '
export const LOG_IN_USER_TYPE_LOOKUP_FAILED = 'LOG_IN_USER_TYPE_LOOKUP_FAILED '
export const LOG_IN_ATTEMPT_SUBMITTED = 'LOG_IN_ATTEMPT_SUBMITTED'
export const LOG_IN_ATTEMPT_SUCCESSFUL = 'LOG_IN_ATTEMPT_SUCCESSFUL'
export const LOG_IN_ATTEMPT_FAILED = 'LOG_IN_ATTEMPT_FAILED'
export const LOG_IN_EMAIL_STRUCTURE_FAILED = 'LOG_IN_EMAIL_STRUCTURE_FAILED'
export const LOG_IN_EMAIL_STRUCTURE_SUCCESSFUL =
  'LOG_IN_EMAIL_STRUCTURE_SUCCESSFUL'
export const LOG_IN_CLEAR_ERRORS = 'LOG_IN_CLEAR_ERRORS'
export const MAGIC_LINK_REQUESTED = 'MAGIC_LINK_REQUESTED'
export const MAGIC_LINK_RESET = 'MAGIC_LINK_RESET'
export const MAGIC_LINK_REQUEST_SUCCESSFUL = 'MAGIC_LINK_REQUEST_SUCCESSFUL'
export const MAGIC_LINK_REQUEST_FAILED = 'MAGIC_LINK_REQUEST_FAILED'
export const SET_LOGIN_STATUS = 'LOGIN_STATUS'

// ---------------------------------------------------------------------------------------------------

type SetEmailActionResult = {
  type: typeof LOG_IN_EMAIL_SET
  email: string
}

type SetEmailStructureFailed = {
  type: typeof LOG_IN_EMAIL_STRUCTURE_FAILED
}

type ResetMagicLinkState = {
  type: typeof MAGIC_LINK_RESET
}

type SetEmailStructureSuccessful = {
  type: typeof LOG_IN_EMAIL_STRUCTURE_SUCCESSFUL
}

type ClearLoginErrors = {
  type: typeof LOG_IN_CLEAR_ERRORS
}

type SetLoginStatusResult = {
  type: typeof SET_LOGIN_STATUS
  loggedIn: boolean
  redirectTo: string
  email?: string
  accountType?: string
}

export function setEmail(email: string): SetEmailActionResult {
  return {
    type: LOG_IN_EMAIL_SET,
    email,
  }
}

export function setEmailStructureFailed(): SetEmailStructureFailed {
  return {
    type: LOG_IN_EMAIL_STRUCTURE_FAILED,
  }
}

export function setEmailStructureSuccessful(): SetEmailStructureSuccessful {
  return {
    type: LOG_IN_EMAIL_STRUCTURE_SUCCESSFUL,
  }
}

export function clearLoginErrors(): ClearLoginErrors {
  return {
    type: LOG_IN_CLEAR_ERRORS,
  }
}

export function setLoginStatus(
  loggedIn: boolean,
  redirectTo: string): SetLoginStatusResult {
  return {
    type: SET_LOGIN_STATUS,
    loggedIn,
    redirectTo
  }
}

// ---------------------------------------------------------------------------------------------------

type UserTypeRequested = {
  type: typeof LOG_IN_USER_TYPE_REQUESTED
}

type UserTypeReceived = {
  type: typeof LOG_IN_USER_TYPE_RECEIVED
  role: UserRole
  isCurrentlyOnboarding: boolean
}

type UserTypeLookupFailed = {
  type: typeof LOG_IN_USER_TYPE_LOOKUP_FAILED
}

type MagicLinkRequestFailed = {
  type: typeof MAGIC_LINK_REQUEST_FAILED
}

type MagicLinkRequested = {
  type: typeof MAGIC_LINK_REQUESTED
}

type MagicLinkRequestSuccessful = {
  type: typeof MAGIC_LINK_REQUEST_SUCCESSFUL
}

type UserEmail = string | null
type UserDestination = string | null

export function submitEmail(
  userEmail?: UserEmail,
  userDestination?: UserDestination
) {
  return async (
    dispatch: Dispatch<ActionResults>,
    getState: () => RootState,
    { accountsBff }: Dependencies
  ) => {

    dispatch({
      type: MAGIC_LINK_REQUESTED,
    })

    try {

      let email = userEmail
        ? decodeURIComponent(userEmail)
        : getState().logIn.email

      const userTypeRes = await accountsBff.verifyEmail(email)
      const userTypeSuccessful = get(
        userTypeRes,
        'data.verifyEmail.success',
        false
      )

      if (userTypeSuccessful) {
        const familyScopes = ['guardian', 'parent', 'child']
        const userScopes = userTypeRes.data.verifyEmail.scopes
        const nonParentScopes = pullAll(userScopes, familyScopes)
        if (nonParentScopes.length === 0) {
          userDestination = config.REACT_APP_PARENT_HOMEPAGE_URL
        }

        if ('email' in userTypeRes.data.verifyEmail) {
          email = userTypeRes.data.verifyEmail.email
        }

      }

      const destination = userDestination
        ? decodeURIComponent(userDestination)
        : config.REDIRECT_AFTER_LOGIN_URL

      const res = await accountsBff.attemptMagicLinkRequest(email, destination)

      const magicLinkSuccessful = get(
        res,
        'data.requestMagicLink.success',
        false
      )

      if (magicLinkSuccessful) {
        dispatch({
          type: MAGIC_LINK_REQUEST_SUCCESSFUL,
        })
        return { success: true }
      }

      dispatch({
        type: MAGIC_LINK_REQUEST_FAILED,
      })
      return { success: false }
    } catch (err) {
      dispatch({
        type: MAGIC_LINK_REQUEST_FAILED,
      })
      return { success: false }
    }
  }
}

export function resetMagicLinkState() {
  return {
    type: MAGIC_LINK_RESET,
  }
}

export function getLoginStatus() {
  return async (
    dispatch: Dispatch<ActionResults>,
    getState: () => RootState,
    { accountsBff }: Dependencies
  ) => {
    try {
      const {
        loggedIn,
        redirectTo,
        email,
        accountType
      } = await accountsBff.getLoginStatus()

      dispatch({
       type: SET_LOGIN_STATUS,
       loggedIn,
       redirectTo,
       email,
       accountType
     })

    } catch (err) {
      return null
    }
  }
}

// ---------------------------------------------------------------------------------------------------

type LoginAttemptSubmitted = {
  type: typeof LOG_IN_ATTEMPT_SUBMITTED
}

type LoginAttemptSuccessful = {
  type: typeof LOG_IN_ATTEMPT_SUCCESSFUL
}

type LoginAttemptFailed = {
  type: typeof LOG_IN_ATTEMPT_FAILED
  message: string
}

// ---------------------------------------------------------------------------------------------------

export type LogInActionResults =
  | SetEmailActionResult
  | SetEmailStructureFailed
  | SetEmailStructureSuccessful
  | MagicLinkRequestSuccessful
  | ResetMagicLinkState
  | MagicLinkRequestFailed
  | MagicLinkRequested
  | ClearLoginErrors
  | UserTypeRequested
  | UserTypeReceived
  | UserTypeLookupFailed
  | LoginAttemptSubmitted
  | LoginAttemptSuccessful
  | LoginAttemptFailed
  | SetLoginStatusResult
