import gql from 'graphql-tag'

export const REQUEST_MAGIC_LINK_MUTATION = gql`
  mutation attemptMagicLinkRequest($input: MagicLinkRequestInput!) {
    requestMagicLink(input: $input) {
      success
    }
  }
`

export const UPDATE_CHILDREN_ENROLLMENTS = gql`
  mutation updateChildrenEnrollments($input: UpdateChildrenInput!) {
    updateChildren(input: $input) {
      familyId
      subscriptions {
        id
        userId
        type
        audienceType
        audienceId
        audienceName
        hierarchyId
        hierarchyType
        hierarchyName
        managingContext
        config {
          ... on FlyerEmailSubscriptionConfig {
            email
            textOnly
            userJoinDate
          }
        }
        filters
        labels
        status
        statusChangeReason
        createdAt
        createdBy
        updatedAt
        updatedBy
        lastActivatedAt
      }
      children {
        childId
        familyId
        enrollments {
          id
          institutionId
          institutionType
          institutionName
          institutionGradeLevels {
            lowGrade
            highGrade
          }
          hierarchyId
          hierarchyType
          guardianId
          gradeLevel
          schoolYear
          active
          studentId
          createdAt
          createdBy
          updatedAt
          updatedBy
        }
      }
    }
  }
`

export const CHANGE_PARENT_SUBSCRIPTIONS = gql`
  mutation changeParentSubscriptions($input: SubscriptionChangesInput!) {
    changeSubscriptions(input: $input) {
      id
    }
  }
`

export const MANAGE_DISTRICT_ACCOUNT = gql`
  mutation manageDistrictAccount($input: ChangeManageStatusInput!) {
    changeManageStatus(input: $input) {
      guardianId
      managedBy
    }
  }
`

export const GET_MY_PROFILE = gql`
  query getMyProfileNewModel {
    profileNewModel {
      id
      managedBy
      userId
      email
      firstName
      lastName
      scopes
      revertBlocked
      hierarchy {
        id
        type
      }
      linkedAccounts {
        userId
        email
        hierarchy {
          id
          type
          name
        }
        scopes
      }
      children {
        institutionType
        institutionId
        institutionName
        hierarchyType
        hierarchyId
        hierarchyName
        gradeLevel
        schoolYear
        schoolType
        childUid
        status
        community
        postalCode
        institutionGradeLevel
      }
      academicGroupMemberships {
        groupName
        groupId
        hierarchyName
        hierarchyId
      }
    }
  }
`

export const COMPLETE_MY_PROFILE = gql`
  mutation completeMyProfile($input: UpdateProfileInput!) {
    updateProfile(input: $input) {
      email
      firstName
      lastName
    }
  }
`

export const VERIFY_EMAIL = gql`
  query verifyEmail($input: VerifyEmailInput!) {
    verifyEmail(input: $input) {
      scopes
      email
    }
  }
`

export const SEARCH_FOR_MY_SCHOOL = gql`
  query searchForMySchool($searchTerms: String!) {
    searchAcademicGroups(searchTerms: $searchTerms) {
      schools {
        schoolId
        schoolName
        districtId
        districtName
        gradeLevels
        city
        postalCode
      }
    }
  }
`

export const SEARCH_FOR_SCHOOLS_AND_DISTRICTS = gql`
  query searchSchoolsAndDistricts($searchTerms: String!) {
    searchAcademicGroups(searchTerms: $searchTerms) {
      schools {
        schoolId
        schoolName
        districtId
        districtName
        gradeLevels
        city
        state
      }
      districts {
        districtName
        districtId
        city
        state
      }
    }
  }
`

export const SUBMIT_PARENT_REGISTRATION = gql`
  mutation submitParentRegistration($input: FamilyNewModelRegistationInput) {
    registerFamilyNewModel(input: $input) {
      guardianId
      managedBy
    }
  }
`

export const REGISTER_ORGANIZATION = gql`
  mutation registerNewOrg($input: RegisterOrganizationInput) {
    registerOrganization(input: $input) {
      id
      email
    }
  }
`

export const SUBMIT_ACADEMIC_LEAD = gql`
  mutation submitAcademicLead($input: AcademicLeadInput) {
    createAcademicLead(input: $input) {
      success
    }
  }
`

export const SUBMIT_ACADEMIC_MEMBERSHIP_REQUEST = gql`
  mutation submitAcademicMembershipRequest(
    $input: AcademicMembershipRequestInput
  ) {
    createAcademicMembershipRequest(input: $input) {
      success
    }
  }
`

export const UPDATE_PROFILE = gql`
  mutation updateProfile($input: UpdateProfileInput) {
    updateProfile(input: $input) {
      firstName
      lastName
      email
    }
  }
`

export const VERIFY_CHANGE_EMAIL = gql`
  mutation verifyChangeEmail($input: VerifyChangeEmailInput) {
    verifyChangeEmail(input: $input) {
      email
      status
    }
  }
`

export const LOGIN_STATUS = gql`
  query {
    loginStatus {
      loggedIn
      redirectTo
      email
      accountType
    }
  }
`
export const CHANGE_NOTIFICATION_PREFERENCES = gql`
  mutation changeNotificationPreferences($input: NotifInput){
    changeNotificationPreferences(input: $input) {
        notifType
        notifTypeId
        notifStatus
        refId
        updatedAt
        updatedBy
    }
  }
`

export const UPDATE_CHILDREN_NEW_MODEL = gql`
  mutation updateChildrenNewModel($input: UpdateChildrenNewModelInput!){
    updateChildrenNewModel(input: $input) {
      guardianId
      managedBy
      children {
        institutionType
        institutionId
        institutionName
        hierarchyType
        hierarchyId
        hierarchyName
        gradeLevel
        schoolYear
        schoolType
        childUid
        community
        postalCode
        status
      }
    }
  }
`

export const GET_NOTIFICATION_PREFERENCES = gql`
  query getNotificationPreferences($input: GetNotifInput!) {
    getNotificationPreferences(input: $input) {
      notifType
      notifTypeId
      notifStatus
      refId
    }
  }
`

export const GET_LINKED_ACCOUNTS = gql`
  query linkedAccounts {
    linkedAccounts {
      userId
      email
      hierarchy{
        id
        type
        name
      }
      scopes
    }
}
`
export const DISMISS_PENDING_NOTIFICATION = gql`
  mutation dismissPendingNotification($guardianId: Int!){
    dismissPendingNotification(guardianId: $guardianId) {
      guardianId
    }
  }
`
