import PortalApiClient, {
  LoginAttemptResponse,
  UserTypeResponse,
} from './PortalApiClient'

export default class PortalApiClientMock implements PortalApiClient {
  async getUserType(email: string): Promise<UserTypeResponse> {
    if (email.startsWith('error@')) {
      throw new Error('Error looking up account.')
    }

    if (email.startsWith('onboarding@')) {
      return {
        role: 'school-staff',
        isCurrentlyOnboarding: true,
      }
    }

    if (email.startsWith('school@')) {
      return {
        role: 'school-staff',
        isCurrentlyOnboarding: false,
      }
    }

    if (email.startsWith('eco@') || email.startsWith('org@')) {
      return {
        role: 'eco',
        isCurrentlyOnboarding: false,
      }
    }

    return {
      role: 'parent',
      isCurrentlyOnboarding: false,
    }
  }
}
