import React from 'react'

const DeskWoman = (props: any) => (
    <svg
        version='1.1'
        id='Layer_1'
        xmlns='http://www.w3.org/2000/svg'
        xmlnsXlink='http://www.w3.org/1999/xlink'
        x='0px'
        y='0px'
        viewBox='0 0 587 574'
        xmlSpace='preserve'
        width={587}
        height={574}
        style={{ enableBackground: 'new 0 0 587 574', width: '100%' }}
        {...props}
    >
        <style
            type='text/css'
            dangerouslySetInnerHTML={{
                __html:
                    '\n\t.st0{fill:#C6C9D5;}\n\t.st1{fill:#EDEEF3;}\n\t.st2{fill:#C6C9D5;stroke:#CDD1D8;stroke-width:8;stroke-linecap:round;stroke-linejoin:round;}\n\t.st3{fill:#C6C9D5;stroke:#CDD1D8;stroke-width:4;stroke-linecap:round;stroke-linejoin:round;}\n\t.st4{fill:#C6C9D5;stroke:#CDD1D8;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;}\n\t.st5{fill:none;stroke:#C6C9D5;}\n\t.st6{fill:#C6C9D5;stroke:#C6C9D5;}\n\t.st7{filter:url(#Adobe_OpacityMaskFilter);}\n\t.st8{fill:#FFFFFF;}\n\t.st9{mask:url(#mask-4_1_);fill:#DFE1E7;}\n\t.st10{fill:#DFE1E7;}\n\t.st11{filter:url(#Adobe_OpacityMaskFilter_1_);}\n\t.st12{mask:url(#mask-6_1_);fill:#C6C9D5;}\n\t.st13{filter:url(#Adobe_OpacityMaskFilter_2_);}\n\t.st14{mask:url(#mask-8_2_);fill:#C6C9D5;}\n\t.st15{filter:url(#Adobe_OpacityMaskFilter_3_);}\n\t.st16{mask:url(#mask-8_1_);fill:#DFE1E7;}\n\t.st17{fill:#F0F2F6;}\n\t.st18{fill:#A5A5A5;}\n\t.st19{fill:#F4F4F4;}\n\t.st20{fill:#FCC35D;}\n\t.st21{fill:#FAA918;}\n\t.st22{fill:#2D344B;}\n\t.st23{fill:#47506B;}\n\t.st24{fill:#FFD561;}\n\t.st25{opacity:0.25;enable-background:new    ;}\n\t.st26{fill:#F7F7F7;}\n\t.st27{opacity:8.000000e-02;fill:#603813;enable-background:new    ;}\n\t.st28{opacity:0.41;fill:#FAA918;enable-background:new    ;}\n\t.st29{opacity:7.000000e-02;enable-background:new    ;}\n\t.st30{fill:#9EA3B7;}\n\t.st31{opacity:7.000000e-02;fill:#FFFFFF;enable-background:new    ;}\n\t.st32{opacity:0.82;fill:#F0F2F6;enable-background:new    ;}\n\t.st33{opacity:0.44;fill:#FFFFFF;enable-background:new    ;}\n\t.st34{opacity:0.53;fill:#2D344B;enable-background:new    ;}\n\t.st35{filter:url(#Adobe_OpacityMaskFilter_4_);}\n\t.st36{mask:url(#mask-4_2_);fill:#DFE1E7;}\n',
            }}
        />
        <title>Group 2</title>
        <desc>Created with Sketch.</desc>
        <g id='School-Onboarding'>
            <g
                id='Someone-signed'
                transform='translate(-778.000000, -135.000000)'
            >
                <g id='Group-2' transform='translate(778.000000, 135.000000)'>
                    <g id='clock' transform='translate(73.000000, 0.000000)'>
                        <circle
                            id='Oval-17'
                            className='st0'
                            cx='54.5'
                            cy='54.5'
                            r='54.5'
                        />
                        <circle
                            id='Oval-17-Copy'
                            className='st1'
                            cx={54}
                            cy={54}
                            r={48}
                        />
                        <path
                            id='Line-6'
                            className='st2'
                            d='M35.3,40.3l29.4,24.4'
                        />
                        <path
                            id='Line-6-Copy'
                            className='st3'
                            d='M85,28L43,66'
                        />
                        <g
                            id='Group-4'
                            transform='translate(54.000000, 11.000000)'
                        >
                            <path id='Line-7' className='st4' d='M1,0.3v12.8' />
                            <path
                                id='Line-7-Copy'
                                className='st4'
                                d='M1,75v12.8'
                            />
                        </g>
                        <g
                            id='Group-4_1_'
                            transform='translate(55.000000, 55.000000) rotate(32.000000) translate(-55.000000, -55.000000) translate(54.000000, 11.000000)'
                        >
                            <path
                                id='Line-7_1_'
                                className='st4'
                                d='M1,0.3l0,12.8'
                            />
                            <path
                                id='Line-7-Copy_1_'
                                className='st4'
                                d='M1,75l0,12.8'
                            />
                        </g>
                        <g
                            id='Group-4_2_'
                            transform='translate(55.000000, 55.000000) rotate(61.000000) translate(-55.000000, -55.000000) translate(54.000000, 11.000000)'
                        >
                            <path
                                id='Line-7_2_'
                                className='st4'
                                d='M1,0.3l0,12.8'
                            />
                            <path
                                id='Line-7-Copy_2_'
                                className='st4'
                                d='M1,75l0,12.8'
                            />
                        </g>
                        <g
                            id='Group-4_3_'
                            transform='translate(55.000000, 55.000000) rotate(91.000000) translate(-55.000000, -55.000000) translate(54.000000, 11.000000)'
                        >
                            <path
                                id='Line-7_3_'
                                className='st4'
                                d='M1,0.3l0,12.8'
                            />
                            <path
                                id='Line-7-Copy_3_'
                                className='st4'
                                d='M1,75l0,12.8'
                            />
                        </g>
                        <g
                            id='Group-4_4_'
                            transform='translate(55.000000, 55.000000) rotate(117.000000) translate(-55.000000, -55.000000) translate(54.000000, 11.000000)'
                        >
                            <path
                                id='Line-7_4_'
                                className='st4'
                                d='M1,0.3l0,12.8'
                            />
                            <path
                                id='Line-7-Copy_4_'
                                className='st4'
                                d='M1,75l0,12.8'
                            />
                        </g>
                        <g
                            id='Group-4_5_'
                            transform='translate(55.000000, 55.000000) rotate(147.000000) translate(-55.000000, -55.000000) translate(54.000000, 11.000000)'
                        >
                            <path
                                id='Line-7_5_'
                                className='st4'
                                d='M1,0.3l0,12.8'
                            />
                            <path
                                id='Line-7-Copy_5_'
                                className='st4'
                                d='M1,75l0,12.8'
                            />
                        </g>
                    </g>
                    <g id='plant' transform='translate(0.000000, 250.000000)'>
                        <path
                            id='Fill-54'
                            className='st0'
                            d='M87.1,41c0.2,0.1,0.3,0.2,0.5,0.3c0.1,0.1,0.3,0.2,0.4,0.3c0.3,0.2,0.5,0.4,0.8,0.6
                      c0.5,0.4,1,0.8,1.4,1.2c0.9,0.8,1.8,1.7,2.6,2.6c1.6,1.8,2.9,3.8,4,5.9c0.3,0.5,0.5,1.1,0.7,1.6c0.2,0.5,0.5,1.1,0.6,1.6
                      l0.3,0.8l0.1,0.4c0,0.1,0.1,0.3,0.1,0.4l0.4,1.7l0.1,0.4l0.1,0.4l0.1,0.9l0.1,0.9c0.1,0.3,0.1,0.6,0.1,0.9l0.1,1.7
                      c0,0.3,0,0.6,0,0.8v0.8l0.2,25.2l0.5,50.5l0.3,50.5L101,242h-9l0.2-50.5l0.3-50.5l0.5-50.5l0.2-25.2v-0.8c0-0.3,0-0.5,0-0.7
                      l0-1.4c0-0.2,0-0.5-0.1-0.7l-0.1-0.7L93,60.1l0-0.4l-0.1-0.3c-0.4-1.9-0.9-3.7-1.7-5.5l-0.6-1.3c-0.2-0.4-0.5-0.9-0.7-1.3
                      c-0.2-0.4-0.5-0.8-0.8-1.3c-0.3-0.4-0.6-0.8-0.9-1.2c-0.6-0.8-1.3-1.6-2-2.4c-0.4-0.4-0.7-0.7-1.1-1.1c-0.2-0.2-0.4-0.3-0.6-0.5
                      c-0.1-0.1-0.2-0.2-0.3-0.2L84,44.4L87.1,41z'
                        />
                        <path
                            id='Fill-55'
                            className='st0'
                            d='M139,230c-6.2,15.1-9.8,26.1-11,33c-0.9,5.5-0.4,13.2,1.5,23.1c-0.6,3.4-4.1,5.9-8.2,5.9H70.7
                      c-4.1,0-7.6-2.5-8.2-5.9c2-10.8,2.5-19.2,1.5-25.1c-1.1-6.6-4.8-16.9-11-31H139z'
                        />
                        <path
                            id='Stroke-56'
                            className='st5'
                            d='M121.8,91L96,116.3 M71,112l25.8,25.3 M121,33L95.2,58.3'
                        />
                        <path
                            id='Path'
                            className='st6'
                            d='M114.5,160.5l-17,11'
                        />
                        <g
                            id='Fill-60'
                            transform='translate(105.000000, 9.000000)'
                        >
                            <g id='Mask_1_'>
                                <path
                                    id='path-3_1_'
                                    className='st0'
                                    d='M12.1,11.7c0.9-0.8,5.2-5.7,8.2-6.8c4.6-1.6,5.6-4.2,10.3-4.8c1.6-0.2,3.2-0.3,4.6,0.8
                              c2.3,2,2.7,7.1-0.2,11.5c-1.8,2.8-0.9,8.7-2.8,11.4c-3.3,4.4-8.8,4.2-12.1,8.4c-3.4,4.2-1.8,9.7-9.3,8c-3.7-0.8-4,1.3-7,0.4
                              c-2.2-0.6-0.8-4.6-3.6-9.9c-0.7-1.3,2.1-4.2,2.4-5.7c1.3-5,4.1-2.7,5.9-8C10.3,11.9,11,12.6,12.1,11.7'
                                />
                            </g>
                            <defs>
                                <filter
                                    id='Adobe_OpacityMaskFilter'
                                    filterUnits='userSpaceOnUse'
                                    x={0}
                                    y={-5}
                                    width='34.9'
                                    height='41.2'
                                >
                                    <feColorMatrix
                                        type='matrix'
                                        values='1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 1 0'
                                    />
                                </filter>
                            </defs>
                            <mask
                                maskUnits='userSpaceOnUse'
                                x={0}
                                y={-5}
                                width='34.9'
                                height='41.2'
                                id='mask-4_1_'
                            >
                                <g className='st7'>
                                    <path
                                        id='path-3_2_'
                                        className='st8'
                                        d='M12.1,11.7c0.9-0.8,5.2-5.7,8.2-6.8c4.6-1.6,5.6-4.2,10.3-4.8c1.6-0.2,3.2-0.3,4.6,0.8
                                  c2.3,2,2.7,7.1-0.2,11.5c-1.8,2.8-0.9,8.7-2.8,11.4c-3.3,4.4-8.8,4.2-12.1,8.4c-3.4,4.2-1.8,9.7-9.3,8c-3.7-0.8-4,1.3-7,0.4
                                  c-2.2-0.6-0.8-4.6-3.6-9.9c-0.7-1.3,2.1-4.2,2.4-5.7c1.3-5,4.1-2.7,5.9-8C10.3,11.9,11,12.6,12.1,11.7'
                                    />
                                </g>
                            </mask>
                            <path
                                className='st9'
                                d='M0.1,30.8C-0.1,29.2,0,27.5,0.3,26c1.3-5.1,3.8-2.9,5.6-8.3s1.4-8.1,4.8-11.6c3.4-3.4,6-2.8,9-3.9
                          C24.2,0.7,25.3-4.4,30-5c1.5-0.2,3.6,4.2,4.9,5.4c0,0-5.5,2.5-8.2,6.6c-2.7,4.1-9.2,11-12.8,14.1C10.4,24.2,1.8,33.8,1.8,36.2
                          C0.5,32.4,0.1,30.8,0.1,30.8'
                            />
                        </g>
                        <g
                            id='Fill-61'
                            transform='translate(33.000000, 0.000000)'
                        >
                            <g id='Mask_2_'>
                                <path
                                    id='path-5_1_'
                                    className='st10'
                                    d='M38.7,6.6c-1.4-0.9-6.5-4.7-11.1-5.9c-7-1.9-10.3,0.9-17.4,0.2c-2.4-0.2-4.9-0.4-7,1
                              C-0.3,4.2-0.8,10.3,1,14.6c1.7,4.3,5,7.4,8,10.6c5,5.4,9.3,11.7,14.4,16.8c5.2,5.2,11.5,9.3,18.2,9.3c6.7,0,12.7-5,14-12.8
                              c0.3-1.9,1.4-4.2,0.8-6c-1.9-6.1-5.5-4.9-8.2-11.3C45.5,14.7,40.4,7.7,38.7,6.6'
                                />
                            </g>
                            <defs>
                                <filter
                                    id='Adobe_OpacityMaskFilter_1_'
                                    filterUnits='userSpaceOnUse'
                                    x={5}
                                    y={-1}
                                    width={52}
                                    height={45}
                                >
                                    <feColorMatrix
                                        type='matrix'
                                        values='1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 1 0'
                                    />
                                </filter>
                            </defs>
                            <mask
                                maskUnits='userSpaceOnUse'
                                x={5}
                                y={-1}
                                width={52}
                                height={45}
                                id='mask-6_1_'
                            >
                                <g className='st11'>
                                    <path
                                        id='path-5_2_'
                                        className='st8'
                                        d='M38.7,6.6c-1.4-0.9-6.5-4.7-11.1-5.9c-7-1.9-10.3,0.9-17.4,0.2c-2.4-0.2-4.9-0.4-7,1
                                  C-0.3,4.2-0.8,10.3,1,14.6c1.7,4.3,5,7.4,8,10.6c5,5.4,9.3,11.7,14.4,16.8c5.2,5.2,11.5,9.3,18.2,9.3c6.7,0,12.7-5,14-12.8
                                  c0.3-1.9,1.4-4.2,0.8-6c-1.9-6.1-5.5-4.9-8.2-11.3C45.5,14.7,40.4,7.7,38.7,6.6'
                                    />
                                </g>
                            </mask>
                            <path
                                className='st12'
                                d='M56.8,37.5c0.4-1.9,0.3-4-0.3-5.8c-1.9-6.1-5.3-4.9-8-11.4C45.9,13.8,41,6.8,39.3,5.6
                          c-1.3-0.9-6.3-4.7-10.7-6c-6.8-1.9-9.9,0.9-16.9,0.2C9.5-0.4,7-0.6,5,0.9c0,0,8.2,3,12.2,7.9c4,5,13.7,13.3,19,17
                          c5.3,3.7,18,15.3,18,18.2C56.3,39.4,56.8,37.5,56.8,37.5'
                            />
                        </g>
                        <g
                            id='Fill-63_1_'
                            transform='translate(0.000000, 75.000000)'
                        >
                            <g id='Mask_4_'>
                                <path
                                    id='path-7_4_'
                                    className='st10'
                                    d='M48.4,14.8c-2.1-1-4.1-6-11.1-7.3c-10.6-2-12.2-6.6-23-7.4C10.7-0.1,6.9-0.3,3.8,1.2
                              c-5.2,2.5-5.2,8.6,1.4,14.5c4.1,3.7,2.2,10.4,6.6,13.7c7.5,5.7,16,2.8,23.8,8.2c7.8,5.4,16,15.3,26.1,15.3S80.9,47.8,83,39.6
                              c0.5-2-3.3-4.5-4.1-6.4c-2.9-6.3-8.6-6.6-12.7-13.3C61.9,13.2,51,16,48.4,14.8L48.4,14.8z'
                                />
                            </g>
                            <defs>
                                <filter
                                    id='Adobe_OpacityMaskFilter_2_'
                                    filterUnits='userSpaceOnUse'
                                    x={-1}
                                    y={0}
                                    width={85}
                                    height={46}
                                >
                                    <feColorMatrix
                                        type='matrix'
                                        values='1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 1 0'
                                    />
                                </filter>
                            </defs>
                            <mask
                                maskUnits='userSpaceOnUse'
                                x={-1}
                                y={0}
                                width={85}
                                height={46}
                                id='mask-8_2_'
                            >
                                <g className='st13'>
                                    <path
                                        id='path-7_3_'
                                        className='st8'
                                        d='M48.4,14.8c-2.1-1-4.1-6-11.1-7.3c-10.6-2-12.2-6.6-23-7.4C10.7-0.1,6.9-0.3,3.8,1.2
                                  c-5.2,2.5-5.2,8.6,1.4,14.5c4.1,3.7,2.2,10.4,6.6,13.7c7.5,5.7,16,2.8,23.8,8.2c7.8,5.4,16,15.3,26.1,15.3S80.9,47.8,83,39.6
                                  c0.5-2-3.3-4.5-4.1-6.4c-2.9-6.3-8.6-6.6-12.7-13.3C61.9,13.2,51,16,48.4,14.8L48.4,14.8z'
                                    />
                                </g>
                            </mask>
                            <path
                                className='st14'
                                d='M83.6,39.3c0.6-2,0.5-4.1-0.4-6c-3.1-6.3-8.7-5.1-13-11.8c-4.3-6.7-13.1-6.3-15.8-7.5
                          C52.2,13,47.2,4.3,39.9,3C28.8,1,17.9-0.6,10,0.2C6.3,0.7,2.3-0.2-1,1.3c0,0,13.4,3.1,19.9,8.2c6.6,5.1,22.4,13.8,31.1,17.6
                          S79.5,43,79.5,46C82.8,41.3,83.6,39.3,83.6,39.3'
                            />
                        </g>
                        <g
                            id='Fill-63'
                            transform='translate(0.000000, 75.000000)'
                        >
                            <g id='Mask_3_'>
                                <path
                                    id='path-7_1_'
                                    className='st0'
                                    d='M144.9-7.7c-2.6,1.2-13.6-1.7-17.7,5c-4.1,6.7-9.8,6.9-12.7,13.3
                              c-0.9,1.9-4.7,4.4-4.1,6.4c2.1,8.2,11,13.4,21.2,13.4s18.4-9.9,26.1-15.3c7.8-5.4,16.3-2.6,23.8-8.2
                              c4.5-3.4,2.5-10.1,6.6-13.7c6.6-6,6.6-12,1.4-14.5c-3.1-1.5-6.9-1.3-10.5-1.1c-10.8,0.7-12.4,5.4-23,7.4
                              C149-13.7,147-8.6,144.9-7.7L144.9-7.7z'
                                />
                            </g>
                            <defs>
                                <filter
                                    id='Adobe_OpacityMaskFilter_3_'
                                    filterUnits='userSpaceOnUse'
                                    x='109.3'
                                    y='-22.5'
                                    width={85}
                                    height={46}
                                >
                                    <feColorMatrix
                                        type='matrix'
                                        values='1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 1 0'
                                    />
                                </filter>
                            </defs>
                            <mask
                                maskUnits='userSpaceOnUse'
                                x='109.3'
                                y='-22.5'
                                width={85}
                                height={46}
                                id='mask-8_1_'
                            >
                                <g className='st15'>
                                    <path
                                        id='path-7_2_'
                                        className='st8'
                                        d='M144.9-7.7c-2.6,1.2-13.6-1.7-17.7,5c-4.1,6.7-9.8,6.9-12.7,13.3
                                  c-0.9,1.9-4.7,4.4-4.1,6.4c2.1,8.2,11,13.4,21.2,13.4s18.4-9.9,26.1-15.3c7.8-5.4,16.3-2.6,23.8-8.2
                                  c4.5-3.4,2.5-10.1,6.6-13.7c6.6-6,6.6-12,1.4-14.5c-3.1-1.5-6.9-1.3-10.5-1.1c-10.8,0.7-12.4,5.4-23,7.4
                                  C149-13.7,147-8.6,144.9-7.7L144.9-7.7z'
                                    />
                                </g>
                            </mask>
                            <path
                                className='st16'
                                d='M109.7,16.8c0,0,0.9,2,4.1,6.7c0-3,20.7-15,29.5-18.9C152,0.8,167.8-7.8,174.4-13
                          c6.5-5.2,19.9-8.2,19.9-8.2c-3.3-1.5-7.3-0.6-11-1.1c-7.8-0.9-18.8,0.8-29.9,2.8c-7.3,1.3-12.3,10-14.5,10.9
                          c-2.7,1.2-11.5,0.8-15.8,7.5c-4.3,6.7-10,5.5-13,11.8C109.2,12.7,109.1,14.8,109.7,16.8'
                            />
                        </g>
                        <path
                            id='Fill-64'
                            className='st10'
                            d='M64,223h64c6.1,0,11,3.6,11,8H53C53,226.6,57.9,223,64,223'
                        />
                    </g>
                    <g
                        id='Group-7'
                        transform='translate(553.000000, 445.000000)'
                    >
                        <rect
                            id='Rectangle-32'
                            y={0}
                            className='st0'
                            width={34}
                            height={57}
                        />
                        <rect
                            id='Rectangle-32-Copy'
                            x={5}
                            y={5}
                            className='st10'
                            width={24}
                            height={46}
                        />
                        <path
                            id='Oval-16'
                            className='st17'
                            d='M21,25.8c1.9-1.1,3-3.5,3-5.9c0-2.4-1.1-4.5-2.7-5.8c-2.4-0.2-5.4-0.1-7.4-0.1
                      c-1.7,1.3-2.9,3.4-2.9,5.9c0,2.6,1.3,4.8,3.1,6.1C15.8,26.1,19.1,25.9,21,25.8z'
                        />
                        <path
                            id='Oval-16-Copy'
                            className='st17'
                            d='M21,44.8c1.9-1.1,3-3.5,3-5.9c0-2.4-1.1-4.5-2.7-5.8c-2.4-0.2-5.4-0.1-7.4-0.1
                      c-1.7,1.3-2.9,3.4-2.9,5.9c0,2.6,1.3,4.8,3.1,6.1C15.8,45.1,19.1,44.9,21,44.8z'
                        />
                    </g>
                    <g
                        id='Artboard-2'
                        transform='translate(127.000000, 55.000000)'
                    >
                        <path
                            id='_Rectangle_'
                            className='st17'
                            d='M39.3,506h356.4c3.5,0,6.3,2.7,6.3,6.1v0.8c0,3.4-2.8,6.1-6.3,6.1H39.3
                      c-3.5,0-6.3-2.7-6.3-6.1v-0.8C33,508.7,35.8,506,39.3,506z'
                        />
                        <g
                            id='_Group_'
                            transform='translate(166.000000, 159.000000)'
                        >
                            <g
                                id='_Group_2'
                                transform='translate(14.933333, 164.443872)'
                            >
                                <path
                                    id='_Path_'
                                    className='st18'
                                    d='M92.6,179.9c-1.4-0.1-2.5-1.4-2.4-2.8L84,24.5C83.8,18.6,79.7,14,74.8,14H27.8
                              c-4.9,0-8.9,4.6-9.2,10.6l-6.1,152.5c0.1,1.4-1,2.7-2.4,2.8H3.4c-0.7-0.1-1.4-0.4-1.9-1c-0.5-0.6-0.7-1.3-0.6-2.1l6.2-153
                              C7.7,10.5,16.8,0.1,27.8,0.1h47.1c11,0,20.1,10.4,20.7,23.7l6.2,153c0.1,0.7-0.1,1.5-0.6,2.1c-0.5,0.6-1.1,1-1.9,1H92.6z'
                                />
                            </g>
                            <g
                                id='_Group_3'
                                transform='translate(110.972645, 164.443872)'
                            >
                                <path
                                    id='_Path_2'
                                    className='st10'
                                    d='M91.8,187.1c-1.4-0.1-2.5-1.4-2.4-2.8L83.2,24.5C83,18.6,78.9,14,74.1,14H27
                              c-4.9,0-8.9,4.6-9.2,10.6l-6.1,152.5c0.1,1.4-1,2.7-2.4,2.8H2.6c-0.7-0.1-1.4-0.4-1.9-1s-0.7-1.3-0.6-2.1l6.2-153
                              C7.7,5,16,0.1,27,0.1h47.1c11,0,20.1,10.4,20.7,23.7l6.2,160.3c0.1,0.7-0.1,1.5-0.6,2.1c-0.5,0.6-1.1,0.9-1.9,1H91.8z'
                                />
                            </g>
                            <g id='_Group_4'>
                                <path
                                    id='_Path_3'
                                    className='st19'
                                    d='M7,182.4c3.3,1.1,105.2,0,105.2,0c3.2-42,93.2-19.9,93.7-36.8l6.3-115.4
                              c0.1-5.9,1.5-11.7,4.1-17c1.7-3.6,1.2-7.8-1.4-10.9l1.3-2.3h-98.2c-9.8,0-10.8,1-13.1,4.1c-5,6.7-6,16.5-6.2,25.6l-3,106.4
                              c-0.2,7.5-5.2,13.3-11.3,13.3H26.3c-11.5,0-21.7,9-25.3,22.3l-0.1,0.3c-1,4.3,1.1,8.8,4.7,9.9L7,182.4z'
                                />
                            </g>
                            <g
                                id='_Group_5'
                                transform='translate(84.169697, 0.000000)'
                            >
                                <path
                                    id='_Path_4'
                                    className='st10'
                                    d='M121.6,145.7c-0.1,1.6-0.9,3-2.3,3.8H0.1c6.1,0,11.1-5.8,11.3-13.3l3-106.4
                              c0.2-9.1,1.2-19,6.2-25.6C23,1,23.9,0.1,33.8,0.1h98.1l-1.3,2.3c2.5,3,3.1,7.3,1.4,10.9c-2.6,5.3-3.9,11.1-4.1,17L121.6,145.7
                              z'
                                />
                            </g>
                            <g
                                id='_Group_6'
                                transform='translate(105.542342, 0.000000)'
                            >
                                <path
                                    id='_Path_5'
                                    className='st0'
                                    d='M6.6,182.4c3.3,1.1,6.8-1.4,7.7-5.4c1.6-6.1,6.3-10.2,11.6-10.2h58
                              c14,0,25.2-13.3,25.7-30.2l3-106.4c0.1-5.9,1.5-11.7,4.1-17c1.7-3.6,1.2-7.8-1.4-10.9l-0.1-0.1c-3.3-3.6-8.4-2.7-10.7,1.9
                              c-3.9,8-6,16.7-6.2,25.6l-3,106.4c-0.2,7.5-5.2,13.3-11.3,13.3H25.9c-11.5,0-21.7,9-25.3,22.3l-0.1,0.3
                              c-1,4.3,1.1,8.8,4.7,9.9L6.6,182.4z'
                                />
                            </g>
                        </g>
                        <path
                            id='_Path_6'
                            className='st20'
                            d='M216,476.1c-1.4-8.3-10.4-21-8.7-32.6c2.1-14.8-19.6-16.4-20,1.6s-15.7,47-28.7,48.4
                      c-7.9,0.9-10.6,5.3-11.5,8.7c7.8,1,15.5,2.7,23.4,3.8c0.9-1.1,3.8-6.2,14.1-12.1c5-2.9,9.1-6.5,14.5-7
                      C204.2,482.8,210,479.2,216,476.1z'
                        />
                        <path
                            id='_Path_7'
                            className='st21'
                            d='M201.8,485.4c4.6-3.2,10.5-7,16.2-8.1c-0.3-5.6-5.4-16.4-7.2-19.8c-0.1-0.2-0.4-0.4-0.6-0.4
                      s-0.5,0.1-0.7,0.2c-5,5.4-24,35-30.3,38c-8.3,4.1-14.8-4.8-14.8-4.7c-13.6,4.4-15.6,6-17.3,12.2c9.2,1.2,18.3,3.3,27.5,4.2
                      l25.9-19.6C200.6,486.6,201.1,485.8,201.8,485.4z'
                        />
                        <path
                            id='_Path_8'
                            className='st22'
                            d='M218.8,477.6c0.2-1.2,0.3-2.4,0.2-3.6c0-0.4-0.2-0.7-0.6-0.8s-0.7-0.2-1,0
                      c-16.9,8.5-45.8,29.4-45.8,29.4s-24.6-2.2-26.8-2.5L144,504l27.6,4C171.6,508,215.6,478.9,218.8,477.6z'
                        />
                        <path
                            id='_Path_9'
                            className='st22'
                            d='M313,234c-20.1,0.5-40,3.9-60,5.2c-99.3,6.5-94.6,46.2-90.1,66.2
                      c9.5,41.8,18.3,142.6,18.3,142.6s19.5-1.5,28-2.4c12.4-64.4,11.3-117.2,5.7-137.1c19.7,1.4,32.6,0,52.5,0.6v-45.4H313V234z'
                        />
                        <path
                            id='_Path_10'
                            className='st23'
                            d='M342.7,239c-26,0-60.4,3.3-90.7,7.2v62.2c2.7,0.1,5.4,0.2,8.1,0.3
                      c17.1,0.5,34.2,0.7,51.2-1.4c8.2-1,39.5-2.3,40.7-33.8C352.5,259.3,348.9,251.9,342.7,239z'
                        />
                        <polygon
                            id='_Path_11'
                            className='st22'
                            points='219,477 215,483.6 211.9,507 207.6,507 206.3,487.7 202,485.5 				'
                        />
                        <path
                            id='_Path_12'
                            className='st20'
                            d='M292.9,441c0,0,2.9,40.1,6.1,62.9l-28,7.1c0,0,2.5-33.4,2.7-68.5L292.9,441z'
                        />
                        <g
                            id='_Group_7'
                            transform='translate(208.000000, 246.000000)'
                        >
                            <path
                                id='_Path_13'
                                className='st23'
                                d='M59.4,203.8c-8.5-41-17.7-63.3-31.4-93.6C13.7,78.6-24.4,27,26.9,5.5
                          C34.4,2.9,42.2,1.1,50.1,0c10.3-1.2,1.1,27.6,2.6,35.3C55.6,50.8,87.3,65,90,198.2L59.4,203.8z'
                            />
                        </g>
                        <path
                            id='_Path_14'
                            className='st21'
                            d='M294.4,462l-1.7,36.4l-17.5,0.6L273,476l-2,35l30-6.7C294.9,483,303.3,467.6,294.4,462z'
                        />
                        <path
                            id='_Path_15'
                            className='st22'
                            d='M298,466c0,0,2.2,0.4,2.9,6.1c0.4,3.3,5.1,28.9,5.1,28.9l-2.5,0.9l-2.4-7.3l2.1,12.9
                      l-8.4,2.8L267,511v-3.2l30.6-3.4L298,466z'
                        />
                        <path
                            id='_Path_16'
                            className='st24'
                            d='M297,503.8c0,0-2.4-7-14.3-6.8s-14.3,5.2-13.5,10l23.7-1.4L297,503.8z'
                        />
                        <path
                            id='_Path_17'
                            className='st23'
                            d='M253.8,34.1c4.6-18.1,21.8-30.5,39.5-33.5c10.1-1.7,20.8-0.1,28.6,7.2
                      c5.8,5.5,10.1,12.5,12.2,20.3c1.1,4,1.5,8.3,1,12.4c-0.5,3.9-2.5,7.7-1.9,11.7c0.4,2.7,2.3,4.6,3.4,7c1.2,2.7,0.8,5.4-0.1,8.1
                      c-1.2,3.9-0.7,8.2,1.4,11.8c1.9,3.2,4.9,5.7,7,8.8c3.2,4.9,2.7,14.7-1.1,19.1c-4.1,4.6-7.8,3.7-10.8,9.2c-1.2,2.3-4.9,0.6-7,2.1
                      c-1.7,1-3.6,1.7-5.5,2c-3.5,0.8-6.5,2.8-8.6,5.6c-2.4,3.6-2.3,8.1-2.9,12.4c-1.5,10-7,18.9-15.2,24.6c-9.5,6.5-22,8.5-29.8,16.9
                      c-5.8,6.3-8,15.1-9.9,23.5s-3.2,24.3-14.9,24.5c-1,0.1-1.9-0.1-2.8-0.6c-2.7-1.7-1.7-5.7-2.4-8.8c-1.1-5.2-6.8-7.6-11.6-10
                      c-3.3-1.7-7.2-4.7-8-8.6c-1.1-5.7,6.3-8.1,5.2-13.6c-0.9-5-7.1-7.2-10.2-10.5c-13.2-14.1,15.9-22,20.7-31.2
                      c0.1-0.1,0.1-0.3,0.2-0.4c4-8.9-1.6-19.4-7.4-26c-3.4-3.8-6.5-7-8.3-11.8c-2-5.7-1.2-12,2.1-17.1c3.6-5.6,9.8-8.3,15.9-10.1
                      c5.2-1.5,11.1-2.9,15.8-5.5c4-2.2,7-5.8,6.8-10.6c-0.3-6.8-2-13.3-2.3-20.1C252.8,40,253.1,37,253.8,34.1z'
                        />
                        <path
                            id='_Path_18'
                            className='st25'
                            d='M285.6,138c-2.5,3.9-4.7,7.9-6.6,12c0.9-0.1,1.9-0.3,2.8-0.4c1.4-0.1,2.7-0.8,3.7-1.8
                      c1.2-1.8,0.1-4.3-0.1-6.5C285.2,140.2,285.3,139.1,285.6,138z'
                        />
                        <path
                            id='_Path_19'
                            className='st22'
                            d='M236.8,160.9c-1.7,5.1,0.2,9,3.9,12.5c1.7,1.6,4.1,2.5,5.7,3.8c2.4,2,2.4,4.5,3.7,7.2
                      c1.7,3.7,6.5,5.4,10.4,4.3c3.9-1.3,7-4.4,8.3-8.3c1.2-3.3,1.4-6.8,2-10.2c1.6-6.2,4.7-12,9-16.7c4-4.9,9-8.8,14.7-11.7
                      c4.6-2.3,9.7-3.6,14.3-5.9c4.6-2.3,9-5.8,10.5-10.7c1.6-5.3-0.4-11.2,1.6-16.3c2.2-5.4,6.3-9.8,6-15.9c-0.4-7.2-5-13.3-6.1-20.3
                      c-1.6-10.3-0.6-20.8-2.3-31.1c-1.2-7.1-9.9-9.5-16.1-10.7c-9.2-1.7-19.7-0.8-27.4,4.8c-3.9,2.7-6.7,6.7-7.9,11.3
                      c-1.7,8,2.8,16-0.5,23.9c-2.8,6.6-8.8,10.9-15,14.3c-3.9,2.2-8.3,3.5-11.8,6.4c-5.9,5-4.2,12.1-0.9,18.1
                      c5.5,10,10.5,17.3,6.4,28.8C242.8,146,239.3,153.4,236.8,160.9z'
                        />
                        <path
                            id='_Path_20'
                            className='st21'
                            d='M313,73c-0.5,6.4-2.9,37-2.9,37l-23.1-0.9l1.9-23.4L313,73z'
                        />
                        <path
                            id='_Path_21'
                            className='st21'
                            d='M287,104l4.4,0c6.9,0,13.6-2.6,18.8-7.3c0.7-9.1,1.6-20.2,1.8-23.7l-23.6,12.8L287,104z'
                        />
                        <path
                            id='_Path_22'
                            className='st21'
                            d='M267.2,105c-3.9,0.2-7.6,1.5-10.8,3.7c-6.4,4.9-44.4,97.1-44.4,97.1l14.2,10.2l8.5,3.1
                      l33.3-83.5L267.2,105z'
                        />
                        <path
                            id='_Path_23'
                            className='st20'
                            d='M293,90.8l-5.1,0.2c-11.1,0.2-21.1-5.1-21.7-18c-0.5-11.5,0.6-43.7,0.6-43.7
                      c-0.1-10.4,9.2-18.9,20.3-19l14.5-0.2c11.1-0.2,19.8,8.1,19.4,18.4c0,0-0.8,28.7-1.4,38.2C318.6,82.1,304,90.7,293,90.8z'
                        />
                        <path
                            id='_Path_24'
                            className='st21'
                            d='M288,60.1c0,0,4,13.4-5,13.6c0,0-0.5,2.5,5,2.3c7.1-0.2,2.6-16,2.6-16L288,60.1z'
                        />
                        <path
                            id='_Path_25'
                            className='st20'
                            d='M318.4,64.6c1.2,4.3,5,5.7,8.3,3.2c3.5-3,5.1-7.8,3.9-12.3c-1.2-4.3-5-5.7-8.3-3.1
                      C318.8,55.2,317.2,60,318.4,64.6z'
                        />
                        <path
                            id='_Path_26'
                            className='st21'
                            d='M325.1,55c1.3,0.1,2.5,1.1,2.7,2.4c0.6,2.5-0.2,5.2-2.2,6.9c-0.8,0.7-1.9,0.9-2.8,0.5
                      s-1.7-1.2-1.8-2.3c0-0.1,0-0.3,0-0.4C323.2,61.3,323.5,55.9,325.1,55z'
                        />
                        <path
                            id='_Path_27'
                            className='st20'
                            d='M238,231.5c1.1-1.6,2.1-3.3,3-5.1c8.9-16.4,14.5-34.4,16.3-52.9l10.1-67l51.1-2.5l5.9,73.1
                      c-1.4,15.7,2.6,31.3,11.2,44.4l6.4,10l-6.8,2.4c-13.9,5.1-29.9,3.9-44.1-3.3l-5.2-2.6l-8.1,4.1C265.8,238.2,251,238,238,231.5
                      L238,231.5z'
                        />
                        <path
                            id='_Path_28'
                            className='st26'
                            d='M335.6,223.1c-8.7-13.2-12.6-29-11.2-44.7l13.7-67.3l-21.6-9.1l-24.2,22.2l-11.3-20.5
                      l-16.9,2.7l-5.4,54.5c3,1.5,4.8,4.6,4.7,8c-0.4,10.9-4.5,20.8-7.3,31.2c-2.2,8.3-3.2,16.9-5.2,25.2c-1,4.3-2.2,8.4-3.8,12.5
                      c29.7,8.4,75.7,2.3,88.2-2.2l6.8-2.4L335.6,223.1z'
                        />
                        <path
                            id='_Path_29'
                            className='st8'
                            d='M308.6,168h-50.2c-4.8,0-9.4-1.9-12.7-5.3c-3.3-3.4-5-8.1-4.7-12.8
                      c0.6-10,10.8-18.9,20.9-18.9l48.7,0.8c4.8,0,9.4,1.9,12.7,5.3c3.3,3.4,5,8.1,4.7,12.8C327.2,160,318.8,167.9,308.6,168z'
                        />
                        <path
                            id='_Path_30'
                            className='st21'
                            d='M276,104l-13.3,2.2l-0.8,8c-0.8,8.5-5.3,16.1-12.4,20.8c-5.4,3.5-9.4,9.3-9.7,15.5
                      c-0.3,4.7,1.3,9.2,4.5,12.7c3.2,3.4,7.6,5.4,12.3,5.5l-0.6,5.5c-0.2,2.2-0.5,4.4-0.8,6.6c-0.2,1.6-0.5,3.2-0.8,4.8
                      c-0.1,0.5-0.2,1-0.3,1.4c-0.4,2-0.8,4.1-1.4,6c0,0,0,0.1,0,0.1c-0.5,2-1,4.1-1.6,6.1c-2.8,9.7-6.6,19.1-11.5,28
                      c-1.5,2.7-6.1,10.2-6.5,10.7c7.8,3.8,12.5,7,23.2,8.1C256.2,245.9,274.2,180.6,276,104z'
                        />
                        <path
                            id='_Path_31'
                            className='st21'
                            d='M326.2,179.1l12.8-70.3l-12.6-3.1l-11-2.7c-2.7,4.6-14.7,29.4-20,68.2
                      c-3.1,22.7,0.1,54.8-0.1,77.8c16-0.8,53.7-11.8,53.7-11.8S324.7,194.3,326.2,179.1z'
                        />
                        <path
                            id='_Path_32'
                            className='st27'
                            d='M295.9,221.3c0.4,10.2,0.8,20.1,0.7,28.7c16.2-0.8,54.4-11.9,54.4-11.9
                      s-24.6-43.2-23.2-58.5l7.7-41.8c-3.6-3.8-7.6-7.1-7.6-3c0.2,7.6-3.2,14.8-9.1,19.5c-8.2,7-65.1,3.8-70.9,1.8
                      c-5.5-1.8-5.2-9.4-5.1-10.3c0,0.1,0,0.2-0.1,0.3l-0.3,1.1c-0.1,0.3-0.2,0.7-0.2,1c0,0.1,0,0.2,0,0.3c-0.1,0.6-0.2,1.1-0.2,1.7
                      c0,0.6,0,1.2,0,1.8c0,0.2,0,0.4,0,0.7s0,0.7,0.1,1.1c0.1,0.4,0.1,0.6,0.2,0.8c0.1,0.3,0.1,0.6,0.2,0.9c0.1,0.3,0.2,0.6,0.3,1
                      c0.1,0.2,0.1,0.5,0.2,0.7c0.1,0.4,0.3,0.7,0.4,1c0.1,0.2,0.1,0.4,0.2,0.5c0.2,0.4,0.4,0.7,0.6,1.1l0.2,0.4
                      c0.2,0.4,0.5,0.8,0.8,1.1c0.1,0.1,0.1,0.2,0.2,0.3c0.3,0.4,0.6,0.8,1,1.1l0.1,0.1c0.4,0.4,0.7,0.8,1.1,1.1
                      c3.1,2.7,7,4.3,11.1,4.3l-0.6,5.5c-0.2,2-0.4,4-0.7,5.9l-0.1,0.7c-0.2,1.6-0.5,3.3-0.8,4.9c-0.1,0.5-0.2,1-0.3,1.4
                      c-0.4,2-0.8,4.1-1.4,6v0.1c-0.5,2.1-1,4.1-1.6,6.1c-1,3.6-2.2,7.3-3.6,10.9l11.1,26.8c1.3-5.4,3-13,4.9-22.3
                      c1.8,3,4,5.2,6.8,5.1C280.3,219.8,288.1,220.3,295.9,221.3z'
                        />
                        <path
                            id='_Path_33'
                            className='st20'
                            d='M273.1,207.1c-3.1-0.8-17.6-5.4-22-5.1c-2.9,0.2-20.3,11.7-24.1,18.3c2.2,1.6,5,2.1,7.6,1.3
                      c0.8-0.2,1.6-0.6,2.4-0.9c1.9-0.5,3.9-0.5,5.9-0.3c5.5,0,10.8,1,16.3,0.3c4.3-0.7,8.5-0.8,12.8-1.5c0.5,0,1-0.2,1.4-0.5
                      c0.6-0.7,0.8-1.6,0.5-2.4C273.4,213.3,273.2,210.2,273.1,207.1z'
                        />
                        <path
                            id='_Compound_Path_'
                            className='st23'
                            d='M322.5,21c-1.7-9.2-20.9-20.4-44.4-12.1S265.5,72,265.5,72
                      c0.4-10,4.9-31.9,21.6-35.8c15.9-3.7,21.6-19.9,21.6-19.9c-2.5,8.4,0.7,19.1,3.4,26.2c1.8,4.4,4.7,8.2,8.5,11
                      C320.5,53.5,324.3,30.1,322.5,21z M268.6,44.7c1.3-13.4,17.2-14.2,26.4-22.3C288,34.2,271.8,31.2,268.6,44.7L268.6,44.7z'
                        />
                        <path
                            id='_Path_34'
                            className='st28'
                            d='M273.9,216.5c-0.5-3.1-0.7-6.1-0.8-9.2c-1.7-0.4-6.8-2-11.8-3.2c4.5,1.2-3.7,11.4-3.7,11.4
                      s1.5-0.2-6.8,0c0.1-1.8,3-7.5,2-7.2c-1,0.3-3.1,5.6-3.7,7.2c-2,0-4.1,0-6.4,0c0.9-1.7,3.7-7.1,3.1-7.2s-4.7,5.3-6.1,7.1
                      c-2.5-0.1-5.1-0.3-7.8-0.5c-1.9,1.6-3.6,3.4-5,5.5c2.2,1.6,5,2.1,7.6,1.3c0.8-0.2,1.6-0.6,2.4-0.9c1.9-0.4,3.9-0.5,5.9-0.3
                      c5.5,0,10.8,1,16.3,0.3c4.3-0.7,8.5-0.8,12.8-1.4c0.5,0,1-0.2,1.4-0.5C273.9,218.2,274.1,217.3,273.9,216.5z'
                        />
                        <path
                            id='_Path_35'
                            className='st8'
                            d='M305.6,113.7l9.6,1.5l19.8-9l-20.1-6.3l-22.2,19.1l-12-18.3l-14.8,3l8.8,7.4h9.3
                      c0,0-3.3,3.5-5,4.3l12.4,11.5l17.7-8.8C308.3,116.5,307.1,115,305.6,113.7z'
                        />
                        <polygon
                            id='_Path_36'
                            className='st8'
                            points='277.5,203 271,203 272.6,221 280,221 				'
                        />
                        <g
                            id='_Group_8'
                            transform='translate(0.000000, 159.000000)'
                        >
                            <rect
                                id='_Rectangle_2'
                                x='170.6'
                                y={77}
                                className='st0'
                                width='19.9'
                                height='269.7'
                            />
                            <polygon
                                id='_Path_37'
                                className='st29'
                                points='190.5,152.6 190.5,77 170.6,77 170.6,146.1 					'
                            />
                            <path
                                id='_Path_38'
                                className='st0'
                                d='M271.9,336.1l-81.5-3.6c-1,0-2,0.5-2.6,1.4l-4.8,7.9c-0.5,0.9-1.5,1.4-2.5,1.4
                          s-1.9-0.5-2.5-1.4l-4.9-7.9c-0.6-0.9-1.5-1.4-2.6-1.4l-82,3.6h-0.2l-41.2,5.2c-1.4,0.2-2.5,1.4-2.5,2.9v5
                          c0,1.6,1.3,2.9,2.9,2.9h264.7c1.6,0,2.9-1.3,2.9-2.9v-5c0-1.4-1.1-2.6-2.5-2.8l-40.5-5.2L271.9,336.1z'
                            />
                            <g
                                id='_Group_9'
                                transform='translate(65.531994, 32.584615)'
                            >
                                <path
                                    id='_Rectangle_3'
                                    className='st30'
                                    d='M1.6,26.9h50.1c0.5,0,0.9,0.4,0.9,0.9v1.6c0,0.5-0.4,0.9-0.9,0.9H1.6
                              c-0.5,0-0.9-0.4-0.9-0.9v-1.6C0.7,27.3,1.1,26.9,1.6,26.9z'
                                />
                                <g
                                    id='_Group_10'
                                    transform='translate(43.105126, 2.715385)'
                                >
                                    <path
                                        id='_Path_39'
                                        className='st30'
                                        d='M6.9,17.3c-2.3,0-4.6-0.5-6.8-1.4l1.2-2.8c1.6,0.7,6.4,2,8.9,0.3
                                  c1.4-0.9,2.1-2.7,2.1-5.4c0-1.7-0.3-3.9-1.5-4.5c-2-1-6.3,1.4-8,2.7L0.9,3.8c0.7-0.5,7-5.1,11.2-3c2.1,1.1,3.2,3.5,3.2,7.2
                                  s-1.2,6.4-3.5,7.9C10.4,16.9,8.6,17.4,6.9,17.3z'
                                    />
                                </g>
                                <path
                                    id='_Path_40'
                                    className='st0'
                                    d='M6,14.8c0,7.6,6.1,13.7,13.7,13.7h14.9c7.6,0,13.7-6.1,13.7-13.7V1.8
                              c0-0.3-0.1-0.6-0.3-0.8c-0.2-0.2-0.5-0.3-0.8-0.3H7.1C6.8,0.6,6.5,0.8,6.3,1C6.1,1.2,6,1.5,6,1.8V14.8z'
                                />
                            </g>
                            <path
                                id='_Path_41'
                                className='st0'
                                d='M280.1,62.5v-0.5c0-0.9-0.7-1.6-1.6-1.6h-45.2c-0.7,0-1.3-0.4-1.5-1.1L212.3,1.1
                          c-0.2-0.6-0.8-1.1-1.5-1.1h-88.5c-0.5,0-1,0.2-1.3,0.6s-0.4,0.9-0.2,1.4l18.3,60.9c0.2,0.7,0.8,1.1,1.5,1.1h137.8
                          C279.4,64,280.1,63.3,280.1,62.5z'
                            />
                            <path
                                id='_Path_42'
                                className='st31'
                                d='M120.9,2.1h91.7l-0.3-1c-0.2-0.7-0.8-1.1-1.5-1.1h-88.5c-0.5,0-1,0.2-1.3,0.6
                          S120.7,1.6,120.9,2.1L120.9,2.1z'
                            />
                            <path
                                id='_Rectangle_4'
                                className='st10'
                                d='M3.3,62.6h360.4c1.8,0,3.2,1.4,3.2,3.2v12.6c0,1.8-1.4,3.2-3.2,3.2H3.3
                          c-1.8,0-3.2-1.4-3.2-3.2V65.8C0.1,64,1.5,62.6,3.3,62.6z'
                            />
                            <path
                                id='_Path_43'
                                className='st32'
                                d='M366.8,67.3v-0.2c0-2.5-2-4.5-4.5-4.5H4.6c-2.5,0-4.5,2-4.5,4.5v0.2H366.8z'
                            />
                            <path
                                id='_Path_44'
                                className='st0'
                                d='M0.1,76.2v1c0,2.5,2,4.5,4.5,4.5h357.8c2.5,0,4.5-2,4.5-4.5v-1H0.1z'
                            />
                        </g>
                        <path
                            id='_Path_45'
                            className='st21'
                            d='M333.4,107c0,0,7.8,0.5,10.9,4.8c3.1,4.4,31.8,85.6,24.5,97.7c-3.6,6.1-56.2,13.5-90,12.3
                      L276,200c0,0,61.2-7.7,63.1-10c1.9-2.3-16-63.5-13.1-71.6C327.7,114.2,330.2,110.3,333.4,107z'
                        />
                        <path
                            id='_Path_46'
                            className='st33'
                            d='M177.7,193.7c0.9,3.6-0.6,6.4-3.5,6.3s-6-3.1-6.9-6.7c-0.9-3.6,0.6-6.4,3.5-6.3
                      C173.7,187.1,176.8,190.1,177.7,193.7z'
                        />
                        <path
                            id='_Path_47'
                            className='st27'
                            d='M338.9,189c0,0.3,0,0.5-0.2,0.8c-0.5,0.4-1.1,0.6-1.8,0.8c2.1,2,7.8,7.1,8.8,6.4
                      c1.2-0.9-5-5.9-5-5.9s13.6,5.1,13.2,3C353.6,192.3,342.8,189.8,338.9,189z'
                        />
                        <path
                            id='_Path_48'
                            className='st34'
                            d='M288.5,9.6c5.9,2.3,12,3.9,18.3,4.6c6.7,0.8,13.4,0.9,20.2,0.3c-1.8-2.6-3.8-4.9-6.1-7.1
                      c-0.6-0.5-1.2-1-1.8-1.5c-2.5,0.1-5.1,0-7.6-0.3c-2.5-0.3-4.9-1-7.2-2c-1.9-0.9-3.1-2.6-4.7-3.5L299.3,0c-2.2,0-4.4,0.2-6.6,0.6
                      c-4.4,0.7-8.7,2-12.8,3.8C282.5,6.6,285.4,8.4,288.5,9.6z'
                        />
                    </g>
                </g>
            </g>
        </g>
        <g id='Fill-60_1_' transform='translate(105.000000, 9.000000)'>
            <g id='Mask_5_'>
                <path
                    id='path-3_4_'
                    className='st0'
                    d='M23.6,382.2c1.1-0.5,6.6-4.1,9.7-4.2c4.8-0.2,6.5-2.5,11.2-1.7c1.5,0.3,3.2,0.6,4.2,2.1
              c1.6,2.5,0.6,7.6-3.4,11c-2.6,2.2-3.3,8.2-5.9,10.2c-4.4,3.4-9.6,1.6-14,4.7c-4.4,3.1-4.5,8.9-11.2,5.1c-3.3-1.8-4.2,0.1-6.9-1.6
              c-1.9-1.2,0.5-4.7-0.7-10.5c-0.3-1.4,3.2-3.5,4-4.8c2.6-4.4,4.7-1.5,7.9-6C21.8,381.9,22.3,382.8,23.6,382.2'
                />
            </g>
            <defs>
                <filter
                    id='Adobe_OpacityMaskFilter_4_'
                    filterUnits='userSpaceOnUse'
                    x='6.7'
                    y='371.1'
                    width='41.9'
                    height='31.8'
                >
                    <feColorMatrix
                        type='matrix'
                        values='1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 1 0'
                    />
                </filter>
            </defs>
            <mask
                maskUnits='userSpaceOnUse'
                x='6.7'
                y='371.1'
                width='41.9'
                height='31.8'
                id='mask-4_2_'
            >
                <g className='st35'>
                    <path
                        id='path-3_3_'
                        className='st8'
                        d='M23.6,382.2c1.1-0.5,6.6-4.1,9.7-4.2c4.8-0.2,6.5-2.5,11.2-1.7c1.5,0.3,3.2,0.6,4.2,2.1
                  c1.6,2.5,0.6,7.6-3.4,11c-2.6,2.2-3.3,8.2-5.9,10.2c-4.4,3.4-9.6,1.6-14,4.7c-4.4,3.1-4.5,8.9-11.2,5.1c-3.3-1.8-4.2,0.1-6.9-1.6
                  c-1.9-1.2,0.5-4.7-0.7-10.5c-0.3-1.4,3.2-3.5,4-4.8c2.6-4.4,4.7-1.5,7.9-6C21.8,381.9,22.3,382.8,23.6,382.2'
                    />
                </g>
            </mask>
            <path
                className='st36'
                d='M6.8,397.2c0.2-1.6,0.7-3.2,1.5-4.6c2.6-4.5,4.5-1.7,7.7-6.4c3.2-4.6,3.6-7.4,7.8-9.8c4.2-2.3,6.5-1,9.7-1.2
          c4.8-0.2,7.3-4.8,12-4.1c1.5,0.2,2.3,5.1,3.2,6.6c0,0-6,0.8-9.7,4c-3.7,3.2-11.9,8-16.2,9.9c-4.3,2-15.2,8.8-15.9,11.1
          C6.7,398.9,6.8,397.2,6.8,397.2'
            />
        </g>
    </svg>
)

export default DeskWoman
