import { redirect } from './redirect'
import config from '../config'

const { REACT_APP_BFF_BASE_URL, REACT_APP_ACCOUNTS_APP_URL } = config

interface ILogOutStorage {
  keys: string[]
}

// redirectUrl is the location to redirect to once the user has been effectively logged out.
const logout = (
  redirectUrl: string = `${REACT_APP_ACCOUNTS_APP_URL}/login`
): void => {
  const logoutUrl = `${REACT_APP_BFF_BASE_URL}/auth/logout?redirect=${encodeURIComponent(
    redirectUrl
  )}`
  redirect(logoutUrl)
}

export default logout
