import React, { FunctionComponent, useEffect } from 'react'
import { connect } from 'react-redux'
import { css } from 'emotion'
import { components, colors, elements, fonts } from '@peachjar/components'
import TextInput from '@peachjar/ui/dist/lib/components/Inputs/TextInput'
import {
  setEmail,
  submitEmail,
  setEmailStructureFailed,
  setEmailStructureSuccessful,
  clearLoginErrors,
  resetMagicLinkState,
} from './Redux/LogInActions'
import emailIsValidish from '../Utility/formUtils'
import { ActionResults, RootState } from '../App/Store/makeStore'
import { Dispatch } from 'redux'
import { UserRole } from '../App/Dependencies/PortalApiClient'
import config from '../config'
import { LOOK_UP_ERROR, EMAIL_STRUCTURE_ERROR } from './LoginForm.constants'
import { APP_ROUTES } from '../App/config'

const {
  Buttons: { ButtonPrimaryLarge },
} = components
const { ProximaNova } = fonts

const TextField: any = TextInput

interface Props {
  email: string
  magicLinkFailed: boolean
  magicLinkRequestSuccessful: boolean
  userType?: UserRole
  handleEmailChange: (event: any) => any
  handleEmailSubmit: () => any
  handleEmailValidationFailed: () => void
  handleEmailValidationSuccessful: () => void
  clearInputErrors: () => void
  clearMagicLinkState: () => void
  userEmailStructureError: boolean
  successfullyAuthenticated: boolean
  loginAttemptInProgress: boolean
  history: any
}

const redirectPARAM = new URLSearchParams(window.location.search)
const targetURL = redirectPARAM.get('target') || null
const decodedTarget = targetURL ? decodeURIComponent(targetURL) : null

const signUpURL = targetURL
  ? `${APP_ROUTES.signup}?target=${encodeURIComponent(targetURL)}`
  : APP_ROUTES.signup

const LogInForm: FunctionComponent<Props> = ({
  email,
  handleEmailChange,
  handleEmailSubmit,
  handleEmailValidationFailed,
  handleEmailValidationSuccessful,
  clearInputErrors,
  clearMagicLinkState,
  userEmailStructureError,
  magicLinkFailed,
  magicLinkRequestSuccessful,
  userType,
  successfullyAuthenticated,
  loginAttemptInProgress,
  history,
}) => {
  const emailErrorText: string = magicLinkFailed
    ? LOOK_UP_ERROR
    : EMAIL_STRUCTURE_ERROR

  const checkKeyAndSubmitEmail = (event?: {
    key: string
    preventDefault: () => void
  }) => {
    if (event && event.hasOwnProperty('key') && event.key === 'Enter') {
      event.preventDefault()

      if (!emailIsValidish(email)) {
        handleEmailValidationFailed()
        return
      }

      handleEmailValidationSuccessful()
      handleEmailSubmit()
    }
  }

  const checkAndSubmitEmail = () => {
    if (!emailIsValidish(email)) {
      handleEmailValidationFailed()
      return
    }

    handleEmailSubmit()
  }

  useEffect(() => {
    if (magicLinkRequestSuccessful) {
      history.push(APP_ROUTES.magicLinkSuccess, { email })
    }
  }, [magicLinkRequestSuccessful])

  useEffect(() => () => clearMagicLinkState(), [])

  if ((magicLinkFailed || userEmailStructureError) && email.length === 0) {
    clearInputErrors()
  }

  return (
    <form className={classNames.form} onSubmit={(e: any) => e.preventDefault()}>
      <TextField
        autoFocus
        id='email'
        className={classNames.input}
        name='email'
        label='Email Address'
        placeholder='user@example.com'
        type='text'
        value={email}
        error={magicLinkFailed || userEmailStructureError}
        errorText={emailErrorText}
        onChange={handleEmailChange}
        onKeyDown={checkKeyAndSubmitEmail}
      />
      <div className={classNames.buttonWrapper}>
        <ButtonPrimaryLarge
          id='btnSubmit'
          disabled={!emailIsValidish(email)}
          role='submit'
          onClick={checkAndSubmitEmail}
          className={classNames.btnSubmit}
        >
          Log In
        </ButtonPrimaryLarge>
      </div>
      <div className={classNames.linkWrapper}>
        <p className={classNames.getStarted}>
          <span className={classNames.getStartedLinkIntro}>
            New to Peachjar?{' '}
            <a href={signUpURL} className={classNames.getStartedLink}>
              Sign Up
            </a>
          </span>
        </p>
      </div>
    </form>
  )
}

const classNames = {
  form: css`
    margin-top: 3rem;
  `,
  buttonWrapper: css`
    margin-left: -5px;
    margin-top: 2rem;
  `,
  btnSubmit: css`
    width: auto !important;
  `,
  input: css`
    width: 100%;
    margin-top: 2rem;
  `,
  forgotLink: css`
    float: right;
    &,
    &:hover {
      color: ${colors.jungle};
      text-decoration: none;
    }
  `,
  getStarted: css`
    color: ${colors.slate};
    font-family: ${ProximaNova};
  `,
  getStartedLinkIntro: css`
    color: ${colors.prussian};
    font-family: ${ProximaNova};
  `,
  getStartedLink: css`
    color: ${colors.jungle};
    font-family: ${ProximaNova};
    text-decoration: underline;
    &:hover {
      color: #c5e8c9;
    }
  `,
  linkWrapper: css`
    margin-top: 32px;
  `,
}

export default connect(
  (state: RootState) => ({
    email: state.logIn.email,
    magicLinkRequested: state.logIn.magicLinkRequested,
    magicLinkRequestSuccessful: state.logIn.magicLinkRequestSuccessful,
    magicLinkFailed: state.logIn.magicLinkRequestFailed,
    successfullyAuthenticated: state.logIn.successfullyAuthenticated,
    loginAttemptInProgress: state.logIn.loginAttemptInProgress,
    userType: state.logIn.userType,
    userEmailStructureError: state.logIn.userEmailStructureError,
  }),
  (dispatch: Dispatch<ActionResults>) => ({
    handleEmailChange: (event: any) => {
      const email = event.target.value
      dispatch(setEmail(email))

      if (!emailIsValidish(email)) {
        dispatch(setEmailStructureFailed())
        return
      }

      dispatch(setEmailStructureSuccessful())
    },
    handleEmailSubmit: () => dispatch<any>(submitEmail()),
    handleEmailValidationFailed: () => dispatch<any>(setEmailStructureFailed()),
    handleEmailValidationSuccessful: () =>
      dispatch<any>(setEmailStructureSuccessful()),
    clearInputErrors: () => dispatch<any>(clearLoginErrors()),
    clearMagicLinkState: () => dispatch<any>(resetMagicLinkState()),
  })
)(LogInForm)
