import React, { FormEvent } from 'react'
import { css, cx } from 'emotion'
import TextInput from '@peachjar/ui/dist/lib/components/Inputs/TextInput'
import AddressInput from '@peachjar/ui/dist/lib/components/Inputs/AddressInput'
import { FormProps } from './StaffSignUpNew'
import FormFooter from '../App/components/FormFooter'
import { ADDRESS_INPUT_TYPE } from './StaffSignUp.constants'

const TextField: any = TextInput

const StaffSignUpNewForm: React.FunctionComponent<FormProps> = ({
  fields,
  formValid,
  handleAddressChange,
  handleFieldChange,
  handleSubmit,
}) => {
  return (
    <div className={cx(styles.formContainer, 'staff-new')}>
      <form onSubmit={(e: FormEvent<HTMLElement>) => handleSubmit(e)}>
        {fields.map(
          ({ id, label, value, error, errorText, inputType }, index) => (
            <div className={styles.formField} key={index}>
              {inputType === ADDRESS_INPUT_TYPE && (
                <AddressInput
                  label={label}
                  // @ts-ignore
                  value={value}
                  error={error ? errorText : null}
                  onChange={handleAddressChange}
                />
              )}
              {inputType !== ADDRESS_INPUT_TYPE && (
                <TextField
                  autoFocus={index === 0}
                  id={id}
                  name={id}
                  label={label}
                  type={inputType}
                  value={value}
                  error={error}
                  errorText={errorText}
                  onChange={e => handleFieldChange(e, id)}
                />
              )}
            </div>
          )
        )}
        <FormFooter
          isFormValid={formValid}
          handleSubmit={handleSubmit}
          primaryBtnText='Submit'
          renderDisclaimer={false}
          renderLoginOption={false}
        />
      </form>
    </div>
  )
}

const styles = {
  formContainer: css`
    margin: 24px 0;
  `,
  formField: css`
    margin: 24px 0;
  `,
}

export default StaffSignUpNewForm
