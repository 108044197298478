import React from 'react'
import { withRouter } from 'react-router-dom'
import LogInForm from './LogInForm'
import { UserRole } from '../App/Dependencies/PortalApiClient'
import PrimaryHeading from '../App/Typography/PimaryHeading'
const { elements } = require('@peachjar/components')
const { typography } = elements

type Props = {
  userType?: UserRole
  history: any
}

const LogInFormContainer = ({ history }: Props) => (
  <div>
    <PrimaryHeading>Welcome back!</PrimaryHeading>
    <LogInForm history={history} />
  </div>
)

export default withRouter(LogInFormContainer)
