import React, { ReactNode } from 'react'
import { css, cx } from 'emotion'
import { withRouter, RouteComponentProps } from 'react-router-dom'
const { colors } = require('@peachjar/components')
import BackArrow from './BackArrow'

type Props = RouteComponentProps & {
  goBackUrl?: string | null
  children: ReactNode
}

const classNames = {
  twoTone: css`
    display: flex;
    flex: 1 1 auto;
    position: relative;
  `,
  col: css`
    flex: 1 1 0;
    align-self: stretch;
    width: 100%;

    @media screen and (min-width: 1025px) {
      width: 50%;
    }
  `,
  left: css`
    background-color: #fafafa;
  `,
  right: css`
    background-color: ${colors.platinum};
    display: none;

    @media screen and (min-width: 1025px) {
      display: block;
    }
  `,
  pageNavigation: css`
    position: absolute;
    top: 16px;
    left: 16px;
  `,
}

const TwoTone: React.FunctionComponent<Props> = ({
  goBackUrl = null,
  history,
  children,
}) => {
  const handlePageNav = () => {
    if (goBackUrl && goBackUrl.includes('http')) {
      window.location.href = goBackUrl
      return
    }

    if (goBackUrl) {
      history.push(goBackUrl)
      return
    }
  }

  if (!children || React.Children.count(children) !== 2) {
    throw new Error('Invalid component usage. Must take exactly two children.')
  }

  return (
    <div className={classNames.twoTone}>
      {goBackUrl && (
        <div onClick={handlePageNav} className={classNames.pageNavigation} role='button' tabIndex={0} aria-label='back'>
          <BackArrow />
        </div>
      )}
      <div className={cx(classNames.col, classNames.left)}>{children[0]}</div>
      <div className={cx(classNames.col, classNames.right)}>{children[1]}</div>
    </div>
  )
}

// @ts-ignore
export default withRouter(TwoTone)
