import React from 'react'
import { useHistory } from 'react-router-dom'
import { css } from 'emotion'
import colors from '@peachjar/ui/dist/lib/styles/colors'
import { ButtonPrimaryLarge } from '@peachjar/ui/dist/lib/components/Buttons/Buttons'
import { APP_ROUTES } from '../config'

type Props = {
  isFormValid: boolean
  primaryBtnText?: string
  renderDisclaimer?: boolean
  renderLoginOption?: boolean
  handleSubmit: any
}

const FormFooter: React.FunctionComponent<Props> = ({
  isFormValid,
  primaryBtnText = 'Get Started',
  renderDisclaimer = true,
  renderLoginOption = true,
  handleSubmit,
}) => {
  const history = useHistory()

  return (
    <>
      {renderDisclaimer && (
        <div className={styles.disclaimerContainer}>
          <p className={styles.disclaimerText}>
            By selecting Get Started, you agree to Peachjar's{' '}
            <a
              className={styles.linkMe}
              href='https://peachjar.com/privacy-policy-terms.html#terms'
              target='_blank'
            >
              Terms of Service
            </a>{' '}
            and{' '}
            <a
              className={styles.linkMe}
              href='https://peachjar.com/privacy-policy-terms.html#privacy'
              target='_blank'
            >
              Privacy Policy
            </a>
            .
          </p>
        </div>
      )}
      <div>
        <div onClick={event => handleSubmit(event)}>
          <ButtonPrimaryLarge
            // onClick={event => handleSubmit(event)}
            disabled={!isFormValid}
          >
            {primaryBtnText}
          </ButtonPrimaryLarge>
        </div>
        {renderLoginOption && (
          <p className={styles.secondaryCTA}>
            Have an account?{' '}
            <span
              className={styles.linkMe}
              onClick={() => history.push(APP_ROUTES.login)}
              tabIndex={0}
            >
              Log In
            </span>
          </p>
        )}
      </div>
    </>
  )
}

const styles = {
  disclaimerContainer: css`
    margin: 40px 0 16px 0;
  `,
  disclaimerText: css`
    font-family: 'Proxima Nova', Helvetica, Arial, sans-serif;
    font-size: 13px;
    color: ${colors.slate};
  `,
  linkMe: css`
    color: ${colors.jungle} !important;
    text-decoration: underline;
    cursor: pointer;
    &:hover {
      color: #c5e8c9 !important;
    }
  `,
  secondaryCTA: css`
    color: ${colors.prussian};
    font-family: 'Proxima Nova', Helvetica, Arial, sans-serif;
    font-size: 13px;
    margin-top: 24px;
  `,
}

export default FormFooter
