import { css } from 'emotion'
import colors from '@peachjar/ui/dist/lib/styles/colors'

const styles = {
  container: css`
    border-radius: 3px;
    padding: 32px;
    background: #f5f5f5;
    margin-bottom: 40px;
  `,
  header: css`
    display: flex;
    align-items: center;
  `,
  emailIcon: css`
    color: ${colors.stone};
    padding-right: 8px;
  `,
  userEmail: css`
    font-family: 'Proxima Nova', Arial, Helvetica, sans-serif;
    font-size: 16px;
    line-height: 24px;
    color: ${colors.prussian};
  `,
  label: css`
    font-family: 'Proxima Nova', Arial, Helvetica, sans-serif;
    font-size: 13px;
    line-height: 16px;
    color: ${colors.slate};
    padding-top: 24px;
    padding-bottom: 8px;
  `,
  unsubscribeFrom: css`
    font-family: 'Proxima Nova', Arial, Helvetica, sans-serif;
    text-transform: capitalize;
    padding-bottom: 16px;
    font-size: 25px;
    line-height: 32px;
    color: ${colors.prussian};
  `,
  footer: css`
    display: flex;
    justify-content: flex-start;
    padding-top: 24px;
  `,
  cancelButton: css`
    padding-right: 16px;
  `,
  disclaimerHeader: css`
   font-family: 'Proxima Nova', Arial, Helvetica, sans-serif;
   font-size: 16px;
   color: ${colors.prussian};
   line-height: 22px;
   padding-top: 8px;
   margin-bottom: 24px;
 `,
  disclaimer: css`
    font-family: 'Proxima Nova', Arial, Helvetica, sans-serif;
    font-size: 13px;
    line-height: 16px;
    padding-top: 8px;
    color: ${colors.slate};
    margin-bottom: 40px;
  `,
  switchWrapper: css`
    padding-top: 40px;
    display: flex;
    align-items: center;
    text-transform: capitalize;
    & .MuiFormControl-root {
      width: 100%;
      flex-direction: column;
    }
    & .MuiFormControlLabel-root {
      width: 100%;
      margin-left: 0px;
      justify-content: space-between;
    }

    & .MuiTypography-root {
      font-size: 25px;
    }

    & .MuiSwitch-root {
      order: 2;
    }

    & .MuiButtonBase-root {
      background: none;
      box-shadow: none;
    }

    & .MuiButtonBase-root:hover {
      background-color: alpha(colors.jungle, 0.15) !important;
    }
  `,
  subscriptionName: css`
    font-family: 'Proxima Nova', Arial, Helvetica, sans-serif;
    font-size: 16px;
    color: ${colors.prussian};
    line-height: 24px;
  `,
  closeIcon: css`
    position: absolute;
    top: 100px;
    left: 50px;
    cursor: pointer;
    @media (max-width: 480px) {
      top: 80px;
      left: 20px;
    }
`,
  warningMessage: css`
    padding-top: 24px;
    padding-bottom: 24px;
   & .MuiPaper-root {
     border: none !important
   }
  `,
  schoolName: css`
    font-family: 'Proxima Nova', Arial, Helvetica, sans-serif;
    font-size: 13px;
    color: ${colors.prussian};
    line-height: 16px;
    text-transform: capitalize;
    display: block;
  `
}

export default styles
