import React, { FunctionComponent } from 'react'
import { css } from 'emotion'
import FlexInset from './Layout/FlexInset'
import PrimaryHeading from './Typography/PimaryHeading'
import IllustratedQuote from './Layout/IllustratedQuote'
import Turtle from './Turtle'
import TwoTone from './Layout/TwoTone'
import config from '../config'
const { copy, elements, components } = require('@peachjar/components')
const { typography } = elements
const { quotes } = copy
const quote = quotes.duncan_lastsLongerThanACatVideo
const { Paragraph } = typography
const { Buttons: { ButtonPrimaryLarge } } = components
const supportUrl = config.REACT_APP_PEACHJAR_SUPPORT_URL || 'https://peachjar.com/contact-us.html'

const ErrorBoundaryPage: FunctionComponent = () => <TwoTone>
    <FlexInset>
        <PrimaryHeading>Uh oh</PrimaryHeading>
        <Paragraph style={{display: 'block', marginBottom: '24px'}}>Something&apos;s gone wrong. Don't worry, we're here to help!</Paragraph>
        <div className={classNames.root}>
          <ButtonPrimaryLarge
              onClick={() => { window.location.href = supportUrl }}>
            Contact Support
          </ButtonPrimaryLarge>
        </div>
    </FlexInset>
    <IllustratedQuote svg={<Turtle />} quote={quote} />
</TwoTone>

const classNames = {
  root: css`
  & button {
    width: auto;
  }
  `
}

export default ErrorBoundaryPage
