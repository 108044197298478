import { css } from 'emotion'
import colors from '@peachjar/ui/dist/lib/styles/colors'

const styles = {
  container: css`
    flex: 1 1 auto;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    text-align: center;
    padding-top: 50px;
    overflow: auto;
    height: 100vh;
    overflow-y: scroll;
  `,
  title: css`
    font-family: 'Proxima Nova-Bold', Arial, Helvetica, sans-serif;
    color: ${colors.prussian};
    font-size: 19px;
    font-weight: bold;
    text-align: center;
    line-height: 24px;
    padding-top: 40px;
    margin-bottom: 8px;
  `,
  description: css`
    font-family: 'Proxima Nova', Arial, Helvetica, sans-serif;
    font-size: 16px;
    line-height: 24px;
    color: ${colors.prussian};
  `,
  greenText: css`
   font-family: 'Proxima Nova', Arial, Helvetica, sans-serif;
   color: ${colors.jungle};
   font-size: 16px;
   line-height: 24px;
   text-decoration: underline;
   &:hover {
      color: #c5e8c9 !important;
    }
  `,
  turtleImage: css`
    width: 95%;
    height: auto;
    max-width: 541px;
    max-height: 455px;
  `
}

export default styles
