import axios, { AxiosInstance } from 'axios'
import PortalApiClient, { LoginAttemptResponse } from './PortalApiClient'

export default class PortalApiClientHttp implements PortalApiClient {
  constructor(protected readonly http: AxiosInstance) {}

  async getUserType(email: string) {
    try {
      const response = await this.http.get(`/auth/lookup/${email}`, {
        withCredentials: false,
      })

      return response.data.data
    } catch (err) {
      throw new Error('User type lookup failed.')
    }
  }
}
