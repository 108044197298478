import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { connect } from 'react-redux'
import { RootState } from '../App/Store/makeStore'
import useGlobalNotifications from '@peachjar/ui/dist/lib/hooks/useGlobalNotifications'
import sortBy from 'lodash/sortBy'
import FlexInset from '../App/Layout/FlexInset'
import PrimaryHeading from '../App/Typography/PimaryHeading'
import EmailNotifications from './EmailNotifications'
import useProfile, { Profile } from '../App/hooks/useProfile'
import LoadingSpinner from '../App/components/LoadingSpinner'
import AccountsBffClientHttp, {
  ChangeNotficationPreferencesInput,
  LoginStatus
 } from '../App/Dependencies/AccountsBffClientHttp'
import APP_ROUTES from '../App/config'
import config from '../config'
import CloseIcon from '@material-ui/icons/Close'
import styles from './EmailNotifications.styles'
import { MESSAGES } from './EmailNotifications.constants'
const httpClient = new AccountsBffClientHttp(axios)

const { REACT_APP_FLYER_BOARD_APP_URL} = config

type Props = {
  loginStatus: LoginStatus
}

const EmailNotificationsContainer: React.FunctionComponent<Props> = ({
  loginStatus
}) => {
  const {
    loading: loadingProfile,
    email,
    userId,
    children,
    getActiveChildren
  } = useProfile() as Profile

  const {
    notifySuccess,
    notifyError,
  } = useGlobalNotifications()

  const isManage = window.location.pathname === '/parent/email-notifications/manage'
  const [isReady, setIsReady] = useState<boolean>(false)
  const [dismissible, setDismissable] = useState<boolean>(!isManage)
  const [dismissLocation, setDismissLocation] = useState<string>('')
  const [isSubscribed, setIsSubscribed] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [schoolList, setSchoolList] = useState<string[]>([])
  const unsubscribeFrom = 'new flyers'

  useEffect(() => {
    if (!loading && children !== null) {
        const activeChildren = getActiveChildren()
        if (activeChildren.length === 0 && isManage) {
          redirectToEmailNotifications()
          return
        }
        const schools = sortBy(
          [
            ...new Set(
              activeChildren.map(item => item.institutionName)
            )
          ]
        )
        setSchoolList(schools)
        setIsReady(true)
    }

  }, [children, loading])

  useEffect(() => {
    const getNotificationPreferences = async () => {
      const {
        data
       } = await httpClient.getNotificationPreferences({
         refId: 'parent',
         notifType: 'flyer-email',
         notifTypeId: userId!.toString()
       })

      if (data.getNotificationPreferences.success) {
         const isUserSubscribed = data.getNotificationPreferences.notifStatus === 'active'
         setIsSubscribed(isUserSubscribed)
         if (!isUserSubscribed) {
          redirectToEmailNotifications()
          return
         }
       }
     }
    if (userId) {
      getNotificationPreferences()
    }
  }, [userId])

  useEffect(() => {
    const { loggedIn, redirectTo } = loginStatus
    if (loggedIn) {
      if (!isManage) {
        const userUnsubcribed = localStorage.getItem('@peachjar/unsubcribed')
        if (userUnsubcribed) {
          showSuccessMessage(false)
          localStorage.removeItem('@peachjar/unsubcribed')
        }
      }

      if (redirectTo && redirectTo.includes(APP_ROUTES.parentAccountInfo)) {
        setDismissable(false)
        return
      }
      setDismissable(true)

      const originUri = document.referrer
      const params = new URLSearchParams(window.location.search)
      const flyerParam = params.get('flyer')
      const resourceParam = params.get('resource')
      const resourceIdParam = params.get('resourceId')
      if (flyerParam && resourceParam && resourceIdParam && children) {
        const activeChildren = getActiveChildren()
        const child = activeChildren!.find(({ institutionId }) => institutionId === resourceIdParam)
        if (child) {
          const url = `${REACT_APP_FLYER_BOARD_APP_URL}/flyers/${flyerParam}/${resourceParam}/${resourceIdParam}`
          setDismissLocation(url)
          return
        }
      }

      if (redirectTo) {
        setDismissLocation(redirectTo)
        return
      }

      if (
        originUri &&
        originUri.includes('app.peachjar') &&
        originUri.includes('/flyers/')
      ) {
        setDismissLocation(originUri)
        return
      }

      setDismissable(false)
    }
  }, [loginStatus])

  const unsubscribeAccount = async () => {
    setLoading(true)
    const input: ChangeNotficationPreferencesInput = {
      refId: 'parent',
      notifType: 'flyer-email',
      notifTypeId: userId!.toString(),
      notifStatus: isSubscribed ? 'unsubscribed' : 'active'
    }
    const { data } = await httpClient.changeNotficationPreferences(input)
    setLoading(false)
    const { changeNotificationPreferences: { success } } = data
    return success
  }

  const onConfirm = async () => {
    const success = await unsubscribeAccount()
    if (success) {
      localStorage.setItem('@peachjar/unsubcribed', 'true')
      window.location.href = '/parent/email-notifications'
    } else {
      showErrorMessage(false)
    }
  }

  const onChangeSubscription = async (value) => {
    const success = await unsubscribeAccount()
    if (success) {
      setIsSubscribed(value)
      setDismissable(false)
      showSuccessMessage(value)
    } else {
      showErrorMessage(value)
    }
  }

  const onCancel = () => {
    window.location.href = dismissLocation !== '' ?
                           dismissLocation : '/parent/account-information'
  }

  const showSuccessMessage = (isUserSubscribed: boolean) => {
    const message = replaceSubscriptionName(
                      isUserSubscribed ?
                      MESSAGES.subscribeSuccess
                     : MESSAGES.unsubscribeSuccess
                    )
    notifySuccess({ message })
  }

  const showErrorMessage = (isUserSubscribed: boolean) => {
    const message = replaceSubscriptionName(
                      isUserSubscribed ?
                      MESSAGES.subscribeError
                     : MESSAGES.unsubscribeError
                    )
    notifyError({
      persist: false,
      message
    })
  }
  const replaceSubscriptionName = (message: string) =>
      message.replace('@subscriptionName', unsubscribeFrom)

  const handleClosePage = () => {
    window.location.href = dismissLocation
  }

  const redirectToEmailNotifications = () => {
    if (isManage) {
      window.location.href = '/parent/email-notifications'
    }
  }

  const mergedProps = {
    userEmail: email,
    unsubscribeFrom,
    isManage,
    isSubscribed,
    loading,
    schoolList,
    handleCancel: onCancel,
    handleConfirm: onConfirm,
    handleChangeSubscription: onChangeSubscription
  }

  if (loadingProfile || !isReady) {
    return <LoadingSpinner/>
  }

  return (
    <>
    {dismissible && (
        <div className={styles.closeIcon} onClick={handleClosePage}>
          <CloseIcon />
        </div>
      )}
     <FlexInset>
        <PrimaryHeading>Email Notifications</PrimaryHeading>
        <EmailNotifications
          {...mergedProps}
        />
     </FlexInset>
    </>
  )
}
export default connect((state: RootState) => ({
  loginStatus: state.logIn.loginStatus,
}),
null
)(EmailNotificationsContainer)
