import React from 'react'
import { css, cx } from 'emotion'
import { toString, get } from 'lodash'
import {
  createMuiTheme,
  MuiThemeProvider,
} from '@material-ui/core/styles'
import AddIcon from '@material-ui/icons/Add'
import AutocompleteInput, {
  LookupFn,
} from '@peachjar/ui/dist/lib/components/Inputs/AutocompleteInput'
import TextInput from '@peachjar/ui/dist/lib/components/Inputs/TextInput'
import SelectInput from '@peachjar/ui/dist/lib/components/Inputs/SelectInput'
import colors from '@peachjar/ui/dist/lib/styles/colors'
import Tooltip from '@material-ui/core/Tooltip'
import IconButton from '@material-ui/core/IconButton'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline'
import CloseIcon from '@material-ui/icons/Close'
import PrimaryHeading from '../App/Typography/PimaryHeading'
import { Field, Grade, EmailState, FormSection } from './ParentSignUp.container'
import { SCHOOL_FIELD_TYPE, GRADE_FIELD_TYPE } from './ParentSignUp.constants'
import FormFooter from '../App/components/FormFooter'
import UserAlreadyExistsError from '../App/components/UserAlreadyExistsError'
import EmailBlacklistedError from '../App/components/EmailBlacklistedError'

const TextField: any = TextInput

type Props = {
  email: EmailState
  formSections: FormSection[]
  isFormValid: boolean
  lookUpSchools: (options: any, callback: any) => void
  onSchoolChange: (value: any, sectionId: string, fieldId: string) => void
  onGradeChange: (input: Grade, sectionId: string, fieldId: string) => void
  onEmailChange: (event: Event) => void
  handleSubmit: (event: any) => void
  onRemoveChild: (sectionId: string) => void
  onAddChild: () => void
  history: any
}

type FieldComponent = typeof SCHOOL_FIELD_TYPE | typeof GRADE_FIELD_TYPE

type SchoolItem = {
  description: string,
  schoolId: number,
  schoolName: string,
  districtId: number,
  districtName: string,
  gradeLevels: string,
}

const ParentSignUpForm: React.FunctionComponent<Props> = ({
  email,
  formSections,
  isFormValid,
  onGradeChange,
  onEmailChange,
  lookUpSchools,
  onSchoolChange,
  onAddChild,
  onRemoveChild,
  handleSubmit,
  history,
}) => {
  const getFieldComponent = ({
    sectionId,
    field,
  }: {
    sectionId: string
    field: Field
  }) => {
    const {
      id,
      value,
      grades,
      renderGradesInput,
      error,
      errorText,
      type,
      hasToolTip,
      toolTipText,
    } = field
    const fieldId = `${
      type === SCHOOL_FIELD_TYPE
        ? `${SCHOOL_FIELD_TYPE}-${id}`
        : `${GRADE_FIELD_TYPE}-${id}`
    }`

    if (type === SCHOOL_FIELD_TYPE) {
      const fieldValue = get(value, 'schoolName', null)

      return (
        <div className={styles.fieldGroup}>
          <div className={cx(styles.inputWithToolTip, styles.inlined)}>
            <AutocompleteInput<SchoolItem>
              lookup={lookUpSchools}
              label='School Name'
              value={fieldValue}
              onChange={val => {
                onSchoolChange(val, sectionId, id)
              }}
              isEqual={(s1, s2) => {
                // Typescript in this project does not seem to be working correctly.
                // this should be (s1?.schoolId === s2?.schoolId) || false
                return (s1 && s2 && s1.schoolId === s2.schoolId) || false
              }}
              renderOption={val => (
                <div>
                  {val.schoolName}
                  <span className={styles.secondaryName}>
                    {val.districtName}
                  </span>
                </div>
              )}
            />
            {error && (
              <span className={styles.messageError}>
                {errorText}
              </span>
            )}
          </div>
          {hasToolTip && (
            <span className={styles.tooltip}>
              <span className={`${styles.tooltip} ${styles.tooltipButton}`}>
                <Tooltip
                  title={toolTipText}
                  enterTouchDelay={0}
                  placement='right'>
                  <IconButton arial-label={toolTipText}>
                    <HelpOutlineIcon fontSize='small' />
                  </IconButton>
                </Tooltip>
              </span>
            </span>
          )}
        </div>
      )
    }

    const currentYear = new Date().getFullYear()
    const nextYear = currentYear + 1

    if (!renderGradesInput || !grades) {
      return null
    }

    return (
      <div className={cx(styles.inputWithToolTip, styles.gradeOptions)}>
        <SelectInput<Grade>
          picker={grade => [grade.description, toString(grade.id)]}
          // @ts-ignore
          options={grades}
          label={`Grade (${currentYear}/${nextYear} school year)`}
          // @ts-ignore
          value={value}
          onChange={input => onGradeChange(input, sectionId, id)}
        />
        {error && (
          <span className={styles.messageError}>
            {errorText}
          </span>
        )}
      </div>
    )
  }

  return (
    <MuiThemeProvider theme={toolTipTheme}>
      <PrimaryHeading>
        Tell us a little bit about you and your family.
      </PrimaryHeading>
      <div className={styles.pageContainer}>
        <div className={styles.emailInput}>
          <TextField
            aria-describedby='errorMessage duplicateError blacklistError'
            autoFocus
            id='emailInput'
            name='emailInput'
            label='Your Email Address'
            placeholder='example@email.com'
            type='text'
            value={email.value}
            error={email.error}
            errorText={email.errorText}
            onChange={onEmailChange}
          />
          {email.userAlreadyExists && <UserAlreadyExistsError />}
          {email.emailBlacklisted && <EmailBlacklistedError />}
        </div>
        <div className={styles.formContainer}>
          <form onSubmit={handleSubmit}>
            {formSections.map(
              ({ label, id: sectionId, renderId, fields }, index) => {
                const isRemovableSection = renderId > 1

                return (
                  <div className={styles.formSection} key={index}>
                    <h4 className={styles.formSectionHeadline}>{label}</h4>
                    {fields.map(field =>
                      getFieldComponent({ sectionId, field })
                    )}
                    {isRemovableSection && (
                      <div
                        className={cx(styles.removeIcon, styles.tooltipButton)}
                        onClick={() => onRemoveChild(sectionId)}
                      >
                        <Tooltip
                          title='Remove Child'
                          placement='right'
                          enterTouchDelay={0}
                        >
                          <IconButton arial-label='Remove Child'>
                            <CloseIcon fontSize='small' />
                          </IconButton>
                        </Tooltip>
                      </div>
                    )}
                  </div>
                )
              }
            )}
            <div className={styles.addSectionContainer}>
              <span className={styles.addIcon}>
                <AddIcon className={styles.iconOverrides} />
              </span>
              <span className={styles.addText} onClick={onAddChild} tabIndex={0}>
                Add Child
              </span>
            </div>
            <FormFooter isFormValid={isFormValid} handleSubmit={handleSubmit} />
          </form>
        </div>
      </div>
    </MuiThemeProvider>
  )
}

const styles = {
  pageContainer: css`
    margin-top: 40px;
  `,
  emailInput: css``,
  formContainer: css`
    margin: 24px 0;
  `,
  formSection: css`
    background-color: ${colors.platinum};
    padding: 24px;
    border-radius: 6px;
    margin-bottom: 24px;
    position: relative;
  `,
  formSectionHeadline: css`
    font-size: 13px;
    color: ${colors.slate};
  `,
  fieldGroup: css`
    position: relative;
    margin-top: 30px;
  `,
  inputWithToolTip: css`
    width: 90% !important;
  `,
  gradeOptions: css`
    padding: 0;
    margin-top: 24px;
  & .MuiInputLabel-root {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`,
  inlined: css`
    display: inline-block;
  `,
  tooltip: css`
    position: absolute;
    top: 3px;
  `,
  addSectionContainer: css`
    margin-bottom: 40px;
  `,
  addIcon: css``,
  addText: css`
    font-family: 'Proxima Nova', Helvetica, Arial, sans-serif;
    color: ${colors.jungle};
    font-size: 16px;
    cursor: pointer;
  `,
  iconOverrides: css`
    color: ${colors.jungle};
    font-size: 16px;
    vertical-align: bottom;
    height: 22px !important;
    width: 22px !important;
    cursor: pointer;
  `,
  linkMe: css`
    color: ${colors.jungle} !important;
    text-decoration: underline;
    cursor: pointer;
  `,
  removeIcon: css`
    position: absolute;
    top: 4px;
    right: 4px;
    cursor: pointer;
  `,
  secondaryName: css`
    font-size: 13px;
    font-family: 'Proxima Nova', Helvetica, Arial, sans-serif;
    color: ${colors.slate};
    display: block;
  `,
  tooltipButton: css`
    & .MuiIconButton-root {
      color: ${colors.prussian}
    }
    & .MuiIconButton-root:hover {
      background-color: rgba(97, 193, 108, 0.15);
    }
  `,
  messageError: css`
    color: #AC0B1F;
  `
}

const toolTipTheme = createMuiTheme({
  overrides: {
    MuiTooltip: {
      tooltip: {
        backgroundColor: '#2d344b',
        borderRadius: '2px',
        fontSize: '13px',
        padding: '8px',
      },
    },
  },
})

export default ParentSignUpForm
