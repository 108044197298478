import React from 'react'

const HouseIllustration = (props: any) => (
  <svg
    width='276px'
    height='192px'
    viewBox='0 0 276 192'
    version='1.1'
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
    {...props}
  >
    <title>DF12002D-D725-4F52-A257-817A9C2EE2CC@1.00x</title>
    <desc>Created with sketchtool.</desc>
    <g
      id='Auth,-Account-+-Help'
      stroke='none'
      stroke-width='1'
      fill='none'
      fillRule='evenodd'
    >
      <g id='Sign-Up' transform='translate(-205.000000, -332.000000)'>
        <g
          id='Illustration-/-House'
          transform='translate(205.000000, 332.000000)'
        >
          <g id='house_fancy' transform='translate(0.000000, 0.000000)'>
            <path
              d='M7.73831282,182.746677 C-28.8903644,170.474422 74.0334375,177.769131 117.624344,172.519798 C161.215251,167.270464 234.077865,160.544331 268.208906,172.519798 C285.813792,178.696777 271.635539,186.181455 245.146406,186.837429 C220.280379,187.453208 234.293465,182.064885 155.609639,186.837429 C50.8160533,193.193652 44.36699,195.018932 7.73831282,182.746677 Z'
              id='Background-Shape'
              fill='#DFE1E7'
            ></path>
            <path
              d='M158.999852,71.6667045 L182.380147,71.6667045 L182.380147,95.5123169 C182.385391,95.8804803 182.242056,96.2352838 181.982374,96.4965806 C181.722691,96.7578775 181.368569,96.9036238 181,96.9009344 L160.35,96.9009344 C159.986641,96.8956364 159.640261,96.7463752 159.387078,96.4859575 C159.133896,96.2255397 158.994657,95.8753036 158.999852,95.5123169 L158.999852,71.6667045 L158.999852,71.6667045 Z'
              id='Path'
              fill='#2D344B'
            ></path>
            <path
              d='M66.92,108.958565 L135.27,62.1864003 C135.75965,61.8569555 136.40035,61.8569555 136.89,62.1864003 L205.24,108.958565 C205.76716,109.309386 206.000477,109.964851 205.813385,110.569395 C205.626293,111.17394 205.063378,111.583493 204.43,111.575992 L67.73,111.575992 C67.0966222,111.583493 66.5337073,111.17394 66.3466151,110.569395 C66.1595228,109.964851 66.3928396,109.309386 66.92,108.958565 Z'
              id='Path'
              fill='#47506B'
            ></path>
            <path
              d='M135.36,62.1864003 C135.853584,61.8579185 136.496416,61.8579185 136.99,62.1864003 L205.34,108.958565 C205.854316,109.317926 206.076398,109.968672 205.88893,110.567043 C205.701462,111.165415 205.14763,111.573566 204.52,111.575888 L136.17,111.575888 L135.36,62.1864003 Z'
              id='Path'
              fill='#2D344B'
            ></path>
            <path
              d='M185.64,177.428574 L86.52,177.428574 C85.72471,177.428574 85.08,176.784488 85.08,175.99001 L85.08,112.43501 C85.08,111.954057 85.3206771,111.504906 85.72,111.236236 L135.28,78.1500738 C135.765068,77.8295198 136.394932,77.8295198 136.88,78.1500738 L186.44,111.236236 C186.839323,111.504906 187.080035,111.954057 187.080035,112.43501 L187.080035,175.99001 C187.082686,176.372348 186.931835,176.739804 186.661198,177.010165 C186.390561,177.280525 186.022729,177.431222 185.64,177.428574 Z'
              id='Path'
              fill='#757D99'
            ></path>
            <rect
              id='Rectangle'
              fill='#47506B'
              x='157.1'
              y='68.9195141'
              width='27.13'
              height='2.74719037'
              rx='1.37'
            ></rect>
            <path
              d='M138.26,118.618685 L175.32,118.618685 C175.717645,118.618685 176.04,118.940711 176.04,119.33795 L176.04,177.438529 L137.54,177.438529 L137.54,119.33795 C137.54,118.940711 137.862355,118.618685 138.26,118.618685 Z'
              id='Path'
              fill='#FFFFFF'
            ></path>
            <path
              d='M140.88,121.995232 L172.21,121.995232 C172.607645,121.995232 172.93,122.317258 172.93,122.714497 L172.93,177.428539 L140.16,177.428539 L140.16,122.714497 C140.16,122.317258 140.482355,121.995232 140.88,121.995232 L140.88,121.995232 Z'
              id='Path'
              fill='#DFE1E7'
            ></path>
            <rect
              id='Rectangle'
              fill='#FFFAE5'
              x='146.16'
              y='125.112044'
              width='8.69'
              height='8.68112158'
              rx='0.72'
            ></rect>
            <rect
              id='Rectangle'
              fill='#FFFAE5'
              x='158.33'
              y='125.112044'
              width='8.69'
              height='8.68112158'
              rx='0.72'
            ></rect>
            <rect
              id='Rectangle'
              fill='#FFFAE5'
              x='146.16'
              y='135.881031'
              width='8.69'
              height='8.68112158'
              rx='0.72'
            ></rect>
            <rect
              id='Rectangle'
              fill='#FFFAE5'
              x='158.33'
              y='135.881031'
              width='8.69'
              height='8.68112158'
              rx='0.72'
            ></rect>
            <path
              d='M146.88,151.455103 L151.69,151.455103 C151.880956,151.455103 152.064091,151.530882 152.199117,151.66577 C152.334143,151.800659 152.41,151.983606 152.41,152.174367 L152.41,177.428539 L146.16,177.428539 L146.16,152.174367 C146.16,151.777128 146.482355,151.455103 146.88,151.455103 Z'
              id='Path'
              fill='#F0F2F6'
            ></path>
            <path
              d='M154,151.455103 L158.81,151.455103 C159.207645,151.455103 159.53,151.777128 159.53,152.174367 L159.53,177.428539 L153.3,177.428539 L153.3,152.174367 C153.29985,151.784802 153.610187,151.465924 154,151.455103 L154,151.455103 Z'
              id='Path'
              fill='#F0F2F6'
            ></path>
            <path
              d='M161.16,151.455103 L166,151.455103 C166.190956,151.455103 166.374091,151.530882 166.509117,151.66577 C166.644143,151.800659 166.72,151.983606 166.72,152.174367 L166.72,177.428539 L160.47,177.428539 L160.47,152.174367 C160.469665,151.788544 160.774117,151.471179 161.16,151.455103 Z'
              id='Path'
              fill='#F0F2F6'
            ></path>
            <ellipse
              id='Oval'
              fill='#9EA3B7'
              cx='169.81'
              cy='148.058576'
              rx='2.1'
              ry='2.09785447'
            ></ellipse>
            <path
              d='M114.13,114.942445 C105.21,114.522874 97.85,120.626632 97.85,129.44761 L97.79,129.44761 L97.79,156.110342 C97.79,156.50758 98.112355,156.829606 98.51,156.829606 L128.19,156.829606 C128.587645,156.829606 128.91,156.50758 128.91,156.110342 L128.91,129.867181 C128.91,121.525712 122.49,115.342037 114.13,114.942445 Z'
              id='Path'
              fill='#FFFFFF'
            ></path>
            <path
              d='M114.1,117.0403 C110.383593,116.859916 106.75549,118.208508 104.061034,120.771855 C101.366578,123.335201 99.8410877,126.889436 99.84,130.606425 L99.79,130.606425 L99.79,153.842661 C99.79,154.2399 100.112355,154.561925 100.51,154.561925 L126.32,154.561925 C126.701943,154.540696 127.000856,154.224804 127,153.842661 L127,130.996027 C127.080848,123.664383 121.422332,117.542778 114.1,117.0403 Z'
              id='Path'
              fill='#DFE1E7'
            ></path>
            <path
              d='M123,129.697355 C123,124.284977 118.607913,119.897378 113.19,119.897378 C107.772087,119.897378 103.38,124.284977 103.38,129.697355 L103.38,129.697355'
              id='Path'
              fill='#FFFAE5'
            ></path>
            <rect
              id='Rectangle'
              fill='#FFFAE5'
              x='103.79'
              y='133.783176'
              width='8.02'
              height='8.01180611'
              rx='0.72'
            ></rect>
            <rect
              id='Rectangle'
              fill='#FFFAE5'
              x='115.02'
              y='133.783176'
              width='8.02'
              height='8.01180611'
              rx='0.72'
            ></rect>
            <rect
              id='Rectangle'
              fill='#FFFAE5'
              x='103.79'
              y='143.723011'
              width='8.02'
              height='8.01180611'
              rx='0.72'
            ></rect>
            <rect
              id='Rectangle'
              fill='#FFFAE5'
              x='115.02'
              y='143.723011'
              width='8.02'
              height='8.01180611'
              rx='0.72'
            ></rect>
            <g id='plant' transform='translate(191.000000, 147.885117)'>
              <path
                d='M22.5879202,0.307668757 C22.2225054,0.0638842724 21.7365742,0 21.2992874,0 C19.5913272,0 17.9859241,0.623446155 16.5990446,1.55239439 C15.8391035,2.06140037 15.1592788,2.70959922 14.5590913,3.38448764 C14.2126622,3.77406352 13.9037597,4.19551588 13.685065,4.66424129 C13.5374897,4.9806096 13.4361985,5.31024063 13.3675421,5.65024546 C13.2838682,6.06460686 13.2174011,6.48737236 13.1873319,6.90862775 C13.1754958,7.07451019 13.0775227,7.95474092 13.3578611,7.96964506 C13.3562533,7.9913447 13.355877,8.01202666 13.3575874,8.03057476 C12.748882,8.96697507 12.3727942,10.0643992 12.0240389,11.1048659 C11.7206098,12.010276 11.4502261,12.9233353 11.2747025,13.8599982 C11.0436244,15.0931682 10.8918414,16.3448863 10.8196615,17.5957837 C10.8041651,17.8646163 10.7935263,18.1335803 10.7843584,18.402774 C10.7765931,18.6323766 10.7687251,18.8619792 10.7610624,19.0915817 C10.7525103,19.3488916 10.7064657,19.645136 10.7480632,19.9009357 C10.7842558,20.12371 10.9802361,20.0899951 10.9888908,19.8683042 C10.9993586,19.6005877 10.9976482,19.332346 11.0095527,19.0644326 C11.0253912,18.708867 11.0417087,18.3533015 11.0593945,17.9978344 C11.1825107,15.528507 11.6443934,13.095488 12.4967999,10.762957 C12.841758,9.81903894 13.2370368,8.92547984 13.7450667,8.05230724 C14.1917266,7.2845798 14.7290047,6.54134242 15.2781874,5.84912055 C15.8119762,5.17643164 16.3669401,4.51930343 17.0209717,3.94936774 C17.551716,3.48687975 18.1355177,3.0839755 18.7356367,2.70907396 C19.4978697,2.23292931 20.2586317,1.7683403 21.0842872,1.40282771 C21.5134666,1.21284915 21.9507534,1.04276466 22.3832852,0.860467968 C22.4611094,0.82767229 22.5445439,0.795861468 22.619392,0.757878887 C22.8690795,0.631193683 22.7868766,0.440394406 22.5879202,0.307668757'
                id='Fill-1'
                fill='#9EA3B7'
              ></path>
              <path
                d='M16.3887553,13.7622886 C15.7265953,13.7622886 15.1373763,13.8515832 14.5272686,14.1246847 C14.0006232,14.3603874 13.5206772,14.6724186 13.0546979,15.0121317 C12.3992444,15.4899109 11.8829664,16.0459869 11.521584,16.7827767 C11.3056837,17.2229384 11.1473117,17.8457823 11.2923015,18.3258735 C11.4631021,18.8913226 11.9320654,19.3629156 12.5497102,19.2632793 C12.9316427,19.2017291 13.2817655,18.9393442 13.5720527,18.6923938 C13.9193145,18.396953 14.1972961,18.0417117 14.4268248,17.6471658 C14.801251,17.0035761 15.0212737,16.2760033 15.3393406,15.6034819 C15.4902678,15.2842959 15.636119,14.9628291 15.816887,14.6594837 C15.9063789,14.5092638 16.0016544,14.3604811 16.137415,14.2488473 C16.2100484,14.1891405 16.516302,14.0857862 16.3991842,13.9516881 C18.1163271,13.9750896 19.6375354,15.3437527 19.9671695,17.0378818 C19.9978718,17.1957876 20.0218675,17.3549744 20.0472169,17.5138488 C20.0632141,17.6141725 20.0567537,17.9841295 20.1431692,18.0482104 C20.3337509,18.1895883 20.253642,17.7601119 20.2454588,17.6982806 C20.2243241,17.5384689 20.2017742,17.3787822 20.1781168,17.2193766 C20.0494011,16.3523317 19.6774668,15.5463058 19.0572071,14.9279611 C18.347917,14.2208841 17.3909168,13.7622886 16.3887553,13.7622886'
                id='Fill-20'
                fill='#757D99'
                transform='translate(15.752727, 16.519469) scale(-1, 1) translate(-15.752727, -16.519469) '
              ></path>
              <path
                d='M2.59605156,7.95550006 C2.33628179,7.95550006 2.077304,7.96099835 1.81786034,7.97522639 C1.62009802,7.98612651 1.31788738,7.94291186 1.15054645,8.07284521 C0.976683328,8.20779454 0.988656218,8.48405966 1.01581651,8.6791526 C1.15939801,9.71085426 1.50982631,10.6256931 2.2429212,11.3566284 C3.3716805,12.4821387 4.66568429,13.0540094 6.1969097,13.3086672 C7.27251309,13.4875547 8.26547093,13.2391668 9.05726559,12.4328952 C9.63578443,13.5647237 10.0746817,14.8172252 10.294899,16.0813503 C10.4035401,16.7051172 10.4367102,17.3213601 10.4176094,17.9565094 C10.4053104,18.3633349 10.3840201,18.7691958 10.3426508,19.174092 C10.3247147,19.3500374 10.2396001,19.616367 10.3049618,19.782618 C10.3816442,19.9778556 10.6402493,19.9967621 10.7563444,19.8302699 C10.8529661,19.6917032 10.8352164,19.419586 10.8550625,19.2556018 C10.887953,18.9831951 10.918421,18.7105955 10.9402237,18.4369349 C10.97563,17.9939847 11.0018119,17.5497804 11,17.1051422 C10.9954761,16.1061409 10.7847625,15.1032329 10.496528,14.154922 C9.94358552,12.335614 9.04641079,10.71873 7.65108001,9.45011941 C6.79313164,8.67003701 5.81121495,8.35412631 4.69717719,8.18454737 C4.00358817,8.0789705 3.29867848,7.95550006 2.59605156,7.95550006'
                id='Fill-16'
                fill='#C6C9D5'
              ></path>
              <path
                d='M3,18.9442616 C3,25.0131869 3.4853581,29.933023 10,29.933023 C16.5146419,29.933023 17,25.0131869 17,18.9442616'
                id='Base'
                fill='#9EA3B7'
              ></path>
              <path
                d='M6.63488782,14.9483483 C3.72214935,14.9483483 1.76041669,17.250826 0.832879562,19.6438651 C0.483234557,20.5458896 0.401285019,21.5363854 0.266530975,22.4835316 C0.22326658,22.7874781 0.239293963,23.2506455 0.113507622,23.5214209 C0.0678581996,23.6196163 -0.036796799,23.6549229 0.0132410741,23.7886976 C0.043530921,23.8697167 0.102965802,23.9193369 0.192213524,23.9354225 C0.375431322,23.9685027 0.63449299,23.7727937 0.791570889,23.6955461 C2.09446361,23.0546638 2.95049762,22.0637137 3.64883361,20.8512902 C4.08171607,20.0998076 4.5209427,19.3196979 4.77809635,18.4946938 C4.93517425,17.9907215 5.0266162,17.4599397 5.08032701,16.9371099 C5.10145835,16.7312224 5.12192189,16.5204274 5.12068167,16.3132676 C5.11944146,16.1077436 5.02194154,15.9990971 4.95186944,15.8202008 C5.92376807,15.4593637 7.02078524,15.3610321 8.00527681,15.7349558 C8.91583254,16.0807978 9.6722201,16.7491257 10.0957533,17.5887614 C10.2057984,17.8070086 10.2906577,18.0372518 10.3524776,18.2720844 C10.3929277,18.425898 10.3948834,18.6254694 10.4698686,18.764515 C10.6517031,19.1016325 11.0342615,18.8762514 10.9975321,18.5624444 C10.895119,17.6870476 10.3845324,16.8081974 9.73194119,16.1995773 C8.90886826,15.432009 7.79320018,14.9483483 6.63488782,14.9483483'
                id='Fill-18'
                fill='#9EA3B7'
              ></path>
            </g>
            <path
              d='M61.1799384,34.8868161 C49.6192745,34.8868161 38.0574464,34.887389 26.4967825,34.8862431 C24.1103802,34.8856701 23.470708,33.9052873 24.4235228,31.7222025 C25.464227,29.3368528 28.3005534,27.9576703 30.7928887,28.7420911 C31.7829546,29.0537967 32.4581318,28.9735783 33.1461141,28.1628001 C35.6797749,25.1763858 40.7325452,24.298568 44.2399745,27.1572059 C45.5367804,28.213223 47.3126129,27.4729223 47.8603214,25.8513659 C48.3457505,24.4171765 48.1670614,22.8809953 48.941769,21.4542548 C51.076144,17.5229831 54.851316,15.5329952 59.3424086,16.3580982 C60.3301464,16.5397354 60.6421248,16.2807448 61.069349,15.4430361 C62.3277397,12.9734571 65.1605738,11.5461437 67.4136868,12.110537 C70.0625933,12.7740573 72.5223338,14.3549316 72.241204,18.0289316 C72.1684478,18.975508 72.3937009,19.4711428 73.4896998,19.7364363 C75.5623775,20.236655 77.1426415,21.5316082 78.2403865,23.3623053 C79.1227733,24.8348849 80.865429,25.0772589 82.1564145,25.9694014 C82.5766541,26.2599064 82.9869989,25.8760044 83.378136,25.6387873 C87.5991578,23.0792492 92.3230702,24.8142573 93.9632853,29.1787081 C94.0081031,29.2978896 94.1111258,29.4142062 94.1082155,29.5293768 C94.0616516,31.4460222 94.6244933,32.8343725 96.8613089,33.1334723 C97.381079,33.2028039 98.1691738,33.8462696 97.9677847,34.4943192 C97.734965,35.2466526 96.8415192,34.8696264 96.2513212,34.8707724 C84.5608603,34.8925459 72.8703993,34.8868161 61.1799384,34.8868161'
              id='Fill-43'
              fill='#DFE1E7'
            ></path>
            <path
              d='M224.924541,14.9551147 C217.978766,14.9551147 211.033574,14.955696 204.087216,14.9545333 C201.778958,14.9539519 201.518207,14.6091905 202.655128,13.1051473 C202.931629,12.7394561 203.280463,12.2981848 203.797299,12.2760922 C205.984223,12.1842333 206.730892,10.6313538 208.277898,10.0110159 C210.343489,9.18370488 210.663156,8.14476973 210.930907,6.55351874 C211.535826,2.96520997 216.698928,0.201886642 221.01561,0.0181689539 C227.516883,-0.257988963 232.039483,2.65126202 232.934903,6.20061799 C233.419071,8.11918879 234.042073,8.69999249 236.768,8.55813453 C239.210425,8.43081119 241.243932,10.0528756 241.755517,11.9324936 C241.950351,12.6505042 242.144602,12.8080595 243.154356,12.6156211 C244.755028,12.3103939 246.346367,12.3557419 247.408621,13.5376978 C247.711372,13.8737384 248.145957,14.2237322 247.951706,14.6347715 C247.710205,15.145809 247.007869,14.9458125 246.498618,14.9475567 C241.704767,14.9615099 236.910333,14.955696 232.1159,14.9551147 C229.718974,14.9551147 227.321466,14.9551147 224.924541,14.9551147'
              id='Fill-45'
              fill='#DFE1E7'
            ></path>
            <path
              d='M155.898071,47.868003 C156.845905,45.8894111 157.957753,44.9749698 159.293467,45.6784306 C160.869161,46.5073237 162.355078,47.4714754 164.087881,47.9737822 C164.318077,48.0408335 164.484969,48.4026794 164.634597,48.6552779 C164.822782,48.9720376 165.143331,49.2968897 164.928673,49.6980415 C164.731855,50.0656677 164.323256,49.9205825 164.00501,49.9211606 C152.323698,49.9275189 140.642386,49.9194265 128.961074,49.9396575 C127.926917,49.9413916 127.804338,49.6234758 128.229625,48.7442943 C128.717066,47.7391026 129.51297,47.1500915 130.529287,46.9119437 C131.107655,46.7761069 131.344757,46.5240865 131.564594,45.9917221 C132.64364,43.382501 135.287444,42.1420517 138.018724,42.9992681 C138.799665,43.2443522 139.084533,43.0455104 139.461479,42.3744192 C141.023936,39.5894774 145.773462,37.4004831 149.411715,40.4027636 C150.880943,41.6154676 151.757415,43.0229673 152.060699,44.8437573 C152.13206,45.2749667 152.214355,45.5975066 152.721938,45.5674491 C154.335038,45.4714964 155.109649,46.5709068 155.898071,47.868003'
              id='Fill-47'
              fill='#DFE1E7'
            ></path>
            <g id='mailbox' transform='translate(44.000000, 120.981834)'>
              <polygon
                id='Fill-64'
                fill='#9DA3B6'
                points='20 60.8322202 21.5 60.8322202 21.5 18.8751309 20 18.8751309'
              ></polygon>
              <path
                d='M10,7.88636938 L26,7.88636938 C28.209139,7.88636938 30,9.67723038 30,11.8863694 L30,20.8730875 L30,20.8730875 L6,20.8730875 L6,11.8863694 C6,9.67723038 7.790861,7.88636938 10,7.88636938 Z'
                id='Rectangle'
                fill='#9EA3B7'
              ></path>
              <g
                id='Group-6'
                transform='translate(23.666511, 7.492337) rotate(18.000000) translate(-23.666511, -7.492337) translate(19.666511, 0.998978)'
              >
                <path
                  d='M1.17716497,1.71571812 C1.41532292,1.65500958 1.65963666,1.78993873 1.73508514,2.02384525 L4.6192448,10.9653611 C4.68888671,11.181266 4.57031713,11.4127474 4.35441223,11.4823893 C4.34627649,11.4850136 4.33806082,11.4873832 4.32977722,11.4894948 C4.09161928,11.5502033 3.84730553,11.4152741 3.77185705,11.1813676 L0.887697387,2.2398518 C0.818055482,2.0239469 0.936625065,1.79246548 1.15252996,1.72282357 C1.1606657,1.72019932 1.16888137,1.71782969 1.17716497,1.71571812 Z'
                  id='Rectangle'
                  fill='#47506B'
                ></path>
                <ellipse
                  id='Oval'
                  fill='#757D99'
                  cx='4.08802707'
                  cy='11.3432452'
                  rx='1.25'
                  ry='1.2487229'
                ></ellipse>
                <path
                  d='M1.40973032,2.48610988 C2.07658036,2.473866 2.80769972,2.26250499 3.60308839,1.85202685 C4.48345058,1.39769622 5.3296096,1.03576356 6.14156547,0.766228863 C6.40364018,0.679256108 6.68661119,0.821180107 6.77360634,1.08324738 C6.77677772,1.09280096 6.77965985,1.10244813 6.78224881,1.11217571 L7.6299144,4.29714135 C7.69819599,4.55394545 7.55371486,4.81932893 7.30094584,4.90129559 C6.37828861,5.2004902 5.5385117,5.56050539 4.7816151,5.98134115 C4.00807618,6.41143006 3.16060503,6.61821283 2.23920163,6.60168946 C2.01593775,6.59771321 1.82239094,6.44616083 1.76495998,6.23037331 L0.935730105,3.11467689 C0.864647448,2.84780874 1.02341738,2.57387703 1.29029965,2.5028474 C1.32928887,2.49247058 1.36939065,2.48685055 1.40973032,2.48610988 Z'
                  id='Rectangle-2'
                  fill='#FFDA80'
                ></path>
              </g>
              <path
                d='M10,7.88636938 L13,7.88636938 C15.209139,7.88636938 17,9.67723038 17,11.8863694 L17,20.8730875 L17,20.8730875 L6,20.8730875 L6,11.8863694 C6,9.67723038 7.790861,7.88636938 10,7.88636938 Z'
                id='Rectangle'
                fill='#47506B'
              ></path>
              <path
                d='M3.86244499,21.5212932 L8.77860168,21.0748265 C10.1594434,20.9494237 11.5067407,21.5480515 12.3391996,22.6568626 L17,28.8649141 L17,28.8649141 L6,28.8649141 L2.52547746,24.8154459 C1.80620962,23.9771575 1.90269491,22.7145086 2.74098338,21.9952408 C3.0564656,21.7245509 3.44845404,21.5588903 3.86244499,21.5212932 Z'
                id='Rectangle'
                fill='#757D99'
                transform='translate(8.500000, 24.869001) scale(1, -1) translate(-8.500000, -24.869001) '
              ></path>
              <path
                d='M16,59.8332419 C16,59.8332419 17.8720883,47.8455021 17.9910749,47.8455021 C18.1103053,47.8455021 16.9955374,59.8332419 16.9955374,59.8332419 L16,59.8332419 Z'
                id='Fill-87'
                fill='#9DA3B6'
              ></path>
              <path
                d='M13.9927273,59.8332419 C13.9927273,59.8332419 16.8299951,51.6681353 16.9829885,51.8442231 C17.1359819,52.0203109 15.4357412,59.8332419 15.4357412,59.8332419 L13.9927273,59.8332419 Z'
                id='Fill-88'
                fill='#9DA3B6'
                transform='translate(15.492727, 55.837329) scale(-1, 1) translate(-15.492727, -55.837329) '
              ></path>
              <g
                id='mail'
                transform='translate(11.000000, 16.377685) rotate(-14.000000) translate(-11.000000, -16.377685) translate(4.000000, 11.882283)'
              >
                <rect
                  id='Rectangle'
                  fill='#FFE6AA'
                  x='0'
                  y='0'
                  width='14'
                  height='8.99080486'
                  rx='0.5'
                ></rect>
                <rect
                  id='Rectangle'
                  fill='#FFDA80'
                  opacity='0.418550037'
                  x='7'
                  y='0'
                  width='7'
                  height='8.99080486'
                  rx='0.5'
                ></rect>
                <path
                  d='M0.5,0 L13.5,0 C13.7761424,-5.07265313e-17 14,0.223857625 14,0.5 L14,1.66812583 C14,1.8682071 13.8807225,2.04902336 13.6967896,2.12777118 L7,4.99489159 L7,4.99489159 L0.303210396,2.12777118 C0.119277544,2.04902336 1.57693561e-16,1.8682071 0,1.66812583 L0,0.5 C-3.38176876e-17,0.223857625 0.223857625,5.07265313e-17 0.5,0 Z'
                  id='Rectangle'
                  fill='#FCC35D'
                ></path>
              </g>
              <path
                d='M17,9.88432602 L21,9.88432602 L21,19.8741092 L17,19.8741092 L17,11.8822827 C17,11.2162971 17,10.5503116 17,9.88432602 Z'
                id='Rectangle'
                fill='#9EA3B7'
              ></path>
              <line
                x1='27'
                y1='60.3345474'
                x2='11'
                y2='60.3345474'
                id='Stroke-86'
                stroke='#9DA3B6'
                stroke-width='3'
                stroke-linecap='round'
                stroke-linejoin='round'
              ></line>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default HouseIllustration
