import React from 'react'

const Turtle = () => (
    <svg
        version='1.1'
        xmlns='http://www.w3.org/2000/svg'
        xmlnsXlink='http://www.w3.org/1999/xlink'
        width='536'
        height='451'
        viewBox='0 0 536 451'
    >
        <defs>
            <path id='a' d='M.12.025h161.523v29.042H.12z'/>
            <path id='c' d='M0 450.558h535.762V0H0z'/>
        </defs>
        <g fill='none' fillRule='evenodd'>
            <path fill='#FEFEFE'
                  d='M412.105 181.99c-52.978-1.793-77.749 104.691-164.669 112.43-39.046 3.479-54.115-16.22-99.065-7.938-42.863 7.896-74.195 34.107-94.471 55.938h463.83c-41.433-130.137-78.505-159.513-105.625-160.43'/>
            <path fill='#DEE0E7'
                  d='M186.474 343.306H18.458c-9.96-8.54-19.462-19.445-17.348-29.688 2.918-14.129 25.323-14.357 28.747-28.7 4.212-17.652-25.88-33.429-20.32-46.016 3.261-7.384 16.69-8.916 24.284-8.412 18.517 1.227 28.034 16.25 31.72 13.854 7.23-4.698-33.408-59.828-20.816-74.22 12.86-14.7 81.165 13.157 82.275 40.573.383 9.505-7.465 15.12-4.462 19.793 7.461 11.614 59.653-17.23 72.362-1.98 7.303 8.766-4.076 25.327 5.947 34.636 4.467 4.15 7.94 1.99 18.337 6.928 6.162 2.927 17.262 9.537 19.826 15.833 4.922 12.088-16.34 35.928-52.536 57.4'/>
            <path fill='#FEFEFE'
                  d='M139.07 219.243s-77.658 44.85-55.932 85.677c6.503 12.224 18.284 26.627 32.072 37.918 15.676 0 34.446.005 47.943.005 13.88-11.037 25.786-24.863 32.294-36.894 22.05-40.761-56.376-86.706-56.376-86.706'/>
            <path fill='#EFF1F5'
                  d='M139.07 219.243s.26 111.174.26 123.598l23.823.002c13.88-11.037 25.786-24.863 32.294-36.894 22.05-40.761-56.376-86.706-56.376-86.706'/>
            <path fill='#DEE0E7'
                  d='M271.602 342.42h-22.955v-1.504c0-.82.667-1.487 1.492-1.487h19.972a1.49 1.49 0 0 1 1.491 1.487v1.503zM308.719 342.42h-34.481v-1.167c0-1.008.818-1.824 1.828-1.824h30.825c1.01 0 1.828.816 1.828 1.824v1.166z'/>
            <path fill='#EFF1F5'
                  d='M257.105 339.428c-.375 0-3.715-2.711-3.48-2.85.234-.14 4.213 1.075 4.213 1.075s-3.379-6.88-3.113-6.94c.265-.062 4.634 6.075 4.634 6.075s-.72-8.786-.163-8.786c.556 0 1.684 8.53 1.684 8.53s3.222-7.307 3.589-7.16c.368.148-2.12 7.431-2.12 7.431s5.805-4.544 6.007-4.277c.202.265-5.041 5.243-5.041 5.243s4.307-1.005 4.4-.794c.094.21-4.244 2.453-4.244 2.453h-6.366zM288.555 339.428c-.374 0-3.713-2.711-3.48-2.85.234-.14 4.213 1.075 4.213 1.075s-3.377-6.88-3.113-6.94c.266-.062 4.635 6.075 4.635 6.075s-.72-8.786-.163-8.786c.556 0 1.685 8.53 1.685 8.53s3.222-7.307 3.588-7.16c.367.148-2.12 7.431-2.12 7.431s5.805-4.544 6.008-4.277c.2.265-5.042 5.243-5.042 5.243s4.307-1.005 4.4-.794c.093.21-4.243 2.453-4.243 2.453h-6.368z'/>
            <path fill='#9DA3B6'
                  d='M206.665 342.843s36.718-37.39 36.065-38.379c-.656-.99-40.952 38.052-40.952 38.052l4.887.327z'/>
            <path fill='#9DA3B6'
                  d='M206.665 342.843s15.649-33.07 14.625-33.07c-1.023 0-19.512 32.743-19.512 32.743l4.887.327z'/>
            <path fill='#9DA3B6'
                  d='M206.665 342.843s16.505-42.047 15.589-42.795c-.916-.747-20.476 42.468-20.476 42.468l4.887.327z'/>
            <path fill='#C5C8D3'
                  d='M171.704 283.269s-4.8 8.914-4.23 9.485c.57.569 6.233-9.485 6.233-9.485a1.613 1.613 0 0 0-1.118-.36c-.463.033-.775.268-.885.36'/>
            <path fill='#C5C8D3' d='M182.914 343.082l-11.21-59.813h2.003l13.805 59.813z'/>
            <path fill='#C5C8D3' d='M187.512 343.082l-1.542-91.126h-.76l-1.283 91.126z'/>
            <path fill='#C5C8D3'
                  d='M185.719 290.374a3.172 3.172 0 0 1-3.175-3.17V254.82c0-1.75 1.421-3.17 3.175-3.17a3.172 3.172 0 0 1 3.175 3.17v32.384c0 1.75-1.422 3.17-3.175 3.17'/>
            <path fill='#9DA3B6'
                  d='M197.96 263.483a3.172 3.172 0 0 1-3.174-3.17V227.93c0-1.75 1.42-3.17 3.174-3.17a3.172 3.172 0 0 1 3.175 3.17v32.383c0 1.751-1.421 3.17-3.175 3.17M202.718 342.42s1.584-40.365.482-40.365c-1.103 0-4.168 40.364-4.168 40.364h3.685z'/>
            <path fill='#9DA3B6'
                  d='M204.323 342.42s1.692-28.837 1.125-28.837c-.569 0-3.67 28.836-3.67 28.836h2.545zM199.92 342.42l-1.492-116.276-.936.109-1.491 116.166'/>
            <path fill='#C5C8D3' d='M190.166 342.42s2.07-53.524.847-53.524c-1.225 0-5.46 53.523-5.46 53.523h4.613z'/>
            <path stroke='#47506B' strokeLinecap='round' strokeLinejoin='round' strokeWidth='3'
                  d='M357.339 448.652h-70.6'/>
            <path fill='#FEFEFE'
                  d='M127.436 95.643c-25.275 0-50.552.002-75.827 0-5.217-.002-6.616-2.176-4.533-7.016 2.276-5.289 8.477-8.347 13.926-6.608 2.164.691 3.64.514 5.144-1.284 5.54-6.621 16.586-8.568 24.254-2.23 2.836 2.342 6.718.7 7.915-2.895 1.062-3.18.671-6.586 2.365-9.75 4.666-8.716 12.92-13.128 22.738-11.298 2.16.402 2.842-.172 3.776-2.03 2.751-5.475 8.944-8.64 13.87-7.388 5.791 1.471 11.169 4.976 10.554 13.122-.159 2.099.334 3.198 2.73 3.786 4.531 1.11 7.986 3.98 10.386 8.04 1.93 3.264 5.74 3.802 8.562 5.78.918.644 1.816-.207 2.67-.733 9.229-5.675 19.557-1.828 23.143 7.848.098.265.323.523.316.778-.101 4.25 1.13 7.328 6.02 7.99 1.136.155 2.859 1.581 2.418 3.018-.509 1.668-2.462.832-3.752.835-25.559.048-51.117.035-76.675.035M398.174 32.68c-15.152 0-30.303.001-45.456-.001-5.035-.002-5.604-.755-3.124-4.042.603-.799 1.364-1.763 2.492-1.811 4.77-.2 6.4-3.594 9.774-4.95 4.506-1.808 5.203-4.078 5.787-7.555 1.32-7.841 12.583-13.88 22-14.28 14.182-.604 24.047 5.753 26 13.509 1.057 4.192 2.416 5.461 8.362 5.152 5.328-.279 9.764 3.266 10.88 7.373.425 1.569.85 1.913 3.052 1.493 3.492-.667 6.963-.568 9.28 2.015.66.734 1.609 1.499 1.185 2.397-.527 1.117-2.059.68-3.17.683-10.457.03-20.916.018-31.375.017h-15.687M246.19 123.747c2.095-4.348 4.553-6.358 7.507-4.812 3.484 1.822 6.77 3.94 10.601 5.045.51.147.878.942 1.21 1.497.415.697 1.124 1.41.65 2.292-.436.808-1.34.49-2.043.49-25.83.015-51.659-.003-77.488.041-2.287.004-2.558-.695-1.618-2.627 1.078-2.21 2.838-3.504 5.085-4.027 1.28-.299 1.803-.853 2.29-2.023 2.386-5.734 8.231-8.46 14.27-6.577 1.728.539 2.357.102 3.191-1.373 3.455-6.12 13.957-10.932 22.002-4.333 3.248 2.665 5.186 5.758 5.857 9.76.158.948.34 1.657 1.462 1.59 3.567-.21 5.28 2.206 7.023 5.057'/>
            <path fill='#EFF1F5'
                  d='M373.951 274.73s-5.417 10.732-4.788 10.732c.63 0 7.056-10.732 7.056-10.732a1.938 1.938 0 0 0-1.14-.373c-.588.002-.996.276-1.128.373M381.203 342.377s-17.416-33.477-16.882-33.991c.534-.514 21.976 34.033 21.976 34.033l-5.094-.042z'/>
            <path fill='#EFF1F5'
                  d='M389.825 342.42s-23.35-56.743-24.16-55.958c-.809.785 16.602 55.957 16.602 55.957h7.558z'/>
            <path fill='#EFF1F5' d='M386.636 342.42l-12.685-67.69h2.267l15.623 67.69z'/>
            <path fill='#EFF1F5' d='M390.329 342.42l1.12-100.143.72.098.932 100.044z'/>
            <path fill='#EFF1F5'
                  d='M391.714 270.247a2.454 2.454 0 0 0 2.456-2.45V242.76c0-1.353-1.1-2.449-2.456-2.449a2.453 2.453 0 0 0-2.455 2.45v25.034c0 1.355 1.101 2.451 2.455 2.451'/>
            <g transform='translate(374.118 313.352)'>
                <mask id='b' fill='#fff'>
                    <use xlinkHref='#a'/>
                </mask>
                <path fill='#EFF1F5'
                      d='M161.644 29.067H.12c1.03-1.664 2.9-3.863 6.41-5.095 3.873-1.358 7.45-.73 11.119-.616 9.92.308 13.011-3.37 24.511-5.758 3.953-.822 6.868-1.069 9.27-1.064.755.001 2.212-.015 10.402.626 6.617.518 6.594.593 7.687.547 2.36-.1 7.275-.306 11.364-3.161 5.503-3.842 6.034-10.062 10.656-12.44 2.28-1.174 4.76-1.69 4.76-1.69a18.331 18.331 0 0 1 2.975-.37c8.189-.388 16.537 4.758 19.124 9.284.241.424.7 1.506 1.83 2.507 1.557 1.38 3.263 2.23 5.85 3.155 7.317 2.614 13.78 3.739 15.047 3.971 5.549 1.015 12.932 3.51 20.52 10.104'
                      mask='url(#b)'/>
            </g>
            <mask id='d' fill='#fff'>
                <use xlinkHref='#c'/>
            </mask>
            <path fill='#9DA3B6' d='M454.609 380.257h3.327V241.624h-3.327z' mask='url(#d)'/>
            <path fill='#C5C8D3'
                  d='M473.024 252.93H439.52a5.075 5.075 0 0 1-5.08-5.07v-47.652a5.076 5.076 0 0 1 5.08-5.073h33.504a5.076 5.076 0 0 1 5.08 5.073v47.651a5.075 5.075 0 0 1-5.08 5.072'
                  mask='url(#d)'/>
            <path fill='#9DA3B6'
                  d='M201.136 342.42L0 341.85c1.412-2.945 4.217-7.451 9.75-9.74 10.741-4.442 21.038 3.919 30.274-.423 7.887-3.709 3.7-11.37 11.289-16.092 10.162-6.324 22.123 4.655 42.587 2.117 20.63-2.559 23.436-15.574 37.457-13.975 14.093 1.607 12.733 18.61 32.327 24.138 6.897 1.947 10.484.921 19.499 4.236a61.03 61.03 0 0 1 17.953 10.308'
                  mask='url(#d)'/>
            <path fill='#F8A91A'
                  d='M267.773 368.658c-.272-1.656 1.045-2.863 4.338-6.332 4.791-5.049 6.115-7.106 7.15-6.66.833.362 1.005 2.137 1.12 3.324.125 1.266-.067 1.928-.095 2.758-.04 1.115.448 3.521 1.962 5.333 1.83 2.189 4.323 2.706 5.504 2.951 2.141.445 2.81-.047 7.162-.23 3.909-.164 5.865-.245 6.535.51 1.864 2.097-1.618 8.405-4.661 12.2-1.413 1.762-5.612 6.657-12.944 8.397-4.922 1.169-12.672 1.239-14.275-2.125-.855-1.794.001-4.636.734-7.065.53-1.76 1.237-3.22 1.185-5.347-.036-1.478-.42-2.503-.877-3.246-.248-.407-.438-.545-.76-.987-1.295-1.765-1.941-2.65-2.078-3.481'
                  mask='url(#d)'/>
            <path stroke='#F8A91A' strokeLinecap='round' strokeLinejoin='round' strokeWidth='3'
                  d='M267.773 368.658c-.272-1.656 1.045-2.863 4.338-6.332 4.791-5.049 6.115-7.106 7.15-6.66.833.362 1.005 2.137 1.12 3.324.125 1.266-.067 1.928-.095 2.758-.04 1.115.448 3.521 1.962 5.333 1.83 2.189 4.323 2.706 5.504 2.951 2.141.445 2.81-.047 7.162-.23 3.909-.164 5.865-.245 6.535.51 1.864 2.097-1.618 8.405-4.661 12.2-1.413 1.762-5.612 6.657-12.944 8.397-4.922 1.169-12.672 1.239-14.275-2.125-.855-1.794.001-4.636.734-7.065.53-1.76 1.237-3.22 1.185-5.347-.036-1.478-.42-2.503-.877-3.246-.248-.407-.438-.545-.76-.987-1.295-1.765-1.941-2.65-2.078-3.481z'
                  mask='url(#d)'/>
            <path fill='#F8A91A'
                  d='M321.838 353.136c.434-1.62 2.131-2.181 6.562-3.993 6.447-2.636 8.5-3.969 9.259-3.135.61.67.037 2.36-.346 3.488-.409 1.206-.855 1.732-1.223 2.475-.495 1.001-1.04 3.395-.406 5.667.766 2.748 2.828 4.242 3.801 4.949 1.77 1.284 2.58 1.11 6.623 2.73 3.632 1.454 5.448 2.182 5.748 3.145.836 2.678-4.934 7-9.272 9.21-2.011 1.026-7.856 3.765-15.257 2.344-4.969-.956-12.062-4.07-12.138-7.797-.04-1.984 1.91-4.224 3.578-6.139 1.21-1.387 2.454-2.429 3.28-4.388.577-1.362.65-2.453.54-3.318-.06-.473-.177-.676-.29-1.212-.45-2.14-.677-3.21-.46-4.026'
                  mask='url(#d)'/>
            <path stroke='#F8A91A' strokeLinecap='round' strokeLinejoin='round' strokeWidth='3'
                  d='M321.838 353.136c.434-1.62 2.131-2.181 6.562-3.993 6.447-2.636 8.5-3.969 9.259-3.135.61.67.037 2.36-.346 3.488-.409 1.206-.855 1.732-1.223 2.475-.495 1.001-1.04 3.395-.406 5.667.766 2.748 2.828 4.242 3.801 4.949 1.77 1.284 2.58 1.11 6.623 2.73 3.632 1.454 5.448 2.182 5.748 3.145.836 2.678-4.934 7-9.272 9.21-2.011 1.026-7.856 3.765-15.257 2.344-4.969-.956-12.062-4.07-12.138-7.797-.04-1.984 1.91-4.224 3.578-6.139 1.21-1.387 2.454-2.429 3.28-4.388.577-1.362.65-2.453.54-3.318-.06-.473-.177-.676-.29-1.212-.45-2.14-.677-3.21-.46-4.026z'
                  mask='url(#d)'/>
            <path fill='#FED97F'
                  d='M256.578 373.876c-.494-1.603.648-2.978 3.437-6.862 4.06-5.652 5.091-7.869 6.178-7.565.873.243 1.287 1.978 1.562 3.137.296 1.24.197 1.922.28 2.746.237 2.331 1.747 4 2.67 5.018.298.33 3.546 3.851 8.375 4.029 3.004.112 4.477-1.151 7.607-2.4 5.474-2.185 13.551-3.349 14.793-1.43 3.14 4.849-32.94 36.11-38.175 31.761-1.671-1.388-1.23-7.265-.319-10.798.716-2.765 1.727-4.135 1.288-6.705-.256-1.508-1.016-3.715-2.68-5.443-.7-.728-1.226-1.02-2.484-2.321-1.522-1.574-2.284-2.362-2.532-3.167'
                  mask='url(#d)'/>
            <path stroke='#FED97F' strokeLinecap='round' strokeLinejoin='round' strokeWidth='3'
                  d='M256.578 373.876c-.494-1.603.648-2.978 3.437-6.862 4.06-5.652 5.091-7.869 6.178-7.565.873.243 1.287 1.978 1.562 3.137.296 1.24.197 1.922.28 2.746.237 2.331 1.747 4 2.67 5.018.298.33 3.546 3.851 8.375 4.029 3.004.112 4.477-1.151 7.607-2.4 5.474-2.185 13.551-3.349 14.793-1.43 3.14 4.849-32.94 36.11-38.175 31.761-1.671-1.388-1.23-7.265-.319-10.798.716-2.765 1.727-4.135 1.288-6.705-.256-1.508-1.016-3.715-2.68-5.443-.7-.728-1.226-1.02-2.484-2.321-1.522-1.574-2.284-2.362-2.532-3.167z'
                  mask='url(#d)'/>
            <path fill='#FED97F'
                  d='M332.064 344.224c.485-1.898 3.303-2.488 6.647-3.655 6.544-2.283 8.353-4.467 9.633-3.448 1.403 1.116.462 4.711.274 5.434-.532 2.033-1.27 2.29-1.853 4.521-.168.642-.687 2.628-.309 4.626.592 3.128 3.125 5.025 4.305 5.91 2.406 1.807 5.519 2.712 11.647 4.45 2.998.85 3.533.895 4.123 1.57 2.708 3.1-1.149 10.582-2.182 12.585-9.827 19.06-70.523 32.96-75.064 23.366-1.56-3.292 5.177-9.988 9.575-14.366 5.753-5.727 11.533-8.902 23.14-18.158 1.123-.895 4.887-3.95 7.073-8.296.648-1.284 1.582-3.525 2.192-6.055.233-.965.444-1.875.547-3.134.088-1.085.01-1.302.09-3.046.063-1.359.093-2.038.162-2.304'
                  mask='url(#d)'/>
            <path stroke='#FED97F' strokeLinecap='round' strokeLinejoin='round' strokeWidth='3'
                  d='M332.064 344.224c.485-1.898 3.303-2.488 6.647-3.655 6.544-2.283 8.353-4.467 9.633-3.448 1.403 1.116.462 4.711.274 5.434-.532 2.033-1.27 2.29-1.853 4.521-.168.642-.687 2.628-.309 4.626.592 3.128 3.125 5.025 4.305 5.91 2.406 1.807 5.519 2.712 11.647 4.45 2.998.85 3.533.895 4.123 1.57 2.708 3.1-1.149 10.582-2.182 12.585-9.827 19.06-70.523 32.96-75.064 23.366-1.56-3.292 5.177-9.988 9.575-14.366 5.753-5.727 11.533-8.902 23.14-18.158 1.123-.895 4.887-3.95 7.073-8.296.648-1.284 1.582-3.525 2.192-6.055.233-.965.444-1.875.547-3.134.088-1.085.01-1.302.09-3.046.063-1.359.093-2.038.162-2.304z'
                  mask='url(#d)'/>
            <path fill='#FED97F'
                  d='M267.128 389.932c-2.476 1.165-4.362 2.152-6.044 3.018-5.07 2.604-5.006 2.54-6.363 3.176-6.213 2.908-9.602 9.444-8.907 10.163.68.706 4.441-4.049 9.862-3.652 4.068.297 6.843 3.334 7.33 3.802.43.412.663.405.945.443 4.357.599 11.311-17.281 8.707-19.048-.428-.291-2.134.501-5.53 2.098'
                  mask='url(#d)'/>
            <path stroke='#FED97F' strokeWidth='3'
                  d='M267.128 389.932c-2.476 1.165-4.362 2.152-6.044 3.018-5.07 2.604-5.006 2.54-6.363 3.176-6.213 2.908-9.602 9.444-8.907 10.163.68.706 4.441-4.049 9.862-3.652 4.068.297 6.843 3.334 7.33 3.802.43.412.663.405.945.443 4.357.599 11.311-17.281 8.707-19.048-.428-.291-2.134.501-5.53 2.098z'
                  mask='url(#d)'/>
            <path fill='#FED97F'
                  d='M354.738 359.693c1.036-.294 3.44-.855 9.737.226 4.369.75 10.35 1.778 17.338 5.558 8.943 4.835 14.298 11.344 16.967 15.138 2.136 2.12 4.138 3.328 5.513 4.024 3.68 1.86 8.14 2.72 9.439 2.964 1.968.37 2.954.411 4.453 1.165.903.452 3.266 1.638 4.03 3.6 1.798 4.62-6.215 11.107-6.893 11.645-3.457 2.745-6.809 3.759-8.802 4.34-4.376 1.277-8.02 2.342-11.452.53-4.55-2.404-5.468-8.148-5.62-9.211-.967-4.96-3.251-12.571-9.544-18.156-13.286-11.795-34.31-5.543-35.79-11.593-.838-3.428 5.073-8.65 10.624-10.23'
                  mask='url(#d)'/>
            <path stroke='#FED97F' strokeLinecap='round' strokeLinejoin='round' strokeWidth='3'
                  d='M354.738 359.693c1.036-.294 3.44-.855 9.737.226 4.369.75 10.35 1.778 17.338 5.558 8.943 4.835 14.298 11.344 16.967 15.138 2.136 2.12 4.138 3.328 5.513 4.024 3.68 1.86 8.14 2.72 9.439 2.964 1.968.37 2.954.411 4.453 1.165.903.452 3.266 1.638 4.03 3.6 1.798 4.62-6.215 11.107-6.893 11.645-3.457 2.745-6.809 3.759-8.802 4.34-4.376 1.277-8.02 2.342-11.452.53-4.55-2.404-5.468-8.148-5.62-9.211-.967-4.96-3.251-12.571-9.544-18.156-13.286-11.795-34.31-5.543-35.79-11.593-.838-3.428 5.073-8.65 10.624-10.23z'
                  mask='url(#d)'/>
            <path fill='#47506B'
                  d='M274.18 412.588c4.852 2.646 5.894 7.772 7.847 11.221 6.807 12.02 20.412 16.643 25.027 18.21 2.61.886 22.967 7.453 40.295-3.81 1.74-1.133 15.36-10.24 18.876-24.986 1.2-5.036.657-10.335 4.878-15.033 4.312-4.8 8.586-3.63 9.733-6.76 2.984-8.136-21.802-27.26-29.88-22.46-1.6.95-3.359 3.319-4.667 2.752-1.259-.545-.577-3.148-1.273-4.87-3.434-8.5-43.124-2.192-43.901 6.14-.108 1.158.444 3.329-.637 4.024-1.072.691-2.943-.604-3.817-1.058-5.225-2.723-15.232 3.484-20.934 8.743-8.75 8.067-15.331 21.246-11.728 25.346 1.977 2.247 5.397-.07 10.18 2.54'
                  mask='url(#d)'/>
            <path stroke='#47506B' strokeLinecap='round' strokeLinejoin='round' strokeWidth='3'
                  d='M274.18 412.588c4.852 2.646 5.894 7.772 7.847 11.221 6.807 12.02 20.412 16.643 25.027 18.21 2.61.886 22.967 7.453 40.295-3.81 1.74-1.133 15.36-10.24 18.876-24.986 1.2-5.036.657-10.335 4.878-15.033 4.312-4.8 8.586-3.63 9.733-6.76 2.984-8.136-21.802-27.26-29.88-22.46-1.6.95-3.359 3.319-4.667 2.752-1.259-.545-.577-3.148-1.273-4.87-3.434-8.5-43.124-2.192-43.901 6.14-.108 1.158.444 3.329-.637 4.024-1.072.691-2.943-.604-3.817-1.058-5.225-2.723-15.232 3.484-20.934 8.743-8.75 8.067-15.331 21.246-11.728 25.346 1.977 2.247 5.397-.07 10.18 2.54z'
                  mask='url(#d)'/>
            <path stroke='#F8A91A' strokeLinecap='round' strokeLinejoin='round' strokeWidth='3'
                  d='M286.693 409.624c2.441-3.58 8.06-10.795 18.027-15.88 9.798-5 18.787-5.387 23.117-5.294'
                  mask='url(#d)'/>
            <path stroke='#2D344B' strokeLinecap='round'
                  d='M397.269 384.892a8.24 8.24 0 0 0 3.553 1.323c.863.104 2.11.257 3.46-.397a5.25 5.25 0 0 0 2.027-1.773'
                  mask='url(#d)'/>
            <path fill='#2D344B' d='M406.272 403.125a1.728 1.728 0 1 1 .714-3.382 1.73 1.73 0 0 1-.714 3.382'
                  mask='url(#d)'/>
            <path fill='#FEFEFE'
                  d='M452.792 224.24c-.636-.693.014.035 0 0m16.86-11.533c-5.64-1.853-4.137 7.52-5.485 7.124 0 0-1.99-5.37-4.348-7.046-2.983-2.12-6.486-2.225-9.574-.14-2.697 1.82-4.455 6.07-5.008 7.744-.104.312-.312.993-.936 1.471-1.027.789-2.302.35-2.388.66-.16.582 4.313 2.4 4.302 2.389-.118-.12 2.721 4.121 4.196 3.844 1.475-.277.1-2.165 1.697-3.242 1.044-.705 3.38-.918 4.572-.58.415.119 4.718 4.968 6.098 2.692.472-.778-1.477-2.084-.842-3.483.52-1.14 3.651-2.084 4.583-3.145 1.673-1.906 6.063-7.325 3.133-8.288'
                  mask='url(#d)'/>
            <path stroke='#9DA3B6' strokeLinecap='round' strokeLinejoin='round' strokeWidth='3'
                  d='M497.71 378.585h-55.23' mask='url(#d)'/>
            <path fill='#9DA3B6' d='M455.392 376.713s9.77-40.87 10.391-40.87c.622 0-5.195 40.87-5.195 40.87h-5.196z'
                  mask='url(#d)'/>
            <path fill='#9DA3B6'
                  d='M459.506 376.713s10.219-25.507 10.77-24.957c.55.55-5.573 24.957-5.573 24.957h-5.197z'
                  mask='url(#d)'/>
            <path fill='#FEFEFE' d='M467.898 237.813h-23.25a1.14 1.14 0 1 1 0-2.279h23.25a1.14 1.14 0 1 1 0 2.28'
                  mask='url(#d)'/>
        </g>
    </svg>
)

export default Turtle
