import React from 'react'
import { css } from 'emotion'
import { colors } from '@peachjar/components'
import PrimaryHeading from '../App/Typography/PimaryHeading'
import {
    ButtonFlatSmall,
    ButtonSecondarySmall,
} from '@peachjar/ui/dist/lib/components/Buttons/Buttons'

type Props = {
    email: string
    resendMagicLinkHandler: () => void
    updateEmailHandler: () => void
}

const MagicLinkSuccess = ({
    email,
    resendMagicLinkHandler,
    updateEmailHandler,
}: Props) => (
    <div>
        <PrimaryHeading>Your email is on its way!</PrimaryHeading>
        <p className={styles.emailConfirmation}>
            We sent an email to {email} with a magic log in link that will
            safely log you into your account automatically.
        </p>
        <p className={styles.disclaimer}>
            Note: The magic log in link will expire in 72 hours.
        </p>
        <div className={styles.ctaSection}>
            <p className={styles.ctaSectionText}>
                Didn't get the email? Resend it or update your email address if
                it's not correct.
            </p>
            <div className={styles.ctaSectionOptions}>
                <ButtonFlatSmall
                    className={styles.ctaLeft}
                    onClick={updateEmailHandler}
                >
                    Update Email
                </ButtonFlatSmall>
                <ButtonSecondarySmall onClick={resendMagicLinkHandler}>
                    Resend
                </ButtonSecondarySmall>
            </div>
        </div>
    </div>
)

const styles = {
    emailConfirmation: css`
        font-size: 16px;
        font-family: 'Proxima Nova', Helvetica, Arial, sans-serif;
        color: ${colors.prussian};
        padding-top: 36px;
    `,
    disclaimer: css`
        font-size: 13px;
        font-family: 'Proxima Nova', Helvetica, Arial, sans-serif;
        color: ${colors.slate};
        padding-top: 4px;
    `,
    ctaSection: css`
        margin-top: 40px;
        background-color: ${colors.platinum};
        padding: 24px;
    `,
    ctaSectionText: css`
        font-size: 13px;
        font-family: 'Proxima Nova', Helvetica, Arial, sans-serif;
        color: ${colors.prussian};
    `,
    ctaSectionOptions: css`
        text-align: right;
    `,
    ctaLeft: css`
        margin-right: 6px !important;
    `,
}

export default MagicLinkSuccess
