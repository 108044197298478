import React, { useState, useEffect } from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { get, debounce, sortBy } from 'lodash'
import TagManager from 'react-gtm-module'
import useGlobalNotifications, { Message } from '@peachjar/ui/dist/lib/hooks/useGlobalNotifications'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import { ActionResults, RootState } from '../App/Store/makeStore'
import shortid from 'shortid'
import axios from 'axios'
import AccountsBffClientHttp from '../App/Dependencies/AccountsBffClientHttp'
import ParentSignUp from './ParentSignUp'
import { APP_ROUTES } from '../App/config'
import {
  numberToStringMapping,
  SCHOOL_FIELD_TYPE,
  GRADE_FIELD_TYPE,
  SCHOOL_NAME_TOOLTIP,
  convertToDescriptionApi,
} from './ParentSignUp.constants'
import { EMAIL_STRUCTURE_ERROR } from '../LogIn/LoginForm.constants'
import emailIsValidish from '../Utility/formUtils'
import { setEmail, submitEmail } from '../LogIn/Redux/LogInActions'
import config from '../config'
import { ErrorCodes } from './ErrorCodes'

const parentRedirectUrl = config.REACT_APP_PARENT_HOMEPAGE_URL || ''

type Props = RouteComponentProps & {
  parentEmail: string
  setUserEmail: (email: string) => void
  requestMagicLink: (email: string, destination: string) => Promise<{ success: boolean }>
}

export type EmailState = {
  value: string
  error: boolean
  errorText: string
  userAlreadyExists: boolean
  emailBlacklisted: boolean
}

export type Enrollment = {
  institutionType: string
  institutionId: number
  hierarchyType: string
  hierarchyId: number
  gradeLevel: string
  schoolYear: string
}

export type Grade = {
  id: number
  description: string
}

export type School = {
  schoolId: number
  schoolName: string
  districtId: number
  districtName: string
  gradeLevels: string[]
  description: string
}

export type Field = {
  id: string
  renderId: number
  value: Grade | School | string | null
  grades: Grade[] | null
  renderGradesInput: boolean | null
  dirty: boolean
  error: boolean
  errorText: string
  hasToolTip: boolean
  toolTipText: string
  type: string
}

export type FormSection = {
  label: string
  id: string
  renderId: number
  fields: Field[]
}

const initialFormState = [
  {
    label: 'Child',
    id: shortid.generate(),
    renderId: 1,
    fields: [
      {
        id: shortid.generate(),
        renderId: 1,
        value: null,
        grades: null,
        renderGradesInput: null,
        error: false,
        dirty: false,
        errorText: '',
        hasToolTip: true,
        toolTipText: SCHOOL_NAME_TOOLTIP,
        type: SCHOOL_FIELD_TYPE,
      },
      {
        id: shortid.generate(),
        renderId: 2,
        value: null,
        grades: null,
        renderGradesInput: false,
        error: false,
        dirty: false,
        errorText: '',
        hasToolTip: false,
        toolTipText: '',
        type: GRADE_FIELD_TYPE,
      },
    ],
  },
]

const httpClient = new AccountsBffClientHttp(axios)

export const getSchoolYear = () => {
  const currentYear = new Date().getFullYear()
  const nextYear = currentYear + 1

  return `${currentYear}-${nextYear}`
}

const YOU_MISSED_THIS_FIELD = 'You missed this field.'

const ParentSignUpContainer: React.FunctionComponent<Props> = ({
  parentEmail,
  setUserEmail,
  requestMagicLink,
  history,
}) => {
  const [emailState, setEmailState] = useState<EmailState>({
    value: '',
    error: false,
    errorText: EMAIL_STRUCTURE_ERROR,
    userAlreadyExists: false,
    emailBlacklisted: false
  })
  const [formState, setFormState] = useState<FormSection[]>(initialFormState)
  const [formValidState, setFormValidState] = useState<boolean>(false)
  const { notifyError } = useGlobalNotifications()

  const checkForm = () => {
    const emailValid =
      emailState.value !== '' && emailIsValidish(emailState.value)
    const fieldsValid: boolean[] = []
    formState.forEach(section => {
      const isSectionValid = section.fields.every(f => f.value !== null)
      fieldsValid.push(isSectionValid)
    })
    setFormValidState(emailValid && fieldsValid.every(f => f === true))
  }

  const handleFieldChange = async (
    value: any,
    sectionId: string,
    fieldId: string
  ) => {
    const gradeLevels: string[] = get(value, 'gradeLevels', null)

    const sectionsToPersist = formState.filter(
      section => section.id !== sectionId
    )
    const targetSection = formState.filter(
      section => section.id === sectionId
    )[0]
    // @ts-ignore
    const fieldsToPersist = targetSection.fields.filter(
      field => field.id !== fieldId
    )[0]
    const fieldToUpdate = targetSection.fields.filter(
      field => field.id === fieldId
    )[0]

    const updatedFieldsToPersist = {
      ...fieldsToPersist,
      grades:
        Array.isArray(gradeLevels) && gradeLevels.length > 0
          ? gradeLevels.map(grade => convertToDescriptionApi(grade))
          : null,
      renderGradesInput:
        Array.isArray(gradeLevels) &&
        gradeLevels.length > 0 &&
        fieldsToPersist.type === GRADE_FIELD_TYPE,
      value: fieldsToPersist.type === GRADE_FIELD_TYPE ? null : fieldsToPersist.value
    }

    const updatedField = {
      ...fieldToUpdate,
      value,
      dirty: true,
      error: fieldToUpdate.dirty && value === null,
      errorText: YOU_MISSED_THIS_FIELD
    }

    const updatedSection = {
      ...targetSection,
      fields: sortBy([updatedFieldsToPersist, updatedField], r => r.renderId),
    }

    setFormState(
      sortBy([...sectionsToPersist, { ...updatedSection }], s => s.renderId)
    )
  }

  const lookUpSchools = async (options, callback) => {
    try {
      const searchTerm = `parentRole-${options.input}` || 'San Diego'
      const res = await httpClient.searchForMySchool(searchTerm)
      const results = res.data.searchAcademicGroups.schools.reduce(
        (accum, curr) => [...accum, { ...curr, description: curr.schoolName }],
        []
      )

      callback(results)
    } catch (e) {
      // tslint:disable:no-console
      console.log('Unable to fetch school names - ', e)
    }
  }

  const handleEmailChange = (event: Event) => {
    // @ts-ignore
    const value = event.target.value

    setEmailState({
      value,
      error: !emailIsValidish(value),
      userAlreadyExists: false,
      emailBlacklisted: false,
      errorText:
        value.length === 0 ? YOU_MISSED_THIS_FIELD : EMAIL_STRUCTURE_ERROR,
    })

    if (emailIsValidish(value)) {
      setUserEmail(value)
      return
    }

    setUserEmail('')
  }

  const handleSectionRemoval = (sectionId: string) => {
    const sectionsToPersist = formState.filter(({ id }) => id !== sectionId)

    setFormState([])

    sectionsToPersist.forEach((section, index) => {
      const childNum = index + 1
      section.label = 'Child'
      section.renderId = childNum
    })

    setFormState([...sectionsToPersist])
  }

  const showError = (msg?: Message) => {
    const message = msg || 'Oops. Something went wrong. Please try again.'

    notifyError({
      persist: false,
      message,
    })
  }
  const setErrorMessageForEmptyFields = () => {
    if (emailState.value === '') {
      setEmailState({
        ...emailState,
        error: true,
        errorText: YOU_MISSED_THIS_FIELD,
      })
    }
    const newFormState = formState.map(section => {
      const fields: Field[] = []
      section.fields.forEach(field => {
        if (field.value == null) {
          fields.push({
            ...field,
            error: true,
            errorText: YOU_MISSED_THIS_FIELD
          })
        } else {
          fields.push(field)
        }
      })
      section.fields = fields
      return section
    })
    setFormState(newFormState)
  }

  const handleSubmit = async (event: Event) => {
    if (!formValidState) {
      setErrorMessageForEmptyFields()
      return
    }
    event.preventDefault()

    // prep input for mutation
    const { value: email } = emailState
    const enrollments = formState.map(section => {
      const schoolField = section.fields.find(
        field => field.type === SCHOOL_FIELD_TYPE
      )

      const gradeField = section.fields.find(
        field => field.type === GRADE_FIELD_TYPE
      )

      const {
        // @ts-ignore
        value: { schoolId: institutionId, districtId: hierarchyId },
      } = schoolField

      let gradeLevel = 'N'
      if (gradeField && gradeField.value !== null) {
        const {
          // @ts-ignore
          value: { description },
        } = gradeField
        gradeLevel = description
      }

      return {
        schoolType: 'peachjar-school',
        institutionType: 'school',
        institutionId: institutionId.toString(),
        hierarchyType: 'district',
        hierarchyId: hierarchyId.toString(),
        gradeLevel,
        schoolYear: getSchoolYear(),
        status: 'active',
      }
    })

    try {
      const response = await httpClient.attemptParentRegistration(
        email,
        enrollments
      )
      const successful = get(response, 'data.registerFamilyNewModel.success', false)

      // register family already sent out welcome parent email with the magic link
      // request another magic link should not be occurred
      if (successful && parentEmail) {
        // try {
          // const {
          //   success: magicLinkRequestSuccessful,
          // } = await requestMagicLink(email, parentRedirectUrl)

          // if (magicLinkRequestSuccessful) {
            TagManager.dataLayer({
              dataLayer: {
                event: 'signup',
                accountType: 'parent'
              }
            })
            history.push(APP_ROUTES.magicLinkSuccess)
            return
          // }

        //   showError()
        // } catch (e) {
        //   showError()
        // }
      }

      const code = response.data.registerFamilyNewModel.errorCode

      if (code && code === ErrorCodes.EmailAlreadyAssociatedToParent) {
        setEmailState({
          ...emailState,
          error: true,
          errorText: '',
          userAlreadyExists: true,
          emailBlacklisted: false,
        })
      }

      if (code && code === ErrorCodes.EmailBlacklisted) {
        setEmailState({
          ...emailState,
          error: true,
          errorText: '',
          userAlreadyExists: false,
          emailBlacklisted: true,
        })
      }

    } catch (e) {
      showError()
    }
  }

  const handleAddChild = () => {
    const childNum = formState.length + 1

    const newSection = {
      label: 'Child',
      id: shortid.generate(),
      renderId: childNum,
      fields: [
        {
          id: shortid.generate(),
          renderId: 1,
          value: null,
          error: false,
          dirty: false,
          errorText: '',
          hasToolTip: true,
          toolTipText: SCHOOL_NAME_TOOLTIP,
          type: SCHOOL_FIELD_TYPE,
        },
        {
          id: shortid.generate(),
          renderId: 2,
          value: null,
          error: false,
          dirty: false,
          errorText: '',
          hasToolTip: false,
          toolTipText: '',
          type: GRADE_FIELD_TYPE,
        },
      ],
    }

    // @ts-ignore
    setFormState([...formState, newSection])
  }

  useEffect(() => {
    checkForm()
  }, [emailState, formState])

  return (
    <ParentSignUp
      email={emailState}
      goBackUrl={APP_ROUTES.signup}
      formSections={formState}
      lookUpSchools={lookUpSchools}
      handleSubmit={handleSubmit}
      isFormValid={formValidState}
      onGradeChange={handleFieldChange}
      onEmailChange={handleEmailChange}
      onSchoolChange={handleFieldChange}
      onAddChild={handleAddChild}
      onRemoveChild={handleSectionRemoval}
      history={history}
    />
  )
}

export default connect(
  (state: RootState) => ({
    parentEmail: state.logIn.email,
  }),
  (dispatch: Dispatch<ActionResults>) => ({
    setUserEmail: (email: string) => dispatch(setEmail(email)),
    requestMagicLink: (email: string, destination: string) => dispatch<any>(submitEmail(email, destination)),
  })
)(withRouter(ParentSignUpContainer))
