import React from 'react'
import colors from '@peachjar/ui/dist/lib/styles/colors'
import { css } from 'emotion'

const EmailBlacklistedError = () => (
  <span className={styles.error} id='blacklistError' role='alert'>
    Cannot sign up this email address. Contact support.{' '}
  </span>
)

const styles = {
  error: css`
    color: #AC0B1F;
    & > a {
      color: ${colors.jungle} !important;
      text-decoration: underline;
      cursor: pointer;
      &:hover {
        color: #c5e8c9 !important;
      }
    }
  `,
}

export default EmailBlacklistedError
