import React, { useState } from 'react'
import get from 'lodash/get'
import axios from 'axios'
import queryString from 'query-string'
import useGlobalNotifications from '@peachjar/ui/dist/lib/hooks/useGlobalNotifications'
import ProfileForm from './CompleteProfile.render'
import PrimaryHeading from '../App/Typography/PimaryHeading'
import AccountsBffClientHttp from '../App/Dependencies/AccountsBffClientHttp'
import config from '../config'

export type Field = {
  label: string
  alias: string
  value: string
  error: boolean
  errorText: string
  textLimit: number
}

type InputRegistry = {
  firstname: Field
  lastname: Field
}

const { REACT_APP_PEACHJAR_HOMEPAGE } = config

const inputTextLimit = 50

const initialRegistryState = {
  firstname: {
    label: 'First Name',
    alias: 'firstname',
    value: '',
    error: false,
    errorText: '',
    textLimit: inputTextLimit,
  },
  lastname: {
    label: 'Last Name',
    alias: 'lastname',
    value: '',
    error: false,
    errorText: '',
    textLimit: inputTextLimit,
  },
}

const httpClient = new AccountsBffClientHttp(axios)

const CompleteProfileContainer = () => {
  const [inputs, setInputs] = useState<InputRegistry>(initialRegistryState)
  const { notifyError } = useGlobalNotifications()
  const { destination: base64EncodedDestinationUrl }: any = queryString.parse(
    window.location.search
  )
  const TEXT_LIMIT_ERROR = `Enter ${inputTextLimit} characters or less.`
  const MISSED_FIELD_ERROR = 'You missed this field.'
  const isFormValid =
    inputs.firstname.value.length >= 1 &&
    !inputs.firstname.error &&
    inputs.lastname.value.length >= 1 &&
    !inputs.lastname.error

  const handleInputChange = (
    id: string,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.target.value
    const updates = { ...inputs }
    updates[id] = {
      ...inputs[id],
      value,
      error: value.length > inputTextLimit || value.length === 0,
      errorText:
        value.length > inputTextLimit ? TEXT_LIMIT_ERROR : MISSED_FIELD_ERROR,
    }
    setInputs(updates)
  }

  const handleProfileSubmit = async () => {
    const {
      firstname: { value: firstNameValue },
      lastname: { value: lastNameValue },
    } = inputs

    if (firstNameValue && lastNameValue) {
      try {
        const finalDestinationUrl = base64EncodedDestinationUrl
          ? atob(base64EncodedDestinationUrl)
          : REACT_APP_PEACHJAR_HOMEPAGE
        const res = await httpClient.completeMyProfile(
          firstNameValue,
          lastNameValue
        )

        const isSuccessful = get(res, 'data.updateProfile.success', false)

        if (isSuccessful) {
          window.location.href = finalDestinationUrl
          return
        }

        notifyError({
          persist: false,
          message:
            'Unfortunately, something went wrong and we were unable to save this information. Please try again.',
        })
      } catch (e) {
        notifyError({
          persist: false,
          message:
            'Unfortunately, something went wrong and we were unable to save this information. Please try again.',
        })
      }
    }
  }

  const mergedProps = {
    fields: [inputs.firstname, inputs.lastname],
    isFormValid,
    handleInputChange,
    handleFormSubmit: handleProfileSubmit,
  }

  return (
    <div>
      <PrimaryHeading>Complete your profile to access Peachjar.</PrimaryHeading>
      <ProfileForm {...mergedProps} />
    </div>
  )
}

export default CompleteProfileContainer
