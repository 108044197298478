import React, { FormEvent } from 'react'
import { css } from 'emotion'
import { AddressAndPlaceId } from '@peachjar/ui/dist/lib/components/Inputs/AddressInput'
import TwoTone from '../App/Layout/TwoTone'
import FlexInset from '../App/Layout/FlexInset'
import StaffSignUpNewForm from './StaffSignUpNewForm'
import IllustratedQuote from '../App/Layout/IllustratedQuote'
import SchoolIllustration from '../App/components/SchoolIllustrationLarge'
import PrimaryHeading from '../App/Typography/PimaryHeading'
import { Field } from './StaffSignUpNew.container'

export type FormProps = {
  fields: Field[]
  formValid: boolean
  handleAddressChange: (o: AddressAndPlaceId | null) => void
  handleFieldChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    id: string
  ) => void
  handleSubmit: (e: FormEvent<HTMLElement>) => void
}

type Props = FormProps & {
  goBackUrl: string
}

const StaffSignUpNew: React.FunctionComponent<Props> = ({
  goBackUrl,
  ...rest
}) => {
  return (
    // @ts-ignore
    <TwoTone goBackUrl={goBackUrl}>
      <FlexInset>
        <PrimaryHeading>
          Looks like your school isn't using Peachjar.
        </PrimaryHeading>
        <div className={styles.pageContainer}>
          <StaffSignUpNewForm {...rest} />
        </div>
      </FlexInset>
      <IllustratedQuote svg={<SchoolIllustration />} />
    </TwoTone>
  )
}

const styles = {
  pageContainer: css`
    margin-top: 40px;
  `,
}

export default StaffSignUpNew
