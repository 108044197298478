import { AxiosInstance } from 'axios'
import {
  GET_ACCOUNT_FEATURE_FLAGS,
  GET_ROLES_FOR_USER,
  GET_SCHOOLS_BY_SCHOOL_ID,
  GET_SUBSCRIPTIONS,
  GET_DISTRICT_NAME_BY_ID
} from './portal.graphql'
import client from './ApolloPortalBffClient'

export type Flags = {
  portal_nav: number;
  portal_nav_banner: number;
  upload_flyer: number;
  approval_center: number;
  approval_center_banner: number;
  org_uploader: number;
  managed_services: number;
  comm_free: number;
}

export type Subscription = {
  id: string;
  name?: string;
  type: string;
  audienceId: string;
  audienceType: string;
  hierarchyType: string;
  hierarchyId: string;
  status: string;
}

export type ForEntity = {
  forEntity: {
    entityType: string;
    entityId: string;
  }
}

export type GetRolesForUserResponse = {
  data: {
    entities: ForEntity[]
  }
}

export type GetSchoolByIdResponse = {
  data: {
    name: string;
    success: boolean;
  }
}

export type GetSubscriptionsResponse = {
  data: {
    subscriptions?: Subscription[],
    success: boolean;
  }
}

export type GetDistrictNameResponse = {
  data: {
    name: string;
    success: boolean;
  }
}

export default class AccountsBffClientHttp {
  constructor(protected readonly http: AxiosInstance) {}
  async getFeatureFlags(): Promise<Flags> {
    try {
      const { data, errors } = await client.query({
        query: GET_ACCOUNT_FEATURE_FLAGS,
        fetchPolicy: 'cache-first',
      })

      if (errors && errors.length > 0) {
        throw errors[0]
      }

      const {
        profile: { featureFlags },
      } = data

      return featureFlags
    } catch (e) {
      return {
        portal_nav: 0,
        portal_nav_banner: 0,
        upload_flyer: 0,
        approval_center: 0,
        approval_center_banner: 0,
        org_uploader: 0,
        managed_services: 0,
        comm_free: 0,
      }
    }
  }

  async getRolesForUser(userId: string): Promise<GetRolesForUserResponse> {
    try {
      const { data } = await client.query({
        query: GET_ROLES_FOR_USER,
        fetchPolicy: 'cache-first',
        errorPolicy: 'all',
        variables: {
          input: {
            userId
          }
        }
      })

      const {
        getRolesForUser
      } = data
      return {
        data: {
          entities: getRolesForUser || []
        }
      }

    } catch (e) {
      return {
        data: {
          entities: []
        }
      }
    }
  }

  async getSchoolBySchoolId(schoolIds: number): Promise<GetSchoolByIdResponse> {
    try {
      const { data } = await client.query({
        query: GET_SCHOOLS_BY_SCHOOL_ID,
        fetchPolicy: 'cache-first',
        errorPolicy: 'all',
        variables: {
          schoolIds
        }
      })

      const {
        getSchoolsBySchoolId
      } = data

      if (getSchoolsBySchoolId && getSchoolsBySchoolId.items.length > 0) {
        return {
          data: {
            name: getSchoolsBySchoolId.items[0].name,
            success: true
          }
        }
      }
      return {
        data: {
          name: '',
          success: false
        }
      }
    } catch (e) {
      return {
        data: {
          name: '',
          success: false
        }
      }
    }
  }

  async getSubscriptions(): Promise<GetSubscriptionsResponse> {
    try {
      const res = await client.query({
        query: GET_SUBSCRIPTIONS,
        fetchPolicy: 'no-cache',
      })

      return {
        data: {
          subscriptions: res.data.subscriptions,
          success: true,
        },
      }
    } catch (e) {
      return {
        data: {
          success: false,
        },
      }
    }
  }

  async getDistrictNameById(districtId: number): Promise<GetDistrictNameResponse> {
    try {
      const { data } = await client.query({
        query: GET_DISTRICT_NAME_BY_ID,
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
        variables: {
          input: {
            districtId
          }
        }
      })

      const {
        getDistrict
      } = data

      if (getDistrict) {
        return {
          data: {
            ...getDistrict,
            success: true
          }
        }
      }
      return {
        data: {
          success: false,
          name: '',
        }
      }
    } catch (e) {
      return {
        data: {
          success: false,
          name: '',
        }
      }
    }
  }
}
